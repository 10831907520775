import { useQuery } from '@tanstack/react-query';
import { createRef, memo, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Gradient1 from '../components/backgrounds/Gradient1';
import Grid from '../components/backgrounds/Grid';
import Banner from '../components/layout/banner/Banner';
import Footer from '../components/layout/footer/Footer';
import { Header } from '../components/layout/header';
import Navbar from '../components/layout/navbar';
import Searchbar from '../components/vault/Searchbar';
import VaultListSkeleton from '../components/vault/skeleton/VaultListSkeleton';
import { VaultListItem } from '../components/vault/vaultListItem';
import { networkFiltersV1 } from '../constants';
import { getVaults } from '../utils/api';
import { scrollToTargetAdjusted } from '../utils/scroll';

function V1VaultsPage() {
  const [searchParams, setSearchParams] = useSearchParams('sortDirection=desc&sort=tvl');

  const {
    data: vaults,
    isLoading,
    error,
    refetch,
  } = useQuery(['vaults', searchParams], getVaultsWithFilters, {
    // refetchInterval: 30000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, [searchParams, refetch]);

  async function getVaultsWithFilters() {
    return getVaults(searchParams.toString());
  }

  const { vaultId } = useParams();
  const vaultIdLowerCase = vaultId?.toLowerCase();

  // scroll to selected vault
  const [vaultRefs, setVaultRefs] = useState([]);

  useEffect(() => {
    setVaultRefs((elRefs) =>
      Array(vaults?.length ?? 0)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [vaults]);

  useEffect(() => {
    if (vaultIdLowerCase && vaultRefs.length > 0) {
      const index = vaults?.findIndex((vault) => vault.id.toLowerCase() === vaultIdLowerCase);
      // @ts-ignore
      if (index && vaultRefs[index]?.current) {
        scrollToTargetAdjusted(vaultRefs[index], 100);
      }
    }
  }, [vaultRefs]);

  return (
    <>
      <Header />

      <Gradient1 />
      <Grid opacity={0.04} />

      <div className="flex flex-col items-center w-full">
        <div className="px-4 md:px-0 max-w-screen-lg w-full">
          <div className=" w-full px-4 md:px-0 max-w-screen-lg flex flex-col gap-10">
            <Banner />

            <Navbar activeItem="deprecated-vaults" />

            <Searchbar
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              networkFilters={networkFiltersV1}
              sortByApr={false}
            />
          </div>
          <ul className={`w-full mb-20`}>
            <>
              {vaults &&
                vaults.map((vault, index) => {
                  return (
                    <li
                      key={vault.id + index}
                      className="flex items-center mt-2 mb-2 mx-2 md:mx-0"
                      // @ts-ignore
                      ref={vaultRefs[index]}
                    >
                      <VaultListItem
                        vault={vault}
                        selectedVaultId={vaultIdLowerCase}
                        refetch={refetch}
                        withNavigation={true}
                      />
                    </li>
                  );
                })}
              {isLoading && <VaultListSkeleton numberOfVaults={10} />}
              {error && <p className="text-white mt-20 text-center">Something went wrong</p>}
              {vaults && vaults.length === 0 && (
                <p className="text-white mt-20 text-center">No vaults found. Try searching for something else.</p>
              )}
            </>
          </ul>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default memo(V1VaultsPage);
