import { Explanation } from '../../../../types';
import { SetFormValue } from '../../../../types/create-vault';
import Badge from '../../../elements/buttons/Badge';
import QuestionButton from '../../../layout/sidebar/QuestionButton';
const FEE_TIERS = [3000, 10000];

interface FeeTierSelectionProps {
  setValue: SetFormValue;
  feeTier: number;
}

function FeeTierSelection({ setValue, feeTier }: FeeTierSelectionProps) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <h2 className="text-[16px] leading-[20px] trailing-[0.16px]">Fee Tier</h2>

        <QuestionButton explanation={Explanation.FEE_TIER} title="Fee Tier explained" />
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center gap-2">
        <div className="flex items-center gap-2">
          {FEE_TIERS.map((fee, index) => (
            <Badge
              key={index}
              onClick={() => {
                setValue('feeTier', fee, { shouldValidate: true });
              }}
              isActive={feeTier === fee}
            >
              {(fee / 10000).toFixed(2)}%
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeeTierSelection;
