import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { isAddress } from 'viem';
import { Explanation } from '../../../../types';
import { truncateEthAddress } from '../../../../utils/n-formatter';
import { Token, getTokenList } from '../../../../utils/tokens';
import Badge from '../../../elements/buttons/Badge';
import QuestionButton from '../../../layout/sidebar/QuestionButton';
import CustomTokenSelector from './CustomTokenSelector';
import NetworkLogo from './NetworkLogo';

interface TokenSelectorProps {
  onSelect: (token: Token) => void;
  currentChainId: number;
  selectedToken: Token | null;
}

function TokenSelector({ onSelect, currentChainId, selectedToken }: TokenSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch('');
  }, [isOpen]);

  const tokens = useMemo(
    () => getTokenList(currentChainId).sort((a, b) => a.symbol.localeCompare(b.symbol)),
    [currentChainId],
  );

  function filterTokens(search: string) {
    return tokens.filter((token) => {
      if (search === '') {
        return true;
      }
      if (token.symbol.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (token.name.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (isAddress(search) && token.address.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }

      return false;
    });
  }

  const filteredTokens = useMemo(() => filterTokens(search), [search, tokens]);

  const error = false;

  return (
    <>
      <div className="flex items-center gap-2">
        <h2 className="text-[16px] leading-[20px] trailing-[0.16px]">Your Token</h2>

        <QuestionButton explanation={Explanation.TOKEN} title="Your Token explained" />
      </div>
      <div className="max-w-[170px]">
        <Badge type="button" onClick={() => setIsOpen(true)}>
          {selectedToken && (
            <>
              <img src={selectedToken.logoURI} className="w-6 h-6 rounded-full" alt={selectedToken.name} />
              <span>{selectedToken.symbol}</span>|
              <span className="hidden sm:inline text-sm text-gray-400 whitespace-nowrap	">
                {truncateEthAddress(selectedToken.address)}
              </span>
            </>
          )}
          {!selectedToken && <span> Choose token</span>}
          <ChevronDownIcon className="w-5 h-5 ml-2 text-white" />
        </Badge>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto text-white">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-primary-black py-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 px-4 flex justify-between items-center"
                  >
                    <span> Select a token</span>
                    <NetworkLogo chainId={currentChainId} />
                  </Dialog.Title>
                  <div className="my-5 px-4">
                    <input
                      type="text"
                      placeholder="Search name or paste address"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className={`block w-full rounded-xl border-0 bg-vault-black py-2 text-white shadow-sm  sm:text-sm sm:leading-6 ${
                        error
                          ? 'ring-1 ring-red-400 focus:ring-2 focus:ring-red-400'
                          : 'ring-1 ring-inset ring-white/0 focus:ring-2 focus:ring-white/0 active:ring-white/00'
                      }`}
                    />
                  </div>
                  <div className="mt-2 h-[300px] overflow-y-scroll flex flex-col gap-5">
                    {filteredTokens.map((token, index) => (
                      <button
                        onClick={() => {
                          onSelect(token);

                          setIsOpen(false);
                        }}
                        key={index}
                        className="flex items-center justify-between hover:bg-vault-gray/20 px-4 py-2"
                      >
                        <div className="flex items-center gap-3">
                          <img src={token.logoURI} className="w-9 h-9 rounded-full" alt={token.name} />
                          <div className="flex flex-col items-start">
                            <span className="text-[14px] tracking-[-0.14px]">{token.name}</span>
                            <span className="text-xs text-[#8B8993]">{token.symbol}</span>
                          </div>
                        </div>
                      </button>
                    ))}
                    {filteredTokens.length === 0 && !isAddress(search) && (
                      <div className="flex flex-col items-center justify-center mt-5">
                        <p className="text-gray-400">No tokens found</p>
                      </div>
                    )}
                    {filteredTokens.length === 0 && isAddress(search) && (
                      <div className="flex flex-col items-center justify-center mt-5">
                        <p className="text-gray-400 mb-5">No tokens found</p>

                        <CustomTokenSelector
                          tokenAddress={search}
                          chainId={currentChainId}
                          onTokenSelect={(token) => {
                            onSelect(token);
                            setIsOpen(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default TokenSelector;
