function TokenExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/tokens.png"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />
      <h2 className="text-lg font-bold mb-1">What does Your Token mean?</h2>
      <p className="mb-4">
        In order to deposit into a vault, you usually need to have two tokens. One of the tokens is your own token that
        you issued. In this input field you need to put the address of your token.
      </p>

      <h2 className="text-lg font-bold mb-1">Where can I get the address of my token?</h2>
      <p className="mb-4">
        You can get the address of your token on the network&apos;s explorer. Make sure that you are on the correct
        network.
      </p>

      <h2 className="text-lg font-bold mb-1">Why can&apos;t I see my token logo?</h2>
      <p className="mb-4">
        If you don&apos;t see your token logo, you can still deposit into the vault. We are fetching the logo from
        public apis and sometimes they are not available.
      </p>
    </div>
  );
}

export default TokenExplanation;
