import React from 'react';
import VaultListSkeletonItem from './VaultListSkeletonItem';

interface VaultListSkeletonProps {
  numberOfVaults?: number;
}

function VaultListSkeleton({ numberOfVaults }: VaultListSkeletonProps) {
  return (
    <div className="w-full flex flex-col gap-2">
      {[...Array(numberOfVaults)].map((_, index) => (
        <VaultListSkeletonItem key={index} />
      ))}
    </div>
  );
}

export default VaultListSkeleton;
