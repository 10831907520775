import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface PriceConfirmModalProps {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  price: string;
  resetCustomTokenConfirmed: () => void;
}

function PriceConfirmModal({ isOpen, closeModal, price, children, resetCustomTokenConfirmed }: PriceConfirmModalProps) {
  function onClose() {
    resetCustomTokenConfirmed();
    closeModal();
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-50 transition-opacity" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden border border-[#1F1F21] rounded-[12px] bg-[#121312] text-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col">
                  <div className="text-[20px] tracking-[-0.2px] border-b border-[#1F1F21] pb-5">
                    Please verify that the reference price your vault will use is correct
                  </div>
                  <div className="flex flex-col gap-[10px] pt-6">
                    {' '}
                    <div className="bg-[#181818] py-[14px] px-4 rounded-[12px]">
                      <div className="flex items-center gap-1 mb-4">
                        <p className="text-[16px]">Reference Price</p>
                      </div>

                      <div>${price}</div>
                    </div>
                    {children}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PriceConfirmModal;
