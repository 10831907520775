function TwapDurationExplanation() {
  return (
    <div>
      <h2 className="text-lg font-bold mb-1">What is TWAP duration?</h2>
      <p>
        The TWAP duration is the time period over which the price of the token is averaged. For example, if you set the
        TWAP duration to 1 hour, the price of the token will be averaged over the last hour.
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Why does it matter?</h2>
      <p className="mb-2">
        The TWAP duration is important because it determines how much slippage you will experience when swapping tokens.
        The longer the TWAP duration, the less slippage you will experience.
      </p>
    </div>
  );
}

export default TwapDurationExplanation;
