// eslint-disable-next-line import/named
import { Address } from 'viem';

export * from './tokenLogos';
export * from './vaults';

export const ARRAKIS_V1_STAKING_ROUTER_ADDRESSES = (chainId: number): Address => {
  switch (chainId) {
    case 1: // mainnet
      return '0xdD92062aDF9F6EDf528babe7F04804fe86424A74';
    case 137: // polygon
      return '0xc73fb100a995b33f9fA181d420f4C8D74506dF66';
    case 10:
      return '0x9ce88a56d120300061593eF7AD074A1B710094d5';
    case 42161:
      return '0x2845c6929d621e32B7596520C8a1E5a37e616F09';
    default:
      throw new Error('Not supported chain.');
  }
};

export const ARRAKIS_V2_TERMS_ROUTER_ADDRESS = '0xB041f628e961598af9874BCf30CC865f67fad3EE';

export const ARRAKIS_V2_PALM_MANAGER = '0x0a7D53FF9C56a3bD6A4A369f14ba3Ba523B3013E';

export const ARRAKIS_V2_ROUTER_ADDRESSES = '0x6aC8Bab8B775a03b8B72B2940251432442f61B94';

// Mainnet, Goerli, Arbitrum, Optimism, Polygon Address
export const PERMIT_2_ADDRESS = '0x000000000022D473030F116dDEE9F6B43aC78BA3';

export const ARRAKIS_V2_RESOLVER_ADDRESSES = (chainId: number): Address => {
  switch (chainId) {
    case 5:
      return '0x535C5fDf31477f799366DF6E4899a12A801cC7b8';
    case 10:
      return '0x535C5fDf31477f799366DF6E4899a12A801cC7b8';
    case 137:
      return '0x535C5fDf31477f799366DF6E4899a12A801cC7b8';
    case 42161:
      return '0x535C5fDf31477f799366DF6E4899a12A801cC7b8';
    default:
      return '0x535C5fDf31477f799366DF6E4899a12A801cC7b8';
  }
};

export const ARRAKIS_V2_HELPER_ADDRESS = '0x89e4be1f999e3a58d16096fbe405fc2a1d7f07d6';

export const ARRAKIS_V2_RESOLVER_ADDRESS = '0xb11bb8ad710579Cc5ED16b1C8587808109c1f193';

export const ERROR_MESSAGE = 'An error occurred. You can find more information in the console.';

export const UNI_V3_FACTORY_CONTRACT = '0x1f98431c8ad98523631ae4a59f267346ea31f984';

export const SEPOLIA_NETWORK_ADDRESSES = {
  addresses: {
    staging: {
      arrakisV2Factory: '0xecb8ffcb2369ef188a082a662f496126f66c8288',
      arrakisV2Resolver: '0x535c5fdf31477f799366df6e4899a12a801cc7b8',
      arrakisV2Helper: '0x89e4be1f999e3a58d16096fbe405fc2a1d7f07d6',
      uniswapV3Factory: '0x0227628f3F023bb0B980b67D528571c95c6DaC1c',
      defaultFeeToken: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      palmManager: '0x0a7d53ff9c56a3bd6a4a369f14ba3ba523b3013e',
      palmTerms: '0xb041f628e961598af9874bcf30cc865f67fad3ee',
      quoter: '0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3',
      swapRouter: '0x3bFA4769FB09eefC5a80d6E87c3B9C650f7Ae48E',
      simpleManager: '0x0000000000000000000000000000000000000000',
    },
  },
  notifications: {
    managerBalanceThreshold: 0.2,
    rebalanceWindow: 600000,
    maxRebalancesInWindow: 3,
  },
};

export const BASE_NETWORK_ADDRESSES = {
  addresses: {
    production: {
      arrakisV2Factory: '0xecb8ffcb2369ef188a082a662f496126f66c8288',
      arrakisV2Resolver: '0x535c5fdf31477f799366df6e4899a12a801cc7b8',
      arrakisV2Helper: '0x89e4be1f999e3a58d16096fbe405fc2a1d7f07d6',
      uniswapV3Factory: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD',
      defaultFeeToken: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      palmManager: '0x0a7d53ff9c56a3bd6a4a369f14ba3ba523b3013e',
      palmTerms: '0xb041f628e961598af9874bcf30cc865f67fad3ee',
      quoter: '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a',
      swapRouter: '0x2626664c2603336E57B271c5C0b26F421741e481',
      simpleManager: '0x0000000000000000000000000000000000000000',
    },
  },
  notifications: {
    managerBalanceThreshold: 0.02,
    rebalanceWindow: 600000,
    maxRebalancesInWindow: 3,
  },
};

export const GNOSIS_NETWORK_ADDRESSES = {
  addresses: {
    production: {
      arrakisV2Factory: '0xecb8ffcb2369ef188a082a662f496126f66c8288',
      arrakisV2Resolver: '0x535c5fdf31477f799366df6e4899a12a801cc7b8',
      arrakisV2Helper: '0x89e4be1f999e3a58d16096fbe405fc2a1d7f07d6',
      uniswapV3Factory: '0xe32F7dD7e3f098D518ff19A22d5f028e076489B1',
      defaultFeeToken: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      palmManager: '0x0a7d53ff9c56a3bd6a4a369f14ba3ba523b3013e',
      palmTerms: '0xb041f628e961598af9874bcf30cc865f67fad3ee',
      quoter: '0x7E9cB3499A6cee3baBe5c8a3D328EA7FD36578f4',
      swapRouter: '0xc6D25285D5C5b62b7ca26D6092751A145D50e9Be',
      simpleManager: '0x0000000000000000000000000000000000000000',
    },
  },
  notifications: {
    managerBalanceThreshold: 0.02,
    rebalanceWindow: 600000,
    maxRebalancesInWindow: 3,
  },
};

export const getUniswapV3FactoryAddress = (chainId: number) => {
  switch (chainId) {
    case 1:
    case 10:
    case 137:
    case 42161:
      return UNI_V3_FACTORY_CONTRACT;
    case 100:
      return GNOSIS_NETWORK_ADDRESSES.addresses.production.uniswapV3Factory;
    case 8453:
      return BASE_NETWORK_ADDRESSES.addresses.production.uniswapV3Factory;
    case 11155111:
      return SEPOLIA_NETWORK_ADDRESSES.addresses.staging.uniswapV3Factory;
    default:
      throw new Error('Not supported chain.');
  }
};

export enum PoolCreationStatus {
  NEW = 'NEW',
  POOL_CREATED = 'POOL_CREATED',
  POOL_INITIALIZED = 'POOL_INITIALIZED',
  CARDINALITY_INCREASED = 'CARDINALITY_INCREASED',
}
