import { FeeAmount } from '@uniswap/v3-sdk';
import bigDecimal from 'js-big-decimal';
import { zeroAddress } from 'viem';
import { concentrationConfigs200Ticks, concentrationConfigs60Ticks } from '../constants/concentration-config';
import { IVault } from '../types';
import { LiquidityConcentration } from '../types/create-vault';
import tokenList from './extendedtokens-uniswap.json';
import { Token } from './tokens';

export interface GetSqrtPriceX96Args {
  ownTokenAddress: string;
  baseTokenAddress: string;
  ownTokenAmount: number;
  baseTokenAmount: number;
  ownTokenDecimals: number;
  baseTokenDecimals: number;
}

export const getSqrtPriceX96 = ({
  ownTokenAddress,
  baseTokenAddress,
  ownTokenAmount,
  baseTokenAmount,
  ownTokenDecimals,
  baseTokenDecimals,
}: GetSqrtPriceX96Args) => {
  const [tokenA] = sortTokensByHexValue(ownTokenAddress, baseTokenAddress);

  let amountA;
  let amountB;
  let tokenADecimals;
  let tokenBDecimals;

  if (tokenA === ownTokenAddress) {
    amountA = ownTokenAmount;
    amountB = baseTokenAmount;
    tokenADecimals = ownTokenDecimals;
    tokenBDecimals = baseTokenDecimals;
  } else {
    amountA = baseTokenAmount;
    amountB = ownTokenAmount;
    tokenADecimals = baseTokenDecimals;
    tokenBDecimals = ownTokenDecimals;
  }

  const partA = new bigDecimal(Math.sqrt((amountB * 10 ** tokenBDecimals) / (amountA * 10 ** tokenADecimals)));
  const partB = new bigDecimal(2 ** 96);

  return BigInt(partA.multiply(partB).getValue().split('.')[0]);
};

interface SqrtPriceX96ToUsdProps {
  sqrtPriceX96: bigint;
  ownTokenAddress: string;
  baseTokenAddress: string;
  ownTokenDecimals: number;
  baseTokenDecimals: number;
  baseTokenPrice: number;
}

export const sqrtPriceX96ToOwnTokenPriceUsd = ({
  sqrtPriceX96,
  ownTokenAddress,
  baseTokenAddress,
  ownTokenDecimals,
  baseTokenDecimals,
  baseTokenPrice,
}: SqrtPriceX96ToUsdProps) => {
  const [tokenA] = sortTokensByHexValue(ownTokenAddress, baseTokenAddress);

  const numerator = new bigDecimal(sqrtPriceX96);
  const denumerator = new bigDecimal(2 ** 96);
  const priceX = numerator.divide(denumerator);
  const priceBigDecimal = priceX.multiply(priceX);
  const price = Number(priceBigDecimal.getValue());

  const isOwnToken0 = tokenA === ownTokenAddress;

  const priceWithDecimals =
    price * 10 ** (isOwnToken0 ? ownTokenDecimals - baseTokenDecimals : baseTokenDecimals - ownTokenDecimals);

  if (tokenA === ownTokenAddress) {
    return baseTokenPrice * priceWithDecimals;
  }

  return baseTokenPrice / priceWithDecimals;
};

export const sortTokensByHexValue = (token0: string, token1: string): [string, string] => {
  const [tokenA, tokenB] = [token0, token1].sort((a, b) => (parseInt(a, 16) < parseInt(b, 16) ? -1 : 1));
  return [tokenA, tokenB];
};

export const BASE_TOKEN_SYMBOLS = ['WETH', 'USDC', 'WMATIC', 'BONSAI'];

export const getAllBaseTokens = (): Token[] => {
  return tokenList.tokens.filter((token) => BASE_TOKEN_SYMBOLS.includes(token.symbol));
};

export const getBaseTokensByChainId = (chainId: number): Token[] => {
  return getAllBaseTokens().filter((token) => token.chainId === chainId);
};

export function getNativeTokenLogoByChainId(chainId: number) {
  switch (chainId) {
    case 1:
      return 'https://assets-stage.dex.guru/icons/0x82af49447d8a07e3bd95bd0d56f35241523fbab1-arbitrum.png';
    case 10:
      return 'https://assets-stage.dex.guru/icons/0x82af49447d8a07e3bd95bd0d56f35241523fbab1-arbitrum.png';
    case 137:
      return 'https://altcoinsbox.com/wp-content/uploads/2023/03/matic-logo.webp';
    case 42161:
      return 'https://assets-stage.dex.guru/icons/0x82af49447d8a07e3bd95bd0d56f35241523fbab1-arbitrum.png';
    default:
      return 'https://assets-stage.dex.guru/icons/0x82af49447d8a07e3bd95bd0d56f35241523fbab1-arbitrum.png';
  }
}

export function getNativeSymbolByChainId(chainId: number) {
  switch (chainId) {
    case 1:
      return 'ETH';
    case 10:
      return 'ETH';
    case 100:
      return 'ETH';
    case 137:
      return 'MATIC';
    case 8453:
      return 'ETH';
    case 42161:
      return 'ETH';
    default:
      return 'ETH';
  }
}

export function getDelegateAddressByChainId(chainId: number) {
  switch (chainId) {
    case 1:
      return '0x5108EF86cF493905BcD35A3736e4B46DeCD7de58';
    case 10:
      return '0x8636600A864797Aa7ac8807A065C5d8BD9bA3Ccb';
    case 100:
      return '0x969cA3961FCeaFd3Cb3C1CA9ecdd475babcD704D';
    case 137:
      return '0xDEb4C33D5C3E7e32F55a9D6336FE06010E40E3AB';
    case 8453:
      return '0x25CF23B54e25daaE3fe9989a74050b953A343823';
    case 42161:
      return '0x77BADa8FC2A478f1bc1E1E4980916666187D0dF7';
    default:
      return zeroAddress;
  }
}

export function getTokenEstimationByNetworkFor3Months(chainId: number) {
  switch (chainId) {
    case 1:
      return {
        token: 'ETH',
        amount: 2,
      };
    case 10:
      return {
        token: 'ETH',
        amount: 0.1,
      };
    case 137:
      return {
        token: 'MATIC',
        amount: 3,
      };
    case 42161:
      return {
        token: 'ETH',
        amount: 0.1,
      };
    default:
      return {
        token: 'ETH',
        amount: 2,
      };
  }
}

export function feeTierToFeeAmount(feeTier: number) {
  switch (feeTier) {
    case 100:
      return FeeAmount.LOWEST;
    case 500:
      return FeeAmount.LOW;
    case 3000:
      return FeeAmount.MEDIUM;
    case 10000:
      return FeeAmount.HIGH;
    default:
      return FeeAmount.MEDIUM;
  }
}

export function getRatioForConcentrationConfig(ratio: number) {
  ratio = Math.round(ratio / 5.0) * 5.0;
  ratio = ratio < 50 ? 100 - ratio : ratio;
  const ratioStr = ratio > 75 ? '75' : ratio.toFixed(0);
  return ratioStr;
}

export const getConcentrationConfig = (
  feeTier: number,
  ratio: string,
  setting: number,
): LiquidityConcentration | undefined => {
  const keys = [LiquidityConcentration.risky, LiquidityConcentration.moderate, LiquidityConcentration['risk averse']];

  if (feeTier === 3000) {
    const settings = concentrationConfigs60Ticks[ratio];
    return keys.reduce((previous, current) => {
      const lt = Math.abs(settings[previous] - setting) <= Math.abs(settings[current] - setting);
      return lt ? previous : current;
    });
  } else if (feeTier === 10000) {
    const settings = concentrationConfigs200Ticks[ratio];
    return keys.reduce((previous, current) => {
      const lt = Math.abs(settings[previous] - setting) <= Math.abs(settings[current] - setting);
      return lt ? previous : current;
    });
  } else {
    return undefined;
  }
};

export const getVault01Data = (assetIsTokenZero?: boolean, vault?: IVault) => {
  if (vault === undefined || assetIsTokenZero === undefined) {
    return {
      ownTokenAddress: undefined,
      baseTokenAddress: undefined,
      baseTokenPrice: undefined,
      ownTokenDecimals: undefined,
      baseTokenDecimals: undefined,
    };
  }

  const ownTokenAddress = assetIsTokenZero ? vault?.token0 : vault?.token1;
  const baseTokenAddress = assetIsTokenZero ? vault?.token1 : vault?.token0;
  const baseTokenPrice = assetIsTokenZero ? vault.price1 ?? vault.cmcPrice1 : vault.price0 ?? vault.cmcPrice0;
  const ownTokenDecimals = assetIsTokenZero ? vault?.token0Decimals : vault?.token1Decimals;
  const baseTokenDecimals = assetIsTokenZero ? vault?.token1Decimals : vault?.token0Decimals;
  return { ownTokenAddress, baseTokenAddress, baseTokenPrice, ownTokenDecimals, baseTokenDecimals };
};
