import { FieldError, RegisterOptions, UseFormRegister } from 'react-hook-form';

interface TextInputProps {
  label?: string | React.ReactNode;
  formPath: string;
  register: UseFormRegister<any>;
  options: RegisterOptions<any, string> | undefined;
  error?: FieldError;
  after?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function TextInput({ label, formPath, register, options, error, after, onChange }: TextInputProps) {
  return (
    <div className="w-full flex flex-col gap-3">
      {label && (
        <label htmlFor={formPath} className="block font-semibold leading-6 text-white">
          {label}
        </label>
      )}
      <div>
        <div className="flex items-center">
          <input
            type="text"
            {...register(formPath, options)}
            className={`block w-full rounded-[12px] border-0 ring-0 bg-accent py-[14px] px-4 text-white text-[16px] leading-5 trailing-[-0.16px] focus:ring-0 focus:ring-white/10 active:ring-white/10 ${
              error ? 'ring-1 ring-red-400 focus:ring-2 focus:ring-red-400' : ''
            }`}
            onChange={onChange}
          />
          <div>{after}</div>
          <div></div>
        </div>
        {error && <div className="text-red-400 text-sm mt-2">{error.message}</div>}
      </div>
    </div>
  );
}

export default TextInput;
