import { numberWithCommas, shortenNumber } from '../../../utils/n-formatter';

interface ExpectedDepositResultProps {
  usdTotal: number;
  amountToken0: number;
  amountToken1: number;
  symbol0: string;
  symbol1: string;
  minReceived0: string | null;
  minReceived1: string | null;
  mintAmount: string | null;
}

function ExpectedDepositResult({
  usdTotal,
  amountToken0,
  amountToken1,
  symbol0,
  symbol1,
  minReceived0,
  minReceived1,
}: ExpectedDepositResultProps) {
  return (
    <div className="border border-vault-gray rounded-[16px] px-[16px] py-[20px] flex flex-col gap-6">
      <div className="flex flex-col sm:flex-row sm:justify-between text-[20px]">
        <div className="text-vault-gray-hover">Expected Output:</div>
        <div className="text-vault-primary">
          ${numberWithCommas(usdTotal.toFixed(2))} ({shortenNumber(amountToken0)} {symbol0.toUpperCase()} +{' '}
          {shortenNumber(amountToken1)} {symbol1.toUpperCase()})
        </div>
      </div>
      <div className="text-[16px] flex flex-col gap-2">
        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="text-vault-gray-hover"> Slippage:</div>
          <div className="text-vault-primary">1%</div>
        </div>

        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="text-vault-gray-hover">Min. Received:</div>
          {minReceived0 && minReceived1 && (
            <div className="text-vault-primary">
              ({shortenNumber(Number(minReceived0))} {symbol0.toUpperCase()} + {shortenNumber(Number(minReceived1))}{' '}
              {symbol1.toUpperCase()}){' '}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExpectedDepositResult;
