import { isAddress } from 'viem';
import { useContractRead } from 'wagmi';
import { z } from 'zod';
import { UniswapPoolAbi } from '../abis/UniswapPool';

const Slot0ResponseSchema = z.tuple([
  z.bigint(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.boolean(),
]);

interface Slot0 {
  sqrtPriceX96: bigint;
  tick: number;
  observationIndex: number;
  observationCardinality: number;
  observationCardinalityNext: number;
  feeProtocol: number;
  unlocked: boolean;
}

interface UsePoolSlot0Response {
  isLoading: boolean;
  slot: Slot0 | undefined;
}

export const usePoolSlot0 = (poolAddress: string, chainId: number): UsePoolSlot0Response => {
  const { data, isLoading } = useContractRead({
    address: poolAddress as '0x${string}',
    abi: UniswapPoolAbi,
    functionName: 'slot0',
    args: [],
    watch: true,
    chainId,
    enabled: isAddress(poolAddress),
    select: (result: unknown) => {
      const parsedSlot0 = Slot0ResponseSchema.safeParse(result);

      if (parsedSlot0.success) {
        return {
          sqrtPriceX96: parsedSlot0.data[0],
          tick: parsedSlot0.data[1],
          observationIndex: parsedSlot0.data[2],
          observationCardinality: parsedSlot0.data[3],
          observationCardinalityNext: parsedSlot0.data[4],
          feeProtocol: parsedSlot0.data[5],
          unlocked: parsedSlot0.data[6],
        };
      }
      return undefined;
    },
    onError(error) {
      console.log('error', error);
    },
  });

  return {
    isLoading,
    slot: data,
  };
};
