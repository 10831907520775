import { ApexOptions } from 'apexcharts';
import { VolumeResponse } from '../../types/charts/volume';
import { abbreviateNumber } from '../n-formatter';

function getVaultChartSeriesFromVolumeData(VolumesData: VolumeResponse['data']) {
  return VolumesData.pool[0]?.history
    .map((item) => ({
      x: new Date(item.timeAt).getTime(),
      y: item.vaultVolumeUSD,
    }))
    .reverse();
}

function getPoolChartSeriesFromVolumeData(VolumesData: VolumeResponse['data']) {
  return VolumesData.pool[0]?.history
    .map((item) => ({
      x: new Date(item.timeAt).getTime(),
      y: item.poolVolumeUSD,
    }))
    .reverse();
}

export const getVolumesChartOptions = (data: VolumeResponse['data']): ApexOptions => ({
  colors: ['#FF7200', '#2E2D31'],
  series: [
    {
      name: 'Vault - Daily Volume',
      data: getVaultChartSeriesFromVolumeData(data),
    },
    {
      name: 'Pool - Daily Volume',
      data: getPoolChartSeriesFromVolumeData(data),
    },
  ],
  grid: {
    borderColor: ' rgba(41, 40, 45, 1)',
  },

  chart: {
    stacked: true,
    animations: {
      enabled: false,
    },

    height: 350,
    type: 'bar',
    width: '19px',
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  stroke: {
    width: [1, 1, 0, 0],
    curve: 'smooth',
    //   colors: ['#FF7200'],
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontSize: '14px',
    itemMargin: {
      horizontal: 20,
    },
    labels: {
      colors: ['#F3F2F2'],
    },
    markers: {
      radius: 100,
      width: 8,
      height: 8,
      offsetX: -8,
    },
  },
  tooltip: {
    followCursor: false,
    intersect: false,
    onDatasetHover: {
      highlightDataSeries: false,
    },
    marker: {
      show: false,
    },

    custom: function ({ series, dataPointIndex }) {
      const inversedDataPointIndex = series[0].length - dataPointIndex - 1;

      return `
      <div class="bg-transparent p-1">
        <div class="bg-vault-light-gray text-primary-black rounded-lg px-2 py-1">
        <div>$${abbreviateNumber(data.pool[0]?.history[inversedDataPointIndex].poolVolumeUSD)} Pool</div>
        <div>$${abbreviateNumber(data.pool[0]?.history[inversedDataPointIndex].vaultVolumeUSD)} Vault</div>
        </div>
        </div>
        `;
    },
  },
  xaxis: {
    crosshairs: {
      show: false,
    },

    labels: {
      rotate: 0,
      style: {
        colors: '#605E67',
      },
    },

    axisTicks: {
      show: false,
    },
    type: 'datetime',
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#605E67',
      },
      formatter: function (val) {
        const decimals = val > 1000 ? 0 : 2;
        return '$ ' + abbreviateNumber(val, decimals);
      },
    },
  },
});
