import Confetti from 'react-confetti';
import { useConfetti } from '../context/ConfettiContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useWindowScrollPosition from '../hooks/useWindowScrollPosition';

function ConfettiAnimation() {
  const colors = ['#FFA760', '#6deedf', '#D5D3D3', '#616068', '#2E2D31', '#8B8993', '#181818', '#121312'];

  const { showConfetti } = useConfetti();

  const { width, height } = useWindowDimensions();
  const { scrollX, scrollY } = useWindowScrollPosition();

  const widthComplete = width + scrollX;
  const heightComplete = height + scrollY;

  return (
    showConfetti && (
      <Confetti width={widthComplete} height={heightComplete} debug={true} colors={colors} numberOfPieces={2000} />
    )
  );
}

export default ConfettiAnimation;
