interface TokenRatioInputProps {
  tokenRatio: number;
  setTokenRatio: (value: number) => void;
}

function TokenRatioInput({ tokenRatio, setTokenRatio }: TokenRatioInputProps) {
  return (
    <div className="flex justify-center items-center gap-1">
      <span className="text-vault-gray-hover whitespace-nowrap">Starting ratio:</span>
      <div className="flex items-center bg-accent py-[2px] px-[6px] rounded-[8px]">
        <span className="flex items-center gap-0 text-[#616068] w-11">
          <input
            type="text"
            pattern="\d*"
            value={isNaN(tokenRatio) ? '' : (tokenRatio * 100).toFixed(0)}
            onInput={(e) => {
              const value = (e.target as HTMLInputElement).value;

              if (value !== '100' && value.length > 2) return;

              if (isNaN(Number(value))) return;
              setTokenRatio(Number(value) / 100);
            }}
            className="w-6 text-xs bg-transparent text-[#616068] border-transparent focus:border-transparent focus:ring-0 p-0 m-0"
          />
          %
        </span>
      </div>

      <span>/</span>
      <div>{(100 - tokenRatio * 100).toFixed(0)}%</div>
    </div>
  );
}

export default TokenRatioInput;
