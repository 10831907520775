// hack: to check if transaction was successful with safe app, we watch token balances of vault and trigger success if they increase

import { useState } from 'react';
import { useContractRead } from 'wagmi';
import { tokenProxyAbi } from '../abis/token-proxy';

interface SafeAppTransactionSuccessHookProps {
  vaultId: string;
  token0: string;
  token1: string;
  chainId: number;
}

export const useSafeAppTransactionSuccess = ({
  vaultId,
  token0,
  token1,
  chainId,
}: SafeAppTransactionSuccessHookProps) => {
  const [isIncreaseLiquiditySuccess, setIsIncreaseLiquiditySuccess] = useState(false);
  const [maxVaultBalance0, setMaxVaultBalance0] = useState<bigint>(BigInt(0));
  const [maxVaultBalance1, setMaxVaultBalance1] = useState<bigint>(BigInt(0));

  const { data: token0BalanceOfVault } = useContractRead({
    address: token0 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [vaultId],
    chainId: chainId,
    watch: true,
    onSuccess: (newBalance: bigint) => {
      if (newBalance > maxVaultBalance0) {
        if (maxVaultBalance0 > BigInt(0)) {
          setIsIncreaseLiquiditySuccess(true);
        }
        setMaxVaultBalance0(newBalance);
      }
    },
  });

  const { data: token1BalanceOfVault } = useContractRead({
    address: token1 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [vaultId],
    chainId: chainId,
    watch: true,
    onSuccess: (newBalance: bigint) => {
      if (newBalance > maxVaultBalance1) {
        if (maxVaultBalance1 > BigInt(0)) {
          setIsIncreaseLiquiditySuccess(true);
        }
        setMaxVaultBalance1(newBalance);
      }
    },
  });

  return { isIncreaseLiquiditySuccess, token0BalanceOfVault, token1BalanceOfVault };
};
