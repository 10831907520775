import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from '../../../../hooks/useOutsideAlerter';
import { IVault, TemporaryVault } from '../../../../types';

interface ZapinProps {
  vault: IVault | TemporaryVault;
  symbol0: string;
  symbol1: string;
  setSelectedZapinToken: React.Dispatch<React.SetStateAction<0 | 1>>;
}

function Zapin({ vault, symbol0, symbol1, setSelectedZapinToken }: ZapinProps) {
  const [isTokenDropdownOpen, setIsTokenDropdownOpen] = useState(false);
  const tokenSelectRef = useRef(null);
  useOutsideAlerter(tokenSelectRef, () => setIsTokenDropdownOpen(false));

  function selectToken(token: 0 | 1) {
    setSelectedZapinToken(token);
    setIsTokenDropdownOpen(false);
  }

  return (
    <div className="relative">
      <ChevronDownIcon
        className="h-6 w-6 text-vault-gray ml-2"
        onClick={() => {
          setIsTokenDropdownOpen(!isTokenDropdownOpen);
        }}
      />

      {isTokenDropdownOpen && (
        <div
          ref={tokenSelectRef}
          className="absolute right-[-180px] z-50 w-48 mt-2 origin-top-right rounded-md shadow-lg"
        >
          <div className="py-1 rounded-[8px] bg-white text-vault-black-primary shadow-xs">
            <button
              onClick={() => selectToken(0)}
              type="button"
              className="w-full flex items-center px-4 py-2 text-sm leading-5 hover:bg-[#f0efef] focus:outline-grey rounded-[8px] duration-300"
            >
              <div className=" flex gap-2">
                <img
                  src={vault.token0LogoUrl}
                  alt={vault.symbol0}
                  className="rounded-full max-w-[20px] max-h-[20px] align-middle border-green mx-0.5"
                />
                <span className="mx-2">{symbol0 ?? ''}</span>
              </div>
            </button>

            <button
              onClick={() => selectToken(1)}
              type="button"
              className="w-full flex items-center px-4 py-2 text-sm leading-5 hover:bg-[#f0efef] focus:outline-grey rounded-[8px] duration-300"
            >
              <div className=" flex gap-2">
                <img
                  src={vault.token1LogoUrl}
                  alt={vault.symbol1}
                  className="rounded-full max-w-[20px] max-h-[20px] align-middle border-green mx-0.5"
                />
                <span className="mx-2">{symbol1 ?? ''}</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Zapin;
