import { Spinner } from '../spinner';

interface TransactionButtonProps {
  text: string;
  onClick: () => void;
  isError: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  useLegacyDesign?: boolean;
}

function TransactionButton({
  onClick,
  isLoading,
  isError,
  isDisabled,
  text,
  useLegacyDesign = false,
}: TransactionButtonProps) {
  return (
    <button
      type="button"
      tabIndex={-1}
      onClick={onClick}
      className={
        useLegacyDesign
          ? `flex flex-row justify-center items-center rounded-[12px] h-[64px] px-4 py-2 grow ${
              !isError
                ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
                : 'bg-vault-gray-2 text-vault-gray'
            }`
          : `rounded-md ring-1  duration-300 px-3 py-2 text-sm font-semibold shadow-sm  flex items-center gap-2 ${
              !isError
                ? 'bg-vault-orange/10 ring-vault-orange/50 hover:ring-vault-orange text-vault-orange'
                : 'bg-vault-gray/10 text-vault-gray ring-vault-gray'
            }`
      }
      disabled={isDisabled}
    >
      <div className="flex items-center gap-3 tracking-wide">{text} </div>

      {isLoading && (
        <span className="mx-2">
          <Spinner color="vault-white" />
        </span>
      )}
    </button>
  );
}

export default TransactionButton;
