interface BadgeProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {
  isActive?: boolean;
}

const Badge: React.FC<BadgeProps> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, isActive, ...rest } = props;

  return (
    <button
      {...rest}
      type="button"
      className={`flex items-center justify-center text-[16px] whitespace-nowrap leading-5 tracking-[-0.16px] rounded-[12px] gap-2 px-4 py-[14px] duration-300  disabled:text-vault-gray ${
        isActive ? 'bg-white text-primary-black' : 'bg-accent text-white hover:bg-vault-gray'
      }`}
    >
      {children}
    </button>
  );
};

export default Badge;
