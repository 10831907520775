import { ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { jsNumberForAddress } from 'react-jazzicon';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import ArrowDownIcon from '/assets/images/icon-arrow-down.svg';

interface CustomConnectButtonProps {
  fullWidth?: boolean;
}

export const CustomConnectButton = ({ fullWidth = false }: CustomConnectButtonProps) => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    type="button"
                    className={`bg-gradient-orange text-vault-black-primary text-[16px] px-[16px] py-[12px] rounded-[12px] flex items-center  justify-center gap-1 ${
                      fullWidth ? 'w-full' : ''
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      <img src={ArrowDownIcon} alt="icon down" />
                      Connect Wallet
                    </div>
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="bg-gradient-orange text-vault-black-primary text-[16px] px-[16px] py-[12px] rounded-[12px] flex items-center gap-1"
                  >
                    <img src={ArrowDownIcon} alt="icon down" />
                    Wrong Network
                  </button>
                );
              }
              return (
                <div className="flex items-center gap-2">
                  <button
                    onClick={openChainModal}
                    className="flex items-center bg-vault-black  bg-opacity-100 px-[12px] sm:px-[16px] py-[12px] rounded-[12px] text-vault-light  text-[16px]"
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 20,
                          height: 20,
                          borderRadius: 999,
                          overflow: 'hidden',
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} style={{ width: 20, height: 20 }} />
                        )}
                      </div>
                    )}
                    <span className="hidden sm:block"> {chain.name}</span>

                    <ChevronDownIcon className="h-4 w-4 ml-2 text-vault-gray hidden sm:block" />
                  </button>
                  <div className="flex items-center bg-vault-black  bg-opacity-100 px-[16px] py-[12px] rounded-[12px] text-vault-light text-[16px]">
                    {account.displayBalance ? ` ${account.displayBalance}` : ''}
                    <span className="mx-1 text-vault-gray">|</span>
                    <span className="mr-2"> {account.displayName}</span>
                    <Jazzicon diameter={20} seed={jsNumberForAddress(account.address)} />
                    <button onClick={openAccountModal}>
                      <EllipsisVerticalIcon className="h-6 w-6 text-vault-gray" />
                    </button>
                  </div>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
