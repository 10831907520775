import { LIQUID_STAKING_TOKENS } from '../constants';
import { IVault } from '../types';

export function getVaultProportions(vault: IVault) {
  const { formatted, price0, price1, amount0, amount1 } = vault;

  const formattedAmount0 = Number(formatted?.amount0 ?? amount0 ?? 0);
  const formattedAmount1 = Number(formatted?.amount1 ?? amount1 ?? 0);

  const tvl = formattedAmount0 * price0 + formattedAmount1 * price1;

  const percentage0 = ((formattedAmount0 * price0) / tvl) * 100;
  const percentage0Str = isNaN(percentage0) ? '0' : percentage0.toFixed(0);
  const percentage1 = ((formattedAmount1 * price1) / tvl) * 100;
  const percentage1Str = isNaN(percentage1) ? '0' : percentage1.toFixed(0);

  return {
    percentage0Str,
    percentage1Str,
  };
}

export function priceToTick(price: number, poolTickSpacing: number): number {
  /**
   * Converts a price to a tick index for a liquidity pool.
   *
   * @param price The price of the token relative to the base token.
   * @param poolTickSpacing The minimum price change between adjacent ticks.
   * @returns The corresponding tick index.
   */

  const ratio = Math.sqrt(price);

  // Assuming token0 is the base token in the price representation, you might include:
  // const normalizedRatio = ratio / pool.token0Price;

  const tick = Math.floor(Math.log(ratio) / Math.log(1.000000001)); // Very small base in the logarithm

  // Ensure tick is within valid pool boundaries (adjust as needed)
  const adjustedTick = Math.min(Math.max(0, tick), poolTickSpacing - 1);

  return adjustedTick;
}
export const isStableVault = (symbol0: string, symbol1: string) =>
  ((symbol0.includes('usd') || symbol0.includes('usc')) && (symbol1.includes('usd') || symbol0.includes('usc'))) ||
  (symbol0.includes('eth') && symbol1.includes('eth'));

export const isLstVault = (symbol0: string, symbol1: string) =>
  LIQUID_STAKING_TOKENS.includes(symbol0.toLowerCase()) || LIQUID_STAKING_TOKENS.includes(symbol1.toLowerCase());
