import { SimulationTab } from '../../../../types/create-vault';
import Badge from '../../../elements/buttons/Badge';

interface SimulationSwitcherProps {
  selectedTab: SimulationTab;
  setSelectedTab: (tab: SimulationTab) => void;
}

function SimulationSwitcher({ selectedTab, setSelectedTab }: SimulationSwitcherProps) {
  return (
    <div className="flex items-center gap-2">
      <Badge onClick={() => setSelectedTab('liquidity')} isActive={selectedTab === 'liquidity'}>
        Liquidity preview
      </Badge>
      <Badge onClick={() => setSelectedTab('price-impact')} isActive={selectedTab === 'price-impact'}>
        Price Impact
      </Badge>
    </div>
  );
}

export default SimulationSwitcher;
