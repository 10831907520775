interface FormSectionProps {
  title: string;
  description: string;
  children?: React.ReactNode;
}

function FormSection({ title, description, children }: FormSectionProps) {
  return (
    <div>
      <div className="max-w-[480px] flex flex-col gap-3">
        <h2 className="text-[20px] tracking-[-0.2px] leading-[26px] text-white">{title}</h2>
        <p className="text-[14px] tracking-[-0.14px] leading-5 text-primary-light mb-8">{description}</p>
      </div>
      <div className="grid grid-cols-1 gap-y-3">{children}</div>
    </div>
  );
}

export default FormSection;
