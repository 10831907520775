import { formatUnits, parseUnits } from 'viem';
import { lostPrecision } from './deposit';
import fromExponential from './from-exponential';

/***
This function formats a big number to a string with a fixed number of decimals.
When converting the returned string back to a number, it will always round down when loosing precision. 
***/

export function formatBigNumber(n: bigint, decimals: number, roundTo = 8): string {
  try {
    if (roundTo > decimals) {
      // you cannot round more than the number of decimals
      roundTo = decimals;
    }

    const str = formatUnits(n, decimals);
    if (!str.includes('.')) {
      return str;
    }

    const sides = str.split('.');
    const start = sides[0];
    const end = sides[1];

    // important: always round down
    const result = start + '.' + end.substring(0, roundTo);

    if (!lostPrecision(result)) {
      return result;
    }

    const amountAsNumber = Number(result);

    if (amountAsNumber.toString().includes('e')) {
      return result;
    }

    const possibleDecimals = amountAsNumber.toString().split('.')?.[1]?.length || null;

    if (possibleDecimals === null) {
      return result;
    }

    return formatBigNumber(n, decimals, possibleDecimals);
  } catch (error) {
    return '0';
  }
}

export const parseTokenInputAmountToBigInt = (amount: number, tokenDecimals: number) => {
  try {
    if (amount) {
      return parseUnits(fromExponential(amount), tokenDecimals);
    }
    return BigInt(0);
  } catch (error) {
    return BigInt(0);
  }
};
