import { XMarkIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { memo, useState } from 'react';
import { CONFIG_DETAILS_ASSET_CONFIG, CONFIG_DETAILS_BASE_CONFIG } from '../../../../constants';
import { ConfigDetailsResponse, ConfigDetailsResponseSchema, SimulationTab } from '../../../../types/create-vault';
import { URL } from '../../../../utils/api';
import { Spinner } from '../../../elements/spinner';
import LiquidityProfileChart from './LiquidityProfileChart';
import PriceImpactChart from './PriceImpactChart';
import SimulationSwitcher from './SimulationSwitcher';
import TokensLockedCard from './TokensLockedCard';

interface SimulatorProps {
  token0: string;
  token1: string;
  ratio: number;
  wideRangeActiveAmountInToken1BPS: number;
  feeTier: number;
  wideRangeAmount0Bps: number;
  wideRangeAmount1Bps: number;
  setPrice0: React.Dispatch<React.SetStateAction<number | null>>;
  setPrice1: React.Dispatch<React.SetStateAction<number | null>>;
  totalAmountUsd: number;
  ownTokenAddress: string;
  token0Symbol: string;
  token1Symbol: string;
  token0LogoUrl: string;
  token1LogoUrl: string;
  sqrtPriceX96?: bigint;
  token0Decimals: number;
  token1Decimals: number;
}

function Simulator({
  token0,
  token1,
  ratio,
  wideRangeActiveAmountInToken1BPS,
  feeTier,
  wideRangeAmount0Bps,
  wideRangeAmount1Bps,
  setPrice0,
  setPrice1,
  totalAmountUsd = 100000,
  ownTokenAddress,
  token0Symbol,
  token1Symbol,
  token0LogoUrl,
  token1LogoUrl,
  sqrtPriceX96,
  token0Decimals,
  token1Decimals,
}: SimulatorProps) {
  const [selectedDataPoint, setSelectedDataPoint] = useState(0);
  const [selectedSimulationTab, setSelectedSimulationTab] = useState<SimulationTab>('liquidity');

  const assetIsTokenZero = token0 === ownTokenAddress;

  const query = [
    'config-details',
    token0,
    token1,
    ratio,
    wideRangeActiveAmountInToken1BPS,
    feeTier,
    wideRangeAmount0Bps,
    wideRangeAmount1Bps,
    totalAmountUsd.toFixed(0),
    sqrtPriceX96?.toString(),
    token0Decimals,
    token1Decimals,
  ];

  const {
    data: configDetails,
    isLoading: isLoadingConfigDetails,
    isError: isErrorConfigDetails,
  } = useQuery(
    query,
    async () => {
      const response = await axios.post<ConfigDetailsResponse>(`${URL}/api/vault/strategy/config-details`, {
        token0,
        token1,
        ratio: assetIsTokenZero ? ratio : 1 - ratio,
        tvl: totalAmountUsd,
        decimals0: token0Decimals,
        decimals1: token1Decimals,
        config: {
          wideRangeActiveAmountInToken1BPS,
          feeTier,
          sqrtPriceX96: sqrtPriceX96?.toString(),
          assetIsTokenZero,
          baseConfig: {
            ...CONFIG_DETAILS_BASE_CONFIG,
            wideRangeAmountBPS: assetIsTokenZero ? wideRangeAmount1Bps : wideRangeAmount0Bps,
          },

          assetConfig: {
            ...CONFIG_DETAILS_ASSET_CONFIG,
            wideRangeAmountBPS: assetIsTokenZero ? wideRangeAmount0Bps : wideRangeAmount1Bps,
          },
        },
      });

      const parsedResponse = ConfigDetailsResponseSchema.safeParse(response.data);

      if (parsedResponse.success) {
        return parsedResponse.data;
      } else {
        throw new Error('Invalid response');
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      onSuccess(data) {
        setPrice0(data.price0);
        setPrice1(data.price1);
      },
    },
  );

  return (
    <div className="pt-12 sm:pt-0">
      <div>
        <h2 className="text-[20px] tracking-[-0.2px] mb-5">Vault Simulations</h2>
        <SimulationSwitcher selectedTab={selectedSimulationTab} setSelectedTab={setSelectedSimulationTab} />
        {selectedSimulationTab === 'liquidity' && (
          <p className="mt-3 text-[14px] text-primary-light tracking-[-0.14px]">
            This simulator shows the liquidity profile of the vault given your specific strategy and deposit amounts.
          </p>
        )}
        {selectedSimulationTab === 'price-impact' && (
          <p className="mt-3 text-[14px] text-primary-light tracking-[-0.14px]">
            This simulator shows the initial price impact you&apos;ll have for different sized swaps given your specific
            strategy and deposit amounts.
          </p>
        )}
      </div>
      {isLoadingConfigDetails && (
        <div className="relative">
          <img src="/assets/images/price-impact-chart-blurred.png" alt="price impact chart dummy" className="" />
          <div className="absolute top-0 w-full h-full flex items-center justify-center mb-10 gap-2 duration-300 rounded-lg py-2 text-vault-gray ">
            <Spinner color="vault-dark" />
          </div>
        </div>
      )}
      {isErrorConfigDetails && (
        <div className="relative">
          <img src="/assets/images/price-impact-chart-blurred.png" alt="price impact chart dummy" className="" />
          <div className="absolute top-0 w-full h-full flex items-center justify-center mb-10 gap-2 duration-300 rounded-lg py-2 text-red-400 ">
            <XMarkIcon className="h-5 w-5 text-red-400" />
            <span>An error occured. Please try again later.</span>
          </div>
        </div>
      )}
      {selectedSimulationTab === 'liquidity' && configDetails && (
        <div className="flex flex-col gap-1 my-5">
          <TokensLockedCard
            configDetails={configDetails}
            token0LogoUrl={token0LogoUrl}
            token1LogoUrl={token1LogoUrl}
            token0Symbol={token0Symbol}
            token1Symbol={token1Symbol}
          />
          <LiquidityProfileChart configDetails={configDetails as ConfigDetailsResponse} />
        </div>
      )}
      {selectedSimulationTab === 'price-impact' && configDetails && (
        <PriceImpactChart
          configDetails={configDetails as ConfigDetailsResponse}
          totalAmountUsd={totalAmountUsd}
          selectedDataPoint={selectedDataPoint}
          setSelectedDataPoint={setSelectedDataPoint}
        />
      )}
    </div>
  );
}

export default memo(Simulator);
