import { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useAccount, useBalance } from 'wagmi';
import { FormErrors, FormTrigger, RegisterForm } from '../../../../types/create-vault';
import { formatBigNumber } from '../../../../utils/format-big-number';
import { roundDownToNDecimals } from '../../../../utils/math';
import { numberWithCommas, shortenNumber } from '../../../../utils/n-formatter';
import { getNativeTokenLogoByChainId, getTokenEstimationByNetworkFor3Months } from '../../../../utils/vault-creation';
import Badge from '../../../elements/buttons/Badge';
import FormSection from '../../FormSection';

interface GasTankFormProps {
  chainId: number;
  errors: FormErrors;
  currentGasTankAmount: number;
  register: RegisterForm;
  trigger: FormTrigger;
  setValue: UseFormSetValue<{
    network: 'ethereum' | 'polygon' | 'optimism' | 'arbitrum' | 'base' | 'gnosis' | 'sepolia' | 'Unknown';
    ownTokenAddress: string;
    baseTokenAddress: string;
    feeTier: number;
    ownerAddress: string;
    ownTokenAmount: string;
    baseTokenAmount: string;
    gasTankAmount: number;
    strategy: string;
  }>;
}

function GasTankForm({ chainId, errors, register, trigger, setValue, currentGasTankAmount }: GasTankFormProps) {
  const [selectedPeriod, setSelectedPeriod] = useState<3 | 6 | 9 | 12>(3);

  useEffect(() => {
    const newGasAmount = roundDownToNDecimals(
      (getTokenEstimationByNetworkFor3Months(chainId).amount * selectedPeriod) / 3,
      1,
    );
    setValue('gasTankAmount', newGasAmount);
  }, [selectedPeriod, chainId, setValue]);

  const { address } = useAccount();

  const { data: nativeBalance, isLoading: isLoadingNativeBalance } = useBalance({
    address: address as '0x${string}',
    chainId: chainId,
    cacheTime: 10000,
  });

  const nativeBalanceValue = nativeBalance ? formatBigNumber(nativeBalance.value, nativeBalance.decimals, 12) : '0';

  const nativeTokenLogoUrl = getNativeTokenLogoByChainId(chainId);

  useEffect(() => {
    trigger(['gasTankAmount']);
  }, []);

  const { amount, token } = getTokenEstimationByNetworkFor3Months(chainId);

  return (
    <div>
      <FormSection
        title="Gas Tank"
        description="Running the automated strategy requires paying for gas. Choose the vault creation period, we will automatically calculate required gas amount."
      />

      <p className="text-sm text-gray-400 mt-1"> </p>
      <div className="flex flex-col md:flex-row justify-between gap-2 relative mt-6 mb-5 max-w-[500px]">
        <div className="">
          <div
            className={`rounded-[16px] p-4 bg-vault-gray-2 hover:bg-opacity-40 duration-300 border min-h-[120px] sm:w-[400px] bg-opacity-60 flex flex-col justify-between ${
              errors.gasTankAmount ? 'border-red-400' : 'border-none'
            }`}
          >
            <div>
              <div className="flex justify-between w-full">
                <div className="flex flex-row items-center">
                  <img
                    alt="token logo"
                    src={nativeTokenLogoUrl}
                    className="rounded-full max-w-[20px] max-h-[20px] align-middle border-green mx-0.5"
                  />
                  <span className="ml-2 min-w-[60px]">{nativeBalance?.symbol}</span>
                </div>
                <input
                  {...register('gasTankAmount', {
                    valueAsNumber: true,
                    required: "Can't be empty",
                    min: {
                      value: 0,
                      message: 'Minimum value is 0',
                    },
                    max: {
                      value: Number(nativeBalanceValue),
                      message: 'Not Enough Funds',
                    },
                  })}
                  type="number"
                  step="0.000000000000000001"
                  placeholder="0.00"
                  className="text-[26px] p-0 max-w-[200px] sm:max-w-none w-full bg-transparent text-right text-2xl text-white border-transparent focus:border-transparent focus:ring-0"
                />
              </div>

              {/* todo: Add usd price */}
            </div>
            <div className="flex justify-between items-center">
              <div className="w-fit text-xs text-vault-orange bg-vault-orange/10 ring-1 ring-vault-orange/20 py-1 px-2 rounded-lg flex items-center">
                Gas tank
              </div>
              <div className="text-vault-gray-hover flex items-center gap-1 justify-end">
                <div>Balance:</div>
                {isLoadingNativeBalance && (
                  <Skeleton height="20px" width="100px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
                )}
                {nativeBalanceValue && !isLoadingNativeBalance && (
                  <div>{numberWithCommas(shortenNumber(Number(nativeBalanceValue)))}</div>
                )}
                <span>{nativeBalance?.symbol}</span>
              </div>
            </div>
          </div>
          {errors.gasTankAmount && <div className="text-red-400 text-sm mt-2">{errors.gasTankAmount.message}</div>}
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-[16px] leading-5 tracking-[-0.16px]">Example top-up amounts</div>
        <div className="flex flex-wrap gap-2">
          <Badge isActive={currentGasTankAmount === amount} onClick={() => setSelectedPeriod(3)}>
            <div className="flex flex-col gap-2">
              <div>3 Months</div>
              <div className="text-secondary-grey text-[12px] leading-4">
                {amount.toFixed(2)} {token}
              </div>
            </div>
          </Badge>
          <Badge isActive={currentGasTankAmount === amount * 2} onClick={() => setSelectedPeriod(6)}>
            <div className="flex flex-col gap-2">
              <div>6 Months</div>
              <div className="text-secondary-grey text-[12px] leading-4">
                {' '}
                {(2 * amount).toFixed(2)} {token}
              </div>
            </div>
          </Badge>
          <Badge
            isActive={currentGasTankAmount === roundDownToNDecimals(amount * 3, 1)}
            onClick={() => setSelectedPeriod(9)}
          >
            <div className="flex flex-col gap-2">
              <div>9 Months</div>
              <div className="text-secondary-grey text-[12px] leading-4">
                {' '}
                {(3 * amount).toFixed(2)} {token}
              </div>
            </div>
          </Badge>
          <Badge isActive={currentGasTankAmount === amount * 4} onClick={() => setSelectedPeriod(12)}>
            <div className="flex flex-col gap-2">
              <div>12 Months</div>
              <div className="text-secondary-grey text-[12px] leading-4">
                {' '}
                {(4 * amount).toFixed(2)} {token}
              </div>
            </div>
          </Badge>
        </div>
      </div>
    </div>
  );
}

export default GasTankForm;
