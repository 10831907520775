import React from 'react';
import { Link } from 'react-router-dom';
import { CustomConnectButton } from '../../elements/buttons/ConnectButton';

export function Header(): React.ReactElement {
  return (
    <header
      className="sticky top-0 z-40 w-full py-4 px-6 bg-[#111016b3]"
      style={{
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div className="hidden sm:block">
        <Link to="/">
          <img src="../../assets/images/arrakis-logo.svg" alt="" />
        </Link>
      </div>
      <div className="block sm:hidden">
        <Link to="/">
          <img src="../../assets/images/arrakis-logo-mobile.svg" alt="" />
        </Link>
      </div>
      <CustomConnectButton />
    </header>
  );
}
