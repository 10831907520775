import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useAccount } from 'wagmi';
import Gradient1 from '../components/backgrounds/Gradient1';
import Grid from '../components/backgrounds/Grid';
import Banner from '../components/layout/banner/Banner';
import FeedbackBanner from '../components/layout/banner/FeedbackBanner';
import Footer from '../components/layout/footer/Footer';
import { Header } from '../components/layout/header';
import Navbar from '../components/layout/navbar';
import VaultListSkeleton from '../components/vault/skeleton/VaultListSkeleton';
import { PalmVaultListItem } from '../components/vault/vaultListItem/PalmListItem';
import { PalmTemporaryVaultListItem } from '../components/vault/vaultListItem/PalmTemporaryVaultListItem';
import { IVault, TemporaryVault, TemporaryVaultSchema } from '../types';
import { getOwnPalmVaults } from '../utils/api';

function PalmOverviewPage() {
  const { address } = useAccount();

  const {
    data: vaults,
    isLoading,
    error,
  } = useQuery(['own-v2-vaults'], () => getOwnPalmVaults(address), {
    refetchInterval: 30000,
    refetchOnWindowFocus: false,
  });

  // check for temporary vault in local storage
  const [temporaryVault, setTemporaryVault] = useState<TemporaryVault | null>(null);
  const [temporaryVaultId, setTemporaryVaultId] = useState<string | null>(null);

  const [closedVaultsSessionStorage] = useSessionStorage<string[]>('closed-vaults', []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const temporaryVault = sessionStorage.getItem('temporary-vault') || null;
    const parsedTemporaryVault = TemporaryVaultSchema.safeParse(JSON.parse(temporaryVault || '{}'));
    const temporaryVaultId = sessionStorage.getItem('temporary-vault-id');

    if (temporaryVaultId) {
      setTemporaryVaultId(temporaryVaultId);
    }

    if (parsedTemporaryVault.success) {
      setTemporaryVault(parsedTemporaryVault.data);
    }
  }, []);

  // replace temporary vault with real vault if it exists
  useEffect(() => {
    if (vaults && vaults.length > 0 && temporaryVaultId) {
      const vault = vaults.find((vault) => vault.id.toLowerCase() === temporaryVaultId.toLowerCase());

      if (vault) {
        sessionStorage.removeItem('temporary-vault');
        sessionStorage.removeItem('temporary-vault-id');
      }
    }
  }, [vaults, temporaryVaultId]);

  const filterClosedVaults = (vaults: IVault[]) =>
    vaults.filter((vault) => !closedVaultsSessionStorage.includes(vault.id));

  const urlParams = new URLSearchParams(window.location.search);
  const showFeedbackBanner = urlParams.get('vault-creation') === 'true';

  return (
    <>
      <Header />
      <Gradient1 />
      <Grid opacity={0.04} />
      <div className="flex flex-col items-center w-full">
        <div className="px-4 md:px-0 max-w-screen-lg w-full">
          <div className="w-full flex flex-col gap-10">
            {showFeedbackBanner ? <FeedbackBanner /> : <Banner />}
            <Navbar activeItem="my-palm-vaults" />
          </div>
          <ul className="w-full my-10">
            <>
              {temporaryVault && temporaryVaultId && !closedVaultsSessionStorage.includes(temporaryVaultId) && (
                <li className="flex items-center mt-2 mb-2 mx-2 md:mx-0">
                  <PalmTemporaryVaultListItem vault={temporaryVault} vaultId={temporaryVaultId} />
                </li>
              )}
              {vaults &&
                filterClosedVaults(vaults)
                  .filter((vault) => !vault.isClosed)
                  .map((vault, index) => {
                    return (
                      <li key={vault.id + index} className="flex items-center mt-2 mb-2 mx-2 md:mx-0">
                        <PalmVaultListItem vault={vault} />
                      </li>
                    );
                  })}
              {isLoading && <VaultListSkeleton numberOfVaults={10} />}
              {error && <p className="text-white mt-20 text-center">Something went wrong</p>}
              {vaults && vaults.length === 0 && !temporaryVault && (
                <div className="bg-vault-black  bg-opacity-100 flex items-center justify-center px-4 sm:px-8 py-8">
                  <div className="text-center tracking-[-0.01em]">
                    <h2 className="text-vault-light-gray text-[24px] mb-5">You have no liquidity now</h2>
                    <p className="text-vault-gray-hover text-[16px]">You can find suitable Vaults on the tab Vaults.</p>
                  </div>
                </div>
              )}
            </>
          </ul>
          <div className="self-start">
            <Footer />
          </div>
        </div>
      </div>{' '}
    </>
  );
}

export default PalmOverviewPage;
