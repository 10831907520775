function RebalancingSpeedExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/speed.jpg"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />

      <h2 className="text-lg font-bold mb-1">What does Rebalance Speed mean?</h2>
      <p>
        Rebalance speed means how often the vault will be rebalanced. Rebalancing means that the vault will be adjusted
        to the target allocation. For example, if the target allocation is 50% ETH and 50% USDC, and the vault is
        currently 60% ETH and 40% USDC, the vault will sell some ETH and buy some USDC to get back to the target
        allocation.
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">What is the advantage of rebalancing aggressively?</h2>
      <p className="mb-2">
        If you rebalance aggressively, the vault will be closer to the target allocation. This means that the vault will
        be less exposed to price changes of the tokens.
      </p>

      <h2 className="text-lg font-bold mb-1 mt-5">What is the disadvantage of rebalancing aggressively?</h2>

      <p className="mb-2">
        If you rebalance aggressively, you will pay more gas fees. This is because the vault will be rebalanced more
        often, and as a result more transactions will be executed.
      </p>
    </div>
  );
}

export default RebalancingSpeedExplanation;
