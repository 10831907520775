function RiskDisclaimer() {
  return (
    <div className="flex flex-col gap-3 text-[16px] leading-[20px] tracking-[-0.16px]">
      <div className="flex items-center gap-2">
        <img src="/assets/images/warning.svg" alt="warning" /> This strategy is unhedged, meaning you are taking
        directional risk
      </div>
      <div className="flex items-center gap-2">
        {' '}
        <img src="/assets/images/warning.svg" alt="warning" />
        Risk of impermanent loss exists{' '}
      </div>
      <div className="flex items-center gap-2">
        {' '}
        <img src="/assets/images/warning.svg" alt="warning" />
        Smart Contract risk exists
      </div>
    </div>
  );
}

export default RiskDisclaimer;
