import { useEffect, useState } from 'react';
import VaultCreateForm from '../components/forms/CreateVault';
import Stepper from '../components/forms/CreateVault/components/Stepper';
import VaultIntro from '../components/forms/CreateVault/components/VaultInto';
import { Header } from '../components/layout/header';

function CreateVault() {
  const [currentStep, setCurrentStep] = useState(0);

  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentStep]);

  return (
    <>
      <Header />
      <div className="mx-auto container max-w-screen-xl text-white px-5 pt-8 pb-12 md:p-0">
        {showIntro && (
          <div className="sm:flex flex-col md:justify-center h-screen md:-mt-[100px]">
            <VaultIntro continueFn={() => setShowIntro(false)} />
          </div>
        )}
        {!showIntro && (
          <div className="flex flex-col gap-10">
            <Stepper currentStep={currentStep} />
            <VaultCreateForm currentStep={currentStep} setCurrentStep={setCurrentStep} />
          </div>
        )}
      </div>
    </>
  );
}

export default CreateVault;
