import React from 'react';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import ConnectWallet from '../../vault/connect-wallet/ConnectWallet';
import PrimaryButton from './PrimaryButton';

interface TransactionButtonWrapperProps {
  children: React.ReactNode;
  chainId: number;
  buttonText?: string;
  className?: string;
}

function TransactionButtonWrapper({ children, chainId, buttonText, className }: TransactionButtonWrapperProps) {
  const { address } = useAccount();
  const { isLoading: isLoadingSwitchNetwork, switchNetwork } = useSwitchNetwork();

  const { chain } = useNetwork();
  return (
    <>
      {!address && <ConnectWallet />}
      {address && chainId !== chain?.id && (
        <PrimaryButton
          onClick={() => switchNetwork?.(chainId)}
          isLoading={isLoadingSwitchNetwork}
          className={className}
        >
          {buttonText ?? 'Switch Network'}
        </PrimaryButton>
      )}
      {address && chainId === chain?.id && children}
    </>
  );
}

export default TransactionButtonWrapper;
