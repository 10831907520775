import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { useAccount, useContractRead } from 'wagmi';
import { ArrakisV2HelperAbi } from '../../../abis/ArrakisV2Helper';
import { ARRAKIS_V2_HELPER_ADDRESS } from '../../../constants';
import { useSafeAppTransactionSuccess } from '../../../hooks/useSafeAppTransactionSuccess';
import { IVault, UnderlyingBalancesSchema } from '../../../types';
import { formatBigNumber } from '../../../utils/format-big-number';
import { abbreviateNumber, capitalizeFirstLetter, getNetworkNameFromChainId } from '../../../utils/n-formatter';
import ChartsWrapper from '../../charts/ChartsWrapper';
import CollapseButton from '../../elements/collapse/CollapseButton';
import GasTank from '../gasTank';
import { ManagerView } from '../managerView';

interface VaultListItemProps {
  vault: IVault;
}

export function PalmVaultListItem({ vault }: VaultListItemProps) {
  const { price0, cmcPrice0, cmcPrice1, price1, symbol0, symbol1 } = vault;

  const [showManager, setShowManager] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);

  const { data: underlyingBalances, isLoading: isLoadingBalances } = useContractRead({
    address: ARRAKIS_V2_HELPER_ADDRESS,
    abi: ArrakisV2HelperAbi,
    functionName: 'totalUnderlying',
    chainId: vault.chainId,
    args: [vault.id],
    watch: true,
    select: (data: unknown) => {
      const parsedData = UnderlyingBalancesSchema.safeParse(data);
      if (parsedData.success) {
        return parsedData.data;
      } else {
        console.error('Error parsing underlying balances', parsedData.error);
      }
      return undefined;
    },
    onError: (error) => {
      console.error('Error fetching underlying balances yyy', error);
    },
  });

  const shortenedVaultName = vault.name.replace('Arrakis Vault V1', '').replace('Gelato Uniswap', '').replace('LP', '');

  // check if connected wallet owns this vault
  const { address } = useAccount();

  const isOwner = address?.toLowerCase() === vault.creator?.toLowerCase();

  // hack: to check if transaction was successful with safe app, we watch token balances of vault and trigger success if they increase
  const { isIncreaseLiquiditySuccess, token0BalanceOfVault, token1BalanceOfVault } = useSafeAppTransactionSuccess({
    vaultId: vault.id,
    chainId: vault.chainId,
    token0: vault.token0,
    token1: vault.token1,
  });

  const underlyingBalance0 = underlyingBalances?.[0];
  const underlyingBalance1 = underlyingBalances?.[1];

  const token0BalanceOfVaultNumber = underlyingBalance0
    ? Number(formatBigNumber(underlyingBalance0, vault.token0Decimals, 18))
    : 0;
  const token1BalanceOfVaultNumber = underlyingBalance1
    ? Number(formatBigNumber(underlyingBalance1, vault.token1Decimals, 18))
    : 0;

  const tvl =
    token0BalanceOfVaultNumber * (price0 ?? cmcPrice0 ?? 0) + token1BalanceOfVaultNumber * (price1 ?? cmcPrice1 ?? 0);

  const percentage0 = token0BalanceOfVault ? ((token0BalanceOfVaultNumber * price0) / tvl) * 100 : 0;
  const percentage0Str = isNaN(percentage0) ? '0' : percentage0.toFixed(0);
  const percentage1 = token1BalanceOfVault ? ((token1BalanceOfVaultNumber * price1) / tvl) * 100 : 0;
  const percentage1Str = isNaN(percentage1) ? '0' : percentage1.toFixed(0);
  return (
    <div className="flex flex-col w-full p-6 min-h-[130px] relative bg-vault-black rounded-[24px] text-vault-white bg-opacity-80 hover:bg-opacity-80 hover:bg-[#1F1F21] duration-500 ease-in-out">
      <>
        <div className="flex flex-col md:flex-row gap-3 justify-between">
          {/* Token Logos */}
          <div className="flex min-w-[240px]">
            <img
              src={vault.token0LogoUrl}
              alt={symbol0}
              className="rounded-full max-w-[40px] max-h-[40px] align-middle mx-0.5 mt-1"
            />
            <img
              src={vault.token1LogoUrl}
              alt={symbol1}
              className="rounded-full max-w-[40px] max-h-[40px] align-middle mt-1"
            />

            <div className="flex flex-col mx-2 ">
              <p className="text-vault-gray">{capitalizeFirstLetter(getNetworkNameFromChainId(vault?.chainId))}</p>
              <p className=" text-[20px]">{shortenedVaultName}</p>
            </div>
          </div>

          {/* Fee Tier */}
          {vault.feeTier && (
            <div className="">
              <p className="text-vault-gray">Fee Tier</p>
              <div className="flex flex-col">
                <p className="mx-1 text-[20px]">{vault.feeTier ? `${vault.feeTier}%` : ''}</p>
              </div>
            </div>
          )}

          {/* TVL */}
          <div className=" min-w-[180px]">
            <p className="text-vault-gray">TVL</p>
            <div className="flex flex-col">
              {isLoadingBalances && (
                <Skeleton height="30px" width="55px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
              )}
              {!isLoadingBalances && <p className="mx-1 text-[20px]">{tvl ? '$' + abbreviateNumber(tvl, 2) : 'n/a'}</p>}
              <p className="text-vault-gray mx-1 text-[14px] mt-1">
                {percentage0Str}% {symbol0.toUpperCase()} - {percentage1Str}% {symbol1.toUpperCase()}
              </p>
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <Link to={`/vault-edit/${vault.id}`}>
              <button className="bg-primary-light text-primary-black text-[16px] px-[16px] py-[14px] rounded-[12px] disabled:bg-vault-gray-2 disabled:text-vault-gray-hover">
                Edit Strategy
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-8">
          <CollapseButton text="Vault information" showContent={showManager} setShowContent={setShowManager} />

          {showManager && (
            <ManagerView
              vault={vault}
              vaultType="palm"
              isIncreaseLiquiditySuccess={isIncreaseLiquiditySuccess}
              palmTvl={tvl}
              onlyForOwner={false}
              isOwner={isOwner}
            />
          )}

          <CollapseButton text="Analytics" showContent={showAnalytics} setShowContent={setShowAnalytics} />

          {showAnalytics && (
            <div className="my-8">
              <ChartsWrapper vaultId={vault.id} />
            </div>
          )}

          <GasTank vaultId={vault.id} chainId={vault.chainId} />
        </div>
      </>
    </div>
  );
}
