function BaseTokenExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/tokens.png"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />

      <h2 className="text-lg font-bold mb-1">What is the base token?</h2>
      <p>
        You can either select ETH or USDC as the base token. This token will be paired with your token of interest and
        the Arrakis vault will market make that pair.
      </p>
    </div>
  );
}

export default BaseTokenExplanation;
