function TokenAmountsExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/vault-money.png"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />

      <h2 className="text-lg font-bold mb-1">What are the token amounts?</h2>
      <p>The token amounts are the amounts of token that you initially deposit into the vault.</p>
      <h2 className="text-lg font-bold mb-1 mt-5">Can I deposit more tokens afterwards?</h2>
      <p className="mb-2">
        Of course, you can deposit more tokens into the vault at any time. To do this you can use the
        &quot;Deposit&quot; button on the vault page.
      </p>
    </div>
  );
}

export default TokenAmountsExplanation;
