import axios from 'axios';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import 'react-loading-skeleton/dist/skeleton.css';
import { useQuery } from 'wagmi';
import { TvlChartSection, TvlResponseSchema } from '../../types/charts/tvl';
import { getTvlChartOptions } from '../../utils/charts/tvl';
import { formatRelativeTime } from '../../utils/n-formatter';
import AboutVault from '../vault/about';
import RiskDisclaimer from '../vault/risk-disclaimer';
import V2Sections from '../vault/v2-sections';
import ChartLoader from './ChartLoader';

interface TvlChartProps {
  vaultId: string;
  vaultType: 'v1' | 'v2' | 'palm';
}

const TvlChart = ({ vaultId, vaultType }: TvlChartProps) => {
  const [activeSection, setActiveSection] = useState<TvlChartSection>(TvlChartSection.COMPOSITION);

  async function fetchTvlData(vaultId: string) {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/analytics/v2/tvl/${vaultId}`);

      const parsedData = TvlResponseSchema.safeParse(response.data);

      if (!parsedData.success) {
        return undefined;
      }

      return parsedData.data.data;
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  }

  const {
    data: tvlData,
    isLoading: isLoadingTvlData,
    isError,
  } = useQuery(['vault-tvl', vaultId], async () => {
    return await fetchTvlData(vaultId);
  });

  console.log('tvlData', tvlData);

  const options = tvlData && tvlData.history?.length > 1 ? getTvlChartOptions(tvlData) : {};

  const symbol0 = tvlData?.metadata?.symbol0;
  const symbol1 = tvlData?.metadata?.symbol1;

  return (
    <div>
      {vaultType === 'v2' && (
        <V2Sections activeSection={activeSection} setActiveSection={setActiveSection} vaultId={vaultId} />
      )}
      {isLoadingTvlData && <ChartLoader />}
      {activeSection === TvlChartSection.COMPOSITION && tvlData?.history && !isLoadingTvlData && (
        <div>
          {options?.series && <ReactApexChart options={options} series={options.series} type="area" />}
          <div className="flex items-center justify-between text-[14px] align-middle">
            <div className="flex items-center gap-5">
              <div className="text-vault-orange-2 flex items-center gap-2">
                <div className="w-[14px] h-[14px] rounded-full bg-vault-orange-2 -mt-[1px]"></div>
                {symbol0?.toUpperCase()} Holdings
              </div>
              <div className="text-[#F3F2F2] flex items-center gap-2">
                <div className="w-[14px] h-[14px] rounded-full bg-[#F3F2F2] -mt-[1px]"></div>
                {symbol1?.toUpperCase()} Holdings
              </div>
            </div>
            {tvlData?.history && tvlData.history[tvlData.history.length - 1]?.timeAt && (
              <div className="text-vault-gray">
                Last updated: {formatRelativeTime(tvlData.history[tvlData.history.length - 1].timeAt)}
              </div>
            )}
          </div>
        </div>
      )}

      {activeSection === TvlChartSection.ABOUT && symbol0 && symbol1 && (
        <AboutVault symbol0={symbol0} symbol1={symbol1} />
      )}
      {activeSection === TvlChartSection.RISKS && <RiskDisclaimer />}
      {(isError || tvlData?.history?.length === 0) && (
        <div className="w-full flex items-center justify-center h-[288px]">No historic data available</div>
      )}
    </div>
  );
};

export default TvlChart;
