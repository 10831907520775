import { arbitrum, base, gnosis, mainnet, optimism, polygon, sepolia } from 'viem/chains';

export function getViemChain(chainId?: number) {
  switch (chainId) {
    case 1:
      return mainnet;
    case 10:
      return optimism;
    case 100:
      return gnosis;
    case 137:
      return polygon;
    case 8453:
      return base;
    case 42161:
      return arbitrum;
    case 11155111:
      return sepolia;
    default:
      return mainnet;
  }
}
