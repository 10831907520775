function Cube() {
  return (
    <div className="cube">
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
    </div>
  );
}

export default Cube;
