import { MaxSigDeadline, PermitBatchTransferFrom, PermitTransferFrom, SignatureTransfer } from '@uniswap/permit2-sdk';
import { signTypedData } from '@wagmi/core';
import * as bigintConversion from 'bigint-conversion';
import { ARRAKIS_V2_ROUTER_ADDRESSES, PERMIT_2_ADDRESS } from '../constants';
import { IVault } from '../types';

interface CreateSignatureProps {
  useNativeToken: boolean;
  input0: bigint;
  input1: bigint;
  vault: IVault;
  setIsSignatureLoading: (loading: boolean) => void;
  nonce: string;
  nativeSymbol0: string | undefined;
  selectedZapinToken?: 0 | 1;
}

export async function createSignature({
  useNativeToken,
  input0,
  input1,
  vault,
  setIsSignatureLoading,
  nonce,
  nativeSymbol0,
  selectedZapinToken,
}: CreateSignatureProps): Promise<string | undefined> {
  try {
    setIsSignatureLoading(true);

    const permitted = getPermittedArray({
      useNativeToken,
      input0,
      input1,
      vault,
      nativeSymbol0,
      selectedZapinToken,
    });

    const permitBody = {
      spender: ARRAKIS_V2_ROUTER_ADDRESSES,
      nonce,
      deadline: MaxSigDeadline.sub(1).toString(),
    };

    const permit: PermitTransferFrom = {
      ...permitBody,
      permitted: permitted[0],
    };

    const permitBatch: PermitBatchTransferFrom = {
      ...permitBody,
      permitted,
    };

    const payload = SignatureTransfer.getPermitData(
      useNativeToken ? permit : permitBatch,
      PERMIT_2_ADDRESS,
      vault.chainId,
    );

    const permittedValues = transformPermittedToValues(permitted);

    const values = {
      deadline: '0x' + bigintConversion.bigintToHex(BigInt('999999999999999')),
      nonce: '0x' + bigintConversion.bigintToHex(BigInt(nonce)),
      permitted: useNativeToken ? permittedValues[0] : permittedValues,
      spender: ARRAKIS_V2_ROUTER_ADDRESSES as '0x${string}',
    } as const;

    const signature = await signTypedData({
      domain: payload.domain as any,
      types: payload.types,
      primaryType: useNativeToken ? 'PermitTransferFrom' : 'PermitBatchTransferFrom',
      message: values as any,
    });
    setIsSignatureLoading(false);

    return signature;
  } catch (error: unknown) {
    console.log('error', error);
    setIsSignatureLoading(false);
    return undefined;
  }
}

interface GetPermittedArrayProps {
  useNativeToken: boolean;
  input0: bigint;
  input1: bigint;
  vault: IVault;
  nativeSymbol0: string | undefined;
  selectedZapinToken?: 0 | 1 | undefined;
}

type Permitted = {
  token: string;
  amount: bigint;
}[];

type PermittedValues = {
  token: string;
  amount: string;
}[];

export function getPermittedArray({
  useNativeToken,
  input0,
  input1,
  vault,
  nativeSymbol0,
  selectedZapinToken,
}: GetPermittedArrayProps): Permitted {
  if (selectedZapinToken === undefined) {
    if (useNativeToken) {
      if (nativeSymbol0) {
        return [{ token: vault.token1, amount: input1 }];
      } else {
        return [{ token: vault.token0, amount: input0 }];
      }
    } else {
      return [
        { token: vault.token0, amount: input0 },
        { token: vault.token1, amount: input1 },
      ];
    }
  } else if (selectedZapinToken === 0) {
    return [
      { token: vault.token0, amount: input0 },
      { token: vault.token1, amount: BigInt(0) },
    ];
  } else if (selectedZapinToken === 1) {
    return [
      { token: vault.token0, amount: BigInt(0) },
      { token: vault.token1, amount: input1 },
    ];
  } else {
    return [];
  }
}

function transformPermittedToValues(permitted: Permitted): PermittedValues {
  return permitted.map((permitted) => ({
    token: permitted.token,
    amount: '0x' + bigintConversion.bigintToHex(permitted.amount),
  }));
}
