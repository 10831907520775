import React from 'react';
import { DepositFormWrapperState } from '../../../types';
import { numberWithCommas } from '../../../utils/n-formatter';
import DepositFormSkeleton from '../../vault/skeleton/DepositFormSkeleton';
import CheckIcon from '/assets/images/icon-check-3.svg';

interface DepositFormWrapperProps {
  children: React.ReactNode;
  state: DepositFormWrapperState;
  isLoading: boolean;
  usdValue?: number;
}

function DepositFormWrapper({ children, state, isLoading, usdValue }: DepositFormWrapperProps) {
  return (
    <>
      {isLoading ? (
        <div className="flex flex-col sm:flex-row justify-between gap-2">
          <DepositFormSkeleton />
          <DepositFormSkeleton />
        </div>
      ) : (
        <>
          {state === DepositFormWrapperState.DEFAULT && children}
          {state === DepositFormWrapperState.DEPOSIT_SUCCESS && (
            <div className="flex flex-col items-center justify-center text-vault-light-gray text-[24px] my-20">
              <img src={CheckIcon} alt="check icon" />
              <div className="mt-[48px]">
                Successfully added {usdValue && <span>{numberWithCommas(usdValue?.toFixed(2))} worth of</span>}{' '}
                liquidity!
              </div>
            </div>
          )}
          {state === DepositFormWrapperState.SAFE_APP_DEPOSIT && (
            <div className="flex flex-col items-center justify-center text-vault-light-gray text-[24px] my-20">
              <img src={CheckIcon} alt="check icon" />

              <div className="mt-[48px]">
                Deposit transaction initiated. Please check your safe app to complete the transaction.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default DepositFormWrapper;
