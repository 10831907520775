import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { EarnedFeesResponseSchema } from '../../types/charts/earned-fees';
import { getEarnedFeesChartOptions } from '../../utils/charts/earned-fees';
import { abbreviateNumber } from '../../utils/n-formatter';
import ChartLoader from './ChartLoader';
import ChartStat from './ChartStat';
import NoData from './NoData';

interface EarnedFeesChartProps {
  vaultId: string;
}

function EarnedFeesChart({ vaultId }: EarnedFeesChartProps) {
  async function fetchEarnedFees(vaultId: string) {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/analytics/v2/fees-earned/${vaultId}`);
      const parsedData = EarnedFeesResponseSchema.safeParse(response.data);

      if (!parsedData.success) {
        return undefined;
      }

      return parsedData.data.data;
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  }

  const { data: earnedFeesData, isLoading } = useQuery(['earned-fees', vaultId], async () => {
    return await fetchEarnedFees(vaultId);
  });

  const options = earnedFeesData ? getEarnedFeesChartOptions(earnedFeesData) : {};

  const totalFeesUsd = earnedFeesData?.totals.feeUSD;
  const fees0 = earnedFeesData?.totals.fee0;
  const fees1 = earnedFeesData?.totals.fee1;

  const symbol0 = earnedFeesData?.metadata.symbol0?.toUpperCase();
  const symbol1 = earnedFeesData?.metadata.symbol1?.toUpperCase();

  return (
    <div className="flex items-center gap-5">
      <div className="grow" id="earned-fees-chart">
        {isLoading && <ChartLoader />}
        {earnedFeesData && earnedFeesData.history.length > 1 && options.series && (
          <ReactApexChart options={options} series={options.series} height={380} type="bar" />
        )}
        {(!earnedFeesData || earnedFeesData.history.length <= 1) && !isLoading && <NoData />}
      </div>
      <div className="flex flex-col gap-4">
        {!!totalFeesUsd && (
          <ChartStat title="Value of the Generated Fees" value={`$${abbreviateNumber(totalFeesUsd)}`} />
        )}
        {!!fees0 && !!symbol0 && (
          <ChartStat title={`Generated ${symbol0} Fees`} value={`${abbreviateNumber(fees0)} ${symbol0}`} />
        )}
        {!!fees1 && !!symbol1 && (
          <ChartStat title={`Generated ${symbol1} Fees`} value={`${abbreviateNumber(fees1)} ${symbol1}`} />
        )}
      </div>
    </div>
  );
}

export default EarnedFeesChart;
