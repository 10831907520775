function VaultOwnerExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/vault-hand.png"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />

      <h2 className="text-lg font-bold mb-1">Who is the vault owner?</h2>
      <p>
        The vault owner is the wallet address that controls the vault. For example, only the vault owner can change the
        strategy. Most importantly only the vault owner account will be able to withdraw tokens from the vault
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Which address should I use?</h2>
      <p className="mb-2">
        We prefill the vault owner address with your wallet address. If you want to use a different address, you can
        change it.
      </p>
    </div>
  );
}

export default VaultOwnerExplanation;
