import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { Explanation } from '../../../../types';
import QuestionButton from '../../../layout/sidebar/QuestionButton';
interface MaxGasPriceProps {
  maxGasPrice: string;
  setMaxGasPrice: UseFormSetValue<{
    maxGasPrice: string;
  }>;
  control: Control<
    {
      maxGasPrice: string;
    },
    any
  >;
}

function MaxGasPrice({ maxGasPrice, setMaxGasPrice, control }: MaxGasPriceProps) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <h2 className="text-[16px] leading-[20px] tracking-[-0.16px]">What is the maximum gas price?</h2>

        <QuestionButton explanation={Explanation.GAS_PRICE} title="Gas Price explained" />
      </div>
      <div>
        <div className="flex flex-col sm:flex-row sm:items-center gap-2 max-w-[400px]">
          <div className="relative flex items-end gap-3 w-full">
            <Controller
              render={() => (
                <NumericFormat
                  autoComplete="off"
                  allowNegative={false}
                  value={maxGasPrice}
                  onChange={(event) => {
                    setMaxGasPrice('maxGasPrice', event.target.value.replace(/,/g, ''));
                  }}
                  thousandSeparator
                  className="block w-full rounded-[12px] border-0 bg-accent py-[14px] px-4 text-white  sm:text-sm sm:leading-6  focus:ring-2 focus:ring-white/0 active:ring-white/0"
                />
              )}
              name="maxGasPrice"
              control={control}
              rules={{
                required: "Can't be empty",
                min: {
                  value: 0,
                  message: 'Minimum value is 0',
                },
              }}
            />

            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-white text-[16px]" id="price-currency">
                GWEI
              </span>
            </div>
          </div>
        </div>
        {!maxGasPrice || Number(maxGasPrice) === 0 ? (
          <div className="text-red-400 text-sm mt-2">Maximum gas price must be defined</div>
        ) : null}
      </div>
    </div>
  );
}

export default MaxGasPrice;
