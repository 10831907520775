import { z } from 'zod';

export const LiquidityProfileResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    metadata: z.object({
      symbol0: z.string(),
      symbol1: z.string(),
    }),

    profiles: z.array(
      z.object({
        pool: z.object({
          id: z.string(),
          tvl: z.number(),
          profile: z.array(
            z.object({
              ratio: z.number(),
              price0: z.number(),
              price1: z.number(),
              liquidity: z.number(),
            }),
          ),
        }),
        vault: z.object({
          id: z.string(),
          tvl: z.number(),
          amount0: z.number(),
          amount1: z.number(),
          profile: z.array(
            z.object({
              ratio: z.number(),
              price0: z.number(),
              price1: z.number(),
              liquidity: z.number(),
            }),
          ),
        }),
      }),
    ),
  }),
  cache: z.boolean(),
});

export type LiquidityProfileResponse = z.infer<typeof LiquidityProfileResponseSchema>;
