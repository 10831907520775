import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { WithdrawFormWrapperState } from '../../../types';
import { numberWithCommas } from '../../../utils/n-formatter';
import CheckIcon from '/assets/images/icon-check-3.svg';

interface WithdrawWrapperProps {
  children: React.ReactNode;
  state: WithdrawFormWrapperState;
  isLoading: boolean;
  usdValue?: number;
}

function WithdrawWrapper({ children, state, isLoading, usdValue }: WithdrawWrapperProps) {
  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          {' '}
          <Skeleton height="40px" width="400px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
        </div>
      ) : (
        <>
          {state === WithdrawFormWrapperState.DEFAULT && children}
          {state === WithdrawFormWrapperState.WITHDRAW_SUCCESS && (
            <div className="flex flex-col items-center justify-center text-vault-light-gray text-[24px] my-20">
              <img src={CheckIcon} alt="check icon" />
              <div className="mt-[48px]">
                Successfully removed {usdValue ? '$' + numberWithCommas(usdValue?.toFixed(2)) + ' worth of' : ''}
                &nbsp; liquidity!
              </div>
            </div>
          )}
          {state === WithdrawFormWrapperState.SAFE_APP_WITHDRAW && (
            <div className="flex flex-col items-center justify-center text-vault-light-gray text-[24px] my-20">
              <img src={CheckIcon} alt="check icon" />

              <div className="mt-[48px]">
                Withdraw transaction initiated. Please check your safe app to complete the transaction.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default WithdrawWrapper;
