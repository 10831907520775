import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import useDebounce from '../../../hooks/useDebounce';
import { IVault, SwapAndAddLiquidityArgs } from '../../../types';
import { formatBigNumber } from '../../../utils/format-big-number';
import { shortenNumber } from '../../../utils/n-formatter';
import { getSwapAndAddLiquidityArgs } from '../../../utils/swap-resolver';

interface ExpectedZapinResultProps {
  vault: IVault;
  selectedZapinToken: 0 | 1;
  slippage: number;
  walletAddress: string;
  input0: bigint;
  input1: bigint;
  setSwapAndAddLiquidityParams: React.Dispatch<React.SetStateAction<SwapAndAddLiquidityArgs | undefined>>;
  swapAndAddLiquidityParams: SwapAndAddLiquidityArgs | undefined;
  symbol0: string;
  symbol1: string;
  mintAmount: string | null;
}

function ExpectedZapinResult({
  vault,
  selectedZapinToken,
  slippage,
  walletAddress,
  input0,
  input1,
  symbol0,
  symbol1,
  swapAndAddLiquidityParams,
  setSwapAndAddLiquidityParams,
}: ExpectedZapinResultProps) {
  const [isLoading, setIsLoading] = useState(false);

  const debouncedInput0 = useDebounce(input0, 700);
  const debouncedInput1 = useDebounce(input1, 700);

  const amountInSwap = swapAndAddLiquidityParams?.[0].swapData.amountInSwap;
  const amountOutSwap = swapAndAddLiquidityParams?.[0].swapData.amountOutSwap;

  useEffect(() => {
    fetchSwapAndAddLiquidityArgs();
  }, [debouncedInput0, debouncedInput1, slippage, selectedZapinToken]);

  async function fetchSwapAndAddLiquidityArgs() {
    if (debouncedInput0 > BigInt(0) || (debouncedInput1 > BigInt(0) && walletAddress)) {
      setIsLoading(true);

      try {
        const swapAndAddLiquidityArgs = await getSwapAndAddLiquidityArgs({
          vault,
          amount0: input0,
          amount1: input1,
          selectedZapinToken,
          slippage,
          walletAddress,
        });

        setSwapAndAddLiquidityParams(swapAndAddLiquidityArgs);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  }

  function calculateOutputs() {
    try {
      let outputUsd = 0;
      let formattedAmountToken0 = 0;
      let formattedAmountToken1 = 0;
      let formattedSwapInAmount = 0;
      let formattedSwapOutAmount = 0;
      const symbolSwapIn = selectedZapinToken === 0 ? symbol0 : symbol1;
      const symbolSwapOut = selectedZapinToken === 0 ? symbol1 : symbol0;

      if (amountInSwap && amountOutSwap) {
        if (selectedZapinToken === 0) {
          const amountToken0 = input0 - BigInt(amountInSwap);
          formattedAmountToken0 = shortenNumber(Number(formatBigNumber(amountToken0, vault.token0Decimals)));

          const amountToken1 = BigInt(amountOutSwap);
          formattedAmountToken1 = shortenNumber(Number(formatBigNumber(amountToken1, vault.token1Decimals)));

          formattedSwapInAmount = shortenNumber(Number(formatBigNumber(BigInt(amountInSwap), vault.token0Decimals)));
          formattedSwapOutAmount = shortenNumber(Number(formatBigNumber(BigInt(amountOutSwap), vault.token1Decimals)));
        } else {
          const amountToken0 = BigInt(amountOutSwap);
          formattedAmountToken0 = shortenNumber(Number(formatBigNumber(amountToken0, vault.token0Decimals)));

          const amountToken1 = input1 - BigInt(amountInSwap);
          formattedAmountToken1 = shortenNumber(Number(formatBigNumber(amountToken1, vault.token1Decimals)));

          formattedSwapInAmount = shortenNumber(Number(formatBigNumber(BigInt(amountInSwap), vault.token1Decimals)));
          formattedSwapOutAmount = shortenNumber(Number(formatBigNumber(BigInt(amountOutSwap), vault.token0Decimals)));
        }

        outputUsd = formattedAmountToken0 * vault.price0 + formattedAmountToken1 * vault.price1;
      }
      return {
        outputUsd: outputUsd,
        formattedToken0: formattedAmountToken0,
        formattedToken1: formattedAmountToken1,
        formattedSwapInAmount,
        formattedSwapOutAmount,
        symbolSwapIn,
        symbolSwapOut,
      };
    } catch (error) {
      return {
        outputUsd: 0,
        formattedToken0: 0,
        formattedToken1: 0,
      };
    }
  }

  const outputs = calculateOutputs();

  return (
    <div className="border border-vault-gray rounded-[16px] px-[16px] py-[20px] flex flex-col gap-6">
      <div className="flex flex-col sm:flex-row sm:justify-between text-center text-[20px]">
        <div className="text-vault-gray-hover">Expected Output:</div>
        {isLoading && (
          <Skeleton
            style={{ borderRadius: '5px' }}
            height="30px"
            width="280px"
            baseColor="#292828"
            highlightColor="#6e6d6d"
            duration={0.9}
          />
        )}
        {!isLoading && (
          <div className="text-vault-primary">
            ${outputs.outputUsd.toFixed(2)} ({outputs.formattedToken0} {symbol0.toUpperCase()} +{' '}
            {outputs.formattedToken1} {symbol1.toUpperCase()})
          </div>
        )}
      </div>
      <div className="text-[16px] flex flex-col gap-2">
        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="text-vault-gray-hover"> Swap:</div>
          <div className="text-vault-primary">
            {outputs.formattedSwapInAmount} {outputs.symbolSwapIn?.toUpperCase()}
            {' --> '}
            {outputs.formattedSwapOutAmount} {outputs.symbolSwapOut?.toUpperCase()}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="text-vault-gray-hover"> Swap Slippage:</div>
          <div className="text-vault-primary">{slippage}%</div>
        </div>

        {/*    <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="text-vault-gray-hover">Min. Received:</div>
          {minReceived0 && minReceived1 && (
            <div className="text-vault-primary">
              ({shortenNumber(Number(minReceived0))} {symbol0.toUpperCase()} + {shortenNumber(Number(minReceived1))}{' '}
              {symbol1.toUpperCase()}){' '}
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default ExpectedZapinResult;
