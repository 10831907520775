import { DetermineStatusProps, Status } from '../types';

export function determineDepositStatus({
  useNativeToken,
  nativeSymbol0,
  nativeSymbol1,
  isZapin,
  selectedZapinToken,
  signatureNative,
  signatureStandard,
  token0Allowance,
  token1Allowance,
  parsedInp0,
  parsedInp1,
}: DetermineStatusProps): Status {
  const hasMinimumBalanceToken0 =
    // @ts-ignore
    (useNativeToken && nativeSymbol0) || ((token0Allowance || BigInt(0)) >= parsedInp0 && parsedInp0 > BigInt(0));

  const hasMinimumBalanceToken1 =
    // @ts-ignore
    (useNativeToken && nativeSymbol1) || ((token1Allowance || BigInt(0)) >= parsedInp1 && parsedInp1 > BigInt(0));

  let newStatus = Status.APPROVE_0;

  if (useNativeToken && nativeSymbol0 && isZapin && selectedZapinToken === 0) {
    newStatus = Status.DEPOSIT;
  } else if (useNativeToken && nativeSymbol1 && isZapin && selectedZapinToken === 1) {
    newStatus = Status.DEPOSIT;
  } else if ((useNativeToken && nativeSymbol0) || (isZapin && selectedZapinToken === 1)) {
    newStatus = Status.APPROVE_1;

    // @ts-ignore
    if (hasMinimumBalanceToken1) {
      newStatus = Status.SIGN;
      /* if (signatureStandard) {
          newStatus = Status.DEPOSIT;
        } */
      // temporary until permit zapin works
      if (isZapin) {
        newStatus = Status.DEPOSIT;
      }
    }

    if (newStatus === Status.SIGN && signatureNative) {
      newStatus = Status.DEPOSIT;
    }
    // @ts-ignore
  } else if ((useNativeToken && nativeSymbol1) || (isZapin && selectedZapinToken === 0)) {
    // @ts-ignore
    if (hasMinimumBalanceToken0) {
      newStatus = Status.SIGN;

      // temporary until permit zapin works
      if (isZapin) {
        newStatus = Status.DEPOSIT;
      }

      if (signatureStandard) {
        newStatus = Status.DEPOSIT;
      }
    }

    if (newStatus === Status.SIGN && signatureNative) {
      newStatus = Status.DEPOSIT;
    }
    // @ts-ignore
  } else {
    // @ts-ignore
    if (hasMinimumBalanceToken0) {
      // @ts-ignore
      newStatus = Status.APPROVE_1;
    }
    // @ts-ignore
    if (
      // @ts-ignore
      (token1Allowance || BigInt(0)) >= parsedInp1 &&
      // @ts-ignore
      (token0Allowance || BigInt(0)) >= parsedInp0 &&
      parsedInp0 > BigInt(0) &&
      parsedInp1 > BigInt(0)
    ) {
      newStatus = Status.SIGN;
    }

    if (newStatus === Status.SIGN && signatureStandard) {
      newStatus = Status.DEPOSIT;
    }
  }

  return newStatus;
}

export const lostPrecision = (input: string) => {
  if (Number(input).toString().length === input.length) {
    return false;
  }
  return true;
};
