import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { track } from '@vercel/analytics/react';
import axios from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { LiquidityConcentration, RebalanceSpeed, SetFormValue, StrategySchema } from '../../../../types/create-vault';
import { URL } from '../../../../utils/api';
import { roundToNearestTokenRatio } from '../../../../utils/math';
import { sortTokensByHexValue } from '../../../../utils/vault-creation';
import FormSection from '../../FormSection';
import LiquidityConcentrationSelection from './LiquidityContentration';
import MaxGasPrice from './MaxGasPrice';

interface StrategyEditorProps {
  setValue: SetFormValue;
  strategy: string;
  error?: FieldError;
  disabled?: boolean;
  ownTokenAddress: string;
  baseTokenAddress: string;
  tokenRatio: number;
  feeTier: number;
  chainId: number;
  children?: ReactNode;
  initialValues?: {
    twapDuration: number;
    maxTwapDeviation: number;
    concentration: LiquidityConcentration;
    rebalanceSpeed: RebalanceSpeed;
    maxGasPrice: string;
  };
  sqrtPriceX96?: bigint;
  type?: 'create' | 'edit';
}

function StrategyEditor({
  setValue,
  ownTokenAddress,
  baseTokenAddress,
  tokenRatio,
  feeTier,
  chainId,
  children,
  initialValues,
  sqrtPriceX96,
  type = 'create',
}: StrategyEditorProps) {
  const [showAdvancedFields, setShowAdvancedFields] = useState(false);

  const [concentration, setConcentration] = useState<LiquidityConcentration>(
    initialValues?.concentration || LiquidityConcentration.moderate,
  );

  const {
    control: controlMaxGasPrice,
    setValue: setValueMaxGasPrice,
    watch: watch,
  } = useForm<{ maxGasPrice: string }>({
    defaultValues: { maxGasPrice: initialValues?.maxGasPrice || (chainId === 137 ? '2000' : '100') },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const maxGasPriceGwei = watch('maxGasPrice');

  const [token0, token1] = sortTokensByHexValue(ownTokenAddress, baseTokenAddress);

  const query = [
    'config-json',
    token0,
    token1,
    feeTier,
    tokenRatio,
    concentration,
    maxGasPriceGwei,
    sqrtPriceX96?.toString(),
  ];

  const { data } = useQuery(query, async () => {
    const response = await axios.post(`${URL}/api/vault/strategy/config-json`, {
      assetIsTokenZero: token0 === ownTokenAddress,
      maxGasPrice: Number(maxGasPriceGwei) * 1e9,
      feeTier,
      token0,
      token1,
      twapDuration: initialValues?.twapDuration || 1200,
      maxTwapDeviation: initialValues?.maxTwapDeviation || 200,
      startingRatio:
        // token ratio is always defined as token 1 / token 0 in uniswap
        // on the frontend however we always display it as own token / base token
        token0 === ownTokenAddress ? roundToNearestTokenRatio(1 - tokenRatio) : roundToNearestTokenRatio(tokenRatio),
      concentrationConfig: concentration,
      rebalanceConfig: initialValues?.rebalanceSpeed || RebalanceSpeed.moderate,
      initialSqrtPriceX96: sqrtPriceX96?.toString(),
      initialToleranceBPS: 200,
    });
    return response.data;
  });

  // update strategy json whenever data changes
  useEffect(() => {
    if (data) {
      const parsedStrategy = StrategySchema.safeParse(data.config);

      if (!parsedStrategy.success) {
        console.log('error', parsedStrategy.error);
        return;
      }

      setValue('strategy', JSON.stringify(parsedStrategy.data));
    }
  }, [data, setValue]);

  function onShowAdvancedFields() {
    track('show advanced fields', {
      step: 'strategy',
      type: 'click',
      feature: 'vault creation',
    });
    setShowAdvancedFields(true);
  }

  const description =
    type === 'create'
      ? 'Here you can finetune your strategy. If you are not sure about what values to choose, you can simply continue with the default values.'
      : 'Here you can update your strategy. To update your strategy you first need to set yourself as a delegate which means you are in control of the strategy.';

  return (
    <div>
      <FormSection title="Strategy" description={description} />

      <div className="flex flex-col gap-5 mb-20">
        {data?.warning && (
          <div className="text-vault-orange-2 flex items-center gap-2 text-sm">
            <ExclamationTriangleIcon width={18} height={18} />
            {data.warning}
          </div>
        )}
        {children}

        <LiquidityConcentrationSelection concentration={concentration} setConcentration={setConcentration} />
      </div>

      <div className="bg-dark-grey-new/80 rounded-[12px] p-4 flex flex-col gap-6">
        {!showAdvancedFields && (
          <div className="rounded-full underline decoration-dashed underline-offset-8 flex-none py-1 px-2 text-[16px] leading-5 tracking-[-0.16px]">
            <button tabIndex={-1} onClick={onShowAdvancedFields} className="flex items-center gap-2">
              See advanced configuration
            </button>
          </div>
        )}
        {showAdvancedFields && (
          <div className="text-vault-gray-hover rounded-full underline decoration-dashed underline-offset-8 flex-none py-1 px-2 text-[16px] leading-5 tracking-[-0.16px]">
            <button tabIndex={-1} onClick={() => setShowAdvancedFields(false)} className="flex items-center gap-2">
              Hide advanced configuration
            </button>
          </div>
        )}
        <div className="flex items-center gap-1 text-vault-orange-2 -mt-4">
          <ExclamationTriangleIcon width={18} height={18} /> Proceeed with caution!
        </div>
        {showAdvancedFields && (
          <>
            <MaxGasPrice
              maxGasPrice={maxGasPriceGwei}
              setMaxGasPrice={setValueMaxGasPrice}
              control={controlMaxGasPrice}
            />

            {/*  <TwapParams
              twapDuration={twapDuration}
              setTwapDuration={setTwapDuration}
              maxTwapDeviation={maxTwapDeviation}
              setMaxTwapDeviation={setMaxTwapDeviation}
            /> */}
          </>
        )}
      </div>
    </div>
  );
}

export default StrategyEditor;
