import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Explanation } from '../../../../types';
import { LiquidityConcentration } from '../../../../types/create-vault';
import Badge from '../../../elements/buttons/Badge';
import QuestionButton from '../../../layout/sidebar/QuestionButton';

interface LiquidityConcentrationSelectionProps {
  concentration: LiquidityConcentration;
  setConcentration: (concentration: LiquidityConcentration) => void;
}

function LiquidityConcentrationSelection({ concentration, setConcentration }: LiquidityConcentrationSelectionProps) {
  return (
    <div>
      <div className="flex items-center gap-2 mt-5 ">
        <h2 className="text-[16px] leading-[20px] tracking-[-0.16px]">How concentrated should the liquidity be?</h2>

        <QuestionButton explanation={Explanation.LIQUIDITY_CONCENTRATION} title="Liquidity Concentration explained" />
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center gap-2 mt-6">
        <div className="flex items-center gap-2">
          {Object.entries(LiquidityConcentration).map(([key, value], index) => (
            <Badge
              key={index}
              onClick={() => {
                setConcentration(value);
              }}
              isActive={concentration === value}
            >
              {key}
            </Badge>
          ))}
          {concentration === LiquidityConcentration.risky && (
            <div
              // @ts-ignore
              style={{ textWrap: 'balance' }}
              className="text-vault-orange flex gap-1 items-center ml-5 text-sm text-balance"
            >
              <ExclamationTriangleIcon className="w-5 h-5" />
              This strategy is risky. You may lose your money.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LiquidityConcentrationSelection;
