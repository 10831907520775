import { z } from 'zod';
import { IVault, SwapAndAddLiquidityArgs } from '../../types';

export interface GetSwapAndAddLiquidityParamsProps {
  vault: IVault;
  amount0: bigint;
  amount1: bigint;
  selectedZapinToken: 0 | 1;
  slippage: number;
  walletAddress: string;
}

export const GetSwapResponseSchema = z.object({
  success: z.boolean(),
  swapAmountIn: z.string(),
  swapAmountOut: z.string(),
  minAmountOut: z.string(),
  zeroForOne: z.boolean(),
  swapRouter: z.string(),
  swapPayload: z.string(),
});

export type GetSwapResponse = z.infer<typeof GetSwapResponseSchema>;

export interface CalculatePriceX18Props {
  vault: IVault;
  amount0Max: string;
  amount1Max: string;
  zeroForOne: boolean;
}

export interface GetSwapParametersFromSwapResolverProps {
  vault: IVault;
  amount0: bigint;
  amount1: bigint;
  selectedZapinToken: 0 | 1;
  slippage: number;
}

export interface GetSwapAndAddLiquidityPermit2ParamsProps {
  vault: IVault;
  amount0: bigint;
  amount1: bigint;
  signature: string;
  swapAndAddLiquidityArgs: SwapAndAddLiquidityArgs;
  selectedZapinToken: 0 | 1;
}
