import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { LiquidityProfileResponseSchema } from '../../types/charts/liquidity-profile';
import { getLiquidityProfileChartOptions } from '../../utils/charts/liquidity-profile';
import ChartLoader from './ChartLoader';
import NoData from './NoData';

interface LiquidityProfileChartProps {
  vaultId: string;
}

function LiquidityProfileChart({ vaultId }: LiquidityProfileChartProps) {
  async function fetchLiquidityProfile(vaultId: string) {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/api/analytics/v2/liquidity-profile/${vaultId}`,
        {
          timeout: 6000,
        },
      );

      const parsedData = LiquidityProfileResponseSchema.safeParse(response.data);

      if (!parsedData.success) {
        return undefined;
      }

      return parsedData.data.data;
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  }

  const { data: liquidityProfileData, isLoading } = useQuery(['vault-liquidity-profile', vaultId], async () => {
    return await fetchLiquidityProfile(vaultId);
  });

  const options = liquidityProfileData ? getLiquidityProfileChartOptions(liquidityProfileData) : {};

  return (
    <div className="flex items-center gap-5">
      <div className="grow">
        {isLoading && <ChartLoader />}
        {liquidityProfileData && options.series && (
          <ReactApexChart options={options} series={options.series} height={380} type="bar" />
        )}
        {!liquidityProfileData && !isLoading && <NoData />}
      </div>
      {/*  <div className="flex flex-col gap-4">
      <ChartStat title="Value of the Generated Fees" value="$179.25k" />
      <ChartStat title="Generated USDC Fees" value="26.69 wETH" />
      <ChartStat title="Generated GEL Fees" value="266,832.00 GEL" />
    </div> */}
    </div>
  );
}

export default LiquidityProfileChart;
