function NetworkExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/network-abstract.png"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />
      <h2 className="text-lg font-bold mb-1">What is a network?</h2>
      <p>
        A network is a blockchain that has its own native currency. For example, Ethereum is a network, and its native
        currency is ETH. Polygon PoS is also a network, and its native currency is MATIC.
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Which network should I use?</h2>
      <p className="mb-2">
        You should select the network for which you want your tokens to be liquid. For example, if you want your tokens
        to be liquid on Ethereum, you should select the Ethereum network. Your token also has to be available on the
        network you select.
      </p>
      <p className="mb-2">Keep in mind that gas fees are usually lower on Polygon than on Ethereum.</p>
    </div>
  );
}

export default NetworkExplanation;
