function Gradient1() {
  return (
    <svg
      className="absolute top-0 h-screen w-screen pointer-events-none -z-10"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M0 0H1440V900H0V0Z" fill="url(#paint0_radial_743_3914)" fillOpacity="0.4" />
      <defs>
        <radialGradient
          id="paint0_radial_743_3914"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(720) rotate(90) scale(608.5 973.6)"
        >
          <stop stopColor="#ba6015" />
          <stop offset="1" stopColor="#06051C" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default Gradient1;
