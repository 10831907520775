import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';
import { FilterParams, NetworkFilter, NetworkName, QueryParams } from '../../types';
import { capitalizeFirstLetter } from '../../utils/n-formatter';
import Toggle from '../elements/toggle/Toggle';
interface SearchbarProps {
  searchParams: URLSearchParams;
  setSearchParams: any;
  networkFilters: NetworkFilter[];
  showOnlyGaugesFilter?: boolean;
  sortByApr?: boolean;
}

function Searchbar({
  searchParams,
  setSearchParams,
  networkFilters,
  showOnlyGaugesFilter = true,
  sortByApr = true,
}: SearchbarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isNetworkFilterOpen, setIsNetworkFilterOpen] = useState(false);

  const [searchFilter, setSearchFilter] = useState<string>(searchParams.get('name') || '');

  const debouncedSearchFilter = useDebounce(searchFilter, 300);

  // close dropdown when clicking outside
  const networkSelectRef = useRef(null);
  useOutsideAlerter(networkSelectRef, () => setIsNetworkFilterOpen(false));

  const sortSelectRef = useRef(null);
  useOutsideAlerter(sortSelectRef, () => setIsOpen(false));

  // update search params when search filter changes
  useEffect(() => {
    setSearchParams((oldParams: any) => {
      const networks = oldParams.get('networks');
      const sortDirection = oldParams.get('sortDirection');
      const sort = oldParams.get('sort');
      const onlyGauges = oldParams.get('onlyGauges');

      const newParams: QueryParams = {};

      if (debouncedSearchFilter) {
        newParams.name = debouncedSearchFilter;
      }

      if (networks) {
        newParams.networks = networks;
      }

      if (sortDirection) {
        newParams.sortDirection = sortDirection;
      }

      if (sort) {
        newParams.sort = sort;
      }

      if (onlyGauges) {
        newParams.onlyGauges = onlyGauges;
      }

      return newParams;
    });
  }, [debouncedSearchFilter, setSearchParams]);

  function updateNetworkFilter(network?: NetworkName) {
    setSearchParams((oldParams: any) => {
      const sortDirection = oldParams.get('sortDirection');
      const sort = oldParams.get('sort');
      const onlyGauges = oldParams.get('onlyGauges');
      const name = oldParams.get('name');

      const newParams: FilterParams = network
        ? {
            networks: network,
          }
        : {};

      if (sortDirection) {
        newParams.sortDirection = sortDirection;
      }

      if (sort) {
        newParams.sort = sort;
      }

      if (onlyGauges) {
        newParams.onlyGauges = onlyGauges;
      }

      if (name) {
        newParams.name = name;
      }

      return newParams;
    });
    setIsNetworkFilterOpen(false);
  }

  function updateSorting(sort?: string, sortDirection?: 'asc' | 'desc') {
    setSearchParams((oldParams: any) => {
      const network = oldParams.get('networks');
      const onlyGauges = oldParams.get('onlyGauges');
      const name = oldParams.get('name');

      const newParams: FilterParams = {};

      if (sortDirection && sort) {
        newParams.sort = sort;
        newParams.sortDirection = sortDirection;
      }

      if (network) {
        newParams.networks = network;
      }

      if (onlyGauges) {
        newParams.onlyGauges = onlyGauges;
      }

      if (name) {
        newParams.name = name;
      }

      return newParams;
    });
    setIsOpen(false);
  }

  const onlyGaugesFilter = searchParams.get('onlyGauges') === 'true';

  function updateOnlyGauges(onlyGauges: boolean) {
    setSearchParams((oldParams: any) => {
      const network = oldParams.get('networks');
      const sortDirection = oldParams.get('sortDirection');
      const sort = oldParams.get('sort');
      const name = oldParams.get('name');

      const newParams: any = onlyGauges
        ? {
            onlyGauges: true,
          }
        : {};

      if (network) {
        newParams.networks = network;
      }

      if (sortDirection) {
        newParams.sortDirection = sortDirection;
      }

      if (sort) {
        newParams.sort = sort;
      }

      if (name) {
        newParams.name = name;
      }

      return newParams;
    });
  }

  const selectedSorting = () => {
    const sortDirection = searchParams.get('sortDirection');

    const icon = sortDirection === 'desc' ? <ArrowDownIcon className="h-5 w-5" /> : <ArrowUpIcon className="h-5 w-5" />;

    switch (searchParams.get('sort')) {
      case 'gauge.rewardApr':
        return <span className="flex items-center gap-1">APR {icon}</span>;
      case 'tvl':
        return <span className="flex items-center gap-1">TVL {icon}</span>;
      case 'liquidity':
        return <span className="flex items-center gap-1">Liquidity {icon}</span>;
      default:
        return <span className="flex items-center gap-1">TVL {icon}</span>;
    }
  };

  return (
    <div id="searchbar" className="relative mt-6 mb-4 w-full max-w-screen-lg">
      <div className="flex gap-2 flex-col sm:flex-row w-full">
        <div className="bg-vault-black rounded-[12px] hover:bg-[#1F1F21] grow duration-500 bg-opacity-60 hover:bg-opacity-60">
          <input
            className="w-full form-input py-[12px] psm:px-[16px] leading-[20px] text-[16px] tracking-[-0.01em] rounded-[12px] text-vault-gray placeholder-vault-gray bg-transparent border-1 border-[#1F1F21]  focus:outline-none focus:border-none focus:ring-vault-gray  duration-500 ease-in-out"
            type="text"
            spellCheck={false}
            value={searchFilter}
            placeholder="Search any tokens..."
            onChange={(event) => setSearchFilter(event.target.value)}
          />
        </div>
        <div className="gap-2 items-center hidden sm:flex">
          {showOnlyGaugesFilter && (
            <div className="relative inline-flex items-center py-[12px] px-[16px] rounded-[12px] gap-2 bg-vault-black  bg-opacity-100 text-vault-gray hover:bg-[#1F1F21] duration-500 ease-in-out">
              <div className="text-[16px] grow flex gap-2">
                Incentivized <span className="hidden sm:block">Vaults</span>{' '}
                <Toggle
                  id="only-gauges-toggle"
                  checked={onlyGaugesFilter}
                  onChange={() => updateOnlyGauges(!onlyGaugesFilter)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between w-full gap-2">
        <div className="my-3 text-vault-light text-[16px] flex items-center gap-2 flex-wrap grow sm:grow-0">
          <div className="relative max-w-none sm:max-w-fit w-full flex">
            <button
              className={`relative inline-flex items-center justify-between py-[12px] px-[16px] rounded-[12px] w-full text-vault-gray  bg-opacity-100 hover:bg-[#1F1F21] duration-500 ease-in-out ${
                isOpen ? 'bg-vault-black' : 'bg-vault-black'
              }`}
              onClick={() => setIsNetworkFilterOpen(!isNetworkFilterOpen)}
            >
              Network{' '}
              <span className="text-vault-white ml-2">{capitalizeFirstLetter(searchParams.get('networks') || '')}</span>
              <ChevronDownIcon className="h-6 w-6 text-vault-gray ml-2" />
            </button>

            {isNetworkFilterOpen && (
              <div
                ref={networkSelectRef}
                className="absolute right-[-180px] z-50 w-48 mt-2 origin-top-right rounded-md shadow-lg"
              >
                <div className="py-1 rounded-md bg-vault-black shadow-xs  bg-opacity-100">
                  {networkFilters.map((networkFilter) => (
                    <button
                      key={networkFilter.key}
                      onClick={() => updateNetworkFilter(networkFilter.key as NetworkName)}
                      className="w-full flex items-center px-4 py-2 text-sm leading-5 text-vault-white hover:bg-[#0f0f0f] focus:outline-grey"
                    >
                      <div className=" flex gap-2">
                        <div
                          className="w-[20px] h-[20px]"
                          dangerouslySetInnerHTML={{ __html: networkFilter.logoInnerHtml }}
                        ></div>
                        {networkFilter.name}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="grow sm:grow-0">
          <button
            className={`relative inline-flex items-center py-[12px] px-[16px] rounded-[12px] text-vault-gray w-full justify-between  bg-opacity-100 hover:bg-[#1F1F21] duration-500 ease-in-out ${
              isOpen ? 'bg-vault-black' : 'bg-vault-black'
            }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            Sort <span className="hidden sm:block ml-1"> by</span>
            <span className="text-vault-white ml-2">{selectedSorting()}</span>
            <ChevronDownIcon className="h-6 w-6 text-vault-gray ml-2" />
          </button>
        </div>
      </div>

      {isOpen && (
        <div ref={sortSelectRef} className="absolute right-0 z-50 w-48 mt-2 origin-top-right rounded-md shadow-lg">
          <div className="py-1 rounded-md bg-vault-black shadow-xs  bg-opacity-100">
            <button
              onClick={() => updateSorting('gauge.rewardApr', 'desc')}
              className="w-full flex items-center px-4 py-2 text-sm leading-5 text-vault-white hover:bg-[#0f0f0f] focus:outline-grey"
            >
              APR <ArrowDownIcon className="h-5 w-5 ml-2" />
            </button>
            <button
              onClick={() => updateSorting('gauge.rewardApr', 'asc')}
              className="w-full flex items-center px-4 py-2 text-sm leading-5 text-vault-white hover:bg-[#0f0f0f] focus:outline-grey"
            >
              APR <ArrowUpIcon className="h-5 w-5 ml-2" />
            </button>
            <button
              onClick={() => updateSorting('tvl', 'desc')}
              className="w-full flex items-center px-4 py-2 text-sm leading-5 text-vault-white hover:bg-[#0f0f0f] focus:outline-grey"
            >
              TVL <ArrowDownIcon className="h-5 w-5 ml-2" />
            </button>

            <button
              onClick={() => updateSorting('tvl', 'asc')}
              className="w-full flex items-center px-4 py-2 text-sm leading-5 text-vault-white  hover:bg-[#0f0f0f]  focus:outline-grey"
            >
              TVL <ArrowUpIcon className="h-5 w-5 ml-2" />
            </button>
            {sortByApr && (
              <>
                <button
                  onClick={() => updateSorting('averageApr', 'desc')}
                  className="w-full flex items-center px-4 py-2 text-sm leading-5 text-vault-white  hover:bg-[#0f0f0f]  focus:outline-grey"
                >
                  APR <ArrowDownIcon className="h-5 w-5 ml-2" />
                </button>

                <button
                  onClick={() => updateSorting('averageApr', 'asc')}
                  className="w-full flex items-center px-4 py-2 text-sm leading-5 text-vault-white  hover:bg-[#0f0f0f]  focus:outline-grey"
                >
                  APR <ArrowUpIcon className="h-5 w-5 ml-2" />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Searchbar;
