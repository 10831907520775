import { track } from '@vercel/analytics/react';
import React, { useEffect } from 'react';
import { zeroAddress } from 'viem';
import { useAccount } from 'wagmi';
import { ARRAKIS_V2_TERMS_ROUTER_ADDRESS } from '../../../../constants';
import { useCreateVault } from '../../../../hooks/useCreateVault';
import { useTokenApprovals } from '../../../../hooks/useTokenApprovals';
import { CreateVaultTransactionStatus } from '../../../../types';
import PlaceholderTransactionButton from '../../../elements/buttons/PlaceholderTransactionButton';
import TransactionButton from '../../../elements/buttons/TransactionButton';
import TransactionButtonWrapper from '../../../elements/buttons/TransactionButtonWrapper';

interface CreateVaultTransactionProps {
  token0: `0x${string}`;
  token1: `0x${string}`;
  symbol0: string;
  symbol1: string;
  ownerAddress: `0x${string}`;
  amount0Bn: bigint;
  amount1Bn: bigint;
  chainId: number;
  feeTier: number;
  gasTankAmount: bigint;
  onSuccess: () => void;
  onStartTransaction: () => void;
  strategy: string;
}

function CreateVaultTransaction({
  amount0Bn,
  amount1Bn,
  symbol0,
  symbol1,
  token0,
  token1,
  chainId,
  feeTier,
  ownerAddress,
  gasTankAmount,
  onSuccess,
  onStartTransaction,
  strategy,
}: CreateVaultTransactionProps) {
  const [status, setStatus] = React.useState<CreateVaultTransactionStatus>(CreateVaultTransactionStatus.APPROVE_0);

  const { address, connector } = useAccount();

  const isSafeWallet = connector?.id === 'safe';

  const [isLoadingTransactionManual, setIsLoadingTransactionManual] = React.useState(false);

  const { isLoadingApprove0, isLoadingApprove1, approve0, approve1, isApproved0, isApproved1 } = useTokenApprovals({
    tokenAddress0: token0,
    tokenAddress1: token1,
    grantedContractAddress: ARRAKIS_V2_TERMS_ROUTER_ADDRESS,
    allowanceAmount0: amount0Bn,
    allowanceAmount1: amount1Bn,
    chainId,
    userAddress: address || zeroAddress,
  });

  useEffect(() => {
    if (isApproved0 && isApproved1) {
      setStatus(CreateVaultTransactionStatus.TRANSACTION);
    } else if (isApproved0) {
      setStatus(CreateVaultTransactionStatus.APPROVE_1);
    } else {
      setStatus(CreateVaultTransactionStatus.APPROVE_0);
    }
  }, [isApproved0, isApproved1]);

  const { createVault, isLoadingCreateVault } = useCreateVault({
    token0,
    token1,
    amount0: amount0Bn,
    amount1: amount1Bn,
    chainId,
    ownerAddress,
    gasTankAmount,
    strategy,
    feeTier,
    onSuccess: () => {
      onSuccess();
      setIsLoadingTransactionManual(false);
    },
  });

  return (
    <TransactionButtonWrapper chainId={chainId}>
      <div>
        {status === CreateVaultTransactionStatus.APPROVE_0 && (
          <div className="mb-5 mt-5 flex gap-3 items-center">
            <TransactionButton
              text={`Approve ${symbol0} `}
              isLoading={isLoadingApprove0}
              isError={false}
              isDisabled={approve0 === undefined}
              onClick={() => approve0?.()}
              useLegacyDesign={true}
            />

            <PlaceholderTransactionButton text="2" />
            <PlaceholderTransactionButton text="3" />
          </div>
        )}
        {status === CreateVaultTransactionStatus.APPROVE_1 && (
          <div className="mb-5 mt-5 flex gap-3 items-center">
            <PlaceholderTransactionButton text="1" isCompleted={true} />

            <TransactionButton
              text={`Approve ${symbol1}`}
              isLoading={isLoadingApprove1}
              isError={false}
              isDisabled={approve1 === undefined}
              onClick={() => approve1?.()}
              useLegacyDesign={true}
            />

            <PlaceholderTransactionButton text="3" />
          </div>
        )}
        {status === CreateVaultTransactionStatus.TRANSACTION && (
          <div className="mb-5 mt-5 flex gap-3 items-center">
            <PlaceholderTransactionButton text="1" isCompleted={true} />

            <PlaceholderTransactionButton text="2" isCompleted={true} />

            <TransactionButton
              text="Deploy Vault"
              isLoading={isLoadingCreateVault || (isLoadingTransactionManual && isSafeWallet)}
              isError={false}
              isDisabled={createVault === undefined}
              onClick={() => {
                track('create vault', {
                  step: 'transaction',
                  type: 'click',
                  feature: 'vault creation',
                });
                setIsLoadingTransactionManual(true);
                onStartTransaction();
                createVault?.();
              }}
              useLegacyDesign={true}
            />
          </div>
        )}
      </div>
    </TransactionButtonWrapper>
  );
}

export default CreateVaultTransaction;
