import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
// eslint-disable-next-line import/named
import { EncodeFunctionDataParameters, WriteContractReturnType, encodeFunctionData } from 'viem';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { MultiCall3Abi } from '../abis/MultiCall3Abi';
import { MULTICALL3_ADDRESS } from '../constants/transactions';

interface BatchTransactionsHookProps {
  calls: (EncodeFunctionDataParameters & { target: string })[];
  chainId: number;
  onSuccess: (hash: WriteContractReturnType | undefined) => void;
}

export const useBatchTransactions = ({ calls, chainId, onSuccess }: BatchTransactionsHookProps) => {
  const addRecentTransaction = useAddRecentTransaction();

  const callData = calls.map((call) => ({
    target: call.target,
    callData: encodeFunctionData(call),
  }));

  const poolCallArgs = callData.map((c) => ({
    target: c.target,
    allowFailure: false,
    callData: c.callData,
  }));

  const { config: batchCallConfig } = usePrepareContractWrite({
    address: MULTICALL3_ADDRESS,
    abi: MultiCall3Abi,
    functionName: 'aggregate3',
    args: [poolCallArgs],
    chainId,
    value: BigInt(0),
  });

  const { data, write, isLoading } = useContractWrite({
    ...(batchCallConfig as any),
    onError(error) {
      console.log('error', error);
    },
  });

  const { isLoading: isLoadingAfter } = useWaitForTransaction({
    hash: data?.hash,
    onSettled(result, error) {
      if (error) {
        return;
      }

      addRecentTransaction({
        hash: data?.hash ?? '',
        description: 'Create Pool',
      });

      onSuccess(data?.hash);
    },
  });

  return {
    isLoading: isLoading || isLoadingAfter,
    write,
    data,
  };
};
