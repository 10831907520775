import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { track } from '@vercel/analytics/react';
import { useAccount, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ArrakisV2RouterAbi } from '../../../../abis/ArrakisV2Router';
import { ARRAKIS_V2_ROUTER_ADDRESSES } from '../../../../constants';
import { DepositFormWrapperState, IVault, SwapAndAddLiquidityArgs } from '../../../../types';
import { ExplorerDataType, getExplorerLink } from '../../../../utils/get-explorer-link';
import { alertUser } from '../../../elements/notify';
import { Spinner } from '../../../elements/spinner';

interface ZapinDepositButtonProps {
  vault: IVault;
  isSafeWallet: boolean;
  setDepositFormWrapperState: (state: DepositFormWrapperState) => void;
  startConfettiAnimation: () => void;
  token0InputUsd: number;
  token1InputUsd: number;
  swapAndAddLiquidityParams: SwapAndAddLiquidityArgs | undefined;
  useNativeToken: boolean;
  nativeTokenValue: bigint;
  isDisabled: boolean;
}

function ZapinDepositButton({
  vault,
  isSafeWallet,
  setDepositFormWrapperState,
  startConfettiAnimation,
  token0InputUsd,
  token1InputUsd,
  swapAndAddLiquidityParams,
  useNativeToken,
  nativeTokenValue,
  isDisabled,
}: ZapinDepositButtonProps) {
  const addRecentTransaction = useAddRecentTransaction();

  const { address } = useAccount();

  /* const swapAndAddLiquidityPermit2Params = transformSwapAndAddLiquidityArgsToPermit2({
    vault,
    swapAndAddLiquidityArgs: swapAndAddLiquidityParams,
    signature: permitSignature,
    amount0,
    amount1,
    selectedZapinToken,
  }); */

  const config = {
    address: ARRAKIS_V2_ROUTER_ADDRESSES as '0x${string}', // 0x6aC8Bab8B775a03b8B72B2940251432442f61B94
    chainId: vault.chainId,
    abi: ArrakisV2RouterAbi,
    functionName: 'swapAndAddLiquidity',
    args: swapAndAddLiquidityParams,
    value: useNativeToken ? nativeTokenValue : BigInt(0),
    watch: true,
    enabled: true,
  };

  const { config: swapAndAddLiquidityPermit2Config } = usePrepareContractWrite(config);

  const {
    data: swapAndAddLiquidityData,
    write: swapAndAddLiquidity,
    isLoading: isSwapAndAddLiquidityLoading,
  } = useContractWrite({
    ...(swapAndAddLiquidityPermit2Config as any),
    onError(error) {
      console.log('error', error);
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isSwapAndAddLiquidityLoadingAfter } = useWaitForTransaction({
    hash: swapAndAddLiquidityData?.hash,
    onSettled(data, error) {
      if (error) {
        console.log('error', error);
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      startConfettiAnimation();
      alertUser(
        'success',
        'Successfully added liquidity!',
        getExplorerLink(vault.chainId || 137, swapAndAddLiquidityData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('deposit zapin v2', {
        type: 'click',
        feature: 'vault deposit form v2',
        wallet: address as string,
        vault: vault.id,
        value: token0InputUsd + token1InputUsd,
      });

      setDepositFormWrapperState(DepositFormWrapperState.DEPOSIT_SUCCESS);

      addRecentTransaction({
        hash: swapAndAddLiquidityData?.hash ?? '',
        description: 'Increase Liquidity',
      });
    },
  });

  const isButtonLoading = isSwapAndAddLiquidityLoading || isSwapAndAddLiquidityLoadingAfter;

  return (
    <div className="flex items-center gap-2 grow">
      <button
        type="button"
        disabled={isButtonLoading || !swapAndAddLiquidityParams || isDisabled}
        onClick={() => swapAndAddLiquidity?.()}
        className={`flex flex-row justify-center items-center  rounded-[12px] h-[64px] p-2 grow
           ${
             isButtonLoading || !swapAndAddLiquidityParams || isDisabled
               ? 'bg-vault-gray-2 text-vault-gray'
               : 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
           }`}
      >
        <span> Add Liquidity & Stake</span>
        {isButtonLoading && (
          <span className="mx-2">
            <Spinner color="vault-white" />
          </span>
        )}
      </button>
    </div>
  );
}

export default ZapinDepositButton;
