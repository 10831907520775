import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { useEffect, useMemo, useState } from 'react';
import { isAddress } from 'viem';
import { useContractRead, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ArrakisV2PalmTermsAbi } from '../abis/ArrakisV2PalmTerms';
import { alertUser } from '../components/elements/notify';
import { ARRAKIS_V2_TERMS_ROUTER_ADDRESS } from '../constants';
import { ExplorerDataType, getExplorerLink } from '../utils/get-explorer-link';
import { getDelegateAddressByChainId } from '../utils/vault-creation';

export enum DelegateStatus {
  ARRAKIS = 'ARAKIS', // arrakis is the delegate
  OWNER = 'OWNER', // zero address is the delegate
}

interface SetDelegateHookResult {
  delegateStatus: DelegateStatus;
  isLoadingSetDelegate: boolean;
  setDelegate: (() => void) | undefined;
}

interface SetDelegateHookProps {
  vaultAddress: string;
  walletAddress: string;
  chainId: number;
  onFinish?: () => void;
}

export function useSetDelegate({
  vaultAddress,
  chainId,
  walletAddress,
  onFinish,
}: SetDelegateHookProps): SetDelegateHookResult {
  const [delegateStatus, setDelegateStatus] = useState<DelegateStatus>(DelegateStatus.ARRAKIS);

  const { data: currentDelegate } = useContractRead({
    address: ARRAKIS_V2_TERMS_ROUTER_ADDRESS as '0x${string}',
    abi: ArrakisV2PalmTermsAbi,
    functionName: 'delegateByVaults',
    args: [vaultAddress],
    chainId,
    watch: true,
    select: (data: unknown) => (isAddress(data as string) ? data : undefined),
  });

  useEffect(() => {
    if (currentDelegate === getDelegateAddressByChainId(chainId)) {
      setDelegateStatus(DelegateStatus.ARRAKIS);
    } else if (currentDelegate === walletAddress) {
      setDelegateStatus(DelegateStatus.OWNER);
    }
  }, [currentDelegate, vaultAddress, chainId, walletAddress]);

  const addRecentTransaction = useAddRecentTransaction();

  const delegateArgs = useMemo(() => {
    function getDelegate(status: DelegateStatus) {
      switch (status) {
        case DelegateStatus.ARRAKIS:
          return walletAddress;
        case DelegateStatus.OWNER:
          return getDelegateAddressByChainId(chainId);
        default:
          break;
      }
    }

    const nextDelegate = getDelegate(delegateStatus);
    return [vaultAddress, nextDelegate];
  }, [delegateStatus, vaultAddress, walletAddress, chainId]);

  const { config } = usePrepareContractWrite({
    address: ARRAKIS_V2_TERMS_ROUTER_ADDRESS as '0x${string}',
    abi: ArrakisV2PalmTermsAbi,
    functionName: 'setDelegate',
    args: delegateArgs,
    chainId,
    value: BigInt(0),
    onError(error) {
      console.log('error', error);
    },
  });

  const {
    data: setDelegateData,
    write: setDelegate,
    isLoading: isLoadingSetDelegate,
  } = useContractWrite({
    ...(config as any),
    onError(error) {
      console.log('error', error);
    },
    onSettled(data, error) {
      console.log('error', error);
      if (delegateStatus === DelegateStatus.OWNER) {
        onFinish?.();
      }
    },
  });

  const { isLoading: isLoadingSetDelegateAfter } = useWaitForTransaction({
    hash: setDelegateData?.hash,
    onSettled(data, error) {
      if (error) {
        console.log('error', error);
        return;
      }
      alertUser(
        'success',
        'Delegate updated successfully!',
        getExplorerLink(chainId || 1, setDelegateData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        false,
      );

      addRecentTransaction({
        hash: setDelegateData?.hash ?? '',
        description: 'Create Pool',
      });
    },
  });

  return {
    delegateStatus,
    isLoadingSetDelegate: isLoadingSetDelegate || isLoadingSetDelegateAfter,
    setDelegate,
  };
}
