function GasPriceExplanation() {
  return (
    <div>
      <h2 className="text-lg font-bold mb-1">What is the maximum gas price?</h2>
      <p className="mb-3">
        The maximum gas price is the maximum gas price you are willing to use for any active management transaction on
        your Arrakis vault.
      </p>
      <p className="mb-3">
        A gas price is the amount of network token, per unit of gas, you are willing to spend on a transaction (each
        transaction consumes a certain amount of gas units, so gas price * gas used = total cost). On blockchains the
        gas prices can fluctuate based on the current demand for block space.
      </p>
      <p>
        {' '}
        If the network&apos;s current gas price is above the chosen value for maximum gas price, the Arrakis vault will
        not execute a rebalancing transaction (even if strategy says a rebalance is needed) until the gas price falls
        below this maximum threshold.
      </p>
    </div>
  );
}

export default GasPriceExplanation;
