import { z } from 'zod';

export const PerformanceResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    metadata: z.object({
      symbol0: z.string(),
      symbol1: z.string(),
    }),

    history: z.array(
      z.object({
        timeAt: z.string(),
        vaultUSD: z.number(),
        hodl: z.object({
          benchmarkPCT: z.number(),
          benchmarkUSD: z.number(),
          vaultPCT: z.number(),
          diffUSD: z.number(),
          diffPCT: z.number(),
        }),
        usd: z.object({
          benchmarkPCT: z.number(),
          benchmarkUSD: z.number(),
          vaultPCT: z.number(),
          diffUSD: z.number(),
          diffPCT: z.number(),
        }),
        token0: z.object({
          benchmarkPCT: z.number(),
          benchmarkUSD: z.number(),
          vaultPCT: z.number(),
          diffUSD: z.number(),
          diffPCT: z.number(),
        }),
        token1: z.object({
          benchmarkPCT: z.number(),
          benchmarkUSD: z.number(),
          vaultPCT: z.number(),
          diffUSD: z.number(),
          diffPCT: z.number(),
        }),
      }),
    ),
  }),
  cache: z.boolean(),
});

export type PerformanceResponse = z.infer<typeof PerformanceResponseSchema>;
