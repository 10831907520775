import { useMemo } from 'react';
import { erc20ABI, useContractReads } from 'wagmi';
import { isNativeToken } from '../utils/tokens';
import useTokenApproval from './useTokenApproval';

interface TokenApproveHookProps {
  grantedContractAddress: `0x${string}`;
  allowanceAmount0: bigint;
  allowanceAmount1: bigint;
  tokenAddress0: `0x${string}`;
  tokenAddress1: `0x${string}`;
  chainId: number;
  userAddress: `0x${string}`;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

interface TokenApproveHookResult {
  isLoadingApprove0: boolean;
  isLoadingApprove1: boolean;
  approve0: (() => void) | undefined;
  approve1: (() => void) | undefined;
  isApproved0: boolean;
  isApproved1: boolean;
  isLoadingApprovals: boolean;
}

export const useTokenApprovals = ({
  grantedContractAddress,
  allowanceAmount0,
  tokenAddress0,
  allowanceAmount1,
  tokenAddress1,
  chainId,
  userAddress,
}: TokenApproveHookProps): TokenApproveHookResult => {
  const token0Contract = {
    address: tokenAddress0,
    abi: erc20ABI,
  };

  const token1Contract = {
    address: tokenAddress1,
    abi: erc20ABI,
  };

  const { approve: approve0, isLoadingApprove: isLoadingApprove0 } = useTokenApproval({
    tokenAddress: tokenAddress0,
    grantedContractAddress,
    allowanceAmount: allowanceAmount0,
    chainId,
  });

  const { approve: approve1, isLoadingApprove: isLoadingApprove1 } = useTokenApproval({
    tokenAddress: tokenAddress1,
    grantedContractAddress,
    allowanceAmount: allowanceAmount1,
    chainId,
  });

  const { data: allowances, isLoading: isLoadingApprovals } = useContractReads({
    contracts: [
      {
        ...token0Contract,
        functionName: 'allowance',
        args: [userAddress ?? '0x0000000000000000000000000000000000000000', grantedContractAddress],
      },
      {
        ...token1Contract,
        functionName: 'allowance',
        args: [userAddress ?? '0x0000000000000000000000000000000000000000', grantedContractAddress],
      },
    ],
    watch: true,
  });

  const isApproved0 = useMemo(() => {
    if (isNativeToken(tokenAddress0, chainId)) return true;

    if (!allowances || !allowances.length) return false;
    // @ts-ignore
    return (allowances[0]?.result || BigInt(0)) >= allowanceAmount0;
  }, [allowances, allowanceAmount0, chainId, tokenAddress0]);

  const isApproved1 = useMemo(() => {
    if (isNativeToken(tokenAddress1, chainId)) return true;

    if (!allowances || !allowances.length) return false;
    // @ts-ignore
    return (allowances[1]?.result || BigInt(0)) >= allowanceAmount1;
  }, [allowances, allowanceAmount1, chainId, tokenAddress1]);

  // todo: memoize this

  return { isLoadingApprove0, isLoadingApprove1, approve0, approve1, isLoadingApprovals, isApproved0, isApproved1 };
};
