import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { truncateEthAddress } from '../../../utils/n-formatter';

interface AddressProps {
  chainId: number;
  address: string;
}

function Address({ chainId, address }: AddressProps) {
  return (
    <div className="flex flex-col sm:flex-row items-left sm:items-center gap-1">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={getExplorerLink(chainId, address, ExplorerDataType.ADDRESS)}
        className="flex items-center gap-2 underline underline-offset-8 text-primary-light decoration-dashed"
      >
        <span className="hidden sm:block">{truncateEthAddress(address)}</span>
        <span className="bock sm:hidden">Contract address</span>
      </a>
    </div>
  );
}

export default Address;
