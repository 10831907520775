function FeeTierExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/vault-with-money.png"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />

      <h2 className="text-lg font-bold mb-1">What is a fee tier?</h2>
      <p>
        The fee tier is the percentage of each swap that is paid as a fee to liquidity providers, whenever someone swaps
        on this liquidity pool. When providing liquidity you will receive your share of the collected fees. These get
        shared equally with all the liquidity providers in the pool (you split these swap fees with Arrakis).
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Why are there different fee tiers?</h2>
      <p className="mb-2">
        Different fee tiers exist because some people want to pay less fees when swapping tokens. The lower the fee
        tier, the less fees you will receive when providing liquidity.
      </p>

      <h2 className="text-lg font-bold mb-1 mt-5">Which fee tier should I select?</h2>

      <p className="mb-2">...</p>
    </div>
  );
}

export default FeeTierExplanation;
