import { IVault } from '../../types';

interface MerklVaultProps {
  vault: IVault;
}

const MerklVault = ({ vault }: MerklVaultProps) => {
  // you can't generate a direct link to merk page for a specific vault
  // hackaround: use network and phrase (=single word) to find the vault

  function getMerklQueryParams(vault: IVault) {
    const rarestSymbol = [vault.symbol0.toLowerCase(), vault.symbol1.toLowerCase()].includes('reth')
      ? vault.symbol1
      : vault.symbol0;

    return `?chains=${vault.chainId}&phrase=${rarestSymbol}`;
  }

  return (
    <a target="_blank" rel="noreferrer" href={`https://merkl.angle.money${getMerklQueryParams(vault)}`}>
      <button className="bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary rounded-[12px] px-[16px] py-[12px] w-full flex items-center justify-center">
        Claim on Merkl
      </button>
    </a>
  );
};

export default MerklVault;
