import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import Gradient1 from '../components/backgrounds/Gradient1';
import Grid from '../components/backgrounds/Grid';
import Banner from '../components/layout/banner/Banner';
import Footer from '../components/layout/footer/Footer';
import { Header } from '../components/layout/header';
import VaultListSkeleton from '../components/vault/skeleton/VaultListSkeleton';
import { PalmVaultListItem } from '../components/vault/vaultListItem/PalmListItem';
import { getPalmVault } from '../utils/api';

function PalmVault() {
  const { vaultId } = useParams();

  const {
    data: vault,
    isLoading,
    isError,
    error,
  } = useQuery(['palm-vault', vaultId], () => getPalmVault(vaultId), {
    refetchInterval: 10000,
    refetchOnWindowFocus: false,
    enabled: !!vaultId,
    retry: 1,
  });

  function getErrorMessage() {
    if (isError && (error as any).response.status === 400) {
      return 'This is not a V2 Vault.';
    }

    if (isError && (error as any).response.status === 404) {
      return 'Vault not found.';
    }

    return 'Something went wrong.';
  }

  return (
    <>
      <Header />
      <Gradient1 />
      <Grid opacity={0.04} />

      <div className="flex flex-col items-center w-full">
        <div className="px-4 md:px-0 max-w-screen-lg w-full">
          <div className=" w-full  px-4 md:px-0 max-w-screen-lg">
            <Banner />
          </div>
          <ul className={`w-full mb-20 mt-8`}>
            {vault && <PalmVaultListItem vault={vault} />}

            {isLoading && <VaultListSkeleton numberOfVaults={1} />}

            {isError && <div className="text-white mt-20 text-center">{getErrorMessage()} </div>}
          </ul>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default PalmVault;
