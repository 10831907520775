import React from 'react';
import styles from './toggle.module.css';

interface ToggleProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
}

function Toggle({ checked, onChange, id }: ToggleProps) {
  return (
    <div className="flex items-center" id={id}>
      <input className={styles.input} type="checkbox" id="switch" onChange={onChange} checked={checked} />
      <label className={styles.labelx} htmlFor="switch">
        Toggle
      </label>
    </div>
  );
}

export default Toggle;
