import { abbreviateNumber } from '../../../../../utils/n-formatter';

const PriceItem = ({ title, logo, price }: { title: string; logo: string; price?: number | null }) => {
  return (
    <div className="bg-accent p-4 rounded-[12px] min-h-[110px] flex flex-col justify-around w-[180px]">
      <div className="flex items-center gap-2">
        <img src={logo} height={20} width={20} alt="logo coingecko" className="object-cover rounded-full" />
        <h2 className="text-[16px]">{title}</h2>
      </div>
      <div className="text-[26px] text-center">
        {price ? (
          <div>${price.toString().includes('e') ? abbreviateNumber(price) : price.toFixed(2)}</div>
        ) : (
          <div>n/a</div>
        )}
      </div>
    </div>
  );
};

export default PriceItem;
