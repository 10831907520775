import { IVault } from '../../../types';
import { numberWithCommas } from '../../../utils/n-formatter';
import CustomOpWithdrawResult from '../custom-withdraw-result';

interface WithdrawResultProps {
  vault: IVault;
  claimableRewards: number | undefined;
  receivableAmount0String: string;
  receivableAmount1String: string;
}

function WithdrawResult({
  vault,
  claimableRewards,
  receivableAmount0String,
  receivableAmount1String,
}: WithdrawResultProps) {
  return (
    <div className="flex gap-3 flex-col my-[56px]">
      <div className="flex justify-between items-center rounded-[16px] border border-[#616068] px-5 py-4 text-[20px]">
        <div className="text-[#8B8993] ">Receive:</div>
        <div className="flex items-center gap-2  text-[#D4D3D3]">
          <div>
            {numberWithCommas(receivableAmount0String)} {vault.symbol0?.toUpperCase() ?? ''}
          </div>
          {/*   <div>
            ${numberWithCommas(((vault.price0 ?? vault.cmcPrice0) * Number(receivableAmount0String)).toFixed(2))}
          </div> */}
          <div>+</div>
          <div>
            {numberWithCommas(receivableAmount1String)} {vault.symbol1?.toUpperCase() ?? ''}
          </div>
          {/*   <div>
            ${numberWithCommas(((vault.price1 ?? vault.cmcPrice1) * Number(receivableAmount1String)).toFixed(2))}
          </div> */}
        </div>
      </div>

      {vault.gauge && (
        <div className="flex justify-between items-center rounded-[16px] border border-[#616068] px-5 py-4 text-[20px]">
          <div className="text-[#8B8993] ">Rewards:</div>
          <div className="flex items-center gap-2  text-[#D4D3D3]">
            <div>
              {numberWithCommas(claimableRewards || 0)} {vault.gauge?.symbol?.toUpperCase() ?? ''}
            </div>
            <div className="text-[#605E67]">
              $
              {numberWithCommas(
                ((vault.gauge.price || vault.gauge.cmcPrice || 0) * (claimableRewards || 0)).toFixed(2),
              )}
            </div>
          </div>
        </div>
      )}
      {vault.id == '0xe10546bee6424213dd9c80edd55e90fea96e6e11' && <CustomOpWithdrawResult vault={vault} />}
    </div>
  );
}

export default WithdrawResult;
