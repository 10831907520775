import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { useEffect } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ArrakisV2TermsRouterAbi } from '../abis/ArrakisV2TermsRouter';
import { alertUser } from '../components/elements/notify';
import { ARRAKIS_V2_TERMS_ROUTER_ADDRESS } from '../constants';
import { ExplorerDataType, getExplorerLink } from '../utils/get-explorer-link';
import { strategyJsonStringToHex } from '../utils/n-formatter';
import { getDelegateAddressByChainId, sortTokensByHexValue } from '../utils/vault-creation';

interface CreateVaultHookProps {
  token0: `0x${string}`;
  token1: `0x${string}`;
  amount0: bigint;
  amount1: bigint;
  chainId: number;
  feeTier: number;
  gasTankAmount: bigint;
  ownerAddress: `0x${string}`;
  onSuccess: () => void;
  strategy: string;
  onError?: (error: unknown) => void;
}

interface CreateVaultHookResult {
  isLoadingCreateVault: boolean;
  createVault: (() => void) | undefined;
}

export const useCreateVault = ({
  token0,
  token1,
  amount0,
  amount1,
  chainId,
  gasTankAmount,
  feeTier,
  ownerAddress,
  strategy,
  onError,
  onSuccess,
}: CreateVaultHookProps): CreateVaultHookResult => {
  const [tokenA, tokenB] = sortTokensByHexValue(token0, token1);

  const amountA = tokenA === token0 ? amount0 : amount1;
  const amountB = tokenB === token1 ? amount1 : amount0;

  const hexData = strategyJsonStringToHex(strategy);

  const openTermArgs = [
    {
      feeTiers: [feeTier.toString()],
      token0: tokenA,
      token1: tokenB,
      owner: ownerAddress,
      amount0: amountA,
      amount1: amountB,
      datas: hexData,
      strat: '0x9d8ed4190794e521863c345cd29c83cb22bb1b81b29a4ad50c767700171f735b',
      isBeacon: true,
      delegate: getDelegateAddressByChainId(chainId),
      routers: [],
    },
  ];

  const config = {
    address: ARRAKIS_V2_TERMS_ROUTER_ADDRESS as '0x${string}',
    chainId: chainId,
    abi: ArrakisV2TermsRouterAbi,
    functionName: 'openTerm',
    args: openTermArgs,
    enabled: !!token0 && !!token1 && feeTier !== undefined && amountA > 0 && amountB > 0,
    value: gasTankAmount,
  };

  const addRecentTransaction = useAddRecentTransaction();

  const { config: createVaultConfig, refetch } = usePrepareContractWrite(config);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const {
    data: createVaultData,
    write: createVault,
    isLoading: isLoadingCreateVault,
  } = useContractWrite({
    ...(createVaultConfig as any),
    onError(error) {
      console.log('error', error);
      onError?.(error);
    },
    onSettled(data, error) {},
  });

  const { isLoading: isLoadingCreateVaultAfter } = useWaitForTransaction({
    hash: createVaultData?.hash,
    onSettled(data, error) {
      if (error) {
        console.log('error', error);
        return;
      }

      alertUser(
        'success',
        'Vault created!',
        getExplorerLink(chainId || 1, createVaultData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        false,
      );

      onSuccess();

      addRecentTransaction({
        hash: createVaultData?.hash ?? '',
        description: 'Deploy Vault',
      });
    },
  });

  return {
    isLoadingCreateVault: isLoadingCreateVault || isLoadingCreateVaultAfter,
    createVault,
  };
};
