import { isLstVault, isStableVault } from '../../../utils/vault';

interface AboutVaultProps {
  symbol0: string;
  symbol1: string;
}

function AboutVault({ symbol0, symbol1 }: AboutVaultProps) {
  return (
    <>
      <p className="text-[16px] leading-[20px] tracking-[-0.16px]">
        This is a {isLstVault(symbol0, symbol1) ? 'LST ' : ''} {isStableVault(symbol0, symbol1) ? 'stable' : 'volatile'}{' '}
        vault, it’s strategy is optimised to reduce the price impact of trades in the {symbol0}/{symbol1} pair, as well
        as risk management for LPers. It utilises limit orders, asymmetric Tvl, and algorithmic rebalancing in order to
        achieve these goals.
      </p>

      <p className="mt-8 text-[16px] leading-[20px] tracking-[-0.16px]">
        The strategy is executed by Gelato Network keepers via Arrakis V2 smart contract infrastructure. The Arrakis V2
        smart contracts underwent{' '}
        <a
          href="https://resources.arrakis.fi/resources/audits"
          target="_blank"
          rel="noreferrer"
          className="text-bg-gradient-orange"
        >
          three independent security audits
        </a>
        .
      </p>
    </>
  );
}

export default AboutVault;
