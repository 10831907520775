import { useState } from 'react';
import { useAccount, useContractRead, useNetwork, useSwitchNetwork } from 'wagmi';
import { ArrakisV2HelperAbi } from '../../../abis/ArrakisV2Helper';
import { tokenProxyAbi } from '../../../abis/token-proxy';
import { ARRAKIS_V2_HELPER_ADDRESS } from '../../../constants';
import { TemporaryVault, UnderlyingBalancesSchema } from '../../../types';
import { formatBigNumber } from '../../../utils/format-big-number';
import { abbreviateNumber, capitalizeFirstLetter, getNetworkNameFromChainId } from '../../../utils/n-formatter';
import TemporaryVaultRatioChart from '../../charts/TemporaryVaultRatioChart';
import SwitchNetworkButton from '../../elements/buttons/SwitchNetworkButton';
import DashboardLink from '../../elements/links/DashboardLink';
import VaultAddressLink from '../../elements/links/VaultAddressLink';
import DepositFormPalm from '../../forms/DepositForm/DepositFormPalm';
import WithDrawFormPalm from '../../forms/WithdrawForm/WithdrawFormPalm';
import ConnectWallet from '../connect-wallet/ConnectWallet';
import GasTank from '../gasTank';
// eslint-disable-next-line import/no-unresolved
import ArrowBackIcon from '/assets/images/icon-back.svg';

interface VaultListItemProps {
  vault: TemporaryVault;
  vaultId: string;
}

export function PalmTemporaryVaultListItem({ vault, vaultId }: VaultListItemProps) {
  const { price0, price1, symbol0, symbol1, tvl, amount0, amount1 } = vault;

  const { address } = useAccount();

  // hack: to check if transaction was successful with safe app, we watch token balances of vault and trigger success if they increase
  const [isIncreaseLiquiditySuccess, setIsIncreaseLiquiditySuccess] = useState(false);
  const [maxVaultBalance0, setMaxVaultBalance0] = useState<bigint>(BigInt(0));
  const [maxVaultBalance1, setMaxVaultBalance1] = useState<bigint>(BigInt(0));

  enum Content {
    overview,
    deposit,
    withdraw,
  }

  const [currentContent, setCurrentContent] = useState<Content>(Content.overview);

  useContractRead({
    address: vault.token0 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [vaultId],
    chainId: vault.chainId,
    watch: true,
    onSuccess: (newBalance: bigint) => {
      if (newBalance > maxVaultBalance0) {
        if (maxVaultBalance0 > BigInt(0)) {
          setIsIncreaseLiquiditySuccess(true);
        }
        setMaxVaultBalance0(newBalance);
      }
    },
  });

  useContractRead({
    address: vault.token1 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [vaultId],
    chainId: vault.chainId,
    watch: true,
    onSuccess: (newBalance: bigint) => {
      if (newBalance > maxVaultBalance1) {
        if (maxVaultBalance1 > BigInt(0)) {
          setIsIncreaseLiquiditySuccess(true);
        }
        setMaxVaultBalance1(newBalance);
      }
    },
  });

  const percentage0 = ((amount0 * price0) / tvl) * 100;
  const percentage0Str = isNaN(percentage0) ? '0' : percentage0.toFixed(0);
  const percentage1 = ((amount1 * price1) / tvl) * 100;
  const percentage1Str = isNaN(percentage1) ? '0' : percentage1.toFixed(0);

  const { data: underlyingBalances } = useContractRead({
    address: ARRAKIS_V2_HELPER_ADDRESS,
    abi: ArrakisV2HelperAbi,
    functionName: 'totalUnderlying',
    chainId: vault.chainId,
    args: [vaultId],
    watch: true,
    select: (data: unknown) => {
      const parsedData = UnderlyingBalancesSchema.safeParse(data);
      if (parsedData.success) {
        return parsedData.data;
      }
      return undefined;
    },
  });

  const underlyingBalance0 = underlyingBalances?.[0];
  const underlyingBalance1 = underlyingBalances?.[1];

  const token0BalanceOfVaultNumber = underlyingBalance0
    ? Number(formatBigNumber(underlyingBalance0, vault.token0Decimals, 18))
    : 0;
  const token1BalanceOfVaultNumber = underlyingBalance1
    ? Number(formatBigNumber(underlyingBalance1, vault.token1Decimals, 18))
    : 0;

  const tvl2 = token0BalanceOfVaultNumber * price0 + token1BalanceOfVaultNumber * price1;

  let currentTvl = tvl;

  if (tvl2 > tvl || isNaN(tvl)) {
    currentTvl = tvl2;
  }

  const { switchNetwork, isLoading: isLoadingSwitchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();

  const isWithdrawButtonDisabled = vault.tvl <= 0;

  return (
    <div className="flex flex-col w-full p-6 min-h-[130px] relative bg-vault-black rounded-[24px] text-vault-white bg-opacity-80 hover:bg-opacity-80 hover:bg-[#1F1F21] duration-500 ease-in-out">
      <>
        <div className="flex flex-col md:flex-row gap-3 justify-between">
          <div className="flex items-center">
            {/* Token Logos */}
            <div className="flex min-w-[340px]">
              <img
                src={vault.token0LogoUrl}
                alt={symbol0}
                className="rounded-full max-w-[40px] max-h-[40px] align-middle mx-0.5 mt-1"
              />
              <img
                src={vault.token1LogoUrl}
                alt={symbol1}
                className="rounded-full max-w-[40px] max-h-[40px] align-middle mt-1"
              />

              <div className="flex flex-col mx-2 ">
                <p className="text-vault-gray">{capitalizeFirstLetter(getNetworkNameFromChainId(vault?.chainId))}</p>
                <p className=" text-[20px]">{vault.name}</p>
              </div>
            </div>

            {/* TVL */}
            <div className="min-w-[180px]">
              <p className="text-vault-gray">TVL</p>
              <div className="flex flex-col">
                <p className="mx-1 text-[20px]">{!isNaN(currentTvl) ? '$' + abbreviateNumber(currentTvl, 2) : 'n/a'}</p>
                <p className="text-vault-gray mx-1 text-[14px] mt-1">
                  {percentage0Str}% {symbol0.toUpperCase()} - {percentage1Str}% {symbol1.toUpperCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-2">
            {!address && <ConnectWallet />}
            {address && (
              <div className="flex flex-row justify-between w-full gap-[8px] text-vault-black-primary">
                {vault.chainId === chain?.id ? (
                  <>
                    <button
                      onClick={() => {
                        setCurrentContent(Content.deposit);
                      }}
                      className="w-[142px] h-[48px] rounded-lg bg-gradient-to-r from-[#FFA760] to-[#F45020] hover:opacity-90 duration-300"
                    >
                      Add Liquidity
                    </button>
                  </>
                ) : (
                  <SwitchNetworkButton
                    switchNetwork={switchNetwork}
                    isLoading={isLoadingSwitchNetwork}
                    chainId={vault.chainId}
                    text="Add / Remove"
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {currentContent === Content.overview && (
          <div className="mt-8">
            <TemporaryVaultRatioChart
              vault={vault}
              percentage0={percentage0}
              percentage1={percentage1}
              currentTvl={currentTvl}
            />
          </div>
        )}

        {currentContent === Content.deposit && (
          <div className="w-full">
            <div className="flex bg-vault-gray-2 rounded-[12px] w-[155px] h-12 justify-center align-center mb-8">
              <button
                onClick={() => {
                  setCurrentContent(Content.overview);
                }}
                className="text-bg-gradient-orange flex items-center gap-2"
              >
                <img src={ArrowBackIcon} alt="view liquidity" height={20} width={20} />
                View liquidity
              </button>
            </div>
            <DepositFormPalm
              vault={{ ...vault, id: vaultId }}
              currentAmountUsd={currentTvl}
              isIncreaseLiquiditySuccess={isIncreaseLiquiditySuccess || false}
            />
          </div>
        )}

        {currentContent === Content.withdraw && (
          <div className="w-full">
            <div className="flex bg-vault-gray-2 rounded-[12px] w-[155px] h-12 justify-center align-center mb-8">
              <button
                onClick={() => {
                  setCurrentContent(Content.overview);
                }}
                className="text-bg-gradient-orange flex items-center gap-2"
              >
                <img src={ArrowBackIcon} alt="view liquidity" height={20} width={20} />
                View liquidity
              </button>
            </div>
            <WithDrawFormPalm vault={{ ...vault, id: vaultId }} />
          </div>
        )}
      </>
      <GasTank vaultId={vaultId} chainId={vault.chainId} />

      <div className="flex flex-col lg:flex-row mt-8 gap-6">
        <VaultAddressLink chainId={vault.chainId} address={vaultId} text="Vault Address:" />
        <VaultAddressLink chainId={vault.chainId} address={vault.creator} text="Creator:" />
        <DashboardLink vaultId={vaultId} />
      </div>

      <button
        disabled={isWithdrawButtonDisabled}
        onClick={() => {
          setCurrentContent(Content.withdraw);
        }}
        className={`${
          isWithdrawButtonDisabled
            ? 'bg-vault-gray-2 text-vault-gray-hover'
            : 'ring-1  text-zinc-400 ring-white/10 hover:bg-white/5 hover:text-white duration-300'
        } h-[48px] rounded-lg w-full mt-6`}
      >
        Close Vault
      </button>
    </div>
  );
}
