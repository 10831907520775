import { IVault } from '../../../types';

interface GaugeLogoProps {
  vault: IVault;
}

function GaugeLogo({ vault }: GaugeLogoProps) {
  if (
    vault.id === '0xf06fda2664d1f88d19919e37034b92bf26896c61' ||
    vault.id === '0x4ca9fb1f302b6bd8421bad9debd22198eb6ab723'
  ) {
    return (
      <img
        src="https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png"
        alt="arbitrum logo"
        className="rounded-full max-w-[20px] max-h-[20px] align-middle"
      />
    );
  }

  if (vault.id === '0xd05004c869566f2f1bb166f0960481807442270a') {
    return (
      <img
        src="https://assets.dex.guru/icons/0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0-eth.png"
        alt="wrsteth logo"
        className="rounded-full max-w-[20px] max-h-[20px] align-middle"
      />
    );
  }

  if (
    vault.id === '0xe10546bee6424213dd9c80edd55e90fea96e6e11' ||
    vault.id === '0x99cac5a58be2e2d2ebe064cd978451573cc08cb5' ||
    vault.id === '0x5b98150ff4a122fb80bcdd23c8f825df7d732797' ||
    vault.id === '0x669d770e6872edf91a2b0959b9e3b89c46cb7fbf'
  ) {
    return (
      <img
        src="https://assets.dex.guru/icons/0x4200000000000000000000000000000000000042-optimism.png"
        alt="otpimism logo"
        className="rounded-full max-w-[20px] max-h-[20px] align-middle"
      />
    );
  } else {
    return (
      <img
        src={vault.gauge?.logo}
        alt={vault.gauge?.name}
        className="rounded-full max-w-[20px] max-h-[20px] align-middle"
      />
    );
  }
}

export default GaugeLogo;
