import { formatUnits } from 'viem';
import { IVault } from '../types';
import { formatBigNumber } from './format-big-number';

// calculateTVL calculates Token USDC value from Token value
export function calculateTVL(supply: bigint, decimals: number, usdPrice: number) {
  return Number(formatBigNumber(supply, decimals, 6)) * usdPrice;
}

// get dollar value of your current vault token balance: amount is in wei-like string
export function vaultTokenValueToUsd(vault: IVault, amount: bigint): string {
  if (amount === BigInt(0)) {
    return '0';
  }

  return amount > BigInt(0)
    ? (((Number(formatUnits(amount, 18)) * Math.pow(10, 18)) / Number(vault.totalSupply)) * vault.tvl).toFixed(2)
    : '0';
}
