import PriceItem from './PriceItem';

interface PriceCheckerProps {
  coingeckoPrice: number | null | undefined;
  coinmarketcapPrice: number | null;
  sqrtPriceX96Usd: number | null;
}

function PriceReferences({ coingeckoPrice, coinmarketcapPrice, sqrtPriceX96Usd }: PriceCheckerProps) {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-[16px]">Reference Prices</h3>
      <div className="flex flex-col sm:flex-row gap-2">
        <PriceItem title="CoinGecko" price={coingeckoPrice} logo="/assets/images/logo-coingecko.png" />
        <PriceItem title="CoinMarketCap" price={coinmarketcapPrice} logo="/assets/images/coinmarketcap-logo.png" />
        <PriceItem title="Uniswap" price={sqrtPriceX96Usd} logo="/assets/images/logo-uniswap.png" />
      </div>
      {/*  <div className="text-vault-orange-2 mb-2 text-[14px] flex items-center gap-1">
        <ExclamationTriangleIcon width={18} height={18} />
        Make sure that you use the correct pice for your token
      </div> */}
    </div>
  );
}

export default PriceReferences;
