import { createContext, useContext, useState } from 'react';
import { Explanation } from '../types';

interface SettingsContextType {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  explanation: Explanation | undefined;
  setExplanation: React.Dispatch<React.SetStateAction<Explanation | undefined>>;
}

const SettingsContext = createContext<SettingsContextType | null>(null);

export default function SettingsContextProvider({ children }: { children: React.ReactNode }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [explanation, setExplanation] = useState<Explanation | undefined>(undefined);

  return (
    <SettingsContext.Provider
      value={{ showSidebar, setShowSidebar, title, setTitle, description, setDescription, explanation, setExplanation }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettingsContext() {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettingsContext must be used within a SettingsContextProvider');
  }
  return context;
}
