import Skeleton from 'react-loading-skeleton';

function PriceItemsLoading() {
  return (
    <div className="flex flex-col sm:flex-row gap-4 pt-8">
      <Skeleton
        height="110px"
        width="180px"
        baseColor="#292828"
        highlightColor="#6e6d6d"
        duration={0.9}
        className="rounded-[12px]"
      />
      <Skeleton
        height="110px"
        width="180px"
        baseColor="#292828"
        highlightColor="#6e6d6d"
        duration={0.9}
        className="rounded-[12px]"
      />
      <Skeleton
        height="110px"
        width="180px"
        baseColor="#292828"
        highlightColor="#6e6d6d"
        duration={0.9}
        className="rounded-[12px]"
      />
    </div>
  );
}

export default PriceItemsLoading;
