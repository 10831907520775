function TargetRatio() {
  return (
    <div className="flex justify-center gap-1 px-4 py-[10px] border border-accent rounded-[16px]  text-[14px] tracking-[-0.14px]">
      <span className="text-vault-gray-hover whitespace-nowrap">Target ratio:</span>
      <span className="text-vault-orange-2 whitespace-nowrap">50% / 50%</span>
    </div>
  );
}

export default TargetRatio;
