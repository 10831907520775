import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { track } from '@vercel/analytics/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSessionStorage } from 'usehooks-ts';
import { useAccount, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ArrakisV2TermsRouterAbi } from '../../../abis/ArrakisV2TermsRouter';
import { ARRAKIS_V2_TERMS_ROUTER_ADDRESS, ERROR_MESSAGE } from '../../../constants';
import { IVault, TemporaryVault, WithdrawFormWrapperState, isIVault } from '../../../types';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { alertUser } from '../../elements/notify';
import { Spinner } from '../../elements/spinner';
import WithdrawWrapper from '../../hoc/withdraw-form-wrapper';

interface WithDrawFormProps {
  vault: IVault | TemporaryVault;
  claimableRewards?: number | undefined;
}

function WithDrawFormPalm({ vault, claimableRewards }: WithDrawFormProps) {
  const { address: ownAddress, connector } = useAccount();

  const [, setClosedVaultsSessionStorage] = useSessionStorage<string[]>('closed-vaults', []);

  const [isLoadingSafeWallet] = useState(false);
  const [withdrawFormWrapperState, setWithdrawFormWrapperState] = useState(WithdrawFormWrapperState.DEFAULT);
  const isSafeWallet = connector?.id === 'safe';

  const {
    register,
    formState: { errors },
    watch,
  } = useForm<{ receiverAddress: string }>({
    defaultValues: {
      receiverAddress: ownAddress,
    },
    mode: 'onChange',
  });

  const receiverAddress = watch('receiverAddress');

  const addRecentTransaction = useAddRecentTransaction();

  const { config: withdrawConfig } = usePrepareContractWrite({
    address: ARRAKIS_V2_TERMS_ROUTER_ADDRESS,
    abi: ArrakisV2TermsRouterAbi,
    functionName: 'closeTerm',
    args: [vault.id as `0x${string}`, receiverAddress, receiverAddress, receiverAddress],
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
  });

  const {
    data: withdrawData,
    write: withdraw,
    isLoading: isLoadingWithdrawTransaction,
  } = useContractWrite({
    ...(withdrawConfig as any),
    onError() {
      alertUser('error', ERROR_MESSAGE);
    },
  });
  const { isLoading: isLoadingWithdrawTransactionAfter } = useWaitForTransaction({
    hash: withdrawData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', ERROR_MESSAGE);
        return;
      }

      setClosedVaultsSessionStorage((closedVaults) => [...closedVaults, vault.id]);

      alertUser(
        'success',
        'Liquidity removed successful!',
        getExplorerLink(vault.chainId || 137, withdrawData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
      );

      track('close palm', {
        type: 'click',
        feature: 'vault close form palm',
        wallet: ownAddress as string,
        vault: vault.id,
      });

      setWithdrawFormWrapperState(WithdrawFormWrapperState.WITHDRAW_SUCCESS);

      addRecentTransaction({
        hash: withdrawData?.hash ?? '',
        description: 'Withdraw',
      });
    },
  });

  function onWithdraw() {
    if (withdraw) {
      withdraw();
    }

    if (isSafeWallet) {
      setWithdrawFormWrapperState(WithdrawFormWrapperState.SAFE_APP_WITHDRAW);
    }
  }

  return (
    <WithdrawWrapper isLoading={!ownAddress} state={withdrawFormWrapperState}>
      <div className="flex md:gap-20 flex-col md:flex-row">
        {isIVault(vault) && vault.gauge && (
          <div>
            <div className="text-vault-gray-hover text-[20px]  mt-6 mb-3">Rewards</div>
            <div className="flex items-center gap-28">
              <div className="flex items-center gap-2 text-[16px]">
                <img
                  src={vault.gauge.logo}
                  alt={vault.gauge.name}
                  className="rounded-full max-w-[20px] max-h-[20px] align-middle"
                />
                <div className="text-vault-light">
                  {claimableRewards} {vault.gauge?.symbol?.toUpperCase() ?? ''}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="my-4">
        <div className="mb-2">Receiver address</div>
        <div className="text-sm mb-2 text-vault-gray">
          Be sure that this address is correct. If you send to the wrong address, you will lose your funds.
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center gap-3 mt-8">
          <div className="grow">
            <fieldset
              id="receiver-address"
              className={`rounded-lg px-4 py-2 bg-vault-gray-2 border ${
                errors.receiverAddress ? 'border-red-400' : 'border-none'
              }`}
            >
              <input
                {...register('receiverAddress', {
                  required: "Can't be empty",
                  pattern: {
                    value: /^0x[a-fA-F0-9]{40}$/,
                    message: 'Invalid address',
                  },
                })}
                type="text"
                className="min-w-full bg-transparent text-md px-0 text-white border-transparent focus:border-transparent focus:ring-0"
              />
            </fieldset>
            {errors.receiverAddress && (
              <div className="text-red-400 text-sm mt-2">{errors.receiverAddress.message}</div>
            )}
          </div>
          <button
            disabled={isLoadingWithdrawTransaction || !!errors.receiverAddress || isLoadingWithdrawTransactionAfter}
            className={`py-4 rounded-lg text-md px-4 sm:w-[200px] w-full ${
              errors.receiverAddress ? 'bg-vault-gray-2 text-vault-gray' : ' bg-vault-light-gray text-vault-black'
            }`}
            onClick={() => onWithdraw()}
          >
            <div className="flex items-center justify-center gap-3">
              Close
              {(isLoadingWithdrawTransaction || isLoadingSafeWallet || isLoadingWithdrawTransactionAfter) && (
                <span className="mx-2">
                  <Spinner color="vault-black" />{' '}
                </span>
              )}
            </div>
          </button>
        </div>
      </div>
    </WithdrawWrapper>
  );
}

export default WithDrawFormPalm;
