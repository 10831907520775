import { ApexOptions } from 'apexcharts';
import { ConfigDetailsResponse } from '../types/create-vault';
import { capNumberMax } from './math';
import { abbreviateNumber, numberWithCommas, shortenNumber } from './n-formatter';

export const getLiquidityProfileChartOptions = (
  currentPrice: number,
  minX: number,
  maxX: number,
  maxY: number,
): ApexOptions => ({
  colors: ['#FF7200'],

  markers: {
    size: 0,
    radius: 0,
    colors: 'transparent',
    fillOpacity: 0,
    strokeColors: 'transparent',
    strokeWidth: 0,
  },
  dataLabels: {
    enabled: false,
  },
  annotations: {
    xaxis: [
      {
        x: currentPrice,
        borderColor: '#F3F2F2',
        offsetY: 10,
        borderWidth: 2,
        strokeDashArray: 0,
        label: {
          borderColor: 'transparent',
          orientation: 'horizontal',
          text: `Current Price (${numberWithCommas(shortenNumber(currentPrice)).toString()})`,
          style: {
            background: 'transparent',
            color: '#F3F2F2',
            fontSize: '11px',
            fontWeight: 400,
          },
        },
      },
      {
        x: minX,
        borderColor: 'transparent',
        offsetY: -20,
        borderWidth: 2,
        strokeDashArray: 0,
        label: {
          offsetY: 15,
          borderColor: 'transparent',
          orientation: 'horizontal',
          text: numberWithCommas(shortenNumber(Number(minX))),
          position: 'bottom',
          style: {
            background: 'transparent',
            color: '#F3F2F2',
            fontSize: '11px',
            fontWeight: 400,
          },
        },
      },
      {
        x: maxX,
        borderColor: 'transparent',
        offsetY: 200,
        offsetX: 100,
        borderWidth: 2,
        strokeDashArray: 0,
        label: {
          offsetY: 15,
          borderColor: 'transparent',
          orientation: 'horizontal',
          text: numberWithCommas(shortenNumber(Number(maxX))),
          position: 'bottom',
          style: {
            background: 'transparent',
            color: '#F3F2F2',
            fontSize: '11px',
            fontWeight: 400,
          },
        },
      },
    ],
  },
  legend: {
    show: false,
  },
  chart: {
    height: 350,
    type: 'bar',
    stacked: true,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },

  plotOptions: {
    area: {
      fillTo: 'origin',
    },
  },
  stroke: {
    curve: 'straight',
  },

  fill: {
    opacity: 1,
    colors: ['#FF7200'],
  },
  grid: {
    borderColor: '#1F1F21',
    position: 'back',
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    type: 'numeric',

    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    // tickAmount: 10,
    labels: {
      show: false,
      style: {
        colors: '#605E67',
      },
      formatter: function (val) {
        /*   const valueX = Math.pow(10, Number(val));

        if (valueX < 1) {
          return valueX.toFixed(1);
        }

        if (valueX > 1000) {
          return abbreviateNumber(valueX);
        } */
        return numberWithCommas(shortenNumber(Number(val)));
      },
    },
    // min: minX ? minX - 0.2 * minX : 0,
    min: 0,
    max: maxX ? maxX + 0.1 * maxX : 0,
  },
  yaxis: {
    max: maxY * 1.4,
    tickAmount: 6,
    labels: {
      style: {
        colors: '#605E67',
      },
      formatter: function (val) {
        if (val < 10) {
          return '$' + val.toFixed(2);
        }
        return '$' + abbreviateNumber(val);
      },
    },
  },
  tooltip: {
    enabled: false,
  },
});

export function getLiquidityProfileChartSeriesFromLiquidityPositions(
  positions: ConfigDetailsResponse['positions'],
  price0: number,
  price1: number,
) {
  const allXValues: number[] = [];

  positions.forEach((position) => {
    allXValues.push(position.range.lowerPrice);
    allXValues.push(position.range.upperPrice);
  });

  const positionsPlaceholders = allXValues.map((x) => ({
    x: x,
    y: null,
  }));

  const minX = positionsPlaceholders.reduce((min, p) => Math.min(min, p.x), Infinity);
  const maxX = positionsPlaceholders.reduce((max, p) => Math.max(max, p.x), -Infinity);
  const maxY = positions.reduce((max, p) => Math.max(max, p.amount0 * price0 + p.amount1 * price1), -Infinity);

  const positionsForChart =
    positions.map((position) => {
      const data = [
        {
          x: position.range.lowerPrice,
          y: position.amount0 * price0 + position.amount1 * price1,
        },
        {
          x: position.range.upperPrice,
          y: position.amount0 * price0 + position.amount1 * price1,
        },
        {
          x: position.range.upperPrice,
          y: null,
        },
      ];

      return {
        name: `Liquidity ${position.range.lowerPrice}`,
        type: 'area',
        data,
      };
    }) || [];

  const dataPoints = positionsForChart.map((position) => position.data).flat();

  const positionsResult: ApexAxisChartSeries = [
    {
      type: 'area',
      name: 'profile liquidity chart',
      // @ts-ignore
      data: dataPoints,
    },
  ];

  return {
    positions: positionsResult,
    minX,
    maxX,
    maxY,
  };
}

export const MAX_CAP_CHART = 100;

export const getPriceImpactChartOptions = (
  priceImpacts: ConfigDetailsResponse['priceImpacts'],
  setSelectedDataPoint: (index: number) => void,
): ApexOptions => {
  const points = [
    {
      x: 1,
      y: priceImpacts[0][2] ? capNumberMax(priceImpacts[0][2], MAX_CAP_CHART) : null,
      marker: {
        size: 0,
      },
      label: {
        offsetY: -5,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: `${
          priceImpacts[0][2]
            ? priceImpacts[0][2] >= MAX_CAP_CHART
              ? '>' + MAX_CAP_CHART + '%'
              : priceImpacts[0][2]?.toFixed(1) + '%'
            : 'n/a'
        }`,
      },
    },
    {
      x: 1,
      y: priceImpacts[0][1],
      marker: {
        size: 0,
      },
      label: {
        offsetY: 25,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: priceImpacts[0][1] ? priceImpacts[0][1]?.toFixed(1) + '%' : 'n/a',
      },
    },
    {
      x: 125,
      y: priceImpacts[1][2] ? (priceImpacts[1][2] ? capNumberMax(priceImpacts[1][2], MAX_CAP_CHART) : null) : 'n/a',

      marker: {
        size: 0,
      },
      label: {
        offsetY: -5,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: `${
          priceImpacts[1][2]
            ? priceImpacts[1][2] >= MAX_CAP_CHART
              ? '>' + MAX_CAP_CHART + '%'
              : priceImpacts[1][2]?.toFixed(1) + '%'
            : 'n/a'
        }`,
      },
    },
    {
      x: 125,
      y: priceImpacts[1][1],
      marker: {
        size: 0,
      },
      label: {
        offsetY: 25,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: priceImpacts[1][1] ? priceImpacts[1][1].toFixed(1) + '%' : 'n/a',
      },
    },
    {
      x: 250,
      y: priceImpacts[2][2] ? capNumberMax(priceImpacts[2][2], MAX_CAP_CHART) : null,

      marker: {
        size: 0,
      },
      label: {
        offsetY: -5,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: `${
          priceImpacts[2][2]
            ? priceImpacts[2][2] >= MAX_CAP_CHART
              ? '>' + MAX_CAP_CHART + '%'
              : priceImpacts[2][2]?.toFixed(1) + '%'
            : 'n/a'
        }`,
      },
    },
    {
      x: 250,
      y: priceImpacts[2][1],
      marker: {
        size: 0,
      },
      label: {
        offsetY: 25,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: priceImpacts[2][1] ? priceImpacts[2][1].toFixed(1) + '%' : 'n/a',
      },
    },
    {
      x: 375,
      y: priceImpacts[3][2] ? capNumberMax(priceImpacts[3][2], MAX_CAP_CHART) : null,

      marker: {
        size: 0,
      },
      label: {
        offsetY: -5,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: `${
          priceImpacts[3][2]
            ? priceImpacts[3][2] >= MAX_CAP_CHART
              ? '>' + MAX_CAP_CHART + '%'
              : priceImpacts[3][2]?.toFixed(1) + '%'
            : 'n/a'
        }`,
      },
    },
    {
      x: 375,
      y: priceImpacts[3][1],
      marker: {
        size: 0,
      },
      label: {
        offsetY: 25,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: priceImpacts[3][1] ? priceImpacts[3][1].toFixed(1) + '%' : 'n/a',
      },
    },
    {
      x: 500,
      y: priceImpacts[4][2] ? capNumberMax(priceImpacts[4][2], MAX_CAP_CHART) : null,

      marker: {
        size: 0,
      },
      label: {
        offsetY: -5,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: `${
          priceImpacts[4][2]
            ? priceImpacts[4][2] >= MAX_CAP_CHART
              ? '>' + MAX_CAP_CHART + '%'
              : priceImpacts[4][2]?.toFixed(1) + '%'
            : 'n/a'
        }`,
      },
    },
    {
      x: 500,
      y: priceImpacts[4][1],
      marker: {
        size: 0,
      },
      label: {
        offsetY: 25,
        borderColor: 'transparent',
        style: {
          color: '#fff',
          background: 'transparent',
        },

        text: priceImpacts[4][1] ? priceImpacts[4][1].toFixed(1) + '%' : 'n/a',
      },
    },
  ].filter((point) => point.y !== null);

  return {
    colors: ['#f98080', '#34d399'],
    annotations: {
      // @ts-ignore
      points,
    },
    chart: {
      height: 350,
      type: 'rangeBar',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      foreColor: '#9ca3af',
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          setSelectedDataPoint(config.dataPointIndex);
        },
      },
    },
    plotOptions: {
      bar: {
        isDumbbell: true,
        columnWidth: 3,
        dumbbellColors: [['#f98080', '#34d399']],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'left',
      customLegendItems: ['Negative Impact', 'Positive Impact'],
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        gradientToColors: ['#34d399'],
        inverseColors: true,
        stops: [0, 100],
      },
    },
    grid: {
      borderColor: ' rgba(41, 40, 45, 1)',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      tickPlacement: 'on',
      axisBorder: {
        offsetY: 12,
      },
      labels: {
        style: {
          colors: '#9ca3af',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#9ca3af',
        },
        formatter: function (val) {
          return val + '%';
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };
};
