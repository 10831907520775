import { ConfigDetailsResponse } from '../../../../types/create-vault';
import { numberWithCommas, shortenNumber } from '../../../../utils/n-formatter';

interface TokensLockedCardProps {
  configDetails: ConfigDetailsResponse;
  token0LogoUrl: string;
  token1LogoUrl: string;
  token0Symbol: string;
  token1Symbol: string;
}

function TokensLockedCard({
  configDetails,
  token0LogoUrl,
  token1LogoUrl,
  token0Symbol,
  token1Symbol,
}: TokensLockedCardProps) {
  const totalToken0Locked = configDetails.positions.reduce((acc, position) => {
    return acc + position.amount0;
  }, 0);

  const totalToken1Locked = configDetails.positions.reduce((acc, position) => {
    return acc + position.amount1;
  }, 0);

  return (
    <div className="flex items-center justify-between gap-5">
      <div className="flex items-center justify-between gap-2 bg-accent rounded-[12px] p-3">
        <div className="flex items-center gap-2">
          <img className="w-7 h-7 rounded-full" src={token0LogoUrl} alt={token0Symbol} />
          <div>{token0Symbol?.toUpperCase()}</div>
        </div>
        <div>{numberWithCommas(shortenNumber(totalToken0Locked))}</div>
      </div>
      <div className="flex items-center justify-between gap-2 bg-accent rounded-[12px] p-3">
        <div className="flex items-center gap-2">
          <img className="w-7 h-7 rounded-full" src={token1LogoUrl} alt={token1Symbol} />
          <div>{token1Symbol?.toUpperCase()}</div>
        </div>
        <div>{numberWithCommas(shortenNumber(totalToken1Locked))}</div>
      </div>
    </div>
  );
}

export default TokensLockedCard;
