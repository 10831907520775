interface ChartStatProps {
  title: string;
  value: string;
}

function ChartStat({ title, value }: ChartStatProps) {
  return (
    <div className="flex flex-col gap-1">
      <h4 className="text-vault-gray text-[14px] whitespace-nowrap">{title}</h4>
      <p className="text-[16px] text-primary-light whitespace-nowrap">{value}</p>
    </div>
  );
}

export default ChartStat;
