function MaxTwapDeviationExplanation() {
  return (
    <div>
      <h2 className="text-lg font-bold mb-1">What is the maximum TWAP deviation?</h2>
      <p>
        The maximum TWAP deviation is the maximum amount of deviation you are willing to accept when swapping tokens. If
        the price of the token changes by more than the maximum TWAP deviation, the transaction will fail.
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Why does it matter?</h2>
      <p className="mb-2">
        The maximum TWAP deviation is important because it determines how much slippage you will experience when
        swapping tokens. The lower the maximum TWAP deviation, the less slippage you will experience.
      </p>
    </div>
  );
}

export default MaxTwapDeviationExplanation;
