import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { useContractRead } from 'wagmi';
import { ArrakisV1GaugeAbi } from '../../../abis/ArrakisV1Gauge';
import { IVault } from '../../../types';
import { URL } from '../../../utils/api';
import { formatBigNumber } from '../../../utils/format-big-number';
import { numberWithCommas } from '../../../utils/n-formatter';

interface CustomOpClaimRewardsProps {
  vault: IVault;
  ownAddress: string;
}

/* This component is similar to ClaimRewards, with the difference that this one fetches the claimable rewards itself  */
function CustomOpClaimRewards({ vault, ownAddress }: CustomOpClaimRewardsProps) {
  const rewardTokenAddress = '0x4200000000000000000000000000000000000042';
  const rewardTokenChainId = 10;
  const rewardTokenDecimals = 18;
  const rewardTokenSymbol = 'OP';
  const logoUrl = 'https://assets.dex.guru/icons/0x4200000000000000000000000000000000000042-optimism.png';

  const { data: claimableRewards, isLoading: isLoadingClaimableRewards } = useContractRead({
    address: vault.gauge?.address as '0x${string}',
    abi: ArrakisV1GaugeAbi,
    functionName: 'claimable_reward',
    args: [ownAddress, rewardTokenAddress],
    chainId: rewardTokenChainId,
    select: (data) => {
      return data
        ? Number(formatBigNumber(BigInt(data.toString()), rewardTokenDecimals || 18, rewardTokenDecimals))
        : 0;
    },
    //select: data => data as bigint
    cacheTime: 10000,
  });

  // get price of reward token
  const { data: opPrice, isLoading: isLoadingOpPrice } = useQuery(
    ['vault-0x3dc6a6ef1836f02d9ff29212c22e71fa3b74d1a9'],
    async () => {
      const res = await axios.get<any>(`${URL}/api/vault/single/0x3dc6a6ef1836f02d9ff29212c22e71fa3b74d1a9`);
      return res?.data?.vault[0]?.gauge?.price;
    },
  );

  const claimableRewardsUsd = (claimableRewards ?? 0) * (opPrice ?? 0);

  return (
    <div>
      <div className="flex items-center justify-between text-[20px]">
        <div className="text-vault-light">{rewardTokenSymbol.toUpperCase()}</div>

        {isLoadingClaimableRewards || isLoadingOpPrice ? (
          <Skeleton height="20px" width="30px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
        ) : (
          <div className="flex items-center gap-4">
            <div className="text-bg-gradient-violet">
              <span> ${claimableRewardsUsd.toFixed(2)}</span>
            </div>
            <div className="flex flex-row items-center gap-2">
              <img src={logoUrl} alt="OP logo" className="rounded-full max-w-[20px] max-h-[20px] align-middle" />
              <div className="text-[#D4D3D3] text-[14px]  min-w-[30px] text-right">
                {numberWithCommas((claimableRewards || 0).toFixed(2))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomOpClaimRewards;
