import { z } from 'zod';

export const EarnedFeesResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    metadata: z.object({
      symbol0: z.string(),
      symbol1: z.string(),
    }),
    totals: z.object({
      fee0: z.number(),
      fee1: z.number(),
      feeUSD: z.number(),
    }),
    history: z.array(
      z.object({
        timeAt: z.string(),
        fee0: z.number(),
        fee1: z.number(),
        feeUSD: z.number(),
      }),
    ),
  }),
  cache: z.boolean(),
});

export type EarnedFeesResponse = z.infer<typeof EarnedFeesResponseSchema>;
