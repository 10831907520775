import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface StepperProps {
  currentStep: number;
}

function Stepper({ currentStep }: StepperProps) {
  const styleCurrentStep = 'text-white';
  const stylePastStep = 'text-vault-white';
  const styleFutureStep = 'text-vault-gray-hover';

  function getStyle(index: number) {
    if (index === currentStep) {
      return styleCurrentStep;
    }
    if (index < currentStep) {
      return stylePastStep;
    }
    return styleFutureStep;
  }

  function getWidth() {
    if (currentStep === 0) {
      return '25%';
    }

    if (currentStep === 1) {
      return '60%';
    }

    if (currentStep === 2) {
      return '100%';
    }

    return `${(currentStep / 2) * 100 + 1}%`;
  }

  return (
    <div className="flex flex-col gap-4" aria-hidden="true">
      <div className="text-[14px] leading-[20px] tracking-[-0.14px] mt-6 text-vault-gray-hover grid grid-cols-3">
        <div className={`text-left flex items-center ${getStyle(0)}`}>
          <span className="sm:mr-1">General</span> <span className="hidden sm:inline">information</span>
          {currentStep > 0 && <CheckCircleIcon className="text-white inline-block w-5 h-5 ml-1" />}
        </div>
        <div className={`text-center ${getStyle(1)}`}>
          Strategy {currentStep > 1 && <CheckCircleIcon className="text-white inline-block w-4 h-4 ml-1" />}
        </div>
        <div className={`text-right ${getStyle(2)}`}>
          Review {currentStep > 2 && <CheckCircleIcon className="text-white inline-block w-4 h-4 ml-1" />}
        </div>
      </div>
      <div className="overflow-hidden rounded-full bg-[#ffffff29]">
        <div className="h-1 rounded-full bg-gradient-orange" style={{ width: getWidth() }} />
      </div>
    </div>
  );
}

export default Stepper;
