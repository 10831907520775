// eslint-disable-next-line import/no-unresolved
import LiquidityGraphImage from '/assets/images/lst-vaults-banner.svg';

function Banner() {
  return (
    <div className="max-w-full w-full rounded-[24px] h-[260px] md:h-[200px] bg-gradient-to-r p-[1px] from-[#ffa760] to-[#f45020] relative mt-10 hover:-translate-y-1 duration-300 opacity-80 hover:opacity-100">
      <div className="flex justify-between h-full bg-gradient-primary text-white rounded-[24px] p-8">
        <div>
          <div className="rounded-[20px] bg-white  text-black py-[6px] px-[12px] text-[14px] max-w-fit	mb-4">
            Announcement
          </div>
          <div className="text-white text-[18px] md:text-[28px] leading-[120%]  max-w-[460px]">
            Liquid Staking Vaults are Live!
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://mirror.xyz/0x916563f8476b988855af0b8b8A3D56072E1917FA/yTtHIw5My8Kqj0ScYFUDl1SEPpkqkBfn-HE9PFINudQ"
              className="text-[14px] md:text-[16px] text-bg-gradient-orange align-middle mt-2"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="absolute bottom-[20px] right-[50%] translate-x-[50%] md:translate-x-0 md:right-20">
          <img src={LiquidityGraphImage} alt="liquidity graph" className="overflow-hidden" width="100%" height="100%" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
