import { IVault } from '../../../types';
import { numberWithCommas, shortenNumber } from '../../../utils/n-formatter';

interface PositionValueProps {
  currentAmountUsd: string;
  vault: IVault;
  receivableAmount0: string;
  receivableAmount1: string;
}

function PositionValue({ currentAmountUsd, vault, receivableAmount0, receivableAmount1 }: PositionValueProps) {
  return (
    /*  <div className="text-vault-white text-[20px] leading-[26px] mb-[16px]">
      Position Value:{' '}
      <span className="underline underline-offset-8 decoration-dashed">
        <HoverCard>
          <HoverCardTrigger>
            <span className="cursor-default">${numberWithCommas(currentAmountUsd)}</span>
          </HoverCardTrigger>
          <HoverCardContent side="top">
            <div className="flex flex-col text-[12px]">
              <div className="flex gap-1 items-center">
                <img src={vault.token0LogoUrl} alt={vault.symbol0} className="rounded-full max-w-[16px] max-h-[16px]" />
                <div>{vault.symbol0?.toUpperCase()}</div>
                <div className="grow text-right">{shortenNumber(Number(receivableAmount0))}</div>
              </div>
              <div className="flex gap-1 items-center">
                <img src={vault.token1LogoUrl} alt={vault.symbol1} className="rounded-full max-w-[16px] max-h-[16px]" />
                <div>{vault.symbol1?.toUpperCase()}</div>

                <div className="grow text-right">{shortenNumber(Number(receivableAmount1))}</div>
              </div>
            </div>
          </HoverCardContent>
        </HoverCard>
      </span>
    </div> */
    <div className="border border-vault-gray-hover px-[16px] py-[12px] rounded-[12px] mb-[16px] w-full">
      <div className="text-vault-white text-[20px] leading-[26px] mb-[16px] flex justify-between">
        Your Position: <span className="cursor-default">${numberWithCommas(currentAmountUsd)}</span>
      </div>
      <div className="flex flex-col text-[14px] gap-1">
        <div className="flex gap-1 items-center">
          <img src={vault.token0LogoUrl} alt={vault.symbol0} className="rounded-full max-w-[16px] max-h-[16px]" />
          <div>{vault.symbol0?.toUpperCase()}</div>
          <div className="grow text-right">{shortenNumber(Number(receivableAmount0))}</div>
        </div>
        <div className="flex gap-1 items-center">
          <img src={vault.token1LogoUrl} alt={vault.symbol1} className="rounded-full max-w-[16px] max-h-[16px]" />
          <div>{vault.symbol1?.toUpperCase()}</div>

          <div className="grow text-right">{shortenNumber(Number(receivableAmount1))}</div>
        </div>
      </div>
    </div>
  );
}

export default PositionValue;
