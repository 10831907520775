import { zeroAddress } from 'viem';
import { useContractRead } from 'wagmi';
import { UniV3FactoryAbi } from '../abis/UniV3Factory';
import { getUniswapV3FactoryAddress } from '../constants';

interface CheckPoolExistenceHookProps {
  token0: `0x${string}`;
  token1: `0x${string}`;
  feeTier: number;
  chainId: number;
}

export const useCheckPoolExistence = ({ token0, token1, feeTier, chainId }: CheckPoolExistenceHookProps) => {
  const { data: poolAddress, isLoading } = useContractRead({
    address: getUniswapV3FactoryAddress(chainId) as `0x${string}`,
    abi: UniV3FactoryAbi,
    functionName: 'getPool',
    args: [token0, token1, feeTier], // order token0 token1 doesn't matter
    watch: true,
    chainId: chainId,
    enabled: !!token0 && !!token1 && feeTier !== undefined,
    select: (poolAddress: unknown) => {
      if (typeof poolAddress === 'string' && poolAddress !== zeroAddress) {
        return poolAddress;
      }
      return undefined;
    },
    onError(error) {
      console.log('error', error);
    },
    staleTime: 3000,
  });

  return { poolAddress, isLoading };
};
