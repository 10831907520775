import React from 'react';
import styles from './toggle.module.css';

interface ToggleProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  id: string;
}

function Toggle({ checked, onChange, id, disabled = false }: ToggleProps) {
  if (!disabled)
    return (
      <div className="flex items-center">
        <input className={styles.input} type="checkbox" id={id} onChange={onChange} checked={checked} />
        <label className={styles.toggleLabel} htmlFor={id}>
          Toggle
        </label>
      </div>
    );

  return (
    <div className="flex items-center">
      <input className={styles.input} type="checkbox" id={id} onChange={onChange} checked={checked} disabled />
      <label className={styles.toggleLabel} htmlFor={id}>
        Toggle
      </label>
    </div>
  );
}

export default Toggle;
