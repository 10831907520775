import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

interface CollapseButtonProps {
  text: string;
  showContent: boolean;
  setShowContent: (showChart: boolean) => void;
}

function CollapseButton({ text, showContent, setShowContent }: CollapseButtonProps) {
  return (
    <button className="text-[20px] mt-5 flex items-center gap-2">
      {text}
      {showContent ? (
        <ChevronUpIcon
          onClick={() => {
            setShowContent(false);
          }}
          className="h-6 w-6 text-vault-gray-hover"
        />
      ) : (
        <ChevronDownIcon
          onClick={() => {
            setShowContent(true);
          }}
          className="h-6 w-6 text-vault-gray-hover"
        />
      )}
    </button>
  );
}

export default CollapseButton;
