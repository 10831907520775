import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { Explanation } from '../../../../../types';
import { getSqrtPriceX96 } from '../../../../../utils/vault-creation';
import QuestionButton from '../../../../layout/sidebar/QuestionButton';

interface SqrtPriceX96InputProps {
  ownTokenLogoUrl: string;
  setManualSqrtPriceX96: React.Dispatch<React.SetStateAction<bigint | undefined>>;
  ownTokenSymbol?: string;
  ownTokenAddress: string;
  baseTokenAddress: string;
  ownTokenDecimals: number;
  baseTokenDecimals: number;
  baseTokenPrice: number;
  customOwnTokenPriceUsd: number | null;
  control: Control<
    {
      customOwnTokenPriceUsd: number | null;
    },
    any
  >;
  setCustomOwnTokenPriceUsd: UseFormSetValue<{ customOwnTokenPriceUsd: number | null }>;
}

export default function SqrtPriceX96Input({
  ownTokenLogoUrl,
  ownTokenSymbol,
  ownTokenAddress,
  baseTokenAddress,
  ownTokenDecimals,
  baseTokenPrice,
  setManualSqrtPriceX96,
  setCustomOwnTokenPriceUsd,
  control,
  customOwnTokenPriceUsd,
  baseTokenDecimals,
}: SqrtPriceX96InputProps) {
  const isOwnTokenAmountValid =
    !!customOwnTokenPriceUsd && (isNaN(customOwnTokenPriceUsd) || customOwnTokenPriceUsd <= 0);

  return (
    <>
      <div>
        <div className="flex items-center gap-2">
          <h2 className="text-[16px]">Initial Arrakis Price</h2>

          <QuestionButton explanation={Explanation.TOKEN_AMOUNTS} title="Token Amounts explained" />
        </div>

        <p className="text-sm text-primary-light mt-1">
          Please input the price (USD) of your token with which you want to start with
        </p>
        <div className="flex flex-col md:flex-row justify-between gap-2 relative my-3 max-w-[500px]">
          <div className="grow">
            <fieldset
              className={`rounded-[16px] px-4 py-3 bg-accent hover:bg-opacity-90 duration-300 border min-h-[110px] relative ${
                isOwnTokenAmountValid ? 'border-red-400' : 'border-none'
              }`}
            >
              <div className="flex flex-row items-center justify-between mb-2">
                <div className="flex items-center gap-1">
                  <img
                    alt="token logo"
                    src={ownTokenLogoUrl}
                    className="rounded-full max-w-[20px] max-h-[20px] align-middle border-green mx-0.5"
                  />
                  <span className="text-[16px]">{ownTokenSymbol?.toUpperCase()}</span>
                </div>

                <Controller
                  name="customOwnTokenPriceUsd"
                  render={() => (
                    <NumericFormat
                      autoComplete="off"
                      allowNegative={false}
                      placeholder="$0.00"
                      value={customOwnTokenPriceUsd}
                      onChange={(event) => {
                        const sqrtPriceX96 = getSqrtPriceX96({
                          ownTokenAddress,
                          baseTokenAddress,
                          ownTokenAmount: baseTokenPrice / Number(event.target.value.replace(/,/g, '')),
                          baseTokenAmount: 1,
                          ownTokenDecimals,
                          baseTokenDecimals,
                        });

                        setManualSqrtPriceX96(sqrtPriceX96);

                        setCustomOwnTokenPriceUsd(
                          'customOwnTokenPriceUsd',
                          Number(event.target.value.replace(/,/g, '')),
                        );
                      }}
                      thousandSeparator
                      className="max-w-[100px] sm:max-w-none bg-transparent rounded-xl text-right text-2xl text-white border-transparent focus:border-transparent focus:ring-0"
                    />
                  )}
                  control={control}
                  rules={{
                    required: "Can't be empty",
                    min: {
                      value: 0,
                      message: 'Minimum value is 0',
                    },
                  }}
                />
              </div>

              <div className="mt-2 w-fit text-xs text-vault-tertiary bg-vault-tertiary/10 ring-1 ring-vault-tertiary/20 py-1 px-2 rounded-lg">
                Your Token
              </div>
            </fieldset>
            {isOwnTokenAmountValid && (
              <div className="text-red-400 text-sm mt-2">Enter a valid amount larger than 0</div>
            )}
          </div>
        </div>
        <div className="text-vault-orange-2 flex items-center gap-1 text-[14px]">
          <ExclamationTriangleIcon width={18} height={18} />
          Make sure value is set correct as setting as incorrect value can lead to loss of funds!
        </div>
      </div>
    </>
  );
}
