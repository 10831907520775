import { Tooltip } from 'flowbite-react';
import { useMemo } from 'react';
import { LST_STABLE_STRATEGY_EXPLANATION, LST_VOLATILE_STRATEGY_EXPLANATION } from '../../../constants/texts';
import { isLstVault, isStableVault } from '../../../utils/vault';

type Strategy = 'stable' | 'volatile' | null;

interface StrategyBadgeProps {
  symbol0: string;
  symbol1: string;
}

function StrategyBadge({ symbol0, symbol1 }: StrategyBadgeProps) {
  const strategy: Strategy = useMemo(() => {
    if (isStableVault(symbol0, symbol1)) {
      return 'stable';
    } else {
      return 'volatile';
    }
  }, [symbol0, symbol1]);

  const isStable = useMemo(() => {
    if (isLstVault(symbol0, symbol1)) {
      return 'LST';
    } else {
      return '';
    }
  }, [symbol0, symbol1]);

  const tooltipText = !isStable
    ? ''
    : strategy === 'volatile'
    ? LST_VOLATILE_STRATEGY_EXPLANATION
    : LST_STABLE_STRATEGY_EXPLANATION;

  return (
    <Tooltip
      content={tooltipText}
      placement="bottom"
      animation="duration-1000"
      className="bg-vault-black text-vault-white text-center rounded-[12px]"
    >
      {strategy === 'volatile' && (
        <div className="w-fit text-sm duration-300 rounded-xl px-3 py-1 bg-orange-400/10 text-orange-400 ring-1 ring-inset ring-orange-400/20 hover:bg-orange-400/10 hover:text-orange-300 hover:ring-orange-300">
          {isStable ? 'LST' : ''} volatile
        </div>
      )}
      {strategy === 'stable' && (
        <div className="w-fit text-sm duration-300 rounded-xl px-3 py-1 bg-emerald-400/10 text-emerald-400 ring-1 ring-inset ring-emerald-400/20 hover:bg-emerald-400/10 hover:text-emerald-300 hover:ring-emerald-300">
          {isStable ? 'LST' : ''} stable
        </div>
      )}
    </Tooltip>
  );
}

export default StrategyBadge;
