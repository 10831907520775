import { useMemo } from 'react';
import { useContractRead } from 'wagmi';
import { tokenProxyAbi } from '../abis/token-proxy';
import { formatBigNumber } from '../utils/format-big-number';
import { getTokenByAddress } from '../utils/tokens';

interface BalancesHookProps {
  address: string;
  baseTokenAddress: string;
  ownTokenAddress: string;
  chainId: number;
}

export const useBalances = ({ address, baseTokenAddress, ownTokenAddress, chainId }: BalancesHookProps) => {
  const { data: balanceBaseTokenData, isLoading: isLoadingBalanceBaseToken } = useContractRead({
    address: baseTokenAddress as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [address],
    chainId,
  });

  const { data: balanceOwnTokenData, isLoading: isLoadingBalanceOwnToken } = useContractRead({
    address: ownTokenAddress as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [address],
    chainId,
  });

  const balanceBaseToken = useMemo(() => {
    const baseToken = getTokenByAddress(baseTokenAddress);

    return balanceBaseTokenData ? formatBigNumber(balanceBaseTokenData as bigint, baseToken?.decimals || 18, 12) : '0';
  }, [balanceBaseTokenData, baseTokenAddress]);

  const balanceOwnToken = useMemo(() => {
    return balanceOwnTokenData ? formatBigNumber(balanceOwnTokenData as bigint, 18, 8) : '0';
  }, [balanceOwnTokenData]);

  return {
    balanceBaseToken,
    balanceOwnToken,
    isLoadingBalanceBaseToken,
    isLoadingBalanceOwnToken,
  };
};
