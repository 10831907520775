import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { track } from '@vercel/analytics/react';
import { erc20ABI } from '@wagmi/core';
import { RangeSlider } from 'flowbite-react';
import { useMemo, useState } from 'react';
import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ArrakisV1GaugeAbi } from '../../../abis/ArrakisV1Gauge';
import { ArrakisV1StakingRouterAbi } from '../../../abis/ArrakisV1StakingRouter';
import { ArrakisVaultV1ProxyAbi } from '../../../abis/ArrakisVaultV1Proxy';
import { ARRAKIS_V1_STAKING_ROUTER_ADDRESSES } from '../../../constants';
import { IVault, WithdrawFormWrapperState } from '../../../types';
import { vaultTokenValueToUsd } from '../../../utils/calculate-tvl';
import { formatBigNumber } from '../../../utils/format-big-number';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { numberWithCommas } from '../../../utils/n-formatter';
import { alertUser } from '../../elements/notify';
import { Spinner } from '../../elements/spinner';
import WithdrawWrapper from '../../hoc/withdraw-form-wrapper';
import WithdrawResult from '../../vault/withdraw-result';
// eslint-disable-next-line import/no-unresolved
import CheckIcon from '/assets/images/icon-check-2.svg';

interface WithDrawFormProps {
  vault: IVault;
  userBalance: bigint;
  underlyingBalances: ({ amount0Current: bigint; amount1Current: bigint } & readonly [bigint, bigint]) | undefined;
  claimableRewards: number | undefined;
  refetchInvestedVaults?: () => void;
}

function WithDrawForm({
  vault,
  userBalance,
  underlyingBalances,
  claimableRewards,
  refetchInvestedVaults,
}: WithDrawFormProps) {
  const abi = vault.gauge ? ArrakisV1GaugeAbi : ArrakisVaultV1ProxyAbi;

  const [withdrawAmount, setwithdrawAmount] = useState(0);

  const { address: ownAddress, connector } = useAccount();

  const userBalanceUsd = vaultTokenValueToUsd(vault, userBalance) ?? 0;

  const userBalanceBN = BigInt(userBalance.toString() ?? '0');
  const totalSupplyBN = BigInt(vault.totalSupply?.toString() ?? '0');

  const [usdToBeRemoved, setUsdToBeRemoved] = useState(0);

  const [isLoadingSafeWallet, setLoadingSafeWallet] = useState(false);
  const [withdrawFormWrapperState, setWithdrawFormWrapperState] = useState(WithdrawFormWrapperState.DEFAULT);
  const isSafeWallet = connector?.id === 'safe';

  // how many tokens 0/1 the user will receive with current withdraw percentage
  const receivableAmount0 =
    withdrawAmount > 0
      ? ((((underlyingBalances?.[0] ?? BigInt(0)) * userBalanceBN) / totalSupplyBN) * BigInt(withdrawAmount)) /
        BigInt(100)
      : BigInt(0);

  const receivableAmount1 =
    withdrawAmount > 0
      ? ((((underlyingBalances?.[1] ?? BigInt(0)) * userBalanceBN) / totalSupplyBN) * BigInt(withdrawAmount)) /
        BigInt(100)
      : BigInt(0);

  const receivableAmount0String = receivableAmount0 ? formatBigNumber(receivableAmount0, vault.token0Decimals) : '0';
  const receivableAmount1String = receivableAmount1 ? formatBigNumber(receivableAmount1, vault.token1Decimals) : '0';

  // allowances: check if user has already approved withdrawal
  const { data: allowance } = useContractRead({
    address: (vault.gauge?.address ?? vault.id) as '0x${string}',
    abi: erc20ABI,
    chainId: vault.chainId,
    functionName: 'allowance',
    args: [(ownAddress as any) ?? BigInt(0), ARRAKIS_V1_STAKING_ROUTER_ADDRESSES(vault.chainId)],
    watch: true,
  });

  const isAllowWithdraw: boolean = useMemo(() => {
    if (allowance) {
      return (userBalanceBN * BigInt(withdrawAmount)) / BigInt(100) <= allowance;
    }
    return false;
  }, [allowance, vault, withdrawAmount]);

  // approving tokens
  const addRecentTransaction = useAddRecentTransaction();

  const { config: approveWithdtrawConfig } = usePrepareContractWrite({
    address: (vault.gauge ? vault.gauge.address : vault.id) as '0x${string}',
    // @ts-ignore
    abi: abi,
    functionName: 'approve',
    args: [ARRAKIS_V1_STAKING_ROUTER_ADDRESSES(vault.chainId), (userBalanceBN * BigInt(withdrawAmount)) / BigInt(100)],
    chainId: vault.chainId,
    enabled: withdrawAmount > 0,
    // @ts-ignore
    value: BigInt(0),
  });

  const {
    write: approveWithdraw,
    data: approveWithdrawData,
    isLoading: isLoadingApproveWithdraw,
  } = useContractWrite({
    ...(approveWithdtrawConfig as any),
    onError(error) {
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isLoadingApproveWithdrawAfter } = useWaitForTransaction({
    hash: approveWithdrawData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      alertUser(
        'success',
        'Transaction successful',
        getExplorerLink(vault.chainId || 137, approveWithdrawData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('withdraw v1', {
        type: 'click',
        feature: 'vault withdraw form v1',
        wallet: ownAddress as string,
        vault: vault.id,
      });

      addRecentTransaction({
        hash: approveWithdrawData?.hash ?? '',
        description: 'Approve withdraw',
      });
    },
  });

  // withdraw transaction

  const burnAmount = userBalanceBN > BigInt(0) ? (userBalanceBN * BigInt(withdrawAmount)) / BigInt(100) : BigInt(0);

  const amount0Min = useMemo(
    () => (((burnAmount * receivableAmount0) / totalSupplyBN) * BigInt(95)) / BigInt(100),
    [burnAmount, receivableAmount0, totalSupplyBN],
  );

  const amount1Min = useMemo(
    () => (((BigInt(burnAmount) * receivableAmount1) / totalSupplyBN) * BigInt(95)) / BigInt(100),
    [burnAmount, receivableAmount1, totalSupplyBN],
  );

  const removeLiquidityArgs = useMemo(() => {
    return [
      vault.gauge ? (vault.gauge.address as `0x${string}`) : (vault.id as `0x${string}`),
      burnAmount,
      amount0Min,
      amount1Min,
      ownAddress as `0x${string}`,
    ];
  }, [vault, burnAmount, amount0Min, amount1Min, ownAddress]);

  const { config: withdrawConfig } = usePrepareContractWrite({
    address: ARRAKIS_V1_STAKING_ROUTER_ADDRESSES(vault.chainId),
    abi: ArrakisV1StakingRouterAbi,
    functionName: vault.gauge ? 'removeLiquidityAndUnstake' : 'removeLiquidity',
    // @ts-ignore
    args: removeLiquidityArgs,
    enabled: !!ownAddress && burnAmount > 0 && isAllowWithdraw,
    // @ts-ignore
    value: BigInt(0),
  });

  const {
    data: withdrawData,
    write: withdraw,
    isLoading: isLoadingWithdrawTransaction,
  } = useContractWrite({
    ...(withdrawConfig as any),
    onError(error) {
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isLoadingWithdrawTransactionAfter } = useWaitForTransaction({
    hash: withdrawData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }

      alertUser(
        'success',
        'Liquidity removed successful!',
        getExplorerLink(vault.chainId || 137, withdrawData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      refetchInvestedVaults?.();

      track('withdraw v1', {
        type: 'click',
        feature: 'vault withdraw form v1',
        wallet: ownAddress as string,
        vault: vault.id,
        value: (Number(userBalanceUsd) * withdrawAmount) / 100,
      });

      setWithdrawFormWrapperState(WithdrawFormWrapperState.WITHDRAW_SUCCESS);

      addRecentTransaction({
        hash: withdrawData?.hash ?? '',
        description: 'Withdraw',
      });
    },
  });

  function onWithdraw() {
    if (withdraw) {
      setUsdToBeRemoved((Number(userBalanceUsd) * withdrawAmount) / 100);
      withdraw();
    }

    if (isSafeWallet) {
      setWithdrawFormWrapperState(WithdrawFormWrapperState.SAFE_APP_WITHDRAW);
    }
  }

  function onApproveWithdraw() {
    if (isSafeWallet) {
      setLoadingSafeWallet(true);
    }
    approveWithdraw?.();
  }

  return (
    <WithdrawWrapper isLoading={!underlyingBalances} usdValue={usdToBeRemoved} state={withdrawFormWrapperState}>
      <div className="flex md:items-center text-[20px] md:text-[24px] justify-between my-3 flex-col md:flex-row">
        <div>
          <div className="text-vault-light">Amount to remove: {withdrawAmount}%</div>
          <div className="text-vault-light">
            ${numberWithCommas(((Number(userBalanceUsd) * withdrawAmount) / 100).toFixed(2))}
          </div>
        </div>
        <div>
          {' '}
          <div className="text-vault-gray-hover md:text-right">
            Position after remove: $
            {numberWithCommas((Number(userBalanceUsd) - (Number(userBalanceUsd) * withdrawAmount) / 100).toFixed(2))}
          </div>
          <div className="text-vault-gray-hover md:text-right">
            Current position: ${numberWithCommas(userBalanceUsd)}
          </div>
        </div>
      </div>

      <RangeSlider
        id="sm-range"
        sizing="sm"
        color="red"
        onChange={(e) => setwithdrawAmount(Number(e.target.value))}
        defaultValue={0}
      />
      <div className="flex items-center justify-between text-vault-gray-hover text-sm mt-5 ">
        <div>0%</div>
        <div>25%</div>
        <div>50%</div>
        <div>75%</div>
        <div>100%</div>
      </div>
      <WithdrawResult
        vault={vault}
        claimableRewards={claimableRewards}
        receivableAmount0String={receivableAmount0String}
        receivableAmount1String={receivableAmount1String}
      />

      {isAllowWithdraw ? (
        <div className="mb-5 mt-10 flex gap-3 items-center">
          <button className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center">
            <img src={CheckIcon} alt="check icon" />
          </button>
          <button
            disabled={withdrawAmount === 0 || isLoadingWithdrawTransaction || isLoadingWithdrawTransactionAfter}
            className={`py-2 grow  rounded-[12px] text-[20px] h-[64px] ${
              withdrawAmount > 0 ? 'bg-vault-light-gray text-vault-black' : 'bg-vault-gray-2 text-vault-gray-hover'
            }`}
            onClick={() => onWithdraw()}
          >
            <div className="flex items-center justify-center gap-3">
              Remove liquidity
              {(isLoadingWithdrawTransaction || isLoadingWithdrawTransactionAfter) && (
                <span className="mx-2">
                  <Spinner color="vault-black" />{' '}
                </span>
              )}
            </div>
          </button>
        </div>
      ) : (
        <div className="mb-5 mt-10 flex gap-3 items-center">
          <button
            disabled={
              withdrawAmount === 0 || isLoadingApproveWithdraw || isLoadingApproveWithdrawAfter || isLoadingSafeWallet
            }
            className={`py-2 grow  rounded-[12px] text-[20px] h-[64px] ${
              withdrawAmount > 0 ? 'bg-vault-light-gray text-vault-black' : 'bg-vault-gray-2 text-vault-gray-hover'
            }`}
            onClick={onApproveWithdraw}
          >
            <div className="flex items-center justify-center gap-3">
              Approve {vault.symbol0?.toUpperCase()} - {vault.symbol1?.toUpperCase()}
              {(isLoadingApproveWithdraw || isLoadingApproveWithdrawAfter || isLoadingSafeWallet) && (
                <span className="mx-2">
                  <Spinner color="vault-black" />{' '}
                </span>
              )}
            </div>
          </button>
          <button className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]">
            2
          </button>
        </div>
      )}
    </WithdrawWrapper>
  );
}

export default WithDrawForm;
