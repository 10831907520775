import { readContract } from '@wagmi/core';
import { ArrakisV2ResolverAbi } from '../abis/ArrakisV2Resolver';
import { ArrakisVaultV1ProxyAbi } from '../abis/ArrakisVaultV1Proxy';
import { ARRAKIS_V2_RESOLVER_ADDRESSES } from '../constants';
import { IVault } from '../types';
import { amountMinusSlippageInPercent } from './math';

export const getAddLiquidityParams = async (
  vaultId: string,
  input0: bigint,
  input1: bigint,
  receiver: string,
  vaultGaugeAddress?: string,
): Promise<any[]> => {
  if (input0 === BigInt(0) || input1 === BigInt(0)) {
    return [vaultId, input0, input1, BigInt(0), BigInt(0), BigInt(0), receiver];
  } else {
    const mintAmounts = await readContract({
      address: vaultId as '0x${string}',
      abi: ArrakisVaultV1ProxyAbi,
      functionName: 'getMintAmounts',
      args: [input0, input1],
    });

    return [
      vaultGaugeAddress ? vaultGaugeAddress : vaultId,
      input0,
      input1,
      Number((mintAmounts[0] * BigInt(9500)) / BigInt(10000)),
      (mintAmounts[1] * BigInt(9500)) / BigInt(10000),
      (mintAmounts[2] * BigInt(9750)) / BigInt(10000),
      receiver,
    ];
  }
};

export const getAddLiquidityPermit2Params = async (
  vault: IVault,
  input0: bigint,
  input1: bigint,
  slippageInPercent?: number,
): Promise<{ amount0: bigint; amount1: bigint; mintAmount: bigint }> => {
  if (input0 === BigInt(0) || input1 === BigInt(0)) {
    return { amount0: BigInt(0), amount1: BigInt(0), mintAmount: BigInt(0) };
  } else {
    const mintAmounts: any = await readContract({
      address: ARRAKIS_V2_RESOLVER_ADDRESSES(vault.chainId) as '0x${string}',
      abi: ArrakisV2ResolverAbi,
      functionName: 'getMintAmounts',
      args: [vault.id, input0, input1],
    });

    return {
      amount0: slippageInPercent ? amountMinusSlippageInPercent(mintAmounts[0], 0.9) : mintAmounts[0],
      amount1: slippageInPercent ? amountMinusSlippageInPercent(mintAmounts[1], 0.9) : mintAmounts[1],
      mintAmount: slippageInPercent ? amountMinusSlippageInPercent(mintAmounts[2], 0.9) : mintAmounts[2],
    };
  }
};

export const getAddLiquidityParamsWithGauge = async (
  guniPool: any,
  input0: bigint,
  input1: bigint,
  receiver: string,
  gaugeAddress: string,
): Promise<any[]> => {
  if (input0 === BigInt(0) || input1 === BigInt(0)) {
    return [gaugeAddress, input0, input1, BigInt(0), BigInt(0), BigInt(0), receiver];
  } else {
    const mintAmounts = await guniPool.getMintAmounts(input0, input1);

    return [
      gaugeAddress,
      input0,
      input1,
      (mintAmounts[0] * BigInt(9500)) / BigInt(10000),
      (mintAmounts[1] * BigInt(9500)) / BigInt(10000),
      (mintAmounts[2] * BigInt(9750)) / BigInt(10000),
      receiver,
    ];
  }
};

export function hasVaultNativeToken(vault: IVault): {
  hasNativeToken: boolean;
  nativeSymbol0?: string;
  nativeSymbol1?: string;
  nativeAddress0?: string;
  nativeAddress1?: string;
} {
  const symbol0 = vault.symbol0.toLowerCase();
  const symbol1 = vault.symbol1.toLowerCase();

  let nativeSymbol0: string | undefined;
  let nativeSymbol1: string | undefined;

  switch (vault.network) {
    case 'ethereum':
    case 'goerli':
      nativeSymbol0 = symbol0 === 'weth' ? 'eth' : undefined;
      nativeSymbol1 = symbol1 === 'weth' ? 'eth' : undefined;
      break;
    case 'optimism':
    case 'optimism_shadow':
      nativeSymbol0 = symbol0 === 'weth' ? 'eth' : undefined;
      nativeSymbol1 = symbol1 === 'weth' ? 'eth' : undefined;
      break;
    case 'arbitrum':
    case 'arbitrum_shadow':
      nativeSymbol0 = symbol0 === 'weth' ? 'eth' : undefined;
      nativeSymbol1 = symbol1 === 'weth' ? 'eth' : undefined;
      break;
    case 'polygon':
    case 'polygon_shadow':
      nativeSymbol0 = symbol0 === 'wmatic' ? 'matic' : undefined;
      nativeSymbol1 = symbol1 === 'wmatic' ? 'matic' : undefined;
      break;
    case 'base':
      nativeSymbol0 = symbol0 === 'weth' ? 'eth' : undefined;
      nativeSymbol1 = symbol1 === 'weth' ? 'eth' : undefined;
      break;
  }

  return {
    hasNativeToken: Boolean(nativeSymbol0 || nativeSymbol1),
    nativeSymbol0,
    nativeSymbol1,
  };
}
