import { Link } from 'react-router-dom';
import Gradient1 from '../components/backgrounds/Gradient1';
import Grid from '../components/backgrounds/Grid';

function EntryPage() {
  return (
    <div className="bg-j-dark h-screen w-screen">
      <div className="hidden sm:block">
        <Gradient1 />

        <div className="z-10 absolute inset-0 h-scren w-full bg-gradient-to-b from-[rgba(255,255,255,0.02)] to-[rgba(0,0,0,1)]"></div>

        <Grid opacity={0.05} />
      </div>

      <div className="container max-w-screen-xl mx-auto flex flex-col items-center mt-8 sm:mt-0 sm:justify-center h-screen gap-8 sm:gap-20 p-4 sm:p-0">
        <div className="text-center flex flex-col gap-4 items-center ">
          <div className="w-fit flex items-center gap-2 ring-1 ring-white/10 backdrop-blur-sm shadow-xl bg-white/5 rounded-[16px] px-4 py-2 text-white">
            <img src="/assets/images/arrakis-logo-mobile.svg" width="20px" height="20px" alt="" />
            <span>Arrakis Finance</span>
          </div>
          <h1 className="text-2xl sm:text-4xl text-white z-10 leading-14">
            Unlock Your Liquidity’s <br /> Greatest Potential
          </h1>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center gap-5 sm:gap-8 ">
          <Link
            to="/create"
            className="backdrop-blur-sm grayscale hover:grayscale-0 shadow-xl z-10 bg-vault-black/10 ring-1 ring-white/10 p-8 rounded-[12px] text-white sm:hover:scale-105 duration-300 w-full sm:w-[400px] lg:w-[500px] text-center flex flex-col gap-3 items-center h-56 sm:h-80 lg:h-[350px] cursor-pointer hover:ring-vault-orange/80"
          >
            <h1 className="text-xl text-white-100 mb-2">For Token Issuers</h1>
            <p className="text-sm text-white/70">Get liquiditiy for your token project with Arrakis PALM vaults</p>
            <img src="/assets/images/liquidity.svg" alt="liquidity" className="h-20 sm:h-48" />
          </Link>
          <Link
            className="grayscale hover:grayscale-0 shadow-xl backdrop-blur-sm z-10 bg-vault-black/10 ring-1 ring-white/10 p-8 rounded-[12px] text-white  duration-300 w-full sm:w-[400px] lg:w-[500px] text-center flex flex-col gap-3 items-center h-56 sm:h-80 lg:h-[350px] cursor-pointer hover:ring-vault-orange/80 sm:hover:scale-105"
            to="/v2-vaults"
          >
            <h1 className="text-xl text-white-100">For Users</h1>
            <p className="text-sm text-white/70">Deposit into Arrakis Vaults and earn yields</p>
            <img src="/assets/images/cube.png" className="h-20 sm:h-48" alt="add liquidity" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EntryPage;
