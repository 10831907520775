import { ValidTokenRatio, ValidTokenRatios } from '../types/create-vault';

export const checkApprox = (num1: number, num2: number, epsilonInPercent: number) => {
  // Calculating the absolute difference
  // and compare with epsilon
  return Math.abs(num1 - num2) / num1 < epsilonInPercent / 100;
};

// Generate a random integer between min (inclusive) and max (inclusive)
export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const amountMinusSlippageInPercent = (amount: bigint, slippage: number) =>
  (amount * (BigInt(10000) - BigInt(slippage * 100))) / BigInt(10000);

export function roundToNearestTokenRatio(value: number): ValidTokenRatio {
  let closest = ValidTokenRatios[0];
  ValidTokenRatios.forEach((target) => {
    if (Math.abs(value - target) < Math.abs(value - closest)) {
      closest = target;
    }
  });
  return closest;
}

export function capNumberMax(num: number, max: number) {
  return Math.min(num, max);
}

export function capNumberMin(num: number, min: number) {
  return Math.max(num, min);
}

export function toFixedIfNecessary(value: string, dp: number) {
  return +parseFloat(value).toFixed(dp);
}

export function roundDownToNDecimals(value: number, dp: number) {
  return Math.floor(value * Math.pow(10, dp)) / Math.pow(10, dp);
}

export function approximatelyEqual(a: number, b: number, marginPercentage: number): boolean {
  const margin = Math.abs((a * marginPercentage) / 100);
  return Math.abs(a - b) <= margin;
}
