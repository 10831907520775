import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { IVault, TemporaryVault } from '../../types';
import { getTvlChartOptionsForTemporaryVault } from '../../utils/charts/tvl';
import CollapseButton from '../elements/collapse/CollapseButton';

interface TemporaryVaultRatioChartProps {
  vault: IVault | TemporaryVault;
  currentTvl: number;
  percentage0: number;
  percentage1: number;
}

function TemporaryVaultRatioChart({ vault, percentage0, percentage1, currentTvl }: TemporaryVaultRatioChartProps) {
  const [showChart, setShowChart] = useState(false);

  const compositionSeries = [
    {
      name: 'Position 0',
      data: Array.from({ length: 10 }, (_, i) => [
        new Date(new Date().getTime() - i * 2 * 60 * 1000),
        (percentage0 * currentTvl) / 100,
      ]),
    },
    {
      name: 'Position 1',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      data: Array.from({ length: 10 }, (_, i) => [new Date(), (percentage1 * currentTvl) / 100]),
    },
  ];

  return (
    <>
      <CollapseButton text="Vault Data" showContent={showChart} setShowContent={setShowChart} />
      {showChart && (
        <div className="hidden md:block w-full lg:w-[653px] lg:h-[450px]">
          <ReactApexChart
            options={getTvlChartOptionsForTemporaryVault(compositionSeries, vault.symbol0, vault.symbol1)}
            // @ts-ignore
            series={compositionSeries}
            height={380}
            type="area"
          />
          <div className="flex items-center justify-between text-[14px] align-middle">
            <div className="flex items-center gap-5">
              <div className="text-vault-orange flex items-center gap-2">
                <div className="w-[14px] h-[14px] rounded-full bg-vault-orange -mt-[1px]"></div>
                {vault.symbol0?.toUpperCase()} Holdings
              </div>
              <div className="text-[#F3F2F2] flex items-center gap-2">
                <div className="w-[14px] h-[14px] rounded-full bg-[#F3F2F2] -mt-[1px]"></div>
                {vault.symbol1?.toUpperCase()} Holdings
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TemporaryVaultRatioChart;
