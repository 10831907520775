import { useQuery } from '@tanstack/react-query';
import { memo } from 'react';
import { useAccount } from 'wagmi';
import Gradient1 from '../components/backgrounds/Gradient1';
import Grid from '../components/backgrounds/Grid';
import Banner from '../components/layout/banner/Banner';
import Footer from '../components/layout/footer/Footer';
import { Header } from '../components/layout/header';
import Navbar from '../components/layout/navbar';
import VaultListSkeleton from '../components/vault/skeleton/VaultListSkeleton';
import { VaultListItem } from '../components/vault/vaultListItem';
import { VaultV2ListItem } from '../components/vault/vaultListItem/V2ListItem';
import { getInvestedVaults } from '../utils/api';

function MyLiquidity() {
  const { address } = useAccount();

  const {
    data: vaults,
    isLoading,
    error,
    refetch: refetchInvestedVaults,
  } = useQuery(['own-vaults'], () => getInvestedVaults(address), {
    //refetchInterval: 30000,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Gradient1 />
      <Grid opacity={0.04} />
      <Header />
      <div className="flex flex-col items-center w-full">
        <div className="px-4 md:px-0 max-w-screen-lg w-full">
          <div className="w-full flex flex-col gap-10">
            <Banner />

            <Navbar activeItem="my-liquidity" />
          </div>

          <ul className="w-full my-14">
            <>
              {vaults?.v2Vaults && vaults.v2Vaults.length > 0 && (
                <>
                  <h2 className="text-vault-light text-[32px] tracking-[-0.02em] leading-[32px] mb-4">V2 Vaults</h2>
                  {vaults.v2Vaults.map((vault, index) => {
                    return (
                      <li key={vault.id + index} className="flex items-center mt-2 mb-2 mx-2 md:mx-0">
                        <VaultV2ListItem vault={vault} refetch={refetchInvestedVaults} />
                      </li>
                    );
                  })}
                </>
              )}

              {vaults?.v1Vaults && vaults.v1Vaults.length > 0 && (
                <>
                  <h2 className="text-vault-light text-[32px] tracking-[-0.02em] leading-[32px] mb-4 mt-12">
                    V1 Vaults
                  </h2>
                  {vaults.v1Vaults.map((vault, index) => {
                    return (
                      <li key={vault.id + index} className="flex items-center mt-2 mb-2 mx-2 md:mx-0">
                        <VaultListItem vault={vault} refetch={refetchInvestedVaults} />
                      </li>
                    );
                  })}
                </>
              )}

              {isLoading && <VaultListSkeleton numberOfVaults={10} />}
              {error && <p className="text-white mt-20 text-center">Something went wrong</p>}
              {vaults && vaults?.v1Vaults?.length === 0 && vaults?.v2Vaults?.length === 0 && (
                <div className="bg-vault-black  bg-opacity-100 flex items-center justify-center px-4 sm:px-8 py-8">
                  <div className="text-center tracking-[-0.01em]">
                    <h2 className="text-vault-light-gray text-[24px] mb-5">You have no liquidity now</h2>
                    <p className="text-vault-gray-hover text-[16px]">You can find suitable Vaults on the tab Vaults.</p>
                  </div>
                </div>
              )}
            </>
          </ul>
          <div className="self-start">
            <Footer />
          </div>
        </div>
      </div>{' '}
    </>
  );
}

export default memo(MyLiquidity);
