import { useQuery } from '@tanstack/react-query';
import { createRef, memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import Gradient1 from '../components/backgrounds/Gradient1';
import Grid from '../components/backgrounds/Grid';
import Banner from '../components/layout/banner/Banner';
import Footer from '../components/layout/footer/Footer';
import { Header } from '../components/layout/header';
import Navbar from '../components/layout/navbar';
import Searchbar from '../components/vault/Searchbar';
import VaultListSkeleton from '../components/vault/skeleton/VaultListSkeleton';
import { VaultV2ListItem } from '../components/vault/vaultListItem/V2ListItem';
import { networkFiltersV2 } from '../constants';
import { getV2Vaults } from '../utils/api';
import { scrollToTargetAdjusted } from '../utils/scroll';

function V2VaultList() {
  const [searchParams, setSearchParams] = useSearchParams('sortDirection=desc&sort=tvl');
  const [isShowMoreVaults, setIsShowMoreVaults] = useState(false);

  const {
    data: vaults,
    isLoading,
    error,
    refetch,
  } = useQuery(['v2-vaults', searchParams], () => getV2Vaults(searchParams.toString()), {
    // refetchInterval: 30000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, [searchParams, refetch]);

  const { vaultId } = useParams();
  const vaultIdLowerCase = vaultId?.toLowerCase();

  // scroll to selected vault
  const [vaultRefs, setVaultRefs] = useState([]);

  useEffect(() => {
    setVaultRefs((elRefs) =>
      Array(vaults?.length ?? 0)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [vaults]);

  useEffect(() => {
    if (vaultIdLowerCase && vaultRefs.length > 0) {
      const index = vaults?.findIndex((vault) => vault.id.toLowerCase() === vaultIdLowerCase);
      // @ts-ignore
      if (index && vaultRefs[index]?.current) {
        scrollToTargetAdjusted(vaultRefs[index], 100);
      }
    }
  }, [vaultRefs]);

  const [vaultsShown, vaultsShowMore] = useMemo(() => {
    const vaultsShown = [];
    const vaultsShowMore = [];

    if (vaults) {
      for (let i = 0; i < vaults.length; i++) {
        if (
          vaults[i].gauge?.rewardApr ||
          0 > 0 || // show vaults with APR
          vaults[i].tvl > 50000 // or with +$50k in TVL.
        ) {
          vaultsShown.push(vaults[i]);
        } else {
          vaultsShowMore.push(vaults[i]);
        }
      }
    }

    return [vaultsShown, vaultsShowMore];
  }, [vaults]);

  return (
    <>
      <Header />
      <Gradient1 />
      <Grid opacity={0.04} />

      <div className="flex flex-col items-center w-full">
        <div className="px-4 md:px-0 max-w-screen-lg w-full">
          <div className=" w-full  px-4 md:px-0 max-w-screen-lg flex flex-col gap-10">
            <Banner />

            <Navbar activeItem="v2-vaults" />
            <Searchbar
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              showOnlyGaugesFilter={false}
              sortByApr={false}
              networkFilters={networkFiltersV2}
            />
          </div>

          <ul className={`w-full mb-20`}>
            <>
              {vaultsShown.map((vault, index) => {
                return (
                  <li
                    key={vault.id + index}
                    className="flex items-center mt-2 mb-2 mx-2 md:mx-0"
                    // @ts-ignore
                    ref={vaultRefs[index]}
                  >
                    <VaultV2ListItem
                      vault={vault}
                      selectedVaultId={vaultIdLowerCase}
                      refetch={refetch}
                      withNavigation={true}
                    />
                  </li>
                );
              })}

              {vaultsShowMore.length > 0 && (
                <div className="mt-10">
                  <button
                    type="button"
                    onClick={() => setIsShowMoreVaults(!isShowMoreVaults)}
                    className="px-4 py-2 mb-5 rounded-[12px] ring-1 ring-vault-orange text-white hover:bg-vault-orange/10 duration-300"
                  >
                    {isShowMoreVaults ? 'Show less' : 'Show more...'}
                  </button>
                  {isShowMoreVaults &&
                    vaultsShowMore.map((vault, index) => {
                      return (
                        <li
                          key={vault.id + index}
                          className="flex items-center mt-2 mb-2 mx-2 md:mx-0"
                          // @ts-ignore
                          ref={vaultRefs[index]}
                        >
                          <VaultV2ListItem
                            vault={vault}
                            selectedVaultId={vaultIdLowerCase}
                            refetch={refetch}
                            withNavigation={true}
                          />
                        </li>
                      );
                    })}
                </div>
              )}

              {isLoading && <VaultListSkeleton numberOfVaults={10} />}
              {error && <p className="text-white mt-20 text-center">Something went wrong</p>}
              {vaults && vaults.length === 0 && (
                <p className="text-white mt-20 text-center">No vaults found. Try searching for something else.</p>
              )}
            </>
          </ul>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default memo(V2VaultList);
