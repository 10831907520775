import { capitalizeFirstLetter, getNetworkNameFromChainId } from '../../../../utils/n-formatter';
import { Token } from '../../../../utils/tokens';
import Address from '../../../elements/links/Address';
import FormSection from '../../FormSection';
import NetworkLogo from './NetworkLogo';

function ReviewItem({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className="flex flex-col gap-2 text-[16px] leading-5 tracking-[-0.16px] text-primary-light whitespace-nowrap">
      <div>{title}</div>
      <div className="flex items-center gap-2">{children}</div>
    </div>
  );
}

interface ReviewVaultProps {
  ownToken: Token;
  baseTokenAddress: string;
  baseTokenSymbol: string;
  baseTokenLogoUrl: string;
  chainId: number;
  feeTier: number;
  ownerAddress: string;
  ownTokenAmount: string;
  baseTokenAmount: string;
  ratio: number;
}

function ReviewVault({
  ownToken,
  baseTokenAddress,
  baseTokenSymbol,
  baseTokenLogoUrl,
  chainId,
  feeTier,
  ownerAddress,
  ownTokenAmount,
  baseTokenAmount,
  ratio,
}: ReviewVaultProps) {
  return (
    <div className="max-w-[700px] grow">
      <FormSection
        title="Review Vault data"
        description="After having reviewed your vault data you can create your vault by approving and executing the transaction using
        the buttons below."
      />

      <div className="flex flex-col gap-6">
        <ReviewItem title="Base token">
          <img src={baseTokenLogoUrl} alt="logo token" className="w-5 h-5 rounded-full" />
          <span className="truncate text-white">
            {baseTokenAmount} {baseTokenSymbol}
          </span>
          <Address chainId={chainId} address={baseTokenAddress} />
        </ReviewItem>
        <ReviewItem title="Your token">
          <img src={ownToken.logoURI} alt="logo token" className="w-5 h-5 rounded-full" />
          <span className="truncate text-white">
            {ownTokenAmount} {ownToken.symbol}
          </span>
          <Address chainId={chainId} address={ownToken.address} />
        </ReviewItem>
        <div className="flex flex-col md:flex-row gap-10">
          <ReviewItem title="Network">
            <NetworkLogo chainId={chainId} size={20} />
            <span className="truncate text-white">{capitalizeFirstLetter(getNetworkNameFromChainId(chainId))}</span>
          </ReviewItem>

          <ReviewItem title="Vault owner">
            <Address chainId={chainId} address={ownerAddress} />
          </ReviewItem>
        </div>

        <div className="flex flex-wrap gap-10">
          <ReviewItem title="Fee tier">
            <span>{(feeTier / 10000).toFixed(2)}%</span>
          </ReviewItem>
          <ReviewItem title="Vault type">
            <div>Private</div>
          </ReviewItem>
          <ReviewItem title="Inventory ratio">
            <div>
              {ratio * 100}% {ownToken.symbol} / {100 - ratio * 100}% {baseTokenSymbol}
            </div>
          </ReviewItem>
        </div>
      </div>
    </div>
  );
}

export default ReviewVault;
