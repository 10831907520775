import { toast } from 'react-toastify';

export function alertUser(
  type: 'success' | 'error',
  message: string,
  linkUrl?: string,
  linkText?: string,
  isSafeWallet?: boolean,
) {
  if (
    message.includes('UserRejectedRequestError') ||
    message.includes('User rejected') ||
    message.includes('User denied') ||
    isSafeWallet
  ) {
    return;
  }

  toast[type](
    <div className="leading-[24px] text-[20px]">
      {type === 'success' && message}
      {type === 'error' && 'An error occurred. Please try again.'}

      {linkUrl && linkText && (
        <div className="text-[16px]">
          <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
        </div>
      )}
    </div>,
    {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'dark',
    },
  );
}
