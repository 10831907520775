function StrategyJson6Explanation() {
  return (
    <div>
      <h2 className="text-lg font-bold mb-1">What is the strategy JSON?</h2>
      <p>
        The strategy JSON is a configuration object that results from all the other input fields. Usually you don't need
        to worry about it, but if you are an advanced user and want to customize the details of your strategy, you can
        edit it here.
      </p>
    </div>
  );
}

export default StrategyJson6Explanation;
