import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { useMemo } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ArrakisV2PalmTermsAbi } from '../abis/ArrakisV2PalmTerms';
import { alertUser } from '../components/elements/notify';
import { ARRAKIS_V2_TERMS_ROUTER_ADDRESS } from '../constants';
import { ExplorerDataType, getExplorerLink } from '../utils/get-explorer-link';
import { strategyJsonStringToHex } from '../utils/n-formatter';

interface UpdateVaultHookResult {
  isLoadingUpdateVault: boolean;
  updateVault: (() => void) | undefined;
}

interface UpdateVaultHookProps {
  vaultAddress: string;
  currentStrategy: string;
  chainId: number;
  onSuccess?: () => void;
}

export function useUpdateVault({
  vaultAddress,
  currentStrategy,
  chainId,
  onSuccess,
}: UpdateVaultHookProps): UpdateVaultHookResult {
  const addRecentTransaction = useAddRecentTransaction();

  const configArgs = useMemo(() => {
    return [vaultAddress, strategyJsonStringToHex(currentStrategy)];
  }, [vaultAddress, currentStrategy]);

  const { config } = usePrepareContractWrite({
    address: ARRAKIS_V2_TERMS_ROUTER_ADDRESS as '0x${string}',
    abi: ArrakisV2PalmTermsAbi,
    functionName: 'setVaultData',
    args: configArgs,
    chainId,
    value: BigInt(0),
  });

  const {
    data: updateVaultData,
    write: updateVault,
    isLoading: isLoadingUpdateVault,
  } = useContractWrite({
    ...(config as any),
    onError(error) {
      console.log('error', error);
    },
    onSettled(data, error) {
      console.log('error', error);
    },
  });

  const { isLoading: isLoadingUpdateVaultAfter } = useWaitForTransaction({
    hash: updateVaultData?.hash,
    onSettled(data, error) {
      if (error) {
        console.log('error', error);
        return;
      }
      alertUser(
        'success',
        'Strategy updated successfully!',
        getExplorerLink(chainId || 1, updateVaultData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        false,
      );

      onSuccess?.();

      addRecentTransaction({
        hash: updateVaultData?.hash ?? '',
        description: 'Create Pool',
      });
    },
  });

  return {
    isLoadingUpdateVault: isLoadingUpdateVault || isLoadingUpdateVaultAfter,
    updateVault,
  };
}
