import BaseTokenExplanation from '../components/layout/sidebar/explanations/BaseToken';
import FeeTierExplanation from '../components/layout/sidebar/explanations/FeeTier';
import GasPriceExplanation from '../components/layout/sidebar/explanations/GasPrice';
import LiquidityConcentrationExplanation from '../components/layout/sidebar/explanations/LiquidityConcentration';
import MaxTwapDeviationExplanation from '../components/layout/sidebar/explanations/MaxTwapDeviation';
import NetworkExplanation from '../components/layout/sidebar/explanations/Network';
import RebalancingSpeedExplanation from '../components/layout/sidebar/explanations/RebalancingSpeed';
import RefundExplanation from '../components/layout/sidebar/explanations/RefundExplanation';
import StrategyJson6Explanation from '../components/layout/sidebar/explanations/StrategyJson';
import TokenExplanation from '../components/layout/sidebar/explanations/Token';
import TokenAmountsExplanation from '../components/layout/sidebar/explanations/TokenAmounts';
import TokenRatioExplanation from '../components/layout/sidebar/explanations/TokenRatio';
import TvlExplanation from '../components/layout/sidebar/explanations/Tvl';
import TwapDurationExplanation from '../components/layout/sidebar/explanations/TwapDuration';
import VaultOwnerExplanation from '../components/layout/sidebar/explanations/VaultOwner';
import VaultTypeExplanation from '../components/layout/sidebar/explanations/VaultType';
import ZapinExplanation from '../components/layout/sidebar/explanations/Zapin';
import { Explanation } from '../types';

export function getExplanationComponent(explanation?: Explanation) {
  switch (explanation) {
    case Explanation.BASE_TOKEN:
      return <BaseTokenExplanation />;
    case Explanation.FEE_TIER:
      return <FeeTierExplanation />;
    case Explanation.GAS_PRICE:
      return <GasPriceExplanation />;
    case Explanation.GAS_TANK:
      return <GasPriceExplanation />;
    case Explanation.LIQUIDITY_CONCENTRATION:
      return <LiquidityConcentrationExplanation />;
    case Explanation.REBALANCE_SPEED:
      return <RebalancingSpeedExplanation />;
    case Explanation.STRATEGY_JSON:
      return <StrategyJson6Explanation />;
    case Explanation.TOKEN:
      return <TokenExplanation />;
    case Explanation.TOKEN_AMOUNTS:
      return <TokenAmountsExplanation />;
    case Explanation.TOKEN_RATIO:
      return <TokenRatioExplanation />;
    case Explanation.TVL:
      return <TvlExplanation />;
    case Explanation.TWAP_DURATION:
      return <TwapDurationExplanation />;
    case Explanation.MAX_TWAP_DEVIATION:
      return <MaxTwapDeviationExplanation />;
    case Explanation.NETWORK:
      return <NetworkExplanation />;
    case Explanation.VAULT_OWNER:
      return <VaultOwnerExplanation />;
    case Explanation.ZAPIN:
      return <ZapinExplanation />;
    case Explanation.REFUND:
      return <RefundExplanation />;
    case Explanation.VAULT_TYPE:
      return <VaultTypeExplanation />;
    default:
      return <ZapinExplanation />;
  }
}
