// ConfettiContext.tsx
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface ConfettiContextType {
  showConfetti: boolean;
  startConfettiAnimation: () => void;
}

const ConfettiContext = createContext<ConfettiContextType | undefined>(undefined);

interface ConfettiProviderProps {
  children: ReactNode;
}

export function ConfettiProvider({ children }: ConfettiProviderProps) {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (showConfetti) {
      const timeout = setTimeout(() => {
        setShowConfetti(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [showConfetti]);

  const startConfettiAnimation = () => {
    setShowConfetti(true);
  };

  return (
    <ConfettiContext.Provider value={{ showConfetti, startConfettiAnimation }}>{children}</ConfettiContext.Provider>
  );
}

export function useConfetti() {
  const context = useContext(ConfettiContext);
  if (context === undefined) {
    throw new Error('useConfetti must be used within a ConfettiProvider');
  }
  return context;
}
