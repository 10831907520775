import { z } from 'zod';
import tokenList from './extendedtokens-uniswap.json';

export const TokenSchema = z.object({
  chainId: z.optional(z.number()),
  address: z.string(),
  name: z.string(),
  symbol: z.string(),
  decimals: z.number(),
  logoURI: z.optional(z.string()),
});

export type Token = z.infer<typeof TokenSchema>;

export function getUnknownToken(chainId: number, address: string): Token {
  return {
    chainId,
    address: address.toLowerCase(),
    name: 'Unknown',
    symbol: 'UNKNOWN',
    decimals: 18,
    logoURI: '/assets/images/question-mark.svg',
  };
}

export function getTokenList(chainId: number): Token[] {
  return tokenList.tokens.filter((token) => token.chainId === chainId);
}

export function getTokenByAddressAndChainId(chainId: number, address: string): Token | undefined {
  return getTokenList(chainId).find((token) => token.address.toLowerCase() === address.toLowerCase());
}

export function getTokenByAddress(address: string): Token | undefined {
  return tokenList.tokens.filter((token) => token.address === address)[0];
}

export function getTokenBySymbolAndChainId(chainId: number, symbol: string): Token | undefined {
  return getTokenList(chainId).find((token) => token.symbol.toLowerCase() === symbol.toLowerCase());
}

export function isNativeToken(address: string, chainId: number): boolean {
  const token = getTokenByAddressAndChainId(chainId, address);

  if (!token) {
    return false;
  }

  switch (chainId) {
    case 1:
    case 10:
    case 42161:
      return token.symbol === 'ETH';
    case 137:
      return token.symbol === 'MATIC';
  }

  return false;
}
