import { useState } from 'react';
import Badge from '../elements/buttons/Badge';
import EarnedFeesChart from './EarnedFeesChart';
import LiquidityProfileChart from './LiquidityProfileChart';
import PerformanceChart from './PerformanceChart';
import TvlChart from './TvlChart';
import VolumeChart from './VolumeChart';

enum VaultChart {
  EARNED_FEES = 'EARNED_FEES',
  VOLUME = 'VOLUME',
  LIQUIDITY_PROFILE = 'LIQUIDITY_PROFILE',
  PERFORMANCE = 'PERFORMANCE',
  TVL = 'TVL',
}

interface ChartsWrapperProps {
  vaultId: string;
}

function ChartsWrapper({ vaultId }: ChartsWrapperProps) {
  const CHARTS = [
    {
      title: 'TVL',
      id: VaultChart.TVL,
      component: <TvlChart key="chart-performance" vaultId={vaultId} vaultType="palm" />,
    },
    {
      title: 'Fees Earned by the Vault',
      id: VaultChart.EARNED_FEES,
      component: <EarnedFeesChart key="chart-earned-fees" vaultId={vaultId} />,
    },
    {
      title: 'Volumes',
      id: VaultChart.VOLUME,
      component: <VolumeChart key="chart-volumes" vaultId={vaultId} />,
    },
    {
      title: 'Performance',
      id: VaultChart.PERFORMANCE,
      component: <PerformanceChart key="chart-performance" vaultId={vaultId} />,
    },
    {
      title: 'Liquidity Profile',
      id: VaultChart.LIQUIDITY_PROFILE,
      component: <LiquidityProfileChart key="chart-liquidity-profile" vaultId={vaultId} />,
    },
  ];

  const [activeChart, setActiveChart] = useState<VaultChart>(VaultChart.EARNED_FEES);

  return (
    <div>
      <div className="flex items-center gap-2 mb-8">
        {CHARTS.map((chart) => (
          <Badge key={chart.id} isActive={activeChart === chart.id} onClick={() => setActiveChart(chart.id)}>
            {chart.title}
          </Badge>
        ))}
      </div>
      {CHARTS.map((chart) => activeChart === chart.id && chart.component)}
    </div>
  );
}

export default ChartsWrapper;
