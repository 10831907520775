import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';



export default function VaultListSkeletonItem()         {
  return (
    <div
      className="flex flex-col justify-center w-full bg-vault-black bg-opacity-100 p-6 rounded-[24px] h-[130px]"
    >
      <div className="flex flex-row gap-3 justify-between">
        <div className="flex flex-row items-center">
          <Skeleton circle height="40px" width="40px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
          <Skeleton circle height="40px" width="40px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />

          <div className="flex flex-col mx-2">
            <Skeleton height="20px" width="200px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
            <Skeleton height="20px" width="200px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
          </div>
        </div>
        {/* TVL */}
        <div>
          <Skeleton height="20px" width="200px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
          <Skeleton height="20px" width="200px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
        </div>
        <div>
          <Skeleton height="20px" width="200px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
          <Skeleton height="20px" width="200px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
        </div>
        <div>
        </div>
      </div>
    </div>
  );
}
