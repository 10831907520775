import { Explanation } from '../../../../types';
import { SetFormValue } from '../../../../types/create-vault';
import { getBaseTokensByChainId } from '../../../../utils/vault-creation';
import Badge from '../../../elements/buttons/Badge';
import QuestionButton from '../../../layout/sidebar/QuestionButton';

interface BaseTokenSelectionProps {
  baseTokenAddress: string;
  setValue: SetFormValue;
  chainId: number;
}

function BaseTokenSelection({ baseTokenAddress, setValue, chainId }: BaseTokenSelectionProps) {
  const baseTokens = getBaseTokensByChainId(chainId);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <h2 className="text-[16px] leading-[20px] trailing-[0.16px]">Base Token</h2>

        <QuestionButton explanation={Explanation.BASE_TOKEN} title="Base Token explained" />
      </div>

      <div className="flex items-center gap-2 flex-wrap min-w-[250px]">
        {baseTokens.map((token) => (
          <Badge
            key={token.address}
            isActive={baseTokenAddress === token.address}
            onClick={() => setValue('baseTokenAddress', token.address, { shouldValidate: true })}
          >
            <img
              src={token.logoURI}
              alt="weth"
              className="rounded-full max-w-[20px] max-h-[20px] align-middle mx-0.5 opacity-90 hover:opacity-100 duration-300"
            />
            <div>{token.symbol}</div>
          </Badge>
        ))}
      </div>
    </div>
  );
}

export default BaseTokenSelection;
