import React from 'react';
import { useSettingsContext } from '../../context/SettingsContext';
import { getExplanationComponent } from '../../utils/explanations';
import Sidebar from './sidebar/Sidebar';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const { showSidebar, setShowSidebar, explanation, title } = useSettingsContext();

  return (
    <div className="min-h-[calc(100vh-80px)] w-full relative">
      <Sidebar
        open={showSidebar}
        setOpen={setShowSidebar}
        explanation={getExplanationComponent(explanation)}
        title={title}
      />
      {children}
    </div>
  );
}

export default Layout;
