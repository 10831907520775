import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  // eslint-disable-next-line import/named
  UsePrepareContractWriteConfig,
  erc20ABI,
  useAccount,
  useBalance,
  useContractRead,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { ArrakisV1StakingRouterAbi } from '../../../abis/ArrakisV1StakingRouter';
import { tokenProxyAbi } from '../../../abis/token-proxy';
import { DepositFormWrapperState, IVault } from '../../../types';
import { getAddLiquidityParams, hasVaultNativeToken } from '../../../utils/blockchain-communication';
import { formatBigNumber, parseTokenInputAmountToBigInt } from '../../../utils/format-big-number';
import './deposit.css';

import { Tooltip } from 'flowbite-react';
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/no-unresolved
import { track } from '@vercel/analytics/react';
import { ARRAKIS_V1_STAKING_ROUTER_ADDRESSES } from '../../../constants';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { numberWithCommas } from '../../../utils/n-formatter';
import { alertUser } from '../../elements/notify';
import { Spinner } from '../../elements/spinner';
import Toggle from '../../elements/toggle/Toggle';
import DepositFormWrapper from '../../hoc/deposit-form-wrapper';
import InputField from './components/InputField';
// eslint-disable-next-line import/no-unresolved
import CheckIcon from '/assets/images/icon-check-2.svg';

enum Status {
  APPROVE_0 = 'Approve_0',
  APPROVE_1 = 'Approve_1',
  DEPOSIT = 'Deposit',
}

interface DepositFormProps {
  vault: IVault;
  currentAmountUsd: string;
}

export interface IDepositFormInput {
  token0Input: number;
  token1Input: number;
}

function DepositForm({ vault, currentAmountUsd }: DepositFormProps) {
  const { address, connector } = useAccount();
  const [status, setStatus] = useState<Status>(Status.APPROVE_0);
  const [useNativeToken, setUseNativeToken] = useState(false);
  const [token0InputUsd, setToken0InputUsd] = useState(0);
  const [token1InputUsd, setToken1InputUsd] = useState(0);
  const [addLiquidityArgs, setAddLiquidityArgs] = useState<any[]>([]);
  const [depositFormWrapperState, setDepositFormWrapperState] = useState(DepositFormWrapperState.DEFAULT);
  const [isLoadingSafeWallet, setLoadingSafeWallet] = useState(false);

  const { data: nativeBalance } = useBalance({
    address: address as '0x${string}',
    chainId: vault.chainId,
    cacheTime: 10000,
  });

  const { data: balance0, isLoading: balance0Loading } = useContractRead({
    address: vault.token0 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [address],
    chainId: vault.chainId,
    select: (data) => {
      if (typeof data !== 'bigint') {
        return undefined;
      }
      return useNativeToken && nativeSymbol0 ? nativeBalance?.value : data;
    },
    cacheTime: 10000,
  });

  const { data: balance1, isLoading: balance1Loading } = useContractRead({
    address: vault.token1 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [address],
    chainId: vault.chainId,
    select: (data) => {
      if (typeof data !== 'bigint') {
        return undefined;
      }
      return useNativeToken && nativeSymbol1 ? nativeBalance?.value : data;
    },
    cacheTime: 10000,
  });

  const { hasNativeToken, nativeSymbol0, nativeSymbol1 } = hasVaultNativeToken(vault);
  const isSafeWallet = connector?.id === 'safe';

  const symbol0 = useNativeToken
    ? nativeSymbol0?.toUpperCase() ?? vault.symbol0?.toUpperCase()
    : vault.symbol0?.toUpperCase();

  const symbol1 = useNativeToken
    ? nativeSymbol1?.toUpperCase() ?? vault.symbol1?.toUpperCase()
    : vault.symbol1?.toUpperCase();

  const balance0Value = balance0 ? formatBigNumber(balance0, vault.token0Decimals, 8) : '0';
  const balance1Value = balance1 ? formatBigNumber(balance1, vault.token1Decimals, 8) : '0';

  // allowances: check if user has already approved tokens

  const token0Contract = {
    address: vault.token0 as '0x${string}',
    abi: erc20ABI,
  };

  const token1Contract = {
    address: vault.token1 as '0x${string}',
    abi: erc20ABI,
  };

  const routerAddress = ARRAKIS_V1_STAKING_ROUTER_ADDRESSES(vault.chainId);

  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IDepositFormInput>({
    defaultValues: {
      token0Input: 0,
      token1Input: 0,
    },
    mode: 'onChange',
  });

  const token0Input = watch('token0Input');
  const token1Input = watch('token1Input');

  const parsedInp0 = useMemo(
    () => parseTokenInputAmountToBigInt(token0Input, vault.token0Decimals),

    [vault.token0Decimals, token0Input],
  );

  const parsedInp1 = useMemo(
    () => parseTokenInputAmountToBigInt(token1Input, vault.token1Decimals),

    [vault.token1Decimals, token1Input],
  );

  // update token0Input and token1Input when one of them changes
  function onValueChanged(type: 'token0' | 'token1', eventKey: string) {
    if (isFinite(Number(eventKey)) || eventKey === 'Backspace') {
      if (type === 'token0') {
        if (isFinite(token0Input))
          setValue(
            'token1Input',
            (token0Input || 0) * (Number(vault.formatted?.amount1 || 0) / Number(vault.formatted?.amount0 || 1)),
          );
      } else {
        if (isFinite(token1Input))
          setValue(
            'token0Input',
            (token1Input || 0) * (Number(vault.formatted?.amount0 || 0) / Number(vault.formatted?.amount1 || 1)),
          );
      }
      trigger(['token0Input', 'token1Input']);
    }
  }

  function setMaxToken0() {
    setValue('token0Input', Number(balance0Value), { shouldDirty: true, shouldValidate: true });
    setValue(
      'token1Input',
      (Number(balance0Value) || 0) * (Number(vault?.formatted?.amount1 || 0) / Number(vault?.formatted?.amount0 || 0)),
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );
  }

  function setMaxToken1() {
    setValue('token1Input', Number(balance1Value), { shouldDirty: true, shouldValidate: true });
    setValue(
      'token0Input',
      (Number(balance1Value) || 0) * (Number(vault?.formatted?.amount0 || 0) / Number(vault?.formatted?.amount1 || 0)),
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );
  }

  // effects

  // trigger input form errors when user toggles between native and non-native token
  useEffect(() => {
    trigger(['token0Input', 'token1Input']);
  }, [useNativeToken, trigger]);

  // update usd value when user changes input
  useEffect(() => {
    setToken0InputUsd((vault.price0 ?? vault.cmcPrice0) * (token0Input || 0));
    setToken1InputUsd((vault.price1 ?? vault.cmcPrice1) * (token1Input || 0));
  }, [token0Input, token1Input]);

  const { data: allowances, isLoading: isLoadingApprovals } = useContractReads({
    contracts: [
      {
        ...token0Contract,
        functionName: 'allowance',
        args: [(address as any) ?? BigInt(0), routerAddress as '0x${string}'],
      },
      {
        ...token1Contract,
        functionName: 'allowance',
        args: [(address as any) ?? BigInt(0), routerAddress as '0x${string}'],
      },
    ],
    watch: true,
    cacheTime: 2000,
  });

  const token0Allowance = allowances?.[0].result;
  const token1Allowance = allowances?.[1].result;

  // approving tokens

  const addRecentTransaction = useAddRecentTransaction();

  const { config: approveToken0Config } = usePrepareContractWrite({
    ...token0Contract,
    functionName: 'approve',
    args: [routerAddress, parsedInp0],
    chainId: vault.chainId,
    onError(error) {
      console.log('error', error);
    },
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
    cacheTime: 10000,
  });

  const { config: approveToken1Config } = usePrepareContractWrite({
    ...token1Contract,
    functionName: 'approve',
    args: [routerAddress, parsedInp1],
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
    cacheTime: 10000,
  });

  const {
    data: token0ApproveData,
    write: token0Approve,
    isLoading: isLoadingToken0Approve,
  } = useContractWrite({
    ...approveToken0Config,
    onError(error) {
      console.log('error', error);
      alertUser('error', error?.message, '', '', isSafeWallet);
    },
  });

  const {
    data: token1ApproveData,
    write: token1Approve,
    isLoading: isLoadingToken1Approve,
  } = useContractWrite({
    ...approveToken1Config,
    onError(error) {
      console.log('error', error);

      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isLoadingToken0ApproveAfter } = useWaitForTransaction({
    hash: token0ApproveData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }

      alertUser(
        'success',
        'Transaction successful!',
        getExplorerLink(vault.chainId || 137, token0ApproveData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('approve token 0 for deposit v1', {
        type: 'click',
        feature: 'vault deposit form v1',
        wallet: address as string,
        vault: vault.id,
      });

      addRecentTransaction({
        hash: token0ApproveData?.hash ?? '',
        description: `Approve ${token0Input} ${vault.symbol0}`,
      });
    },
  });

  const { isLoading: isLoadingToken1ApproveAfter } = useWaitForTransaction({
    hash: token1ApproveData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }

      alertUser(
        'success',
        'Transaction successful!',
        getExplorerLink(vault.chainId || 137, token1ApproveData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );
      track('approve token 1 for deposit v1', {
        type: 'click',
        feature: 'vault deposit form v1',
        wallet: address as string,
        vault: vault.id,
      });

      addRecentTransaction({
        hash: token1ApproveData?.hash ?? '',
        description: `Approve ${token1Input} ${vault.symbol1}`,
      });
    },
  });

  function getDepositConfig() {
    const config: UsePrepareContractWriteConfig = {
      address: ARRAKIS_V1_STAKING_ROUTER_ADDRESSES(vault.chainId),
      chainId: vault.chainId,
      abi: ArrakisV1StakingRouterAbi,
      functionName: vault.gauge ? 'addLiquidityAndStake' : 'addLiquidity',
      // enabled: false,
      args: addLiquidityArgs,
      value: BigInt(0), // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
      // cacheTime: 10000,
    };

    if (useNativeToken) {
      config.functionName = vault.gauge ? 'addLiquidityETHAndStake' : 'addLiquidityETH';
      config.value = nativeSymbol0 ? parsedInp0 : parsedInp1;
    }

    if (isSafeWallet) {
      config.gas = BigInt(5000000);
    }

    return config;
  }

  // Increase Liquidity
  const { config: addLiquidityConfig } = usePrepareContractWrite(getDepositConfig());

  const {
    data: addLiquidityData,
    write: addLiquidity,
    isLoading: isAddLiquidityLoading,
  } = useContractWrite({
    ...addLiquidityConfig,
    onError(error) {
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isAddLiquidityLoadingAfter } = useWaitForTransaction({
    hash: addLiquidityData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }

      setDepositFormWrapperState(DepositFormWrapperState.DEPOSIT_SUCCESS);

      alertUser(
        'success',
        'Liquidity added successful!',
        getExplorerLink(vault.chainId || 137, addLiquidityData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('deposit v1', {
        type: 'click',
        feature: 'vault deposit form v1',
        wallet: address as string,
        vault: vault.id,
        value: token0InputUsd + token1InputUsd,
      });

      addRecentTransaction({
        hash: addLiquidityData?.hash ?? '',
        description: 'Increase Liquidity',
      });
    },
  });

  // determine current status of deposit
  useEffect(() => {
    const oldStatus = status;
    let newStatus = Status.APPROVE_0;

    if (useNativeToken && nativeSymbol0) {
      newStatus = Status.APPROVE_1;

      // @ts-ignore
      if ((token1Allowance || BigInt(0)) >= parsedInp1 && parsedInp1 > BigInt(0)) {
        newStatus = Status.DEPOSIT;
      }
    } else if (useNativeToken && nativeSymbol1) {
      if ((token0Allowance || BigInt(0) >= parsedInp0) && parsedInp0 > BigInt(0)) {
        newStatus = Status.DEPOSIT;
      }
      // @ts-ignore
    } else if (!useNativeToken) {
      // @ts-ignore
      if ((token0Allowance ?? BigInt(0)) >= parsedInp0 && parsedInp0 > BigInt(0)) {
        newStatus = Status.APPROVE_1;
      }

      if (
        // @ts-ignore
        (token1Allowance ?? BigInt(0)) >= parsedInp1 &&
        // @ts-ignore
        (token0Allowance ?? BigInt(0)) >= parsedInp0 &&
        parsedInp0 > BigInt(0) &&
        parsedInp1 > BigInt(0)
      ) {
        newStatus = Status.DEPOSIT;
      }

      if (oldStatus !== newStatus) {
        setLoadingSafeWallet(false);
      }
    }

    setStatus(newStatus);
  }, [
    token0Allowance,
    token0InputUsd,
    token1InputUsd,
    token1Allowance,
    useNativeToken,
    nativeSymbol0,
    nativeSymbol1,
    parsedInp0,
    parsedInp1,
  ]);

  // update addLiquidity params whenever needed
  useEffect(() => {
    const updateAddLiquidityParams = async () => {
      const vaultId = vault.gauge?.address ? vault.gauge.address : vault.id;

      if (status === Status.DEPOSIT) {
        const addLiquidityArgs = await getAddLiquidityParams(
          vault.id,
          parsedInp0,
          parsedInp1,
          address as string,
          vault.gauge?.address,
        );

        setAddLiquidityArgs(addLiquidityArgs);
      } else {
        setAddLiquidityArgs([vaultId, BigInt(0), BigInt(0), BigInt(0), BigInt(0), BigInt(0), address]);
      }
    };
    updateAddLiquidityParams();
  }, [token0Allowance, token1Allowance, vault, address, parsedInp0, parsedInp1, useNativeToken, status]);

  function onAddLiquidity() {
    if (isSafeWallet && addLiquidity) {
      addLiquidity();
      setDepositFormWrapperState(DepositFormWrapperState.SAFE_APP_DEPOSIT);
    } else {
      addLiquidity?.();
    }
  }

  function onApproveToken0() {
    if (isSafeWallet) {
      setLoadingSafeWallet(true);
    }
    token0Approve?.();
  }

  function onApproveToken1() {
    if (isSafeWallet) {
      setLoadingSafeWallet(true);
    }
    token1Approve?.();
  }

  return (
    <DepositFormWrapper
      isLoading={balance0Loading || balance1Loading}
      state={depositFormWrapperState}
      usdValue={token0InputUsd + token1InputUsd}
    >
      <form>
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row justify-between gap-2 relative">
            <div className="grow w-1/2">
              <InputField
                vault={vault}
                balanceValue={balance0Value}
                tokenInputUsd={token0InputUsd}
                symbol={symbol0}
                showError={!!errors.token0Input && isDirty}
                errorMessage={errors.token0Input?.message}
                onValueChanged={onValueChanged}
                tokenType="token0"
                register={register}
                setMaxToken={setMaxToken0}
                isZapin={false}
              />
            </div>
            <div className="grow w-1/2">
              <InputField
                vault={vault}
                balanceValue={balance1Value}
                tokenInputUsd={token1InputUsd}
                symbol={symbol1}
                showError={!!errors.token1Input && isDirty}
                errorMessage={errors.token1Input?.message}
                onValueChanged={onValueChanged}
                tokenType="token1"
                register={register}
                setMaxToken={setMaxToken1}
                isZapin={false}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between">
            <div>
              <div className="text-xl font-semibold mt-5 mb-2 text-vault-light text-[24px]">
                Deposit amount: ${numberWithCommas((token0InputUsd + token1InputUsd).toFixed(2))}{' '}
              </div>
              <div className="text-xl font-semibold mb-3 text-vault-gray-hover text-[20px]">
                New position will be: $
                {numberWithCommas((token0InputUsd + token1InputUsd + Number(currentAmountUsd)).toFixed(2))}{' '}
              </div>
            </div>
            <div>
              {hasNativeToken && (
                <div className="flex items-center mt-5 gap-2">
                  <div>Use {(nativeSymbol0 || nativeSymbol1)?.toUpperCase()}</div>
                  <Toggle
                    id="native-token-toggle"
                    checked={useNativeToken}
                    onChange={() => setUseNativeToken((val) => !val)}
                  />
                </div>
              )}
            </div>
          </div>

          <div>
            {status === Status.APPROVE_0 && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  type="button"
                  onClick={onApproveToken0}
                  className={`flex flex-row justify-center items-center rounded-lg h-[64px] p-2 grow ${
                    !errors.token0Input && isDirty
                      ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
                      : 'bg-vault-gray-2 text-vault-gray'
                  }`}
                  disabled={!!errors.token0Input || !isDirty}
                >
                  <div className="flex items-center gap-3">
                    Approve {vault.symbol0?.toUpperCase()}{' '}
                    {token0Allowance !== undefined && (
                      <Tooltip
                        // @ts-ignore
                        content={`already approved ${formatBigNumber(token0Allowance, vault.token0Decimals, 10)}`}
                        placement="right"
                        animation="duration-1000"
                        className="bg-[#181818] text-vault-white text-center"
                      ></Tooltip>
                    )}
                  </div>

                  {(isLoadingApprovals ||
                    isLoadingToken0Approve ||
                    isLoadingToken0ApproveAfter ||
                    isLoadingSafeWallet) && (
                    <span className="mx-2">
                      <Spinner color="vault-white" />
                    </span>
                  )}
                </button>
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  2
                </button>
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  3
                </button>
              </div>
            )}
            {status === Status.APPROVE_1 && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex justify-center items-center"
                >
                  <img src={CheckIcon} alt="check icon" />
                </button>
                <button
                  type="button"
                  onClick={onApproveToken1}
                  className={`flex flex-row justify-center items-center rounded-lg h-[64px] p-2 grow ${
                    !errors.token1Input && isDirty
                      ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
                      : 'bg-vault-gray-2 text-vault-gray'
                  }`}
                  disabled={!!errors.token1Input || !isDirty}
                >
                  <div className="flex items-center gap-3">
                    Approve {vault.symbol1?.toUpperCase()}{' '}
                    {token1Allowance !== undefined && (
                      <Tooltip
                        // @ts-ignore
                        content={`already approved ${formatBigNumber(token1Allowance, vault.token1Decimals, 10)}`}
                        placement="right"
                        animation="duration-1000"
                        className="bg-[#181818] text-vault-white text-center"
                      ></Tooltip>
                    )}
                  </div>

                  {(isLoadingApprovals ||
                    isLoadingToken1Approve ||
                    isLoadingToken1ApproveAfter ||
                    isLoadingSafeWallet) && (
                    <span className="mx-2">
                      <Spinner color="vault-white" />
                    </span>
                  )}
                </button>

                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  3
                </button>
              </div>
            )}
            {status === Status.DEPOSIT && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center"
                >
                  <img src={CheckIcon} alt="check icon" />
                </button>

                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center"
                >
                  <img src={CheckIcon} alt="check icon" />
                </button>
                {vault.gauge ? (
                  <button
                    type="button"
                    disabled={!addLiquidity || isAddLiquidityLoading || isAddLiquidityLoadingAfter}
                    onClick={onAddLiquidity}
                    className="flex flex-row justify-center items-center bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary h-12 rounded-[12px] h-[64px] p-2 grow"
                  >
                    <span> Add Liquidity & Stake </span>
                    {(isAddLiquidityLoading || isAddLiquidityLoadingAfter) && (
                      <span className="mx-2">
                        <Spinner color="vault-white" />
                      </span>
                    )}
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled={!addLiquidity || isAddLiquidityLoading || isAddLiquidityLoadingAfter}
                    onClick={onAddLiquidity}
                    className="flex flex-row justify-center items-center bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary h-12 rounded-[12px] h-[64px] p-2 grow"
                  >
                    <span> Add Liquidity </span>
                    {(isAddLiquidityLoading || isAddLiquidityLoadingAfter) && (
                      <span className="mx-2">
                        <Spinner color="vault-white" />
                      </span>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </DepositFormWrapper>
  );
}

export default memo(DepositForm);
