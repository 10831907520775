import { CustomConnectButton } from '../../elements/buttons/ConnectButton';
import LockedImage from '/assets/images/locked.svg';

function ConnectWallet() {
  return (
    <div className="flex flex-col items-center sm:h-[400px]">
      <img className="hidden sm:block" src={LockedImage} alt="lock" />

      <div className="mt-4 sm:-mt-28 mb-5">
        <h2 className="text-center text-vault-light-gray text-[24px]"> Arrakis is not connected to your wallet</h2>
        <div className="text-center text-vault-gray-hover text-[16px] max-w-[380px] mt-5">
          To see more information about this vault and add liquidity you need to connect your wallet
        </div>
      </div>
      <CustomConnectButton />
    </div>
  );
}

export default ConnectWallet;
