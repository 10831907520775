import { ethers } from 'ethers';
import { zeroAddress } from 'viem';
import { Strategy, StrategySchema } from '../types/create-vault';

export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'm' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : num.toFixed(digits);
}

export function abbreviateNumber(num: number, toFixed = 1): string {
  const res = num.toFixed(toFixed).replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return res;
  }
  const si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
}

export function capitalizeFirstLetter(str: string | undefined): string {
  if (!str) return '';

  return str.charAt(0).toUpperCase() + str.slice(1);
}

// truncate address
const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
export const truncateEthAddress = (address: string) => {
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export function numberWithCommas(x: number | string) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const formatDate = (input: string) => {
  const date = new Date(input);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  return `${day}. ${month}`;
};

export function formatRelativeTime(input: string): string {
  console.log('input', input);

  const date = new Date(input);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();
  const minutesAgo = Math.floor(timeDifference / (1000 * 60));

  if (minutesAgo < 1) {
    return 'just now';
  } else if (minutesAgo === 1) {
    return '1 minute ago';
  } else {
    return `${minutesAgo} minutes ago`;
  }
}

export const isOlderThan = (input: string, hours: number) => {
  const date = new Date(input);
  const now = new Date();
  return now.getTime() - date.getTime() > hours * 60 * 60 * 1000;
};

export function shortenNumber(input: number) {
  if (input === 0) return 0;

  if (Math.abs(input) > 1) {
    return Number(input.toFixed(2));
  }

  const numberOfZeroDecimals = -Math.floor(Math.log10(input) + 1);

  return Number(input.toFixed(numberOfZeroDecimals + 3));
}

export function getNetworkNameFromChainId(chainId: number) {
  switch (chainId) {
    case 1:
      return 'ethereum';
    case 10:
      return 'optimism';
    case 100:
      return 'gnosis';
    case 137:
      return 'polygon';
    case 8453:
      return 'base';
    case 42161:
      return 'arbitrum';
    case 11155111:
      return 'sepolia';
    default:
      return 'Unknown';
  }
}

export function getChainIdFromNetworkName(network: string) {
  switch (network) {
    case 'ethereum':
      return 1;
    case 'goerli':
      return 5;
    case 'optimism':
    case 'optimism_shadow':
      return 10;
    case 'gnosis':
      return 100;
    case 'polygon':
    case 'polygon_shadow':
      return 137;
    case 'base':
      return 8453;
    case 'arbitrum':
    case 'arbitrum_shadow':
      return 42161;
    case 'sepolia':
      return 11155111;
    default:
      return 0;
  }
}

export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function hexToJSONObject(hexString: string): any {
  const strippedHexString = hexString.startsWith('0x') ? hexString.slice(2) : hexString;

  const buffer = hex2buf(strippedHexString);

  const utf8String = ethers.utils.toUtf8String(buffer);

  const jsonData = JSON.parse(utf8String);

  return jsonData;
}

export function getStrategyFromHex(hexString: string): Strategy | undefined {
  if (hexString == '0x') {
    return undefined;
  }

  const strategy = hexToJSONObject(hexString);

  const parsedStrategy = StrategySchema.safeParse(strategy);

  if (!parsedStrategy.success) {
    console.log('strategy parse error', parsedStrategy);
    return undefined;
  }

  return parsedStrategy.data;
}
export function buf2hex(buffer: any) {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)].map((x) => x.toString(16).padStart(2, '0')).join('');
}

export function hex2buf(hexString: string) {
  return Buffer.from(hexString, 'hex');
}

export function strategyJsonStringToHex(strategy: string) {
  try {
    const stratData = JSON.parse(strategy);

    const dataFormatted = ethers.utils.toUtf8Bytes(JSON.stringify(stratData));

    const hexData = '0x' + buf2hex(dataFormatted.buffer);

    return hexData;
  } catch (error) {
    return zeroAddress;
  }
}

export function formatStrategyJson(strategy: Strategy) {
  /* 
   apiData: z.nullable(z.unknown()),
  assetIsTokenZero: z.boolean(),
  feeTier: z.number(),
  limitOrderMaxDistanceTicks: z.number(),
  limitOrderToBPS: z.number(),
  maxGasPrice: z.number(),
  maxLimitOrderBPS: z.number(),
  maxTwapDeviation: z.number(),
  minDeviationBPS: z.number(),
  skewLimitBPS: z.number(),
  strategy: z.string(),
  twapDuration: z.number(),
  version: z.number(),
  wideRangeActiveAmountInToken1BPS: z.number(),
  wideRangeAmount0Bps: z.number(),
  wideRangeAmount1Bps: z.number(),
   */
  const {
    apiData,
    assetIsTokenZero,
    feeTier,
    limitOrderMaxDistanceTicks,
    limitOrderToBPS,
    maxGasPrice,
    maxLimitOrderBPS,
    maxTwapDeviation,
    minDeviationBPS,
    skewLimitBPS,
    twapDuration,
    version,
    wideRangeActiveAmountInToken1BPS,
    wideRangeAmount0Bps,
    wideRangeAmount1Bps,
  } = strategy;

  return `
  {
   "apiData": ${apiData}
   "assetIsTokenZero": ${assetIsTokenZero},
   "feeTier": ${feeTier},
   "strategy": "${strategy.strategy}",
   "limitOrderMaxDistanceTicks": ${limitOrderMaxDistanceTicks},
   "limitOrderToBPS": ${limitOrderToBPS},
   "maxLimitOrderBPS": ${maxLimitOrderBPS},
   "minDeviationBPS": ${minDeviationBPS},
   "skewLimitBPS": ${skewLimitBPS},
   "wideRangeActiveAmountInToken1BPS": ${wideRangeActiveAmountInToken1BPS},
   "wideRangeAmount0Bps": ${wideRangeAmount0Bps},
   "wideRangeAmount1Bps": ${wideRangeAmount1Bps},
   "version": ${version},
   "twapDuration": ${twapDuration},
   "maxTwapDeviation": ${maxTwapDeviation},
   "maxGasPrice": ${maxGasPrice},
  }
  `;
}
