function LiquidityConcentrationExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/distribution-abstract-2.png"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />
      <h2 className="text-lg font-bold mb-1">What does Liquidity Concentration mean?</h2>
      <p className="mb-3">
        Liquidity Concentration refers to the idea that you can choose specific price ranges where you want to provide
        liquidity for a particular token pair.
      </p>
      <p className="mb-3">
        For example, if you want to provide liquidity for the ETH/USDC pair, you can choose to provide liquidity for the
        price range between $2000 and $3000. This means that you will only provide liquidity for this price range. If
        the price of ETH goes above $3000, you will not facilitate trades or earn fees. If the price of ETH goes below
        $2000, you will similarly not facilitate any trades or earn any fees on swaps.
      </p>
      <p className="mb-3">
        If your is very liquidity concentration is set to risky, it means that you will provide liquidity for a very
        small price range (e.g. $2000 to $2100). If your liquidity concentration is set to risk averse, it means that
        you will provide liquidity for a very large price range (e.g. $200 to $20,000).
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Why does Liquidity Concentration matter?</h2>
      <p className="mb-3">
        Liquidity Concentration matters because it determines how efficiently you use your capital for market making,
        but also how much risk you are taking on as a market maker.
      </p>
      <p>
        More concentration means a deeper and more liquid market with less capital, provided that the price stays within
        your range(s). However, more concentration also means more risk -- both that the market will escape your range
        and you stop market making for a time, but also more risk in terms of return on investment (your inventory will
        turn over much quicker as prices move meaning you have more sensitivity to large price moves)
      </p>
    </div>
  );
}

export default LiquidityConcentrationExplanation;
