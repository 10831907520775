import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { track } from '@vercel/analytics/react';
import Skeleton from 'react-loading-skeleton';
import { useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ERROR_MESSAGE } from '../../../constants';
import { IVault } from '../../../types';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { numberWithCommas } from '../../../utils/n-formatter';
import { alertUser } from '../../elements/notify';
import { Spinner } from '../../elements/spinner';
import CustomOpClaimRewards from '../custom-claim-rewards';

interface ClaimRewardsProps {
  vault: IVault;
  abi: any;
  isLoadingClaimableRewards: boolean;
  claimableRewards: number | undefined;
  ownAddress: string;
}

function ClaimRewards({ vault, abi, claimableRewards, isLoadingClaimableRewards, ownAddress }: ClaimRewardsProps) {
  const { chain: currentChain } = useNetwork();

  const addRecentTransaction = useAddRecentTransaction();

  const { config: claimRewardsConfig } = usePrepareContractWrite({
    address: (vault.gauge ? vault.gauge.address : BigInt(0)?.toString()) as '0x${string}',
    abi: abi,
    functionName: 'claim_rewards',
    chainId: vault.chainId,
    enabled: !!vault.gauge,
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
  });

  const {
    write: claimRewards,
    data: claimRewardsData,
    isLoading: isLoadingClaimRewards,
  } = useContractWrite({
    ...(claimRewardsConfig as any),
  });

  const { isLoading: isLoadingClaimRewardsAfter } = useWaitForTransaction({
    hash: claimRewardsData?.hash,
    onSettled(data, error) {
      if (error) {
        console.warn('error', error);

        alertUser('error', ERROR_MESSAGE);
        return;
      }
      alertUser(
        'success',
        'Deposit successful',
        getExplorerLink(vault.chainId || 137, claimRewardsData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
      );

      track('claim reward', {
        type: 'click',
        feature: 'vault claim reward',
        wallet: ownAddress as string,
        vault: vault.id,
        value: claimableRewardsUsd,
      });

      addRecentTransaction({
        hash: claimRewardsData?.hash ?? '',
        description: 'Claim rewards',
      });
    },
  });

  const isClaimButtonDisabled =
    (claimableRewards || BigInt(0)) <= BigInt(0) || isLoadingClaimRewards || currentChain?.id !== vault.chainId;

  const claimableRewardsUsd = (claimableRewards ?? 0) * (vault.gauge?.price ?? vault.gauge?.cmcPrice ?? 0);

  return (
    <div>
      <div className="flex items-center justify-between text-[20px] gap-3">
        <div className="text-vault-light">{vault.gauge?.symbol.toUpperCase()}</div>

        {isLoadingClaimableRewards ? (
          <Skeleton height="20px" width="30px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
        ) : (
          <div className="flex items-center gap-4">
            <div className="text-bg-gradient-violet">
              <span> ${claimableRewardsUsd.toFixed(2)}</span>
            </div>
            <div className="flex flex-row items-center gap-2">
              <img
                src={vault.gauge?.logo}
                alt={vault.gauge?.symbol}
                className="rounded-full max-w-[20px] max-h-[20px] align-middle"
              />
              <div className="text-[#D4D3D3] text-[14px] min-w-[30px] text-right">
                {numberWithCommas((claimableRewards || 0).toFixed(2))}
              </div>
            </div>
          </div>
        )}
      </div>
      {vault.id === '0xe10546bee6424213dd9c80edd55e90fea96e6e11' && (
        <CustomOpClaimRewards vault={vault} ownAddress={ownAddress as string} />
      )}
      <button
        disabled={isClaimButtonDisabled}
        className={`${
          !isClaimButtonDisabled
            ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
            : 'bg-vault-gray-2 text-vault-gray-hover'
        }  rounded-[12px] px-[16px] py-[12px] w-full mt-4 flex items-center justify-center`}
        onClick={() => claimRewards?.()}
      >
        Claim
        {(isLoadingClaimRewards || isLoadingClaimRewardsAfter) && (
          <span className="mx-2">
            <Spinner color="vault-black" />{' '}
          </span>
        )}
      </button>
    </div>
  );
}

export default ClaimRewards;
