import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useEffect } from 'react';
import { FieldError } from 'react-hook-form';
import { isAddress, zeroAddress } from 'viem';
import { useAccount } from 'wagmi';
import { Explanation } from '../../../../types';
import { RegisterForm, SetFormValue } from '../../../../types/create-vault';
import PrimaryButton from '../../../elements/buttons/PrimaryButton';
import QuestionButton from '../../../layout/sidebar/QuestionButton';
import TextInput from '../../TextInput';
// eslint-disable-next-line import/no-unresolved
import ArrowDownIcon from '/assets/images/icon-arrow-down.svg';

interface VaultOwnerInputProps {
  register: RegisterForm;
  errors: FieldError | undefined;
  setValue: SetFormValue;
  ownerAddress: string;
}

function VaultOwnerInput({ register, errors, setValue, ownerAddress }: VaultOwnerInputProps) {
  const { address } = useAccount();

  const { openConnectModal } = useConnectModal();

  useEffect(() => {
    if (ownerAddress === zeroAddress && isAddress(address || '') && address !== zeroAddress) {
      setValue('ownerAddress', address as string, { shouldValidate: true });
    } else if (!address) {
      setValue('ownerAddress', zeroAddress, { shouldValidate: true });
    }
  }, [address, ownerAddress, setValue]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <h2 className="text-[16px] leading-[20px] trailing-[0.16px]">Vault owner</h2>

        <QuestionButton explanation={Explanation.VAULT_OWNER} title="Vault Owner explained" />
      </div>
      {ownerAddress !== undefined && ownerAddress !== null && ownerAddress !== zeroAddress && (
        <TextInput
          formPath="ownerAddress"
          register={register}
          options={{
            required: 'This field is required',
            validate: (value) => {
              return isAddress(value) || "This doesn't look like a valid address";
            },
          }}
          onChange={(e) => setValue('ownerAddress', e.target.value, { shouldValidate: true })}
          error={errors}
        />
      )}
      {ownerAddress !== address && ownerAddress !== zeroAddress && !errors && (
        <p className="text-[14px] leading-5 tracking-[-0.14px] flex items-center gap-3 text-vault-orange-2">
          Vault owner is not your connected wallet
        </p>
      )}
      {(address === zeroAddress || address === undefined || address === null) && (
        <PrimaryButton onClick={openConnectModal}>
          <div className="flex items-center justify-center gap-2">
            <img src={ArrowDownIcon} alt="icon down" />
            Connect Wallet
          </div>
        </PrimaryButton>
      )}
    </div>
  );
}

export default VaultOwnerInput;
