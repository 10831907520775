import { createPublicClient, http, webSocket } from 'viem';
import { ArrakisV2TermsRouterAbi } from '../abis/ArrakisV2TermsRouter';
import { ARRAKIS_V2_TERMS_ROUTER_ADDRESS } from '../constants';
import { getViemChain } from '../utils/chains';

interface WatchVaultCreationEventProps {
  onVaultCreated: (event: unknown) => void;
  chainId?: number;
}

let unwatchFunction: (() => void) | undefined;

export function watchVaultCreationEvent({ onVaultCreated, chainId }: WatchVaultCreationEventProps) {
  if (unwatchFunction) {
    unwatchFunction();
  }

  if (chainId) {
    const viemChain = getViemChain(chainId);

    const publicClient = createPublicClient({
      chain: viemChain,
      transport:
        viemChain.id === 8453
          ? webSocket('wss://base-mainnet.g.alchemy.com/v2/X8oUUS5n8e360eBwD-U62ukTod3kz0c0')
          : http(), // bugfix: on base chain events are not triggering with http
    });

    unwatchFunction = publicClient.watchContractEvent({
      address: ARRAKIS_V2_TERMS_ROUTER_ADDRESS,
      abi: ArrakisV2TermsRouterAbi,
      eventName: 'SetupVault',
      onLogs: (event) => {
        onVaultCreated(event);
      },
    });
  }
}
