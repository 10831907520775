import { Link, useRouteError } from 'react-router-dom';
import { Header } from '../components/layout/header';

function NotFound() {
  const error = useRouteError();
  console.log('error', error);

  return (
    <div className="h-screen w-screen">
      <div className="fixed w-[40%] h-[35%] top-0 orange__gradient right-0 z-0"></div>
      <Header />
      <div className="text-vault-white flex items-center justify-center mt-20">
        <div className="container max-w-screen-xl flex flex-col md:flex-row items-center justify-center px-5">
          <div className="max-w-md">
            <div className="text-5xl font-dark font-bold">404</div>
            <p className="text-2xl md:text-3xl font-light leading-normal">Sorry we could not find this page. </p>
            <p className="mb-8">But dont worry, you can find plenty of other things on our homepage.</p>
            <Link to="/">
              <button className="bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary w-full h-12 rounded-lg">
                Back to homepage
              </button>
            </Link>
          </div>
          <div className="max-w-lg"></div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
