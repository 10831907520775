import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useSettingsContext } from '../../../context/SettingsContext';
import { Explanation } from '../../../types';

interface QuestionButtonProps {
  children?: React.ReactNode;
  explanation: Explanation;
  title: string;
}

function QuestionButton({ children, explanation, title }: QuestionButtonProps) {
  const { showSidebar, setShowSidebar, setExplanation, setTitle } = useSettingsContext();

  function onOpen() {
    setShowSidebar(!showSidebar);
    setTitle(title);
    setExplanation(explanation);
  }

  return (
    <button type="button" tabIndex={-1} onClick={onOpen}>
      {children ? children : <QuestionMarkCircleIcon className="h-5 w-5 text-vault-gray" />}
    </button>
  );
}

export default QuestionButton;
