import { useEffect } from 'react';
import { Control, Controller } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { NumericFormat } from 'react-number-format';
import { FormErrors, FormTrigger } from '../../../../types/create-vault';
import { numberWithCommas, shortenNumber } from '../../../../utils/n-formatter';
import TargetRatio from './token-amounts/TargetRatio';
import TokenRatioInput from './token-amounts/TokenRatioInput';
import TotalValueInput from './token-amounts/TotalValueInput';

interface TokenAmountsFormProps {
  control: Control<any>;
  baseTokenAmount: string;
  ownTokenAmount: string;
  errors: FormErrors;
  baseTokenLogoUrl: string;
  baseTokenSymbol: string;
  ownTokenLogoUrl: string;
  ownTokenSymbol: string;
  trigger: FormTrigger;
  ownAmountUsd: number | null;
  baseAmountUsd: number | null;
  totalValueUsd: string;
  setTotalValueUsd: (value: string) => void;
  tokenRatio: number;
  setTokenRatio: (ratio: number) => void;
  setNewOwnTokenAmount(newOwnTokenAmount: string): void;
  setNewBaseTokenAmount(newBaseTokenAmount: string): void;
  balanceBaseToken: string;
  balanceOwnToken: string;
  isLoadingBalanceOwnToken: boolean;
  isLoadingBalanceBaseToken: boolean;
}

function TokenAmountsForm({
  control,
  baseTokenAmount,
  ownTokenAmount,
  errors,
  baseTokenLogoUrl,
  baseTokenSymbol,
  ownTokenLogoUrl,
  ownTokenSymbol,
  trigger,
  ownAmountUsd,
  baseAmountUsd,
  totalValueUsd,
  setTotalValueUsd,
  tokenRatio,
  setTokenRatio,
  setNewOwnTokenAmount,
  setNewBaseTokenAmount,
  balanceBaseToken,
  balanceOwnToken,
  isLoadingBalanceOwnToken,
  isLoadingBalanceBaseToken,
}: TokenAmountsFormProps) {
  useEffect(() => {
    setInterval(() => {
      trigger(['baseTokenAmount', 'ownTokenAmount']);
    }, 1000);
  }, []);

  const ownAmountUsdFormatted = ownAmountUsd ? numberWithCommas(ownAmountUsd.toFixed(2)) : '0.00';
  const baseAmountUsdFormatted = baseAmountUsd ? numberWithCommas(baseAmountUsd.toFixed(2)) : '0.00';

  function setMaxBaseToken() {
    if (balanceBaseToken) {
      setNewBaseTokenAmount(balanceBaseToken);
    }
  }

  function setMaxOwnToken() {
    if (balanceOwnToken) {
      setNewOwnTokenAmount(balanceOwnToken);
    }
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2 flex-wrap">
        <div className="flex items-center flex-wrap gap-2 px-4 py-[10px] border border-accent rounded-[16px] divide-accent text-[14px] tracking-[-0.14px]">
          <TotalValueInput control={control} total={totalValueUsd} setTotal={setTotalValueUsd} />

          <TokenRatioInput tokenRatio={tokenRatio} setTokenRatio={setTokenRatio} />
        </div>
        <TargetRatio />
      </div>

      <div className="flex flex-col justify-between gap-2 relative mt-6 max-w-[500px]">
        <div className="grow">
          <div
            className={`relative rounded-[16px] p-4 bg-accent hover:bg-opacity-40 duration-300 border min-h-[150px] bg-opacity-60 flex flex-col justify-between ${
              errors.baseTokenAmount ? 'border-red-400' : 'border-none'
            }`}
          >
            <div>
              <div className="flex justify-between">
                <div className="flex flex-row items-center">
                  <img
                    alt="token logo"
                    src={baseTokenLogoUrl}
                    className="rounded-full max-w-[20px] max-h-[20px] align-middle border-green mx-0.5"
                  />
                  <span className="ml-2 min-w-[60px]">{baseTokenSymbol}</span>
                </div>

                <Controller
                  render={() => (
                    <NumericFormat
                      autoComplete="off"
                      allowNegative={false}
                      value={baseTokenAmount}
                      onChange={(event) => {
                        setNewBaseTokenAmount(event.target.value.replace(/,/g, ''));
                      }}
                      thousandSeparator
                      className="p-0 text-[26px] max-w-[200px] sm:max-w-none bg-transparent text-right text-2xl text-white border-transparent focus:border-transparent focus:ring-0"
                    />
                  )}
                  name="baseTokenAmount"
                  control={control}
                  rules={{
                    required: "Can't be empty",

                    max: {
                      value: Number(balanceBaseToken),
                      message: 'Not Enough Funds',
                    },
                  }}
                />
              </div>
              <div className="flex justify-end text-[12px]">
                {typeof baseAmountUsd === 'number' && (
                  <div className="text-vault-gray-hover text-sm">${baseAmountUsdFormatted}</div>
                )}
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="w-fit text-xs text-vault-tertiary bg-vault-tertiary/10 ring-1 ring-vault-tertiary/20 py-1 px-2 rounded-lg">
                Base Token
              </div>
              <div className="text-vault-gray-hover flex flex-row items-center gap-1">
                <div className="mr-1">Balance:</div>
                {isLoadingBalanceBaseToken && (
                  <Skeleton height="20px" width="100px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
                )}
                {balanceBaseToken && !isLoadingBalanceBaseToken && (
                  <div>{numberWithCommas(shortenNumber(Number(balanceBaseToken)))}</div>
                )}
                <span>{baseTokenSymbol}</span>
                <button
                  type="button"
                  onClick={setMaxBaseToken}
                  className="border-solid border-vault-lite text-vault-light border-2 rounded-lg px-1 sm:ml-2 w-12"
                >
                  Max
                </button>
              </div>
            </div>
          </div>
          {errors.baseTokenAmount && <div className="text-red-400 text-sm mt-2">{errors.baseTokenAmount.message}</div>}
        </div>
        <div className="grow">
          <div
            className={`relative rounded-[16px] p-4 bg-accent hover:bg-opacity-40 duration-300 border min-h-[150px] bg-opacity-60 flex flex-col justify-between ${
              errors.ownTokenAmount ? 'border-red-400' : 'border-none'
            }`}
          >
            <div>
              <div className="flex justify-between">
                <div className="flex flex-row items-center">
                  <img
                    alt="token logo"
                    src={ownTokenLogoUrl}
                    className="rounded-full max-w-[20px] max-h-[20px] align-middle border-green mx-0.5"
                  />
                  <span className="ml-2 min-w-[60px]">{ownTokenSymbol}</span>
                </div>

                <Controller
                  render={() => (
                    <NumericFormat
                      autoComplete="off"
                      allowNegative={false}
                      value={ownTokenAmount}
                      onChange={(event) => {
                        setNewOwnTokenAmount(event.target.value.replace(/,/g, ''));
                      }}
                      thousandSeparator
                      className="p-0 text-[26px] max-w-[200px] sm:max-w-none bg-transparent text-right text-2xl text-white border-transparent focus:border-transparent focus:ring-0"
                    />
                  )}
                  name="ownTokenAmount"
                  control={control}
                  rules={{
                    required: "Can't be empty",

                    max: {
                      value: Number(balanceOwnToken),
                      message: 'Not Enough Funds',
                    },
                  }}
                />
              </div>
              <div className="flex justify-end text-[12px]">
                {typeof ownAmountUsd === 'number' && (
                  <div className="text-vault-gray-hover text-sm">${ownAmountUsdFormatted}</div>
                )}
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="w-fit text-xs text-vault-tertiary bg-vault-tertiary/10 ring-1 ring-vault-tertiary/20 py-1 px-2 rounded-lg">
                Your Token
              </div>
              <div className="text-vault-gray-hover flex flex-row items-center gap-1">
                <div className="mr-1">Balance:</div>
                {isLoadingBalanceOwnToken && (
                  <Skeleton height="20px" width="100px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
                )}
                {balanceOwnToken && !isLoadingBalanceOwnToken && (
                  <div>{numberWithCommas(shortenNumber(Number(balanceOwnToken)))}</div>
                )}
                <span>{ownTokenSymbol}</span>
                <button
                  type="button"
                  onClick={setMaxOwnToken}
                  className="border-solid border-vault-lite text-vault-light border-2 rounded-lg px-1 sm:ml-2 w-12"
                >
                  Max
                </button>
              </div>
            </div>
          </div>
          {errors.ownTokenAmount && <div className="text-red-400 text-sm mt-2">{errors.ownTokenAmount.message}</div>}
        </div>
      </div>
    </div>
  );
}

export default TokenAmountsForm;
