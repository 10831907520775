import { Control, Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface TotalValueInputProps {
  control: Control;
  total: string;
  setTotal: (value: string) => void;
}

function TotalValueInput({ total, setTotal, control }: TotalValueInputProps) {
  return (
    <div className="flex justify-center gap-1 items-center">
      <span className="text-vault-gray-hover whitespace-nowrap	">Total value:</span>
      <span className="flex items-center bg-accent py-[2px] px-[6px] rounded-[8px] text-[#616068] w-25">
        $
        <Controller
          render={() => (
            <NumericFormat
              autoComplete="off"
              allowNegative={false}
              value={total}
              onChange={(event) => {
                setTotal(event.target.value.replace(/,/g, ''));
              }}
              thousandSeparator
              className="text-xs bg-transparent text-[#616068] border-transparent focus:border-transparent focus:ring-0 p-0 m-0"
            />
          )}
          name="totalValueUsd"
          control={control}
        />
      </span>
    </div>
  );
}

export default TotalValueInput;
