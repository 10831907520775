import { z } from 'zod';

export const VolumeResponseSchema = z.object({
  success: z.boolean(),
  cache: z.boolean(),
  data: z.object({
    metadata: z.object({
      symbol0: z.string(),
      symbol1: z.string(),
      pools: z.array(
        z.object({
          id: z.string(),
          feeTier: z.number(),
          _id: z.string(),
        }),
      ),
    }),
    pool: z.array(
      z.object({
        id: z.string(),
        history: z.array(
          z.object({
            poolVolumeUSD: z.number(),
            vaultVolumeUSD: z.number(),
            timeAt: z.string(),
          }),
        ),
      }),
    ),
  }),
});

export type VolumeResponse = z.infer<typeof VolumeResponseSchema>;
