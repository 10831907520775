function TokenRatioExplanation() {
  return (
    <div>
      <div>
        <img className="rounded-lg mb-8" src="/assets/images/zapin.jpg" alt="zapin" />
      </div>

      <h2 className="text-lg font-bold mb-1">What is the token ratio?</h2>
      <p>
        The token ratio is the ratio of tokens you want to deposit into the vault. For example, if you want to deposit
        50% ETH and 50% USDC, you should set the token ratio to 50% ETH and 50% USDC.
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Why should I use a ratio that is different than 50% / 50%?</h2>
      <p className="mb-2">
        It is important to understand that the target ratio is always 50% / 50%. Here you can set the ratio you want to
        deposit in the beginning. For example, if you do not have lots of ETH, you can set the ratio to 10% ETH and 90%
        USDC.
      </p>
    </div>
  );
}

export default TokenRatioExplanation;
