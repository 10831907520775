import { z } from 'zod';

export enum TvlChartSection {
  TVL = 'TVL',
  COMPOSITION = 'COMPOSITION',
  ABOUT = 'ABOUT',
  RISKS = 'RISKS',
}

export const TvlResponseSchema = z.object({
  success: z.boolean(),
  cache: z.boolean(),
  data: z.object({
    metadata: z.object({
      symbol0: z.string(),
      symbol1: z.string(),
    }),
    history: z.array(
      z.object({
        timeAt: z.string(),
        amount0: z.number(),
        amount1: z.number(),
        value0USD: z.number(),
        value1USD: z.number(),
        valueUSD: z.number(),
      }),
    ),
  }),
});

export type TvlResponse = z.infer<typeof TvlResponseSchema>;
