import axios from 'axios';
import { readContracts } from 'wagmi';
import { ArrakisVaultV1ProxyAbi } from '../../abis/ArrakisVaultV1Proxy';
import { ARRAKIS_V2_PALM_MANAGER } from '../../constants';
import { IVault } from '../../types';
import { ReverseJsonStrategyResponse, ReverseJsonStrategyResponseSchema } from '../../types/api';
import { CreateVaultPayload } from '../../types/create-vault';
import { vaultTokenValueToUsd } from '../calculate-tvl';
import { numberWithCommas } from '../n-formatter';

//export const URL = import.meta.env.VITE_BACKEND_URL || 'https://indexer.api.arrakis.finance';
export const URL = import.meta.env.VITE_BACKEND_URL;

export const getVaults = async (queryParams = ''): Promise<IVault[]> => {
  try {
    const response = await fetch(`${URL}/api/vault/all?version=V1&${queryParams}`);
    const data = await response.json();

    return data.vaults as IVault[];
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

export const getV2Vaults = async (queryParams = ''): Promise<IVault[]> => {
  try {
    const response = await fetch(`${URL}/api/vault/v2-strategy?${queryParams}`);
    const data = await response.json();

    const vaults = data.vaults as IVault[];

    return vaults;
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

export const getV1V2Vaults = async (queryParams = ''): Promise<IVault[]> => {
  try {
    const response = await fetch(`${URL}/api/vault/all?&${queryParams}`);
    const data = await response.json();

    return data.vaults as IVault[];
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

export const getSingleVault = async (vaultId: string): Promise<IVault[]> => {
  try {
    const response = await fetch(`${URL}/api/vault/single/${vaultId}`);
    const data = await response.json();

    return data.vault as IVault[];
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

// get vaults that user is invested in (position > 0)
export const getInvestedVaults = async (
  walletAddress: string | undefined,
): Promise<{ v1Vaults: IVault[]; v2Vaults: IVault[] }> => {
  try {
    const response = await fetch(`${URL}/api/vault/all`);
    const data = await response.json();

    const vaults = data.vaults as IVault[];

    if (walletAddress) {
      // get user´s balance of the vaults
      const balances = await readContracts({
        // @ts-ignore
        contracts: vaults
          ? vaults.map((vault) => ({
              address: vault.gauge?.address ?? vault.id,
              abi: ArrakisVaultV1ProxyAbi,
              functionName: 'balanceOf',
              args: [walletAddress],
              chainId: vault.chainId,
            }))
          : [],
      });

      const v1VaultsWithBalancesUsd = [];
      const v2VaultsWithBalancesUsd = [];

      for (let i = 0; i < balances.length; i++) {
        if (balances[i]?.status === 'success' && balances[i].result !== BigInt(0)) {
          const vault = vaults[i];
          const balanceUsd = vaultTokenValueToUsd(vault, balances[i].result as bigint);

          if (vault.version === 'V1') {
            v1VaultsWithBalancesUsd.push({
              ...vault,
              userBalance: numberWithCommas(balanceUsd),
            });
          } else if (vault.version === 'V2') {
            v2VaultsWithBalancesUsd.push({
              ...vault,
              userBalance: numberWithCommas(balanceUsd),
            });
          }
        }
      }

      // sort by user balance
      const sortedV2Vaults = v2VaultsWithBalancesUsd.sort((a, b) =>
        Number(a?.userBalance) > Number(b?.userBalance) ? -1 : 1,
      );
      const sortedV1Vaults = v1VaultsWithBalancesUsd.sort((a, b) =>
        Number(a?.userBalance) > Number(b?.userBalance) ? -1 : 1,
      );

      return {
        v1Vaults: sortedV1Vaults,
        v2Vaults: sortedV2Vaults,
      };
    } else {
      return {
        v1Vaults: [],
        v2Vaults: [],
      };
    }
  } catch (error: any) {
    console.log('error', error.message);

    return {
      v1Vaults: [],
      v2Vaults: [],
    };
  }
};

export const getPalmVault = async (vaultId = ''): Promise<IVault> => {
  const res = await axios.get(`${URL}/api/vault/palm/single/${vaultId}`);
  return res.data.vault[0];
};

export const getVaultStrategy = async (vaultId = ''): Promise<ReverseJsonStrategyResponse | null> => {
  const res = await axios.get(`${URL}/api/vault/strategy/reverse-json/${vaultId}`);

  const parsedResponse = ReverseJsonStrategyResponseSchema.safeParse(res.data);

  if (parsedResponse.success) {
    return parsedResponse.data;
  }

  console.warn('error', parsedResponse.error);

  return null;
};

export const getOwnPalmVaults = async (walletAddress = ''): Promise<IVault[]> => {
  const res = await axios.get(`${URL}/api/vault/v2/owner/${walletAddress}`);
  return res.data.vaults
    .filter((vault: IVault) => vault.manager === ARRAKIS_V2_PALM_MANAGER.toLowerCase())
    .sort((vaultA: IVault, vaultB: IVault) => {
      const dateA = new Date(vaultA.createdAt);
      const dateB = new Date(vaultB.createdAt);
      // @ts-ignore
      return dateB - dateA;
    });
};

export const createVault = async (vault: CreateVaultPayload): Promise<IVault> => {
  const res = await axios.post(`${URL}/api/vault/add-vault`, vault);
  return res.data.vault;
};
