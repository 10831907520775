import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  erc20ABI,
  useAccount,
  useBalance,
  useContractRead,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { tokenProxyAbi } from '../../../abis/token-proxy';
import { ARRAKIS_V2_ROUTER_ADDRESSES, PERMIT_2_ADDRESS, VAULTS_ZAPIN_DISABLED } from '../../../constants';
import {
  DepositFormWrapperState,
  Explanation,
  IVault,
  Slippages,
  Status,
  SwapAndAddLiquidityArgs,
} from '../../../types';
import { getAddLiquidityPermit2Params, hasVaultNativeToken } from '../../../utils/blockchain-communication';
import { formatBigNumber, parseTokenInputAmountToBigInt } from '../../../utils/format-big-number';
import './deposit.css';

import { PERMIT2_ADDRESS } from '@uniswap/permit2-sdk';
import { track } from '@vercel/analytics/react';
import 'react-toastify/dist/ReactToastify.css';
import { ArrakisV2RouterAbi } from '../../../abis/ArrakisV2Router';
import { useConfetti } from '../../../context/ConfettiContext';
import { determineDepositStatus } from '../../../utils/deposit';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { getRandomInt } from '../../../utils/math';
import { numberWithCommas } from '../../../utils/n-formatter';
import { createSignature } from '../../../utils/permit2';
import { alertUser } from '../../elements/notify';
import { Spinner } from '../../elements/spinner';
import Toggle from '../../elements/toggle/Toggle';
import DepositFormWrapper from '../../hoc/deposit-form-wrapper';
import QuestionButton from '../../layout/sidebar/QuestionButton';
import ExpectedDepositResult from '../../vault/vaultListItem/ExpectedDepositResult';
import ExpectedZapinResult from '../../vault/vaultListItem/ExpectedZapinResult';
import InputField from './components/InputField';
import ZapinDepositButton from './components/ZapinDepositButton';
// eslint-disable-next-line import/no-unresolved
import CheckIcon from '/assets/images/icon-check-2.svg';

interface DepositFormProps {
  vault: IVault;
  currentAmountUsd: string;
}

export interface IDepositFormInput {
  token0Input: number;
  token1Input: number;
}

function DepositFormMaxiProfit({ vault, currentAmountUsd }: DepositFormProps) {
  const { address, connector } = useAccount();

  const [status, setStatus] = useState<Status>(Status.APPROVE_0);

  const { hasNativeToken, nativeSymbol0, nativeSymbol1 } = hasVaultNativeToken(vault);
  const [useNativeToken, setUseNativeToken] = useState(false);
  const [isZapin, setIsZapin] = useState(true);

  const zapinDisabled = vault.chainId === 100 || VAULTS_ZAPIN_DISABLED.includes(vault.id.toLowerCase());

  useEffect(() => {
    // for 0x32c2a3d571ae280fd8dc95faccf1f73d5f6ec2b4 swap resolver from 1inch isn't working
    if (zapinDisabled) {
      setIsZapin(false);
    }
  }, [zapinDisabled]);

  const [mintAmount0, setMintAmount0] = useState<string | null>(null);
  const [mintAmount1, setMintAmount1] = useState<string | null>(null);
  const [mintAmount, setMintAmount] = useState<string | null>(null);

  const [selectedZapinToken, setSelectedZapinToken] = useState<0 | 1>(0);
  const [zapinSlippageTolerance, setZapinSlippageTolerance] = useState<Slippages>((0.25).toString());

  const [isSignatureLoading, setIsSignatureLoading] = useState(false);

  const [nonce] = useState(getRandomInt(1, 9999999999).toString());

  const [swapAndAddLiquidityParams, setSwapAndAddLiquidityParams] = useState<SwapAndAddLiquidityArgs>();

  const [signatureStandard, setSignatureStandard] = useState<string | null>(null);
  const [signatureNative, setSignatureNative] = useState<string | null>(null);

  const [depositFormWrapperState, setDepositFormWrapperState] = useState(DepositFormWrapperState.DEFAULT);
  const [isLoadingSafeWallet, setLoadingSafeWallet] = useState(false);
  const isSafeWallet = connector?.id === 'safe';

  const { startConfettiAnimation } = useConfetti();

  // trigger input form errors when user toggles between native and non-native token
  useEffect(() => {
    trigger(['token0Input', 'token1Input']);
  }, [useNativeToken]);

  const symbol0 = useNativeToken
    ? nativeSymbol0?.toUpperCase() ?? vault.symbol0?.toUpperCase()
    : vault.symbol0?.toUpperCase();
  const symbol1 = useNativeToken
    ? nativeSymbol1?.toUpperCase() ?? vault.symbol1?.toUpperCase()
    : vault.symbol1?.toUpperCase();

  // get user balances for both tokens
  const { data: nativeBalance } = useBalance({
    address: address as '0x${string}',
    chainId: vault.chainId,
  });

  const { data: balance0, isLoading: balance0Loading } = useContractRead({
    address: vault.token0 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [address],
    chainId: vault.chainId,
    select: (data) => {
      if (typeof data !== 'bigint') {
        return undefined;
      }
      return useNativeToken && nativeSymbol0 ? nativeBalance?.value : data;
    },
  });

  const { data: balance1, isLoading: balance1Loading } = useContractRead({
    address: vault.token1 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [address],
    chainId: vault.chainId,
    select: (data) => {
      if (typeof data !== 'bigint') {
        return undefined;
      }
      return useNativeToken && nativeSymbol1 ? nativeBalance?.value : data;
    },
  });

  const balance0Value = balance0 ? formatBigNumber(balance0 as bigint, vault.token0Decimals, 8) : '0';
  const balance1Value = balance1 ? formatBigNumber(balance1 as bigint, vault.token1Decimals, 8) : '0';

  // input fields are in token currency, this is the USD value
  const [token0InputUsd, setToken0InputUsd] = useState(0);
  const [token1InputUsd, setToken1InputUsd] = useState(0);

  // add liquidity params for the transaction need to be fetched
  const [addLiquidityArgs, setAddLiquidityArgs] = useState<any>([]);

  // form
  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IDepositFormInput>({
    defaultValues: {
      token0Input: 0,
      token1Input: 0,
    },
    mode: 'onBlur',
  });

  const token0Input = watch('token0Input');
  const token1Input = watch('token1Input');

  async function onCreateSignature() {
    const signature = await createSignature({
      useNativeToken,
      vault,
      input0: parsedInp0,
      input1: parsedInp1,
      nonce,
      nativeSymbol0,
      setIsSignatureLoading,
      selectedZapinToken: isZapin ? selectedZapinToken : undefined,
    });

    if (signature) {
      if (useNativeToken) {
        setSignatureNative(signature);
      } else {
        setSignatureStandard(signature);
      }
    }
  }

  // update token0Input and token1Input when one of them changes
  function onValueChanged(type: 'token0' | 'token1', eventKey: string) {
    if (isFinite(Number(eventKey)) || eventKey === 'Backspace') {
      if (type === 'token0') {
        const value0 = token0Input;
        if (isFinite(value0)) {
          setValue(
            'token1Input',
            (value0 || 0) * (Number(vault.formatted?.amount1 || 0) / Number(vault.formatted?.amount0 || 1)),
          );
        }
      } else {
        const value1 = token1Input;
        if (isFinite(value1)) {
          setValue(
            'token0Input',
            (value1 || 0) * (Number(vault.formatted?.amount0 || 0) / Number(vault.formatted?.amount1 || 1)),
          );
        }
      }
      trigger(['token0Input', 'token1Input']);
    }
  }

  // update usd value when user changes input
  useEffect(() => {
    const value0 = token0Input;
    setToken0InputUsd((vault.price0 ?? vault.cmcPrice0) * (value0 || 0));
    const value1 = token1Input;
    setToken1InputUsd((vault.price1 ?? vault.cmcPrice1) * (value1 || 0));
  }, [token0Input, token1Input]);

  // update input fields when user clicks max button
  function setMaxToken0() {
    setValue('token0Input', Number(balance0Value), { shouldDirty: true, shouldValidate: true });
    console.log('errors', errors);
    // unregister('token1Input');

    if (!isZapin) {
      setValue(
        'token1Input',
        (Number(balance0Value) || 0) *
          (Number(vault?.formatted?.amount1 || 0) / Number(vault?.formatted?.amount0 || 0)),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    } else {
      setValue('token1Input', 0, { shouldDirty: true, shouldValidate: true });
    }
  }

  function setMaxToken1() {
    setValue('token1Input', Number(balance1Value), { shouldDirty: true, shouldValidate: true });
    if (!isZapin) {
      setValue(
        'token0Input',
        (Number(balance1Value) || 0) *
          (Number(vault?.formatted?.amount0 || 0) / Number(vault?.formatted?.amount1 || 0)),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    } else {
      setValue('token0Input', 0, { shouldDirty: true, shouldValidate: true });
    }
  }

  const parsedInp0 = useMemo(
    () => parseTokenInputAmountToBigInt(token0Input, vault.token0Decimals),

    [vault.token0Decimals, token0Input],
  );

  const parsedInp1 = useMemo(
    () => parseTokenInputAmountToBigInt(token1Input, vault.token1Decimals),

    [vault.token1Decimals, token1Input],
  );

  // set signature loading to false if native toggle is changed or if input values change
  useEffect(() => {
    setIsSignatureLoading(false);
    setSignatureNative(null);
    setSignatureStandard(null);
  }, [useNativeToken, parsedInp0, parsedInp1, isZapin, selectedZapinToken]);

  // allowances: check if user has already approved tokens to permit contract
  // this is different to other pages where we check if user has approved tokens to router contract

  const token0Contract = {
    address: vault.token0 as '0x${string}',
    abi: erc20ABI,
  };

  const token1Contract = {
    address: vault.token1 as '0x${string}',
    abi: erc20ABI,
  };

  const { data: allowances, isLoading: isLoadingApprovals } = useContractReads({
    contracts: [
      {
        ...token0Contract,
        functionName: 'allowance',
        args: [
          address ?? '0x0000000000000000000000000000000000000000',
          isZapin ? ARRAKIS_V2_ROUTER_ADDRESSES : PERMIT2_ADDRESS,
        ],
      },
      {
        ...token1Contract,
        functionName: 'allowance',
        args: [
          address ?? '0x0000000000000000000000000000000000000000',
          isZapin ? ARRAKIS_V2_ROUTER_ADDRESSES : PERMIT2_ADDRESS,
        ],
      },
    ],
    watch: true,
  });

  const token0Allowance = allowances?.[0].result;
  const token1Allowance = allowances?.[1].result;

  // approving tokens

  const addRecentTransaction = useAddRecentTransaction();

  const { config: approveToken0Config } = usePrepareContractWrite({
    ...token0Contract,
    functionName: 'approve',
    args: [
      isZapin ? ARRAKIS_V2_ROUTER_ADDRESSES : PERMIT_2_ADDRESS,
      isZapin ? parsedInp0 : BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
    ],
    chainId: vault.chainId,
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
  });

  const { config: approveToken1Config } = usePrepareContractWrite({
    ...token1Contract,
    functionName: 'approve',
    args: [
      isZapin ? ARRAKIS_V2_ROUTER_ADDRESSES : PERMIT_2_ADDRESS,
      isZapin ? parsedInp1 : BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
    ],
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
  });

  const {
    data: token0ApproveData,
    write: token0Approve,
    isLoading: isLoadingToken0Approve,
  } = useContractWrite({
    ...approveToken0Config,
    onError(error) {
      console.log('error', error);
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const {
    data: token1ApproveData,
    write: token1Approve,
    isLoading: isLoadingToken1Approve,
  } = useContractWrite({
    ...approveToken1Config,
    onError(error) {
      console.log('error', error);
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isLoadingToken0ApproveAfter } = useWaitForTransaction({
    hash: token0ApproveData?.hash,
    onSettled(data, error) {
      if (error) {
        console.log('error', error);
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      alertUser(
        'success',
        'Transaction successful!',
        getExplorerLink(vault.chainId || 137, token0ApproveData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('approve token 0 for deposit v2', {
        type: 'click',
        feature: 'vault deposit form v2',
        wallet: address as string,
        vault: vault.id,
      });

      addRecentTransaction({
        hash: token0ApproveData?.hash ?? '',
        description: `Approve ${token0Input} ${vault.symbol0}`,
      });
    },
  });

  const { isLoading: isLoadingToken1ApproveAfter } = useWaitForTransaction({
    hash: token1ApproveData?.hash,
    onSettled(data, error) {
      if (error) {
        console.log('error', error);
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      alertUser(
        'success',
        'Transaction successful!',
        getExplorerLink(vault.chainId || 137, token1ApproveData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('approve token 1 for deposit v2', {
        type: 'click',
        feature: 'vault deposit form v2',
        wallet: address as string,
        vault: vault.id,
      });

      addRecentTransaction({
        hash: token1ApproveData?.hash ?? '',
        description: `Approve ${token1Input} ${vault.symbol1}`,
      });
    },
  });

  // Increase Liquidity

  const config = useNativeToken
    ? {
        address: ARRAKIS_V2_ROUTER_ADDRESSES as '0x${string}',
        chainId: vault.chainId,
        abi: ArrakisV2RouterAbi,
        functionName: 'addLiquidityPermit2',
        enabled:
          !!parsedInp0 &&
          !!parsedInp1 &&
          parsedInp0 > BigInt(0) &&
          parsedInp1 > BigInt(0) &&
          status === Status.DEPOSIT &&
          !!signatureNative,
        // @ts-ignore
        args: addLiquidityArgs,
        value: nativeSymbol0 ? parsedInp0 : parsedInp1,
        // gasLimit: BigInt(50000000),
        watch: true,
      }
    : {
        address: ARRAKIS_V2_ROUTER_ADDRESSES as '0x${string}',
        chainId: vault.chainId,
        abi: ArrakisV2RouterAbi,
        functionName: 'addLiquidityPermit2',
        onError(error: any) {
          console.log('Error here', error);
        },
        enabled:
          !!parsedInp0 &&
          !!parsedInp1 &&
          parsedInp0 > BigInt(0) &&
          parsedInp1 > BigInt(0) &&
          status === Status.DEPOSIT &&
          !!signatureStandard,
        args: addLiquidityArgs,
        // gasLimit: BigInt(5000000),
        watch: true,
        value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
      };

  const { config: addLiquidityConfig } = usePrepareContractWrite(config);

  const {
    data: addLiquidityData,
    write: addLiquidity,
    isLoading: isAddLiquidityLoading,
  } = useContractWrite({
    ...(addLiquidityConfig as any),
    onError(error) {
      console.log('error', error);
      alertUser('error', error.message, '', '', isSafeWallet);
    },
    onSettled(data, error) {
      console.log('error', error);
    },
  });

  const { isLoading: isAddLiquidityLoadingAfter } = useWaitForTransaction({
    hash: addLiquidityData?.hash,
    onSettled(data, error) {
      if (error) {
        console.log('error', error);
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      startConfettiAnimation();
      alertUser(
        'success',
        'Liquidity added  !',
        getExplorerLink(vault.chainId || 137, addLiquidityData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('deposit v2', {
        type: 'click',
        feature: 'vault deposit form v2',
        wallet: address as string,
        vault: vault.id,
        value: token0InputUsd + token1InputUsd,
      });

      setDepositFormWrapperState(DepositFormWrapperState.DEPOSIT_SUCCESS);

      addRecentTransaction({
        hash: addLiquidityData?.hash ?? '',
        description: 'Increase Liquidity',
      });
    },
  });

  const hasMinimumBalanceToken0 =
    // @ts-ignore
    (useNativeToken && nativeSymbol0) || ((token0Allowance || BigInt(0)) >= parsedInp0 && parsedInp0 > BigInt(0));

  const hasMinimumBalanceToken1 =
    // @ts-ignore
    (useNativeToken && nativeSymbol1) || ((token1Allowance || BigInt(0)) >= parsedInp1 && parsedInp1 > BigInt(0));
  const isZapinButtonDisabled = selectedZapinToken === 0 ? !hasMinimumBalanceToken0 : !hasMinimumBalanceToken1;

  // determine current status of deposit
  useEffect(() => {
    const oldStatus = status;

    const newStatus = determineDepositStatus({
      useNativeToken,
      signatureNative,
      signatureStandard,
      parsedInp0,
      parsedInp1,
      nativeSymbol0,
      nativeSymbol1,
      token0Allowance: token0Allowance as bigint | undefined,
      token1Allowance: token1Allowance as bigint | undefined,
      isZapin,
      selectedZapinToken,
    });

    setStatus(newStatus);

    if (oldStatus !== newStatus) {
      setLoadingSafeWallet(false);
    }
  }, [
    token0Allowance,
    token0InputUsd,
    token1InputUsd,
    token1Allowance,
    useNativeToken,
    signatureNative,
    signatureStandard,
    parsedInp0,
    parsedInp1,
    nativeSymbol0,
    nativeSymbol1,
    isZapin,
    selectedZapinToken,
  ]);

  // update addLiquidity params whenever needed
  useEffect(() => {
    const updateAddLiquidityParams = async () => {
      if (status === Status.SIGN || status === Status.DEPOSIT) {
        const params = await getAddLiquidityPermit2Params(vault, parsedInp0, parsedInp1, 1);

        setMintAmount0(formatBigNumber(params.amount0, vault.token0Decimals, 18));
        setMintAmount1(formatBigNumber(params.amount1, vault.token1Decimals, 18));
        setMintAmount(formatBigNumber(params.mintAmount, 18, 18));

        if (status === Status.DEPOSIT && (signatureStandard || signatureNative)) {
          const permitted = useNativeToken
            ? nativeSymbol0
              ? [{ token: vault.token1, amount: parsedInp1 }]
              : [{ token: vault.token0, amount: parsedInp0 }]
            : [
                { token: vault.token0, amount: parsedInp0 },
                { token: vault.token1, amount: parsedInp1 },
              ];

          const addData = {
            amount0Max: (parsedInp0 * BigInt(9950)) / BigInt(10000), // todo: remove this, for now it fixes signature problem
            amount1Max: (parsedInp1 * BigInt(9950)) / BigInt(10000), // todo: remove this, for now it fixes signature problem
            amount0Min: params.amount0,
            amount1Min: params.amount1,
            amountSharesMin: params.mintAmount,
            vault: vault.id,
            receiver: address,
            gauge: vault.gauge?.address || '0x0000000000000000000000000000000000000000',
          };

          /*  if (useNativeToken) {
          addData.value = nativeSymbol0 ? parsedInp0 : parsedInp1;
        } */

          const addLiquidityPermit2Data = [
            {
              addData,
              permit: {
                deadline: BigInt('999999999999999'),
                nonce: BigInt(nonce),
                permitted,
              },
              signature: useNativeToken ? signatureNative : signatureStandard,
            },
          ];

          setAddLiquidityArgs(addLiquidityPermit2Data);
        } else {
          setAddLiquidityArgs([BigInt(0)]);
        }
      }
    };
    updateAddLiquidityParams();
  }, [
    token0Allowance,
    token1Allowance,
    vault,
    address,
    parsedInp0,
    parsedInp1,
    useNativeToken,
    status,
    signatureNative,
    signatureStandard,
    nonce,
    nativeSymbol0,
  ]);

  function onAddLiquidity() {
    if (isSafeWallet && addLiquidity) {
      addLiquidity();
      setDepositFormWrapperState(DepositFormWrapperState.SAFE_APP_DEPOSIT);
    } else {
      addLiquidity?.();
    }
  }

  function onApproveToken0() {
    if (isSafeWallet) {
      setLoadingSafeWallet(true);
    }
    token0Approve?.();
  }

  function onApproveToken1() {
    if (isSafeWallet) {
      setLoadingSafeWallet(true);
    }
    token1Approve?.();
  }

  useEffect(() => {
    if (isZapin) {
      setUseNativeToken(false);
    }
  }, [isZapin]);

  function onToggleWethEth() {
    if (!useNativeToken) {
      if (isZapin && nativeSymbol0 && selectedZapinToken === 1) {
        setSelectedZapinToken(0);
      } else if (isZapin && nativeSymbol1 && selectedZapinToken === 0) {
        setSelectedZapinToken(1);
      }
    }

    setUseNativeToken(!useNativeToken);
  }

  return (
    <DepositFormWrapper
      isLoading={balance0Loading || balance1Loading}
      state={depositFormWrapperState}
      usdValue={isZapin ? undefined : token0InputUsd + token1InputUsd}
    >
      <form>
        <div className="flex flex-col overflow-hidden mb-8 gap-4">
          <div className="mb-2 flex flex-col gap-3">
            {!zapinDisabled && (
              <div className="flex items-center gap-1">
                <Toggle checked={isZapin} onChange={() => setIsZapin(!isZapin)} id="zap-in" />
                <span className={`text-[16px] ml-1 ${isZapin ? 'text-white ' : 'text-vault-gray-hover'}`}>Zap In</span>
                <QuestionButton explanation={Explanation.ZAPIN} title="Zapin explained" />
              </div>
            )}

            {hasNativeToken && (
              <div className="flex items-center gap-1">
                <Toggle id="native-token-toggle" checked={useNativeToken} onChange={onToggleWethEth} />
                <div className={`text-[16px] ml-1 ${useNativeToken ? 'text-white ' : 'text-vault-gray-hover'}`}>
                  Use {(nativeSymbol0 || nativeSymbol1)?.toUpperCase()}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col md:flex-row justify-between gap-2 relative">
            {isZapin && (
              <div className="grow w-full">
                {selectedZapinToken === 0 && (
                  <InputField
                    vault={vault}
                    balanceValue={balance0Value}
                    tokenInputUsd={token0InputUsd}
                    symbol={symbol0}
                    showError={!!errors.token0Input && isDirty}
                    errorMessage={errors.token0Input?.message}
                    onValueChanged={onValueChanged}
                    tokenType="token0"
                    register={register}
                    setMaxToken={setMaxToken0}
                    setSelectedZapinToken={setSelectedZapinToken}
                    symbol0={symbol0}
                    symbol1={symbol1}
                    isZapin={isZapin}
                    zapinSlippageTolerance={zapinSlippageTolerance}
                    setZapinSlippageTolerance={setZapinSlippageTolerance}
                  />
                )}
                {selectedZapinToken === 1 && (
                  <InputField
                    vault={vault}
                    balanceValue={balance1Value}
                    tokenInputUsd={token1InputUsd}
                    symbol={symbol1}
                    showError={!!errors.token1Input && isDirty}
                    errorMessage={errors.token1Input?.message}
                    onValueChanged={onValueChanged}
                    tokenType="token1"
                    register={register}
                    setMaxToken={setMaxToken1}
                    setSelectedZapinToken={setSelectedZapinToken}
                    symbol0={symbol0}
                    symbol1={symbol1}
                    isZapin={isZapin}
                    zapinSlippageTolerance={zapinSlippageTolerance}
                    setZapinSlippageTolerance={setZapinSlippageTolerance}
                  />
                )}
              </div>
            )}

            {!isZapin && (
              <>
                {' '}
                <div className="grow w-1/2">
                  <InputField
                    vault={vault}
                    balanceValue={balance0Value}
                    tokenInputUsd={token0InputUsd}
                    symbol={symbol0}
                    showError={!!errors.token0Input && isDirty}
                    errorMessage={errors.token0Input?.message}
                    onValueChanged={onValueChanged}
                    tokenType="token0"
                    register={register}
                    setMaxToken={setMaxToken0}
                    isZapin={false}
                  />
                </div>
                <div className="grow w-1/2">
                  <InputField
                    vault={vault}
                    balanceValue={balance1Value}
                    tokenInputUsd={token1InputUsd}
                    symbol={symbol1}
                    showError={!!errors.token1Input && isDirty}
                    errorMessage={errors.token1Input?.message}
                    onValueChanged={onValueChanged}
                    tokenType="token1"
                    register={register}
                    setMaxToken={setMaxToken1}
                    isZapin={false}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col sm:flex-row justify-between">
            {!isZapin && (
              <div>
                <div className="text-xl font-semibold mt-5 mb-2 text-vault-light text-[24px]">
                  Deposit amount: ${numberWithCommas((token0InputUsd + token1InputUsd).toFixed(2))}{' '}
                </div>
                <div className="text-xl font-semibold mb-3 text-vault-gray-hover text-[20px]">
                  New position will be: $
                  {numberWithCommas((token0InputUsd + token1InputUsd + Number(currentAmountUsd)).toFixed(2))}{' '}
                </div>
              </div>
            )}
            <div></div>
          </div>
          {!isZapin && (
            <ExpectedDepositResult
              usdTotal={token0InputUsd + token1InputUsd}
              amountToken0={token0Input}
              symbol0={symbol0}
              amountToken1={token1Input}
              symbol1={symbol1}
              minReceived0={mintAmount0}
              minReceived1={mintAmount1}
              mintAmount={mintAmount}
            />
          )}

          {isZapin && (
            <>
              {' '}
              <ExpectedZapinResult
                vault={vault}
                input0={parsedInp0}
                input1={parsedInp1}
                symbol0={symbol0}
                symbol1={symbol1}
                mintAmount={mintAmount}
                slippage={Number(zapinSlippageTolerance)}
                walletAddress={address as string}
                selectedZapinToken={selectedZapinToken}
                setSwapAndAddLiquidityParams={setSwapAndAddLiquidityParams}
                swapAndAddLiquidityParams={swapAndAddLiquidityParams}
              />
              {token0InputUsd + token1InputUsd > 10000 && (
                <div className="mt-6 border px-4 py-2 rounded-[12px] text-center flex justify-center items-center gap-2">
                  <span>
                    Please be aware that you will receive about 0-1% of the total transaction value as a refund.
                  </span>
                  <QuestionButton explanation={Explanation.TOKEN} title="Token explained">
                    <div className="whitespace-nowrap cursor-pointer font-bold">Learn more</div>
                  </QuestionButton>
                </div>
              )}
            </>
          )}
          <div>
            {status === Status.APPROVE_0 && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  type="button"
                  onClick={onApproveToken0}
                  className={`flex flex-row justify-center items-center  rounded-[12px] h-[64px] p-2 grow disabled: ${
                    !errors.token0Input && isDirty && parsedInp0 > BigInt(0)
                      ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
                      : 'bg-vault-gray-2 text-vault-gray'
                  }`}
                  disabled={!!errors.token0Input || !isDirty || !(parsedInp0 > BigInt(0))}
                >
                  <div className="flex items-center gap-3">Approve {vault.symbol0?.toUpperCase()} </div>

                  {(isLoadingApprovals ||
                    isLoadingToken0Approve ||
                    isLoadingToken0ApproveAfter ||
                    isLoadingSafeWallet) && (
                    <span className="mx-2">
                      <Spinner color="vault-white" />
                    </span>
                  )}
                </button>
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  2
                </button>
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  3
                </button>
                {!isZapin && (
                  <button
                    disabled
                    className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                  >
                    4
                  </button>
                )}
              </div>
            )}
            {status === Status.APPROVE_1 && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex justify-center items-center"
                >
                  <img src={CheckIcon} alt="check icon" />
                </button>
                <button
                  type="button"
                  onClick={onApproveToken1}
                  className={`flex flex-row justify-center items-center  rounded-[12px] h-[64px] p-2 grow ${
                    !errors.token1Input && isDirty && parsedInp1 > BigInt(0)
                      ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
                      : 'bg-vault-gray-2 text-vault-gray'
                  }`}
                  disabled={!!errors.token1Input || !isDirty || !(parsedInp1 > BigInt(0))}
                >
                  <div className="flex items-center gap-3">Approve {vault.symbol1?.toUpperCase()} </div>

                  {(isLoadingApprovals ||
                    isLoadingToken1Approve ||
                    isLoadingToken1ApproveAfter ||
                    isLoadingSafeWallet) && (
                    <span className="mx-2">
                      <Spinner color="vault-white" />
                    </span>
                  )}
                </button>

                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  3
                </button>
                {!isZapin && (
                  <button
                    disabled
                    className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                  >
                    4
                  </button>
                )}
              </div>
            )}
            {status === Status.SIGN && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex justify-center items-center"
                >
                  <img src={CheckIcon} alt="check icon" />
                </button>
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex justify-center items-center"
                >
                  <img src={CheckIcon} alt="check icon" />
                </button>
                <button
                  type="button"
                  onClick={() => onCreateSignature()}
                  className={`flex flex-row justify-center items-center  rounded-[12px] h-[64px] p-2 grow ${
                    !errors.token1Input && isDirty
                      ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
                      : 'bg-vault-gray-2 text-vault-gray'
                  }`}
                  disabled={!!errors.token1Input || !isDirty}
                >
                  <div className="flex items-center gap-3">Sign</div>

                  {isSignatureLoading && (
                    <span className="mx-2">
                      <Spinner color="vault-white" />
                    </span>
                  )}
                </button>
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  4
                </button>
              </div>
            )}
            {status === Status.DEPOSIT && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center"
                >
                  <img src={CheckIcon} alt="check icon" />
                </button>

                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center"
                >
                  <img src={CheckIcon} alt="check icon" />
                </button>
                {!isZapin && (
                  <button
                    disabled
                    className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center"
                  >
                    <img src={CheckIcon} alt="check icon" />
                  </button>
                )}
                {isZapin && (
                  <ZapinDepositButton
                    vault={vault}
                    token0InputUsd={token0InputUsd}
                    token1InputUsd={token1InputUsd}
                    isSafeWallet={isSafeWallet}
                    setDepositFormWrapperState={setDepositFormWrapperState}
                    startConfettiAnimation={startConfettiAnimation}
                    swapAndAddLiquidityParams={swapAndAddLiquidityParams}
                    useNativeToken={useNativeToken}
                    nativeTokenValue={nativeSymbol0 ? parsedInp0 : parsedInp1}
                    isDisabled={isZapinButtonDisabled}
                  />
                )}

                {!isZapin && (
                  <button
                    type="button"
                    disabled={!addLiquidity || isAddLiquidityLoading || isAddLiquidityLoadingAfter}
                    onClick={onAddLiquidity}
                    className="flex flex-row justify-center items-center bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary rounded-[12px] h-[64px] p-2 grow"
                  >
                    <span> Add Liquidity & Stake </span>
                    {(isAddLiquidityLoading || isAddLiquidityLoadingAfter) && (
                      <span className="mx-2">
                        <Spinner color="vault-white" />
                      </span>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </DepositFormWrapper>
  );
}

export default memo(DepositFormMaxiProfit);
