import { useConnectModal } from '@rainbow-me/rainbowkit';
import { track } from '@vercel/analytics/react';
import React from 'react';
import { useAccount, useChainId, useSwitchNetwork } from 'wagmi';
import PrimaryButton from '../../../elements/buttons/PrimaryButton';
import SecondaryButton from '../../../elements/buttons/SecondaryButton';
import { Spinner } from '../../../elements/spinner';

interface NavigationButtonsProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isContinueButtonEnabled: boolean;
  chainId: number;
  openConfirmationModal: () => void;
  isPriceConfirmed: boolean;
}

function NavigationButtons({
  currentStep,
  setCurrentStep,
  isContinueButtonEnabled,
  chainId,
  openConfirmationModal,
  isPriceConfirmed,
}: NavigationButtonsProps) {
  function increaseStep() {
    track('continue vault creation', {
      step: currentStep,
      type: 'click',
      feature: 'vault creation',
    });
    setCurrentStep((prev) => prev + 1);
  }

  function decreaseStep() {
    track('back vault creation', {
      step: currentStep,
      type: 'click',
      feature: 'vault creation',
    });
    setCurrentStep((prev) => prev - 1);
  }

  const currentChainId = useChainId();

  const { isLoading: isLoadingSwitchNetwork, switchNetwork } = useSwitchNetwork();

  const { openConnectModal } = useConnectModal();

  const { address } = useAccount();

  function onContinue() {
    if (!address) {
      openConnectModal?.();
    }

    if (chainId !== currentChainId) {
      switchNetwork?.(chainId);
    } else {
      if (currentStep === 0 && !isPriceConfirmed) {
        openConfirmationModal();
      } else {
        increaseStep();
      }
    }
  }

  return (
    <div className="flex justify-between w-full gap-4">
      {currentStep !== 0 && currentStep !== 3 && <SecondaryButton onClick={decreaseStep}>Back</SecondaryButton>}
      {currentStep < 2 && (
        <PrimaryButton onClick={onContinue} disabled={!isContinueButtonEnabled || !address} className="grow">
          <div className=" flex items-center gap-2">
            {address && chainId !== currentChainId && isContinueButtonEnabled
              ? 'Switch network to continue'
              : 'Continue'}
            {isLoadingSwitchNetwork && (
              <span className="mx-2">
                <Spinner color="vault-black" />{' '}
              </span>
            )}
          </div>
        </PrimaryButton>
      )}
    </div>
  );
}

export default NavigationButtons;
