import ReactApexChart from 'react-apexcharts';
import { ConfigDetailsResponse } from '../../../../types/create-vault';
import {
  getLiquidityProfileChartOptions,
  getLiquidityProfileChartSeriesFromLiquidityPositions,
} from '../../../../utils/charts';

interface LiquidityProfileChartProps {
  configDetails: ConfigDetailsResponse;
}

function LiquidityProfileChart({ configDetails }: LiquidityProfileChartProps) {
  const currentPrice = configDetails?.price0 && configDetails?.price1 ? configDetails.price0 / configDetails.price1 : 0;

  const { positions, minX, maxX, maxY } = getLiquidityProfileChartSeriesFromLiquidityPositions(
    configDetails.positions,
    configDetails.price0,
    configDetails.price1,
  );

  const liquidityProfileSeries: ApexAxisChartSeries = positions || [];

  const liquidityProfileChartOptions = getLiquidityProfileChartOptions(currentPrice, minX, maxX, maxY);

  return <ReactApexChart options={liquidityProfileChartOptions} series={liquidityProfileSeries} height={350} />;
}

export default LiquidityProfileChart;
