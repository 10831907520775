interface NetworkLogoProps {
  chainId: number;
  size?: number;
}

function NetworkLogo({ chainId, size = 40 }: NetworkLogoProps) {
  if (chainId === 1) {
    return (
      <img
        src="/assets/images/networks/ethereum.svg"
        alt="ethereum network logo"
        className={`rounded-full max-w-[${size}px] max-h-[${size}px] align-middle opacity-90 hover:opacity-100 duration-300`}
      />
    );
  }

  if (chainId === 10) {
    return (
      <img
        src="/assets/images/networks/optimism.png"
        alt="optimism network logo"
        className={`rounded-full max-w-[${size}px] max-h-[${size}px] align-middle opacity-90 hover:opacity-100 duration-300`}
      />
    );
  }

  if (chainId === 100) {
    return (
      <img
        src="/assets/images/networks/gnosis.svg"
        alt="gnosis network logo"
        className={`rounded-full max-w-[${size}px] max-h-[${size}px] align-middle opacity-90 hover:opacity-100 duration-300`}
      />
    );
  }

  if (chainId === 8453) {
    return (
      <img
        src="/assets/images/networks/base.svg"
        alt="base network logo"
        className={`rounded-full max-w-[${size}px] max-h-[${size}px] align-middle opacity-90 hover:opacity-100 duration-300`}
      />
    );
  }

  if (chainId === 137) {
    return (
      <img
        src="/assets/images/networks/polygon.webp"
        alt="poygon network logo"
        className={`rounded-full max-w-[${size}px] max-h-[${size}px] align-middle  opacity-90 hover:opacity-100 duration-300`}
      />
    );
  }

  if (chainId === 42161) {
    return (
      <img
        src="/assets/images/networks/arbitrum.png"
        alt="arbitrum network logo"
        className={`rounded-full max-w-[${size}px] max-h-[${size}px] align-middle  opacity-90 hover:opacity-100 duration-300`}
      />
    );
  }

  if (chainId === 11155111) {
    return (
      <img
        src="/assets/images/networks/sepolia.png"
        alt="sepolia network logo"
        className={`rounded-full max-w-[${size}px] max-h-[${size}px] align-middle  opacity-90 hover:opacity-100 duration-300`}
      />
    );
  }
}

export default NetworkLogo;
