import { ApexOptions } from 'apexcharts';
import { EarnedFeesResponse } from '../../types/charts/earned-fees';
import { abbreviateNumber } from '../n-formatter';

function getChartSeriesFromEarnedFeesData(earnedFeesData: EarnedFeesResponse['data']) {
  return earnedFeesData.history.map((item) => item.feeUSD).reverse();
}

export const getEarnedFeesChartOptions = (data: EarnedFeesResponse['data']): ApexOptions => ({
  colors: ['rgba(255, 114, 0, 0.16)'],
  series: [
    {
      name: 'Fees',
      data: getChartSeriesFromEarnedFeesData(data),
    },
  ],
  grid: {
    borderColor: ' rgba(41, 40, 45, 1)',
  },

  chart: {
    animations: {
      enabled: false,
    },

    height: 350,
    type: 'bar',
    toolbar: {
      show: false,
    },
  },

  plotOptions: {
    bar: {
      borderRadius: 10,
      borderRadiusApplication: 'end',
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  stroke: {
    width: [1, 1, 0, 0],
    curve: 'smooth',
    colors: ['#FF7200'],
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return '$' + abbreviateNumber(Number(val));
    },
    // offsetY: '-50%',
    style: {
      fontSize: '12px',
      colors: ['#FF7200'],
    },
  },
  tooltip: {
    followCursor: false,
    intersect: false,
    onDatasetHover: {
      highlightDataSeries: false,
    },
    marker: {
      show: false,
    },

    custom: function ({ series, dataPointIndex }) {
      const inversedDataPointIndex = series[0].length - dataPointIndex - 1;

      return `
      <div class="bg-transparent p-1">
        <div class="bg-vault-light-gray text-primary-black rounded-lg px-2 py-1">
        <div>${abbreviateNumber(
          data.history[inversedDataPointIndex].fee0,
        )} ${data.metadata?.symbol0?.toUpperCase()}</div>
        <div>${abbreviateNumber(
          data.history[inversedDataPointIndex].fee1,
        )} ${data.metadata?.symbol1?.toUpperCase()}</div>
        </div>
        </div>
        `;
    },
  },
  xaxis: {
    crosshairs: {
      show: false, // <--- HERE
    },
    categories: data.history
      .map((item) => new Date(item.timeAt).toLocaleString('default', { month: 'short' }))
      .reverse(),
    labels: {
      rotate: 0,
      style: {
        colors: '#605E67',
      },
    },

    axisTicks: {
      show: false,
    },
    // type: 'category',
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#605E67',
      },
      formatter: function (val) {
        const decimals = val > 1000 ? 0 : 2;
        return '$ ' + abbreviateNumber(val, decimals);
      },
    },
  },
});
