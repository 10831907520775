import CheckIcon from '/assets/images/icon-check-2.svg';

interface PlaceholderTransactionButtonProps {
  text: string;
  isCompleted?: boolean;
}

function PlaceholderTransactionButton({ text, isCompleted = false }: PlaceholderTransactionButtonProps) {
  return (
    <button
      disabled
      className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] w-[64px] max-w-[64px] h-[64px] flex items-center justify-center"
    >
      {isCompleted ? <img src={CheckIcon} alt="check icon" /> : text}
    </button>
  );
}

export default PlaceholderTransactionButton;
