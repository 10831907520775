import { ApexOptions } from 'apexcharts';
import { LiquidityProfileResponse } from '../../types/charts/liquidity-profile';
import { abbreviateNumber } from '../n-formatter';

function getVaultChartSeriesFromLiquidityProfileData(liquidityProfileData: LiquidityProfileResponse['data']) {
  return liquidityProfileData.profiles[0].vault.profile.map((item) => ({
    x: item.ratio,
    y: item.liquidity,
  }));
}

function getPoolChartSeriesFromLiquidityProfileData(liquidityProfileData: LiquidityProfileResponse['data']) {
  return liquidityProfileData.profiles[0].pool.profile.map((item) => ({
    x: item.ratio,
    y: item.liquidity,
  }));
}

export const getLiquidityProfileChartOptions = (data: LiquidityProfileResponse['data']): ApexOptions => ({
  colors: ['#FF7200', '#2E2D31'],
  series: [
    {
      name: 'Arrakis liquidity',
      data: getVaultChartSeriesFromLiquidityProfileData(data),
    },
    {
      name: 'Other liquidity',
      data: getPoolChartSeriesFromLiquidityProfileData(data),
    },
  ],
  grid: {
    borderColor: ' rgba(41, 40, 45, 1)',
  },
  chart: {
    stacked: false,
    animations: {
      enabled: false,
    },
    height: 350,
    type: 'bar',
    width: '19px',
    toolbar: {
      show: false,
    },
  },

  plotOptions: {
    bar: {
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  stroke: {
    width: [1, 1, 0, 0],
    curve: 'smooth',
    //   colors: ['#FF7200'],
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontSize: '14px',
    itemMargin: {
      horizontal: 20,
    },
    labels: {
      colors: ['#F3F2F2'],
    },
    markers: {
      radius: 100,
      width: 8,
      height: 8,
      offsetX: -8,
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    onDatasetHover: {
      highlightDataSeries: false,
    },
    marker: {
      show: false,
    },

    custom: function ({ dataPointIndex }) {
      return `
        <div class="bg-transparent p-1">
          <div class="bg-vault-light-gray text-primary-black rounded-lg px-2 py-1">
          <div>$${abbreviateNumber(data.profiles[0].pool.profile[dataPointIndex].liquidity)} Other liquidity</div>
          <div>$${abbreviateNumber(data.profiles[0].vault.profile[dataPointIndex].liquidity)} Arrakis liquidity</div>
          </div>
          </div>
          `;
    },
  },
  xaxis: {
    crosshairs: {
      show: false,
    },

    labels: {
      rotate: 0,
      style: {
        colors: '#605E67',
      },
      formatter: function (val) {
        return Number(val).toFixed(2);
      },
    },

    axisTicks: {
      show: false,
    },
    type: 'numeric',
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#605E67',
      },
      formatter: function (val) {
        const decimals = val > 1000 ? 0 : 2;
        return '$ ' + abbreviateNumber(val, decimals);
      },
    },
  },
});
