export const concentrationConfigs200Ticks: {
  [key: string]: { [key: string]: number };
} = {
  '50': {
    Risky: 165,
    Moderate: 98,
    RiskAverse: 72,
  },
  '55': {
    Risky: 165,
    Moderate: 99,
    RiskAverse: 73,
  },
  '60': {
    Risky: 166,
    Moderate: 100,
    RiskAverse: 75,
  },
  '65': {
    Risky: 167,
    Moderate: 103,
    RiskAverse: 78,
  },
  '70': {
    Risky: 169,
    Moderate: 105,
    RiskAverse: 82,
  },
  '75': {
    Risky: 173,
    Moderate: 109,
    RiskAverse: 86,
  },
};

export const concentrationConfigs60Ticks: {
  [key: string]: { [key: string]: number };
} = {
  '50': {
    Risky: 50,
    Moderate: 30,
    RiskAverse: 22,
  },
  '55': {
    Risky: 51,
    Moderate: 30,
    RiskAverse: 22,
  },
  '60': {
    Risky: 51,
    Moderate: 30,
    RiskAverse: 23,
  },
  '65': {
    Risky: 51,
    Moderate: 31,
    RiskAverse: 24,
  },
  '70': {
    Risky: 52,
    Moderate: 32,
    RiskAverse: 25,
  },
  '75': {
    Risky: 53,
    Moderate: 33,
    RiskAverse: 26,
  },
};
