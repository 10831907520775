import { ApexOptions } from 'apexcharts';
import { TvlResponse } from '../../types/charts/tvl';
import { abbreviateNumber } from '../n-formatter';

function getTvlChartSeriesFromTvlData(data: TvlResponse['data']) {
  const positions0: [string, number][] = [];
  const positions1: [string, number][] = [];

  for (let i = 0; i < data.history.length; i++) {
    const point = data.history[i];
    const timestamp = point.timeAt;
    const position0 = point.value0USD;
    const position1 = point.value1USD;

    positions0.push([timestamp, position0]);
    positions1.push([timestamp, position1]);
  }

  const seriesDataComposition = [
    {
      name: data.metadata.symbol0?.toUpperCase() + ' Holdings',
      data: positions0,
    },
    {
      name: data.metadata.symbol1?.toUpperCase() + ' Holdings',
      data: positions1,
    },
  ];

  if (seriesDataComposition[0].data.length === 1) {
    const lastPoint0 = seriesDataComposition[0].data[0];

    const newPoint0: [string, number] = [
      new Date(new Date(lastPoint0[0]).getTime() - 10 * 60 * 1000).toString(),
      lastPoint0[1],
    ];

    seriesDataComposition[0].data.unshift(newPoint0);

    const lastPoint1 = seriesDataComposition[1].data[0];

    const newPoint1: [string, number] = [
      new Date(new Date(lastPoint0[0]).getTime() - 10 * 2 * 60 * 1000).toString(),
      lastPoint1[1],
    ];
    seriesDataComposition[1].data.unshift(newPoint1);
  }

  return seriesDataComposition;
}

export function getTvlChartOptions(tvlData: TvlResponse['data']): ApexOptions {
  return {
    colors: ['#FF7200', '#f3f2f2'],
    fill: {
      opacity: [0.12, 0.2],
    },
    chart: {
      type: 'area',
      stacked: false,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '14px',

      itemMargin: {
        horizontal: 20,
      },
      labels: {
        colors: ['#FF7200', '#f3f2f2'],
        useSeriesColors: false,
      },
      markers: {
        radius: 100,
        width: 8,
        height: 8,
        offsetX: -8,
      },
    },

    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: function ({ series, dataPointIndex }) {
        return `
          <div class="bg-transparent p-1">
          <div class="bg-white text-[#121312] p-2 m-1 text-[14px] leading-[18px] flex flex-col gap-1 rounded-[12px]">
          <div class="flex items-center gap-1 justify-between">
          $${
            abbreviateNumber(series[0][dataPointIndex], 2) +
            ' (' +
            ((series[0][dataPointIndex] * 100) / (series[0][dataPointIndex] + series[1][dataPointIndex])).toFixed(2) +
            '%)'
          }
          <span>${tvlData.metadata?.symbol0?.toUpperCase()}</span>
          </div>
          <div class="flex items-center gap-1 justify-between">
          <div>
          $${
            abbreviateNumber(series[1][dataPointIndex], 2) +
            ' (' +
            ((series[1][dataPointIndex] * 100) / (series[0][dataPointIndex] + series[1][dataPointIndex])).toFixed(2) +
            '%)'
          }
          </div>
          <span>${tvlData.metadata?.symbol1?.toUpperCase()}</span>
          </div>
          <div>
      
        </div>
    
     
          </div>
          </div>
          `;
      },
      fillSeriesColor: false,
      // theme: 'light',
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
        format: 'dd MMM yyyy HH:mm',
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },

      marker: {
        show: false,
      },
      items: {
        display: 'flex',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 10,
        offsetY: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 0, 0],
      curve: 'smooth',
      colors: ['#FF7200', '#f3f2f2'],
    },
    xaxis: {
      labels: {
        rotate: 0,
        style: {
          colors: '#605E67',
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      tickAmount: 6,
    },
    yaxis: {
      labels: {
        style: {
          colors: '#605E67',
        },
        formatter: function (val) {
          const decimals = val > 1000 ? 0 : 2;
          return '$ ' + abbreviateNumber(val, decimals);
        },
      },
    },
    grid: {
      borderColor: ' rgba(41, 40, 45, 1)',
    },
    // @ts-ignore
    series: getTvlChartSeriesFromTvlData(tvlData),
  };
}

export function getTvlChartOptionsForTemporaryVault(
  tvlData: {
    name: string;
    data: (number | Date)[][];
  }[],
  symbol0: string,
  symbol1: string,
): ApexOptions {
  return {
    colors: ['#FF7200', '#f3f2f2'],
    fill: {
      opacity: [0.12, 0.2],
    },
    chart: {
      type: 'area',
      stacked: false,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '14px',

      itemMargin: {
        horizontal: 20,
      },
      labels: {
        colors: ['#FF7200', '#f3f2f2'],
        useSeriesColors: false,
      },
      markers: {
        radius: 100,
        width: 8,
        height: 8,
        offsetX: -8,
      },
    },

    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: function ({ series, dataPointIndex }) {
        return `
          <div class="bg-transparent p-1">
          <div class="bg-white text-[#121312] p-2 m-1 text-[14px] leading-[18px] flex flex-col gap-1 rounded-[12px]">
          <div class="flex items-center gap-1 justify-between">
          $${
            abbreviateNumber(series[0][dataPointIndex], 2) +
            ' (' +
            ((series[0][dataPointIndex] * 100) / (series[0][dataPointIndex] + series[1][dataPointIndex])).toFixed(2) +
            '%)'
          }
          <span>${symbol0?.toUpperCase()}</span>
          </div>
          <div class="flex items-center gap-1 justify-between">
          <div>
          $${
            abbreviateNumber(series[1][dataPointIndex], 2) +
            ' (' +
            ((series[1][dataPointIndex] * 100) / (series[0][dataPointIndex] + series[1][dataPointIndex])).toFixed(2) +
            '%)'
          }
          </div>
          <span>${symbol1?.toUpperCase()}</span>
          </div>
          <div>
      
        </div>
    
     
          </div>
          </div>
          `;
      },
      fillSeriesColor: false,
      // theme: 'light',
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
        format: 'dd MMM yyyy HH:mm',
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },

      marker: {
        show: false,
      },
      items: {
        display: 'flex',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 10,
        offsetY: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 0, 0],
      curve: 'smooth',
      colors: ['#FF7200', '#f3f2f2'],
    },
    xaxis: {
      labels: {
        rotate: 0,
        style: {
          colors: '#605E67',
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      tickAmount: 6,
    },
    yaxis: {
      labels: {
        style: {
          colors: '#605E67',
        },
        formatter: function (val) {
          const decimals = val > 1000 ? 0 : 2;
          return '$ ' + abbreviateNumber(val, decimals);
        },
      },
    },
    grid: {
      borderColor: ' rgba(41, 40, 45, 1)',
    },
    // @ts-ignore
    series: getTvlChartSeriesFromTvlData(tvlData),
  };
}
