function ZapinExplanation() {
  return (
    <div>
      <div>
        <img className="rounded-lg mb-8" src="/assets/images/zapin.jpg" alt="zapin" />
      </div>

      <h2 className="text-lg font-bold mb-1">What is Zapin?</h2>
      <p>
        In order to deposit into a vault, you usually need to have both tokens in your wallet. The Zapin feature allows
        you to deposit into a vault with only one token in your wallet. It will automatically swap the token you have
        for the token you need, and then deposit both tokens into the vault.
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Why should I use it?</h2>
      <p className="mb-2">
        Zapin is a great way to get exposure to a vault without having to worry about swapping tokens yourself. It
        allows you to deposit into a vault with only one token in your wallet.
      </p>

      <h2 className="text-lg font-bold mb-1 mt-5">What is the slippage?</h2>

      <p className="mb-2">
        The slippage is the maximum amount of slippage you are willing to accept when swapping tokens. If the price of
        the token changes by more than the slippage, the transaction will fail. The default slippage is 0.5%.
      </p>

      <h2 className="text-lg font-bold mb-1 mt-5">Why do I sometimes get small refunds?</h2>

      <p className="mb-2">
        When you Zapin, the amount of tokens you receive is based on the current price of the token. If the price of the
        token changes between the time you start the transaction and the time it is confirmed, you may receive a small
        refund, which usually is about 0-1% of the amount you deposited.
      </p>
    </div>
  );
}

export default ZapinExplanation;
