// eslint-disable-next-line import/named
import { Address } from 'wagmi';

import { z } from 'zod';
import { StrategySchema } from './create-vault';
export interface VaultsInfo {
  vaultAddress: string;
  totalUnderlying: {
    amount0: string;
    amount1: string;
    fee0: string;
    fee1: string;
    leftOver0: string;
    leftOver1: string;
  };
  token0: Address;
  token1: Address;
}

export interface Vault {
  id: Address;
  manager: Address;
  name: string;
  owner: Address;
  symbol: string;
  token0: Address;
  token1: Address;
  totalUnderlying?: {
    amount0: string;
    amount1: string;
    fee0: string;
    fee1: string;
    leftOver0: string;
    leftOver1: string;
  } | null;
}

export interface IVault {
  _id: string;
  isClosed: boolean;
  id: string;
  network: string;
  createdAt: string;
  manager: string;
  name: string;
  owner: string;
  creator: string;
  symbol: string;
  symbol0: string;
  symbol1: string;
  token0: string;
  token1: string;
  updatedAt: string;
  version: string;
  token0Decimals: number;
  token1Decimals: number;
  token0LogoUrl: string;
  token1LogoUrl: string;
  price0: number;
  price1: number;
  cmcPrice0: number;
  cmcPrice1: number;
  amount0: string;
  amount1: string;
  feeTier: number;
  fee0: number;
  fee1: number;
  leftOver0: number;
  leftOver1: number;
  averageApr: number;
  aprDate: string;
  liquidity: number;
  totalSupply: string;
  tvl: number;
  chainId: number;
  nativeSymbol0: string;
  nativeSymbol1: string;
  nativePrice0: number;
  nativePrice1: number;
  nativeToken0Url: string;
  nativeToken1Url: string;
  nativeToken0Decimals: number;
  nativeToken1Decimals: number;
  managerFeeBPS?: number;
  pools?: {
    id: string;
    feeTier: number;
  }[];
  gauge?: {
    address: string;
    rewardToken: string;
    rewardLength: number;
    rewardApr: number;
    symbol: string;
    decimals: number;
    name: string;
    logo: string;
    price: number;
    cmcPrice: number;
    numberOfHolders?: number;
  };
  restrictions?: {
    whiteList: string[];
    supplyCapExceeded: boolean;
  };
  formatted?: {
    amount0: string;
    amount1: string;
  };
  performance?: {
    beginningOfYearPerformance: number;
    lastMonthPerformance: number;
    lastThreeMonthsPerformance: number;
    lastSixMonthsPerformance: number;
    lastWeekPerformance: number;
    lastYearPerformance: number;
  };
}

export function isIVault(vault: IVault | TemporaryVault): vault is IVault {
  return (vault as IVault).updatedAt !== undefined;
}

export interface IVaultFilters {
  network?: NetworkName;
  address?: string; // filters for all addresses: manager, owner, id etc
  name?: string;
  onlyGauges?: boolean;
}

export interface IVaultSorting {
  sort: string;
  sortDirection: 'asc' | 'desc';
}

export type NetworkName =
  | 'ethereum'
  | 'polygon'
  | 'arbitrum'
  | 'optimism'
  | 'base'
  | 'gnosis'
  | 'sepolia'
  | 'polygon_shadow'
  | 'arbitrum_shadow'
  | 'optimism_shadow';

export interface FilterParams {
  networks?: NetworkName;
  sort?: string;
  sortDirection?: 'asc' | 'desc';
  search?: string;
  onlyGauges?: boolean;
  name?: string;
}

export interface VaultAnalyticsTvlDataResponse {
  success: boolean;
  data: {
    metadata: {
      symbol0: string;
      symbol1: string;
    };
    current: {
      amount0: number;
      amount1: number;
      value0USD: number;
      value1USD: number;
      valueUSD: number;
    };
    history: {
      timeAt: string;
      amount0: number;
      amount1: number;
      value0USD: number;
      value1USD: number;
      valueUSD: number;
    }[];
  };
  cache: boolean;
}

export interface VaultReturnVsHodlDataResponse {
  success: boolean;
  data: {
    userReturnVsHodl: number | null;
    transfers: {
      timestamp: string;
      amount: string;
      inventory0: string;
      inventory1: string;
      receiver: string;
      receiverBalance: string;
      sender: string;
      senderBalance: string;
      totalSupply: string;
    }[];
  };
}

export interface TokenLogoUrlResponse {
  success: boolean;
  logoUrl: string;
}

export enum TimeFrame {
  ONE_WEEK = '1w',
  ONE_MONTH = '1m',
  SIX_MONTHS = '6m',
  ONE_YEAR = '1y',
}

export function convertTimeFrameToTimeStamp(timeframe: TimeFrame) {
  const currentTime = new Date();

  switch (timeframe) {
    case TimeFrame.ONE_WEEK:
      currentTime.setDate(currentTime.getDate() - 7);
      break;
    case TimeFrame.ONE_MONTH:
      currentTime.setMonth(currentTime.getMonth() - 1);
      break;
    case TimeFrame.SIX_MONTHS:
      currentTime.setMonth(currentTime.getMonth() - 6);
      break;
    case TimeFrame.ONE_YEAR:
      currentTime.setFullYear(currentTime.getFullYear() - 1);
      break;
    default:
      break;
  }

  const timestamp = currentTime.toISOString();
  return timestamp;
}

export interface NetworkFilter {
  name: string;
  key: NetworkName | null | undefined;
  logoInnerHtml: string;
}

export enum DepositFormWrapperState {
  DEFAULT = 'DEFAULT',
  DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS',
  SAFE_APP_DEPOSIT = 'SAFE_APP_DEPOSIT',
}

export enum WithdrawFormWrapperState {
  DEFAULT = 'DEFAULT',
  WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS',
  SAFE_APP_WITHDRAW = 'SAFE_APP_WITHDRAW',
}

export type QueryParams = {
  name?: string;
  networks?: string;
  sort?: string;
  sortDirection?: string;
  onlyGauges?: string;
};

export const SLIPPAGES = ['0.1', '0.25', '0.5', '1.0', '2.5'];

export type Slippages = (typeof SLIPPAGES)[number];

export type SwapAndAddLiquidityArgs = [
  {
    swapData: {
      swapPayload: string;
      amountInSwap: string;
      amountOutSwap: string;
      swapRouter: string;
      zeroForOne: boolean;
    };
    addData: {
      amount0Max: string;
      amount1Max: string;
      amount0Min: string;
      amount1Min: string;
      amountSharesMin: string;
      vault: string;
      receiver: string;
      gauge: string;
    };
  },
];

export type SwapAndAddLiquidityPermit2Args = [
  {
    swapAndAddData: {
      swapData: {
        swapPayload: string;
        amountInSwap: string;
        amountOutSwap: string;
        swapRouter: string;
        zeroForOne: boolean;
      };
      addData: {
        amount0Max: string;
        amount1Max: string;
        amount0Min: string;
        amount1Min: string;
        amountSharesMin: string;
        vault: string;
        receiver: string;
        gauge: string;
      };
    };
    permit: {
      permitted: {
        token: string;
        amount: bigint;
      }[];
      nonce: bigint;
      deadline: bigint;
      // spender: string;
    };
    signature: string;
  },
];

export enum Status {
  APPROVE_0 = 'Approve_0',
  APPROVE_1 = 'Approve_1',
  SIGN = 'Sign',
  DEPOSIT = 'Deposit',
}

export enum CreateVaultTransactionStatus {
  APPROVE_0 = 'Approve_0',
  APPROVE_1 = 'Approve_1',
  TRANSACTION = 'Transaction',
}

export interface DetermineStatusProps {
  useNativeToken: boolean;
  nativeSymbol0: string | undefined;
  nativeSymbol1: string | undefined;
  isZapin: boolean;
  selectedZapinToken: 0 | 1;
  signatureStandard: string | null;
  signatureNative: string | null;
  token0Allowance: bigint | undefined;
  token1Allowance: bigint | undefined;
  parsedInp0: bigint;
  parsedInp1: bigint;
}

export enum Explanation {
  BASE_TOKEN = 'baseToken',
  FEE_TIER = 'feeTier',
  GAS_PRICE = 'gasPrice',
  GAS_TANK = 'gasTank',
  LIQUIDITY_CONCENTRATION = 'liquidityConcentration',
  REBALANCE_SPEED = 'rebalanceSpeed',
  STRATEGY_JSON = 'strategyJson',
  TOKEN = 'token',
  TOKEN_AMOUNTS = 'tokenAmounts',
  TOKEN_RATIO = 'tokenRatio',
  TVL = 'tvl',
  TWAP_DURATION = 'twapDuration',
  MAX_TWAP_DEVIATION = 'maxTwapDeviation',
  NETWORK = 'network',
  VAULT_OWNER = 'vaultOwner',
  ZAPIN = 'zapin',
  REFUND = 'refund',
  VAULT_TYPE = 'vaultType',
}

export const UnderlyingBalancesSchema = z.array(z.optional(z.bigint()), z.optional(z.bigint()));

export type UnderlyingBalances = z.infer<typeof UnderlyingBalancesSchema>;

export const TemporaryVaultSchema = z.object({
  id: z.string(),
  token0: z.string(),
  token1: z.string(),
  network: z.string(),
  name: z.string(),
  symbol0: z.string(),
  symbol1: z.string(),
  price0: z.number(),
  price1: z.number(),
  token0LogoUrl: z.string(),
  token1LogoUrl: z.string(),
  version: z.string(),
  manager: z.string(),
  owner: z.string(),
  creator: z.string(),
  isClosed: z.boolean(),
  gasTank: z.number(),
  chainId: z.number(),
  totalSupply: z.string(),
  token0Decimals: z.number(),
  token1Decimals: z.number(),
  tvl: z.number(),
  amount0: z.number(),
  amount1: z.number(),
  feeTier: z.number(),
  strategy: z.nullable(StrategySchema),
});

export type TemporaryVault = z.infer<typeof TemporaryVaultSchema>;

export const SetupVaultEventSchema = z.array(
  z.object({
    address: z.string(),
    args: z.object({
      creator: z.string(),
      vault: z.string(),
    }),
    eventName: z.string(),
    logIndex: z.number(),
  }),
);

export type SetupVaultEvent = z.infer<typeof SetupVaultEventSchema>;
