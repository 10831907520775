import { connectorsForWallets, darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import {
  coinbaseWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  rainbowWallet,
  safeWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { configureChains, createConfig, sepolia, WagmiConfig } from 'wagmi';
import { arbitrum, base, gnosis, mainnet, optimism, polygon } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import './global.css';
import './polyfills';
import { router } from './router';

import { createTheme, MantineProvider } from '@mantine/core';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics } from '@vercel/analytics/react';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import ConfettiAnimation from './components/ConfettiAnimation';
import Layout from './components/layout/Layout';
import { ConfettiProvider } from './context/ConfettiContext';
import SettingsContextProvider from './context/SettingsContext';

const queryClient = new QueryClient();

if (import.meta.env.VITE_GA4_ID) {
  ReactGA.initialize(import.meta.env.VITE_GA4_ID);
}

const { chains, publicClient } = configureChains(
  [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    { ...base, iconUrl: 'https://assets.coingecko.com/asset_platforms/images/131/small/base.jpeg' },
    { ...gnosis, iconUrl: 'https://assets.coingecko.com/asset_platforms/images/11062/small/Aatar_green_white.png' },
    sepolia,
  ],
  [
    alchemyProvider({
      apiKey:
        import.meta.env.VITE_ALCHEMY_ID || import.meta.env.DEV
          ? 'tA7hjUBFtWhYkdrhUtLjsAL8JkQVJkKL'
          : 'llk0GbNvQwja1DT4C204PNAIRERUS51R',
    }),
    publicProvider(),
  ],
);

export const viemClient = publicClient;

const projectId = '696f51164e388610f6f33a41911dddea';

const connectors = connectorsForWallets([
  {
    groupName: 'Connect a wallet',
    wallets: [
      rainbowWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName: 'Arrakis V1 PALM User Interface' }),
      walletConnectWallet({ projectId, chains }),
      okxWallet({ projectId, chains }),
      safeWallet({ chains }),
      rabbyWallet({ chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const theme = createTheme({
  /** Put your mantine theme override here */
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          showRecentTransactions={true}
          chains={chains}
          theme={darkTheme({
            accentColor: '#FFA760',
            accentColorForeground: '#121312',
            fontStack: 'rounded',
          })}
          appInfo={{
            appName: 'Arrakis Finance - Web App',
            learnMoreUrl: 'https://resources.arrakis.fi',
          }}
        >
          <SettingsContextProvider>
            <MantineProvider theme={theme}>
              <Layout>
                <ConfettiProvider>
                  <ConfettiAnimation />
                  <RouterProvider router={router} />
                </ConfettiProvider>
              </Layout>
            </MantineProvider>
          </SettingsContextProvider>
        </RainbowKitProvider>
      </WagmiConfig>
      <ToastContainer autoClose={8000} />
      <Analytics />
    </QueryClientProvider>
  </React.StrictMode>,
);
