import { ManagerView } from '../managerView';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import { track } from '@vercel/analytics/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { IVault } from '../../../types';
import { abbreviateNumber, capitalizeFirstLetter } from '../../../utils/n-formatter';
import { getVaultProportions } from '../../../utils/vault';

interface VaultListItemProps {
  vault: IVault & { userBalance?: string };
  withNavigation?: boolean;
  selectedVaultId?: string | undefined;
  refetch: () => void;
}

export function VaultListItem({ vault, selectedVaultId, withNavigation, refetch }: VaultListItemProps) {
  const [showManager, setShowManager] = useState(selectedVaultId === vault.id);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setShowManager(selectedVaultId === vault.id);
  }, [selectedVaultId, vault]);

  const { tvl, symbol0, symbol1 } = vault;

  const { percentage0Str, percentage1Str } = getVaultProportions(vault);

  const navigate = useNavigate();

  const { address } = useAccount();

  const shortenedVaultName = vault.name.replace('Arrakis Vault V1', '').replace('Gelato Uniswap', '').replace('LP', '');

  return (
    <div className="flex flex-col w-full p-6 min-h-[130px] relative bg-vault-black rounded-[24px] text-vault-white bg-opacity-80 hover:bg-opacity-80 hover:bg-[#1F1F21] duration-500 ease-in-out">
      <div className="flex flex-col md:flex-row gap-3 justify-between">
        {/* Token Logos */}
        <div className="flex min-w-[260px]">
          <img
            src={vault.token0LogoUrl}
            alt={symbol0}
            className="rounded-full max-w-[40px] max-h-[40px] align-middle mx-0.5 mt-1 object-cover"
          />
          <img
            src={vault.token1LogoUrl}
            alt={symbol1}
            className="rounded-full max-w-[40px] max-h-[40px] align-middle mt-1 object-cover"
          />

          <div className="flex flex-col mx-2 ">
            <p className="text-vault-gray">{capitalizeFirstLetter(vault?.network)}</p>
            <p className=" text-[20px]">{shortenedVaultName}</p>
          </div>
        </div>

        {/* Fee Tier */}
        <div className="min-w-[100px]">
          <p className="text-vault-gray">Fee Tier</p>
          <div className="flex flex-col">
            <p className="mx-1 text-[20px]">{vault.feeTier ? `${vault.feeTier}%` : ''}</p>
          </div>
        </div>

        {/* TVL */}
        <div className="min-w-[190px]">
          <p className="text-vault-gray">TVL</p>
          <div className="flex flex-col">
            <p className="mx-1 text-[20px]">{tvl ? '$' + abbreviateNumber(tvl) : 'n/a'}</p>
            <p className="text-vault-gray mx-1 text-[14px] mt-1">
              {percentage0Str}% {symbol0.toUpperCase()} - {percentage1Str}% {symbol1.toUpperCase()}
            </p>
          </div>
        </div>

        {/* Fee APR */}
        <div className="min-w-[80px]">
          <p className="text-vault-gray">Fee APR</p>
          <div className="flex flex-row">
            <p className="mx-1 text-[20px]">{vault.averageApr.toFixed(2)}%</p>
          </div>
        </div>

        {/* Reward APR */}
        <div className="min-w-[150px]">
          {vault.gauge && (
            <div>
              {' '}
              <p className="text-vault-gray">Reward APR</p>
              <div className="flex flex-row">
                <div>
                  <div className="flex items-center gap-1">
                    <p className="text-[20px]">{(vault.gauge?.rewardApr || 0).toFixed(2)}%</p>
                    <img
                      src={vault.gauge?.logo}
                      alt={vault.gauge?.name}
                      className="rounded-full max-w-[20px] max-h-[20px] align-middle"
                    />
                  </div>
                  <p className="text-vault-gray text-[14px] mt-1">
                    Rewards {Number(vault.gauge?.rewardApr) > 0 ? 'active' : 'ended'}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Position */}
        {!!vault.userBalance && (
          <div>
            <p className="text-vault-gray">My Position</p>
            <div className="flex flex-row">
              <p className="mx-1 text-[20px]">${vault.userBalance}</p>
            </div>
          </div>
        )}

        {/* Expand/Collapse */}
        <div className="flex justify-end ">
          <button>
            {showManager ? (
              <ChevronUpIcon
                onClick={() => {
                  if (withNavigation) {
                    navigate(`/vaults?${searchParams.toString()}`);
                  } else {
                    setShowManager(false);
                  }
                }}
                className="h-6 w-6 text-vault-gray-hover"
              />
            ) : (
              <ChevronDownIcon
                onClick={() => {
                  if (withNavigation) {
                    track('expand vault', {
                      type: 'click',
                      feature: 'vault',
                      wallet: address as string,
                      vault: vault.id,
                    });

                    navigate(`/vaults/${vault.id}?${searchParams.toString()}`);
                  } else {
                    setShowManager(true);
                  }
                }}
                className="h-6 w-6 text-vault-gray-hover"
              />
            )}
          </button>
        </div>
      </div>
      {/* Expanded View */}

      {showManager && <ManagerView vault={vault} refetchInvestedVaults={refetch} />}
    </div>
  );
}
