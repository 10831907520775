import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Spinner } from '../spinner';

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {
  isLoading?: boolean;
}

const PrimaryButton: React.FC<ButtonProps> = (props) => {
  const { children, isLoading, ...rest } = props;

  return (
    <button
      {...rest}
      type="button"
      className={`${twMerge(
        'bg-gradient-orange text-primary-black text-[16px] px-[16px] py-[14px] rounded-[12px] disabled:bg-vault-gray-2 disabled:text-vault-gray-hover disabled:bg-gradient-to-r flex items-center justify-center gap-2 whitespace-nowrap',
        props.className,
      )}`}
    >
      <div>{children}</div>
      {isLoading && (
        <span className="mx-2">
          <Spinner color="vault-white" />
        </span>
      )}
    </button>
  );
};

export default PrimaryButton;
