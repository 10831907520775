import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { VolumeResponseSchema } from '../../types/charts/volume';
import { getVolumesChartOptions } from '../../utils/charts/volume';
import ChartLoader from './ChartLoader';
import NoData from './NoData';

interface VolumeChartProps {
  vaultId: string;
}

function VolumeChart({ vaultId }: VolumeChartProps) {
  async function fetchVaultVolumes(vaultId: string) {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/analytics/v2/volumes/${vaultId}`);

      const parsedData = VolumeResponseSchema.safeParse(response.data);

      if (!parsedData.success) {
        return undefined;
      }

      return parsedData.data.data;
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  }

  const { data: volumesData, isLoading } = useQuery(['vault-volumes', vaultId], async () => {
    return await fetchVaultVolumes(vaultId);
  });

  const options = volumesData ? getVolumesChartOptions(volumesData) : {};

  return (
    <div className="flex items-center gap-5">
      <div className="grow">
        {isLoading && <ChartLoader />}
        {volumesData && volumesData.pool.length > 1 && options.series && (
          <ReactApexChart options={options} series={options.series} height={380} type="bar" />
        )}
        {(!volumesData || volumesData.pool.length <= 1) && !isLoading && <NoData />}
      </div>
      {/*  <div className="flex flex-col gap-4">
        <ChartStat title="Value of the Generated Fees" value="$179.25k" />
        <ChartStat title="Generated USDC Fees" value="26.69 wETH" />
        <ChartStat title="Generated GEL Fees" value="266,832.00 GEL" />
      </div> */}
    </div>
  );
}

export default VolumeChart;
