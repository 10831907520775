import React from 'react';
import { SLIPPAGES, Slippages } from '../../../../types';

interface SlippageSelectionProps {
  activeSlippage: Slippages;
  setActiveSlippage: React.Dispatch<React.SetStateAction<Slippages>>;
}

function SlippageSelection({ activeSlippage, setActiveSlippage }: SlippageSelectionProps) {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center gap-2 mt-6">
      <div className="text-[16px] text-white">Slippage</div>
      <div className="flex items-center gap-[4px]">
        {SLIPPAGES.map((slippage, index) => (
          <button
            type="button"
            key={index}
            onClick={() => {
              setActiveSlippage(slippage);
            }}
            className={`border rounded-[8px] px-[8px] py-[3px] text-[13px] ${
              activeSlippage === slippage
                ? 'bg-vault-light-gray text-vault-black-primary border border-vault-light.gray'
                : 'border-vault-gray-hover text-vault-gray-hover hover:text-vault-white hover:border-vault-white duration-300'
            }`}
          >
            {slippage}%
          </button>
        ))}
      </div>
    </div>
  );
}

export default SlippageSelection;
