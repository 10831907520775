import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { truncateEthAddress } from '../../../utils/n-formatter';
// eslint-disable-next-line import/no-unresolved
import IconRight from '/assets/images/icon-right.svg';

interface VaultAddressLinkProps {
  chainId: number;
  address: string;
  text: string;
}

function VaultAddressLink({ chainId, address, text }: VaultAddressLinkProps) {
  return (
    <div className="flex flex-col sm:flex-row items-left sm:items-center gap-1">
      <div className="text-vault-gray-hover text-[16px] tracking-[-0.01em]">{text}</div>
      <div className="text-vault-light-gray text-[16px]  tracking-[-0.01em] flex items-center gap-2">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={getExplorerLink(chainId, address, ExplorerDataType.ADDRESS)}
          className="flex items-center gap-2"
        >
          {truncateEthAddress(address.toLowerCase())} <img src={IconRight} alt="check-icon" />
        </a>
      </div>
    </div>
  );
}

export default VaultAddressLink;
