import { Token } from '@uniswap/sdk-core';
import { Pool } from '@uniswap/v3-sdk';
import { UniV3FactoryAbi } from '../../../../abis/UniV3Factory';
import { UniswapPoolAbi } from '../../../../abis/UniswapPool';
import { PoolCreationStatus, getMinimumCardinalityByChainId, getUniswapV3FactoryAddress } from '../../../../constants';
import { useBatchTransactions } from '../../../../hooks/useBatchTransactions';
import { ExplorerDataType, getExplorerLink } from '../../../../utils/get-explorer-link';
import { feeTierToFeeAmount } from '../../../../utils/vault-creation';
import PrimaryButton from '../../../elements/buttons/PrimaryButton';
import { alertUser } from '../../../elements/notify';

interface CreatePoolButtonProps {
  token0: string;
  token1: string;
  feeTier: number;
  chainId: number;
  token0Decimals: number;
  token1Decimals: number;
  sqrtPriceX96?: bigint;
  status: PoolCreationStatus;
  disabled?: boolean;
}

function CreatePoolButton({
  token0,
  token1,
  feeTier,
  chainId,
  token0Decimals,
  token1Decimals,
  sqrtPriceX96,
  status,
  disabled = false,
}: CreatePoolButtonProps) {
  if (!sqrtPriceX96) {
    console.error('We could not fetch the uniswap price data of your pool. Please try again later.');
    return <div>We could not fetch the uniswap price data of your pool. Please try again later.</div>;
  } else {
    return (
      <CreatePool
        token0={token0}
        token1={token1}
        feeTier={feeTier}
        chainId={chainId}
        token0Decimals={token0Decimals}
        token1Decimals={token1Decimals}
        status={status}
        sqrtPriceX96={sqrtPriceX96}
        disabled={disabled}
      />
    );
  }
}

interface CreatePoolProps {
  token0: string;
  token1: string;
  feeTier: number;
  chainId: number;
  token0Decimals: number;
  token1Decimals: number;
  sqrtPriceX96: bigint;
  status: PoolCreationStatus;
  disabled?: boolean;
}

function CreatePool({
  token0,
  token1,
  feeTier,
  chainId,
  token0Decimals,
  token1Decimals,
  sqrtPriceX96,
  status,
  disabled = false,
}: CreatePoolProps) {
  const poolAddress = Pool.getAddress(
    new Token(chainId, token0, token0Decimals),
    new Token(chainId, token1, token1Decimals),
    feeTierToFeeAmount(feeTier),
    undefined,
    chainId === 8453 || chainId === 11155111 ? getUniswapV3FactoryAddress(chainId) : undefined,
  );

  const cardinality = getMinimumCardinalityByChainId(chainId);

  function getCalls() {
    const createPool = {
      target: getUniswapV3FactoryAddress(chainId),
      abi: UniV3FactoryAbi,
      functionName: 'createPool',
      args: [token0, token1, feeTier],
    };

    const initialize = {
      target: poolAddress,
      abi: UniswapPoolAbi,
      functionName: 'initialize',
      args: [sqrtPriceX96],
    };

    const increaseObservationCardinalityNext = {
      target: poolAddress,
      abi: UniswapPoolAbi,
      functionName: 'increaseObservationCardinalityNext',
      args: [cardinality],
    };

    switch (status) {
      case PoolCreationStatus.NEW:
        return [createPool, initialize, increaseObservationCardinalityNext];
      case PoolCreationStatus.POOL_CREATED:
        return [initialize, increaseObservationCardinalityNext];
      case PoolCreationStatus.POOL_INITIALIZED:
        return [increaseObservationCardinalityNext];
      default:
        return [createPool, initialize, increaseObservationCardinalityNext];
    }
  }

  const { write: createPool, isLoading: isLoadingCreatePool } = useBatchTransactions({
    // @ts-ignore
    calls: getCalls(),
    chainId,
    onSuccess(hash) {
      alertUser(
        'success',
        'Pool created!',
        getExplorerLink(chainId || 1, hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        false,
      );
    },
  });

  return (
    <PrimaryButton disabled={disabled} onClick={() => createPool?.()} isLoading={isLoadingCreatePool}>
      Accept Price &{status === PoolCreationStatus.NEW && ' Create Pool'}
      {status === PoolCreationStatus.POOL_CREATED && ' Initialize Pool'}
      {status === PoolCreationStatus.POOL_INITIALIZED && ' Increase Observation Cardinality'}
    </PrimaryButton>
  );
}

export default CreatePoolButton;
