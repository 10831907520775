import { z } from 'zod';
import { LiquidityConcentration, RebalanceSpeed } from './create-vault';

export const ReverseJsonStrategyResponseSchema = z.object({
  success: z.boolean(),
  concentrationConfig: z.nativeEnum(LiquidityConcentration),
  rebalanceConfig: z.nativeEnum(RebalanceSpeed),
  maxGasPrice: z.number(),
  twapDuration: z.number(),
  maxTwapDeviation: z.number(),
});

export type ReverseJsonStrategyResponse = z.infer<typeof ReverseJsonStrategyResponseSchema>;
