import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAccount, useContractRead } from 'wagmi';
import { ArrakisV1GaugeAbi } from '../../../abis/ArrakisV1Gauge';
import { IVault } from '../../../types';
import { URL } from '../../../utils/api';
import { formatBigNumber } from '../../../utils/format-big-number';
import { numberWithCommas } from '../../../utils/n-formatter';

interface CustomOpWithdrawResultProps {
  vault: IVault;
}

function CustomOpWithdrawResult({ vault }: CustomOpWithdrawResultProps) {
  const { address: ownAddress } = useAccount();

  const rewardTokenAddress = '0x4200000000000000000000000000000000000042';
  const rewardTokenChainId = 10;
  const rewardTokenDecimals = 18;
  const rewardTokenSymbol = 'OP';

  const { data: claimableRewards, isLoading: isLoadingClaimableRewards } = useContractRead({
    address: vault.gauge?.address as '0x${string}',
    abi: ArrakisV1GaugeAbi,
    functionName: 'claimable_reward',
    args: [ownAddress, rewardTokenAddress],
    chainId: rewardTokenChainId,
    select: (data) => {
      const x = data
        ? Number(formatBigNumber(BigInt(data.toString()), rewardTokenDecimals || 18, rewardTokenDecimals))
        : 0;

      return x;
    },
    //select: data => data as bigint
    cacheTime: 10000,
  });

  // get price of reward token
  const { data: opPrice, isLoading: isLoadingOpPrice } = useQuery(
    ['vault-0x3dc6a6ef1836f02d9ff29212c22e71fa3b74d1a9'],
    async () => {
      const res = await axios.get<any>(`${URL}/api/vault/single/0x3dc6a6ef1836f02d9ff29212c22e71fa3b74d1a9`);
      return res?.data?.vault[0]?.gauge?.price;
    },
  );

  const claimableRewardsUsd = (claimableRewards ?? 0) * (opPrice ?? 0);

  return (
    <div className="flex gap-3 flex-col">
      {vault.gauge && !isLoadingOpPrice && !isLoadingClaimableRewards && (
        <div className="flex justify-between items-center rounded-[16px] border border-[#616068] px-5 py-4 text-[20px]">
          <div className="text-[#8B8993] ">Rewards:</div>
          <div className="flex items-center gap-2  text-[#D4D3D3]">
            <div>
              {numberWithCommas(claimableRewards || 0)} {rewardTokenSymbol.toUpperCase() ?? ''}
            </div>
            <div className="text-[#605E67]">${numberWithCommas(claimableRewardsUsd.toFixed(2))}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomOpWithdrawResult;
