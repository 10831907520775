import { UseFormRegister } from 'react-hook-form';
import { IVault, Slippages, TemporaryVault, isIVault } from '../../../../types';
import { numberWithCommas, shortenNumber } from '../../../../utils/n-formatter';
import { IDepositFormInput } from '../DepositFormV2';
import SlippageSelection from './SlippageSelection';
import Zapin from './Zapin';

interface InputFieldProps {
  vault: IVault | TemporaryVault;
  errorMessage: string | undefined;
  showError: boolean;
  register: UseFormRegister<IDepositFormInput>;
  setMaxToken: () => void;
  balanceValue: string;
  onValueChanged?: (type: 'token0' | 'token1', eventKey: string) => void;
  tokenType: 'token0' | 'token1';
  tokenInputUsd: number;
  symbol: string;
  symbol0?: string;
  symbol1?: string;
  isZapin: boolean;
  setSelectedZapinToken?: React.Dispatch<React.SetStateAction<0 | 1>>;
  zapinSlippageTolerance?: Slippages;
  setZapinSlippageTolerance?: React.Dispatch<React.SetStateAction<Slippages>>;
}

function InputField({
  vault,
  register,
  setMaxToken,
  balanceValue,
  onValueChanged,
  tokenType,
  tokenInputUsd,
  symbol,
  symbol0,
  symbol1,
  showError,
  errorMessage,
  isZapin,
  setSelectedZapinToken,
  zapinSlippageTolerance,
  setZapinSlippageTolerance,
}: InputFieldProps) {
  const logoUrl = tokenType === 'token0' ? vault.token0LogoUrl : vault.token1LogoUrl;
  const registerType = tokenType === 'token0' ? 'token0Input' : 'token1Input';
  const price0 = isIVault(vault) ? vault.price0 ?? vault.cmcPrice0 : vault.price0;
  const price1 = isIVault(vault) ? vault.price1 ?? vault.cmcPrice1 : vault.price1;
  const price = tokenType === 'token0' ? price0 : price1;

  return (
    <>
      <fieldset
        className={`rounded-[16px] px-4 py-5 bg-vault-gray-2 border min-h-[110px] bg-opacity-60 ${
          showError ? 'border-red-400' : 'border-none'
        }`}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <img
              src={logoUrl}
              alt={symbol}
              className="rounded-full max-w-[20px] max-h-[20px] align-middle border-green mx-0.5"
            />
            <span className="ml-2 min-w-[60px]">{symbol ?? ''}</span>
            {setSelectedZapinToken && (
              <Zapin
                vault={vault}
                setSelectedZapinToken={setSelectedZapinToken}
                symbol0={symbol0 || ''}
                symbol1={symbol1 || ''}
              />
            )}
          </div>
          <input
            {...register(registerType, {
              valueAsNumber: true,
              required: "Can't be empty",
              min: !isZapin
                ? {
                    value: 0.000000000000000001,
                    message: 'Minimum value is 0.000000000000000001',
                  }
                : undefined,
              max: {
                value: Number(balanceValue),
                message: 'Not Enough Funds',
              },
            })}
            onKeyUp={(e) => onValueChanged?.(tokenType, e.key)}
            type="number"
            step="0.000000000000000001"
            placeholder="0.00"
            className="max-w-[200px] sm:max-w-none  bg-transparent text-right text-2xl text-white border-transparent focus:border-transparent focus:ring-0"
          />
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="text-vault-gray-hover flex flex-col sm:flex-row sm:items-center gap-1">
            <div className="mr-1">Balance:</div>
            <div>
              {numberWithCommas(shortenNumber(Number(balanceValue))) +
                ' ' +
                symbol +
                ' ≈ $' +
                numberWithCommas((price * Number(balanceValue)).toFixed(2))}
            </div>
            <button
              type="button"
              onClick={setMaxToken}
              className="border-solid border-vault-lite text-vault-light border-2 rounded-lg px-1 sm:ml-2 w-12"
            >
              Max
            </button>
          </div>
          <div>
            <div>
              <div className="text-vault-gray-hover">${numberWithCommas(tokenInputUsd.toFixed(2))}</div>
            </div>
          </div>
        </div>
        {zapinSlippageTolerance && setZapinSlippageTolerance && (
          <SlippageSelection activeSlippage={zapinSlippageTolerance} setActiveSlippage={setZapinSlippageTolerance} />
        )}
      </fieldset>
      {showError && errorMessage && <div className="text-red-400 text-sm mt-2">{errorMessage}</div>}
    </>
  );
}

export default InputField;
