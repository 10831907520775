import Skeleton from 'react-loading-skeleton';

function ChartLoader() {
  return (
    <div className="flex flex-col mx-2 gap-4">
      <Skeleton
        style={{ borderRadius: '12px' }}
        height="288px"
        width="655px"
        baseColor="#292828"
        highlightColor="#6e6d6d"
        duration={0.9}
      />
    </div>
  );
}

export default ChartLoader;
