import { useAccount } from 'wagmi';
import PrimaryButton from '../../elements/buttons/PrimaryButton';

function FeedbackBanner() {
  const { address } = useAccount();

  return (
    <div className="mt-10 max-w-full w-full rounded-[24px] bg-gradient-to-r p-[1px] from-[#ffa760] to-[#f45020] relative duration-300 opacity-80 hover:opacity-100">
      <div className="flex justify-between h-full bg-gradient-primary text-white rounded-[24px] p-4">
        <div className="flex flex-col sm:flex-row items-center gap-3 justify-between w-full">
          <div className="text-center sm:text-left">
            We would love to hear your feedback on the vault creation process!
          </div>
          <a href={`https://qtbwfilnd5m.typeform.com/to/IvK2bZGf?wallet=${address}`} target="blank">
            <PrimaryButton> Give Feedback</PrimaryButton>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FeedbackBanner;
