import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { SignatureTransfer } from '@uniswap/permit2-sdk';
import { track } from '@vercel/analytics/react';
import { erc20ABI, signTypedData } from '@wagmi/core';
import * as bigintConversion from 'bigint-conversion';
import { RangeSlider } from 'flowbite-react';
import { useEffect, useMemo, useState } from 'react';
import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ArrakisV2Abi } from '../../../abis/ArrakisV2';
import { ArrakisV2RouterAbi } from '../../../abis/ArrakisV2Router';
import { ARRAKIS_V2_ROUTER_ADDRESSES, PERMIT_2_ADDRESS } from '../../../constants';
import { useConfetti } from '../../../context/ConfettiContext';
import { IVault, WithdrawFormWrapperState } from '../../../types';
import { hasVaultNativeToken } from '../../../utils/blockchain-communication';
import { vaultTokenValueToUsd } from '../../../utils/calculate-tvl';
import { formatBigNumber } from '../../../utils/format-big-number';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { getRandomInt } from '../../../utils/math';
import { numberWithCommas } from '../../../utils/n-formatter';
import { alertUser } from '../../elements/notify';
import { Spinner } from '../../elements/spinner';
import WithdrawWrapper from '../../hoc/withdraw-form-wrapper';
import WithdrawResult from '../../vault/withdraw-result';
import Toggle from './Toggle/Toggle';
// eslint-disable-next-line import/no-unresolved
import CheckIcon from '/assets/images/icon-check-2.svg';

interface WithDrawFormProps {
  vault: IVault;
  userBalance: bigint;
  underlyingBalances: ({ amount0Current: bigint; amount1Current: bigint } & readonly [bigint, bigint]) | undefined;
  claimableRewards: number | undefined;
}

enum Status {
  APPROVE = 'Approve',
  SIGN = 'Sign',
  WITHDRAW = 'Withdraw',
}

function WithDrawFormV2({ vault, userBalance, underlyingBalances, claimableRewards }: WithDrawFormProps) {
  const [withdrawAmount, setwithdrawAmount] = useState(0);
  const [status, setStatus] = useState<Status>(Status.APPROVE);
  const [usdToBeRemoved, setUsdToBeRemoved] = useState(0);
  const [isSignatureLoading, setIsSignatureLoading] = useState(false);
  const [nonce] = useState(getRandomInt(1, 9999999999).toString());
  const [removeLiquidityArgs, setRemoveLiquidityArgs] = useState<any>([]);
  const [signature, setSignature] = useState<string | null>(null);

  const { address: ownAddress, connector } = useAccount();

  const [isLoadingSafeWallet, setLoadingSafeWallet] = useState(false);
  const [withdrawFormWrapperState, setWithdrawFormWrapperState] = useState(WithdrawFormWrapperState.DEFAULT);
  const isSafeWallet = connector?.id === 'safe';

  const { hasNativeToken, nativeSymbol0, nativeSymbol1 } = hasVaultNativeToken(vault);
  const [useNativeToken, setUseNativeToken] = useState(false);

  const { startConfettiAnimation } = useConfetti();

  const userBalanceUsd = vaultTokenValueToUsd(vault, userBalance) ?? 0;
  const totalSupplyBN = BigInt(vault.totalSupply?.toString() ?? '0');

  // how many tokens 0/1 the user will receive with current withdraw percentage
  const receivableAmount0 =
    withdrawAmount > 0
      ? ((((underlyingBalances?.[0] ?? BigInt(0)) * userBalance) / totalSupplyBN) * BigInt(withdrawAmount)) /
        BigInt(100)
      : BigInt(0);

  const receivableAmount1 =
    withdrawAmount > 0
      ? ((((underlyingBalances?.[1] ?? BigInt(0)) * userBalance) / totalSupplyBN) * BigInt(withdrawAmount)) /
        BigInt(100)
      : BigInt(0);

  const receivableAmount0String = receivableAmount0 ? formatBigNumber(receivableAmount0, vault.token0Decimals) : '0';
  const receivableAmount1String = receivableAmount1 ? formatBigNumber(receivableAmount1, vault.token1Decimals) : '0';

  // allowances: check if user has already approved tokens to permit contract
  // this is different to other pages where we check if user has approved tokens to router contract

  // allowances: check if user has already approved withdrawal
  const { data: allowance } = useContractRead({
    address: (vault.gauge?.address ?? vault.id) as '0x${string}',
    abi: erc20ABI,
    chainId: vault.chainId,
    functionName: 'allowance',
    args: [(ownAddress as any) ?? BigInt(0), PERMIT_2_ADDRESS],
    watch: true,
  });

  // set correct withdraw status
  useEffect(() => {
    let newStatus = Status.APPROVE;

    if (allowance && (userBalance * BigInt(withdrawAmount)) / BigInt(100) <= allowance) {
      newStatus = Status.SIGN;
    }

    if (newStatus === Status.SIGN && signature) {
      newStatus = Status.WITHDRAW;
    }

    setStatus(newStatus);
  }, [allowance, withdrawAmount, signature, userBalance]);

  // approving tokens
  const addRecentTransaction = useAddRecentTransaction();

  const { config: approveWithdtrawConfig } = usePrepareContractWrite({
    address: (vault.gauge?.address ? vault.gauge.address : vault.id) as '0x${string}',
    abi: ArrakisV2Abi,
    functionName: 'approve',
    args: [PERMIT_2_ADDRESS, BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')],
    chainId: vault.chainId,
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
  });

  const {
    write: approveWithdraw,
    data: approveWithdrawData,
    isLoading: isLoadingApproveWithdraw,
  } = useContractWrite({
    ...(approveWithdtrawConfig as any),
    onError(error) {
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isLoadingApproveWithdrawAfter } = useWaitForTransaction({
    hash: approveWithdrawData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      alertUser(
        'success',
        'Transaction successful',
        getExplorerLink(vault.chainId || 137, approveWithdrawData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('approve withdraw v2', {
        type: 'click',
        feature: 'vault withdraw form v2',
        wallet: ownAddress as string,
        vault: vault.id,
      });

      addRecentTransaction({
        hash: approveWithdrawData?.hash ?? '',
        description: 'Approve withdraw',
      });
    },
  });

  // withdraw transaction

  const burnAmount = (userBalance * BigInt(withdrawAmount)) / BigInt(100);

  const amount0Min = useMemo(
    () => (((burnAmount * receivableAmount0) / totalSupplyBN) * BigInt(95)) / BigInt(100),
    [burnAmount, receivableAmount0, totalSupplyBN],
  );

  const amount1Min = useMemo(
    () => (((BigInt(burnAmount) * receivableAmount1) / totalSupplyBN) * BigInt(95)) / BigInt(100),
    [burnAmount, receivableAmount1, totalSupplyBN],
  );

  const { config: withdrawConfig } = usePrepareContractWrite({
    address: ARRAKIS_V2_ROUTER_ADDRESSES,
    abi: ArrakisV2RouterAbi,
    chainId: vault.chainId,
    functionName: 'removeLiquidityPermit2',
    args: removeLiquidityArgs,
    enabled: !!ownAddress && status === Status.WITHDRAW,
    onError(error: any) {
      console.log('Error here', error);
    },
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
  });

  console.log('withdrawConfig', withdrawConfig);

  const deadlineIn30Days = (BigInt(new Date().getTime() + 60 * 60 * 24 * 30 * 1000) / BigInt(1000)).toString();

  const deadlineIn30DaysHex = useMemo(() => '0x' + bigintConversion.bigintToHex(BigInt(deadlineIn30Days)), []);

  const {
    data: withdrawData,
    write: withdraw,
    isLoading: isLoadingWithdrawTransaction,
  } = useContractWrite({
    ...(withdrawConfig as any),
    onError(error) {
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isLoadingWithdrawTransactionAfter } = useWaitForTransaction({
    hash: withdrawData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }

      startConfettiAnimation();

      alertUser(
        'success',
        'Liquidity removed successful!',
        getExplorerLink(vault.chainId || 137, withdrawData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('withdraw v2', {
        type: 'click',
        feature: 'vault withdraw form v2',
        wallet: ownAddress as string,
        vault: vault.id,
        value: (Number(userBalanceUsd) * withdrawAmount) / 100,
      });

      setWithdrawFormWrapperState(WithdrawFormWrapperState.WITHDRAW_SUCCESS);

      addRecentTransaction({
        hash: withdrawData?.hash ?? '',
        description: 'Withdraw',
      });
    },
  });

  async function createSignature() {
    try {
      setIsSignatureLoading(true);
      const payload = SignatureTransfer.getPermitData(
        {
          permitted: {
            token: vault.id,
            amount: burnAmount,
          },
          spender: ARRAKIS_V2_ROUTER_ADDRESSES,
          nonce: nonce,
          deadline: deadlineIn30DaysHex,
        },
        PERMIT_2_ADDRESS,
        vault.chainId,
      );

      const values = {
        deadline: deadlineIn30DaysHex,
        nonce: '0x' + bigintConversion.bigintToHex(BigInt(nonce)),
        permitted: {
          token: vault.gauge?.address ? vault.gauge.address : (vault.id as '0x${string}'),
          amount: '0x' + bigintConversion.bigintToHex(burnAmount),
        },
        spender: ARRAKIS_V2_ROUTER_ADDRESSES,
      } as const;

      const signature = await signTypedData({
        // @ts-ignore
        domain: payload.domain,
        types: payload.types,
        primaryType: 'PermitTransferFrom',
        message: values,
      });

      setSignature(signature);
    } catch (error: any) {
      console.log('error', error.message);
      setIsSignatureLoading(false);
      throw error;
    }
  }

  // update removeLiquidity params whenever needed
  useEffect(() => {
    const updateRemoveLiquidityParams = async () => {
      if (status === Status.WITHDRAW && signature) {
        const removeLiquidityPermit2Data = [
          {
            removeData: {
              burnAmount: burnAmount,
              amount0Min,
              amount1Min,
              vault: vault.id,
              receiver: ownAddress,
              gauge: vault.gauge?.address || '0x0000000000000000000000000000000000000000',
              receiveETH: useNativeToken ? true : false,
            },
            permit: {
              deadline: deadlineIn30DaysHex,
              nonce: BigInt(nonce),
              permitted: { token: vault.gauge?.address ? vault.gauge.address : vault.id, amount: burnAmount },
            },
            signature,
          },
        ];

        setRemoveLiquidityArgs(removeLiquidityPermit2Data);
      } else {
        setRemoveLiquidityArgs([BigInt(0)]);
      }
    };
    updateRemoveLiquidityParams();
  }, [
    allowance,
    vault,
    status,
    signature,
    burnAmount,
    amount0Min,
    amount1Min,
    nonce,
    ownAddress,
    userBalance,
    withdrawAmount,
  ]);

  // set signature loading to false if native toggle is changed or if input values change
  useEffect(() => {
    setIsSignatureLoading(false);
    setSignature(null);
  }, [useNativeToken, withdrawAmount]);

  const symbol0 = useNativeToken
    ? nativeSymbol0?.toUpperCase() ?? vault.symbol0?.toUpperCase()
    : vault.symbol0?.toUpperCase();
  const symbol1 = useNativeToken
    ? nativeSymbol1?.toUpperCase() ?? vault.symbol1?.toUpperCase()
    : vault.symbol1?.toUpperCase();

  function onWithdraw() {
    if (withdraw) {
      setUsdToBeRemoved((Number(userBalanceUsd) * withdrawAmount) / 100);
      withdraw();
    }

    if (isSafeWallet) {
      setWithdrawFormWrapperState(WithdrawFormWrapperState.SAFE_APP_WITHDRAW);
    }
  }

  function onApproveWithdraw() {
    if (isSafeWallet) {
      setLoadingSafeWallet(true);
    }
    approveWithdraw?.();
  }
  return (
    <WithdrawWrapper isLoading={!underlyingBalances} usdValue={usdToBeRemoved} state={withdrawFormWrapperState}>
      <div className="flex md:items-center text-[20px] md:text-[24px] justify-between my-3 flex-col md:flex-row">
        <div>
          <div className="text-vault-light">Amount to remove: {withdrawAmount}%</div>
          <div className="text-vault-light">
            ${numberWithCommas(((Number(userBalanceUsd) * withdrawAmount) / 100).toFixed(2))}
          </div>
        </div>
        <div>
          {' '}
          <div className="text-vault-gray-hover md:text-right">
            Position after remove: $
            {numberWithCommas((Number(userBalanceUsd) - (Number(userBalanceUsd) * withdrawAmount) / 100).toFixed(2))}
          </div>
          <div className="text-vault-gray-hover md:text-right">
            Current position: ${numberWithCommas(userBalanceUsd)}
          </div>
        </div>
      </div>

      <RangeSlider
        id="sm-range"
        sizing="sm"
        color="red"
        onChange={(e) => setwithdrawAmount(Number(e.target.value))}
        defaultValue={0}
      />
      <div className="flex items-center justify-between text-vault-gray-hover text-sm mt-5 ">
        <div>0%</div>
        <div>25%</div>
        <div>50%</div>
        <div>75%</div>
        <div>100%</div>
      </div>
      {hasNativeToken && (
        <div className="flex items-center justify-end mt-5 gap-2">
          <div>Use {(nativeSymbol0 || nativeSymbol1)?.toUpperCase()}</div>
          <Toggle
            id="native-token-toggle-withdraw"
            checked={useNativeToken}
            onChange={() => setUseNativeToken((val) => !val)}
          />
        </div>
      )}
      <WithdrawResult
        vault={vault}
        claimableRewards={claimableRewards}
        receivableAmount0String={receivableAmount0String}
        receivableAmount1String={receivableAmount1String}
      />
      {status === Status.APPROVE && (
        <div className="mb-5 mt-10 flex gap-3 items-center">
          <button
            disabled={
              withdrawAmount === 0 || isLoadingApproveWithdraw || isLoadingApproveWithdrawAfter || !approveWithdraw
            }
            className={`py-2 grow  rounded-[12px] text-[20px] h-[64px] ${
              withdrawAmount > 0 ? 'bg-vault-light-gray text-vault-black' : 'bg-vault-gray-2 text-vault-gray-hover'
            }`}
            onClick={onApproveWithdraw}
          >
            <div className="flex items-center justify-center gap-3">
              Approve {symbol0?.toUpperCase()} - {symbol1?.toUpperCase()}
              {(isLoadingApproveWithdraw || isLoadingApproveWithdrawAfter || isLoadingSafeWallet) && (
                <span className="mx-2">
                  <Spinner color="vault-black" />{' '}
                </span>
              )}
            </div>
          </button>
          <button className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]">
            2
          </button>
          <button className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]">
            3
          </button>
        </div>
      )}
      {status === Status.SIGN && (
        <div className="mb-5 mt-10 flex gap-3 items-center">
          <button className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center">
            <img src={CheckIcon} alt="check icon" />
          </button>
          <button
            type="button"
            onClick={() => createSignature()}
            className="flex flex-row justify-center items-center  rounded-lg h-[64px] p-2 grow bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary"
          >
            <div className="flex items-center gap-3">Sign</div>

            {isSignatureLoading && (
              <span className="mx-2">
                <Spinner color="vault-white" />
              </span>
            )}
          </button>
          <button className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]">
            3
          </button>
        </div>
      )}
      {status === Status.WITHDRAW && (
        <div className="mb-5 mt-10 flex gap-3 items-center">
          <button className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center">
            <img src={CheckIcon} alt="check icon" />
          </button>
          <button className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center">
            <img src={CheckIcon} alt="check icon" />
          </button>
          <button
            disabled={
              withdrawAmount === 0 || isLoadingWithdrawTransaction || isLoadingWithdrawTransactionAfter || !withdraw
            }
            className={`py-2 grow  rounded-[12px] text-[20px] h-[64px] ${
              withdrawAmount > 0 ? 'bg-vault-light-gray text-vault-black' : 'bg-vault-gray-2 text-vault-gray-hover'
            }`}
            onClick={() => onWithdraw()}
          >
            <div className="flex items-center justify-center gap-3">
              Remove liquidity
              {(isLoadingWithdrawTransaction || isLoadingWithdrawTransactionAfter) && (
                <span className="mx-2">
                  <Spinner color="vault-black" />{' '}
                </span>
              )}
            </div>
          </button>
        </div>
      )}
    </WithdrawWrapper>
  );
}

export default WithDrawFormV2;
