import React from 'react';
import Skeleton from 'react-loading-skeleton';

function DepositFormSkeleton() {
  return (
    <div className="rounded-lg w-482 px-4 py-5 bg-vault-gray-2 flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <Skeleton circle height="20px" width="20px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
        </div>
        <Skeleton height="40px" width="350px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
      </div>
      <div className="flex flex-row justify-between">
        <div>
          <div>
            <Skeleton height="20px" width="200px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton height="20px" width="200px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositFormSkeleton;
