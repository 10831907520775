import React from 'react';

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {}

const SecondaryButton: React.FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <button
      {...rest}
      type="button"
      className="bg-additional-grey text-vault-orange text-[16px] px-[16px] py-[14px] rounded-[12px]"
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
