import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { PerformanceResponseSchema } from '../../types/charts/performance';
import { getPerformanceChartOptions } from '../../utils/charts/performance';
import ChartLoader from './ChartLoader';
import NoData from './NoData';

interface PerformanceChartProps {
  vaultId: string;
}

function PerformanceChart({ vaultId }: PerformanceChartProps) {
  async function fetchPerformance(vaultId: string) {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/analytics/v2/performance/${vaultId}`);

      const parsedData = PerformanceResponseSchema.safeParse(response.data);

      if (!parsedData.success) {
        return undefined;
      }

      return parsedData.data.data;
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  }

  const { data: performanceData, isLoading } = useQuery(['vault-performance', vaultId], async () => {
    return await fetchPerformance(vaultId);
  });

  const options = performanceData ? getPerformanceChartOptions(performanceData) : {};

  return (
    <div className="flex items-center gap-5">
      <div className="grow">
        {isLoading && <ChartLoader />}
        {performanceData && options.series && (
          <ReactApexChart options={options} series={options.series} height={380} type="line" />
        )}
        {!performanceData && !isLoading && <NoData />}
      </div>
      {/*  <div className="flex flex-col gap-4">
      <ChartStat title="Value of the Generated Fees" value="$179.25k" />
      <ChartStat title="Generated USDC Fees" value="26.69 wETH" />
      <ChartStat title="Generated GEL Fees" value="266,832.00 GEL" />
    </div> */}
    </div>
  );
}

export default PerformanceChart;
