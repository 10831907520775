import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { useEffect, useState } from 'react';
import { erc20ABI, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { alertUser } from '../components/elements/notify';
import { ExplorerDataType, getExplorerLink } from '../utils/get-explorer-link';

interface TokenApproveHookProps {
  grantedContractAddress: `0x${string}`;
  allowanceAmount: bigint;
  tokenAddress: `0x${string}`;
  chainId: number;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

interface TokenApproveHookResult {
  isLoadingApprove: boolean;
  approve: (() => void) | undefined;
}

const useTokenApproval = ({
  grantedContractAddress,
  allowanceAmount,
  tokenAddress,
  chainId,
  onSuccess,
  onError,
}: TokenApproveHookProps): TokenApproveHookResult => {
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingApproveAfter, setIsLoadingApproveAfter] = useState(false);

  const addRecentTransaction = useAddRecentTransaction();

  const tokenContract = {
    address: tokenAddress,
    abi: erc20ABI,
  };

  const { config: approveTokenConfig } = usePrepareContractWrite({
    ...tokenContract,
    functionName: 'approve',
    args: [grantedContractAddress, allowanceAmount],
    chainId,
    value: BigInt(0) as any,
  });

  const {
    data: approveData,
    write: approve,
    isLoading: isLoadingApproveData,
  } = useContractWrite({
    ...approveTokenConfig,
    onError(error: unknown) {
      console.log('error', error);
    },
  });

  const { isLoading: isLoadingApproveAfterData } = useWaitForTransaction({
    hash: approveData?.hash,
    onSettled(data: unknown, error: unknown) {
      setIsLoadingApproveAfter(false);
      if (error) {
        console.log('error', error);
        onError?.(error);
        return;
      }
      onSuccess?.();

      alertUser(
        'success',
        'Transaction successful!',
        getExplorerLink(chainId || 1, approveData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        false,
      );

      addRecentTransaction({
        hash: approveData?.hash ?? '',
        description: 'Approve token',
      });
    },
  });

  useEffect(() => {
    setIsLoadingApprove(isLoadingApproveData);
  }, [isLoadingApproveData]);

  useEffect(() => {
    setIsLoadingApproveAfter(isLoadingApproveAfterData);
  }, [isLoadingApproveAfterData]);

  return {
    approve,
    isLoadingApprove: isLoadingApprove || isLoadingApproveAfter,
  };
};

export default useTokenApproval;
