import { track } from '@vercel/analytics/react';
import { useAccount } from 'wagmi';
import { TvlChartSection } from '../../../types/charts/tvl';

interface V2SectionsProps {
  activeSection: string;
  setActiveSection: (section: TvlChartSection) => void;
  vaultId: string;
}

function V2Sections({ activeSection, setActiveSection, vaultId }: V2SectionsProps) {
  const { address: ownAddress } = useAccount();

  return (
    <div className="flex items-center flex-wrap gap-2 mb-6">
      <button
        onClick={() => {
          track('click active section composition', {
            feature: 'vault tvl chart',
            type: 'click',
            wallet: ownAddress as string,
            vaultId: vaultId,
          });
          setActiveSection(TvlChartSection.COMPOSITION);
        }}
        className={`border  rounded-[16px] px-[12px] py-[6px]  ${
          activeSection === TvlChartSection.COMPOSITION
            ? 'bg-vault-light-gray text-vault-black-primary border border-vault-light.gray'
            : 'border-vault-gray-hover text-vault-gray-hover hover:text-vault-white hover:border-vault-white duration-300'
        }`}
      >
        Composition
      </button>

      <button
        onClick={() => {
          track('click active section about', {
            feature: 'vault tvl chart',
            type: 'click',
            wallet: ownAddress as string,
            vaultId: vaultId,
          });

          setActiveSection(TvlChartSection.ABOUT);
        }}
        className={`border  rounded-[16px] px-[12px] py-[6px]  ${
          activeSection === TvlChartSection.ABOUT
            ? 'bg-vault-light-gray text-vault-black-primary border border-vault-light.gray'
            : 'border-vault-gray-hover text-vault-gray-hover hover:text-vault-white hover:border-vault-white duration-300'
        }`}
      >
        About
      </button>

      <button
        onClick={() => {
          track('click active section risks', {
            feature: 'vault tvl chart',
            type: 'click',
            wallet: ownAddress as string,
            vaultId: vaultId,
          });

          setActiveSection(TvlChartSection.RISKS);
        }}
        className={`border  rounded-[16px] px-[12px] py-[6px]  ${
          activeSection === TvlChartSection.RISKS
            ? 'bg-vault-light-gray text-vault-black-primary border border-vault-light.gray'
            : 'border-vault-gray-hover text-vault-gray-hover hover:text-vault-white hover:border-vault-white duration-300'
        }`}
      >
        Risks
      </button>
    </div>
  );
}

export default V2Sections;
