function VaultTypeExplanation() {
  return (
    <div>
      <img
        src="/assets/images/abstract/vault-lock.png"
        className="rounded-xl w-full object-cover max-h-[300px] mb-5"
        alt="network"
      />

      <h2 className="text-lg font-bold mb-1">What are Vault Types?</h2>
      <p>
        An Arrakis Vault is either public or private. Public vaults are open for everyone which means that anyone can
        deposit into them. Private vaults are only open for the creator of the vault.
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">Why can I only select a private vault?</h2>
      <p>At the moment our self service vaults are only private. Public vaults will be available soon!</p>
    </div>
  );
}

export default VaultTypeExplanation;
