import { isAddress } from 'viem';
import { useToken } from 'wagmi';
import { Token, TokenSchema, getUnknownToken } from '../../../../utils/tokens';

interface CustomTokenSelectorProps {
  onTokenSelect: (token: Token) => void;
  tokenAddress: string;
  chainId: number;
}

function CustomTokenSelector({ onTokenSelect, tokenAddress, chainId }: CustomTokenSelectorProps) {
  const { data } = useToken({
    address: tokenAddress as '0x${string}',
    chainId,
    enabled: isAddress(tokenAddress),
    cacheTime: 10000,
  });

  function onUseToken() {
    const parsedToken = TokenSchema.safeParse(data);

    if (parsedToken.success) {
      onTokenSelect({
        ...parsedToken.data,
        logoURI: parsedToken.data.logoURI || '/assets/images/question-mark.svg',
        chainId: parsedToken.data.chainId || chainId,
      });
    } else {
      onTokenSelect(getUnknownToken(chainId, tokenAddress));
    }
  }

  return (
    <button
      onClick={onUseToken}
      type="button"
      className="rounded-xl ring-1 ring-vault-orange/50 hover:ring-vault-orange duration-300 px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
    >
      Use this address
    </button>
  );
}

export default CustomTokenSelector;
