import { IVault } from '../../../types';
import { Spinner } from '../spinner';
import IconUp from '/assets/images/icon-up.svg';

interface SwitchNetworkButtonProps {
  switchNetwork?: (chainId: number) => void;
  isLoading?: boolean;
  chainId: IVault['chainId'];
  text?: string;
}

function SwitchNetworkButton({ switchNetwork, isLoading, chainId, text = 'Change Network' }: SwitchNetworkButtonProps) {
  return (
    <button
      disabled={!switchNetwork}
      onClick={() => switchNetwork?.(chainId)}
      className="py-2 px-6 mt-4 grow  rounded-[12px] text-[20px] h-[64px] bg-vault-gray-2 text-vault-orange"
    >
      <div className="flex items-center justify-center gap-2 text-bg-gradient-orange">
        <img src={IconUp} alt="icon up" />
        {text}
        {isLoading && (
          <span className="mx-2">
            <Spinner color="vault-black" />{' '}
          </span>
        )}
      </div>
    </button>
  );
}

export default SwitchNetworkButton;
