import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div id="footer" className="text-white mt-28 mb-28 flex items-center gap-4">
      <Link
        to="/disclaimer"
        className="text-vault-gray text-[16px] tracking-[-0.01em] 
"
      >
        Disclaimer
      </Link>
      <Link
        to="/privacy-policy"
        className="text-vault-gray text-[16px] tracking-[-0.01em] 
"
      >
        Privacy Policy
      </Link>
    </div>
  );
}

export default Footer;
